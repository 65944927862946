/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Brandon's Portfolio",
  description:
    "An avid cybersecurity specialist focused on developing end-to-end solutions that enhance system security and counter malware threats, aiming for impactful and sustainable technological advancements.",
  og: {
    title: "Brandon Russell's Portfolio",
    type: "website",
    url: "https://brandon-russell-1.github.io/",
  },
};

//Home Page
const greeting = {
  title: "Brandon Russell",
  logo_name: "Brandon Russell",
  nickname: "Bwiz",
  subTitle:
    "An avid cybersecurity specialist focused on developing end-to-end solutions that enhance system security and counter malware threats, aiming for impactful and sustainable technological advancements.",
  resumeLink:
    "https://drive.google.com/file/d/1c_-DnttreCStetBTIDj1giQElehAuBtq/view?usp=sharing",
  portfolio_repository: "https://github.com/Brandon-Russell-1",
  githubProfile: "https://github.com/Brandon-Russell-1",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/Brandon-Russell-1",
  // linkedin: "www.linkedin.com/in/brandon-r-russell",
  // gmail: "brandon.r.russell.1@gmail.com",


  {
    name: "Github",
    link: "https://github.com/Brandon-Russell-1",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/brandon-r-russell",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:brandon.r.russell.1@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "Software Development",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Developing robust, production-ready full-stack solutions tailored for military operation scenarios, ensuring high scalability and reliability across diverse deep learning and statistical applications",
        "⚡ Demonstrates expertise in developing advanced tools and applications using Rust, Python, C#, and Nim, highlighting a versatile programming skill set aimed at optimizing operational efficiencies and enhancing functionality across various environments",
        
      ],
      softwareSkills: [
        {
          skillName: "Java",
          fontAwesomeClassname: "logos:java",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "C#",
          fontAwesomeClassname: "devicon:csharp",
          style: {
            backgroundColor: "white",
            color: "#D00000",
          },
        },
        {
          skillName: "Nim",
          fontAwesomeClassname: "logos-nim-lang",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "ion-logo-python",
          style: {
            backgroundColor: "transparent",
            color: "#3776AB",
          },
        },
        {
          skillName: "Rust",
          fontAwesomeClassname: "logos:rust",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Offensive Cyber",
      fileName: "FullStackImg",
      skills: [
        "⚡ Demonstrates exceptional offensive cyber skills, utilizing advanced penetration tools and reverse engineering techniques to identify, exploit, and mitigate vulnerabilities in complex systems, ensuring the highest level of security and resilience against cyber threats",
        "⚡ Exhibits profound expertise in conducting cyber risk assessments and vulnerability assessments, adept at identifying critical system weaknesses and leveraging exploitation techniques to enhance security postures and mitigate potential threats effectively",
        
      ],
      softwareSkills: [
        {
          skillName: "nmap",
          fontAwesomeClassname: "file-icons:nmap",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Metasploit",
          fontAwesomeClassname: "simple-icons:metasploit",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Vulnerability Assessment",
          fontAwesomeClassname: "grommet-icons:vulnerability",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "Reverse Engineering Techniques",
          fontAwesomeClassname: "flat-color-icons:engineering",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Advanced Penetration Testing",
          fontAwesomeClassname: "icon-park-outline:new-computer",
          style: {
            color: "#61DAFB",
          },
        },

      ],
    },
    {
      title: "Cybersecurity",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Exhibits exceptional cybersecurity prowess, leading teams in implementing robust security measures, managing incident response, and ensuring compliance with industry standards, thereby significantly enhancing the security infrastructure and resilience of critical systems against cyber threats",
        "⚡ Employs advanced cybersecurity strategies and technologies to safeguard sensitive information and infrastructure, skillfully mitigating risks and responding to emerging threats, demonstrating a proactive approach to maintaining organizational security and integrity",
      
      ],
      softwareSkills: [
        {
          skillName: "Security Auditing",
          fontAwesomeClassname: "mdi:firewall",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Intrusion Detection and Response",
          fontAwesomeClassname: "arcticons:changedetection",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Cyber Risk Assessment",
          fontAwesomeClassname: "grommet-icons:risk",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Incident Management",
          fontAwesomeClassname: "ant-design:project-filled",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Network Security",
          fontAwesomeClassname: "mdi:network",
          style: {
            color: "#336791",
          },
        },

      ],
    },
    {
      title: "Data Science",
      fileName: "DesignImg",
      skills: [
        "⚡ Demonstrates superior skills in database development, expertly designing, implementing, and optimizing relational and non-relational databases to ensure efficient data storage, retrieval, and integrity, significantly boosting organizational data management capabilities and performance",
        "⚡ Leverages advanced data analytics techniques to extract meaningful insights from complex datasets, employing statistical models and machine learning algorithms to inform strategic decisions and drive significant improvements in business outcomes",
        
      ],
      softwareSkills: [
        {
          skillName: "Machine Learning",
          fontAwesomeClassname: "eos-icons:machine-learning",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "NoSQL Databases",
          fontAwesomeClassname: "skill-icons:mongodb",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "SQL Programming",
          fontAwesomeClassname: "tabler:file-type-sql",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Big Data Technologies",
          fontAwesomeClassname: "eos-icons:big-data",
          style: {
            color: "#000000",
          },
        },        
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "TS/SCI Clearance",
      iconifyClassname: "material-symbols:security",
      style: {
        color: "#2EC866",
      },
      //profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Hack the Box",
      iconifyClassname: "simple-icons:hackthebox",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://app.hackthebox.com/users/1759559",
    },

    
  ],
};

const degrees = {
  degrees: [
    {
      title: "Liberty University",
      subtitle: "Doctor of Education (ED.D) in Educational Leadership",
      logo_path: "LU.png",
      alt_name: "LU",
      duration: "2021 - 2022",
      descriptions: [
        "⚡ Mastered advanced leadership theories and educational practices, focusing on enhancing organizational effectiveness and championing educational innovation to foster environments conducive to learning and growth.",
        "⚡ Specialized in developing strategic initiatives to improve diversity and inclusion within educational settings, leveraging research and analytical skills to address complex challenges and drive meaningful change.",
        
      ],
      website_link: "https://www.liberty.edu/",
    },
    {
      title: "University of Maryland Global Campus",
      subtitle: "M.S. in Information Technology",
      logo_path: "UMGC.png",
      alt_name: "UMGC",
      duration: "2012 - 2019",
      descriptions: [
        "⚡ Acquired in-depth knowledge of database design, administration, and security, focusing on the creation and management of robust, scalable database systems critical for organizational data integrity and accessibility.",
        "⚡ Enhanced technical proficiency in software development and IT project management, employing advanced tools and methodologies to drive efficiency and innovation in technology-driven environments.",
        
      ],
      website_link: "https://www.umgc.edu/",
    },
    {
      title: "University of Maryland Global Campus",
      subtitle: "B.S. in Computer Studies",
      logo_path: "UMGC.png",
      alt_name: "UMGC",
      duration: "2005 - 2010",
      descriptions: [
        "⚡ Developed a comprehensive foundation in computer science principles, software development, and system analysis, focusing on applying technology solutions to solve real-world problems efficiently.",
        "⚡ Emphasized on cybersecurity measures and information assurance, preparing for a dynamic career in protecting digital assets and ensuring the reliability and security of computing environments.",
        
      ],
      website_link: "https://www.umgc.edu/",
    },
    {
      title: "Community College of the Air Force",
      subtitle: "Associate of Applied Science (A.A.S.) in Instructor of Technology & Military Science",
      logo_path: "CCAF.png",
      alt_name: "CCAF",
      duration: "2013 - 2015",
      descriptions: [
        "⚡ Gained expertise in educational methodologies and instructional design, focusing on the development and delivery of technology and military science curriculum to diverse learner populations.",
        "⚡ Emphasized leadership in educational settings, preparing for roles that require guiding, mentoring, and inspiring students towards academic and professional excellence.",
        
      ],
      website_link: "https://www.airuniversity.af.edu/Barnes/CCAF/",
    },        
    {
      title: "Community College of the Air Force",
      subtitle: "Associate of Applied Science (A.A.S.) in Aviation Maintenance Technology",
      logo_path: "CCAF.png",
      alt_name: "CCAF",
      duration: "2012",
      descriptions: [
        "⚡ Focused on the technical aspects of aviation maintenance and repair, ensuring a solid understanding of aircraft systems, diagnostics, and preventative maintenance practices.",
        "⚡ Prepared for a career in aviation technology with a strong emphasis on safety, precision, and regulatory compliance, essential for maintaining the integrity and reliability of aviation operations.",
        
      ],
      website_link: "https://www.airuniversity.af.edu/Barnes/CCAF/",
    },      
  ],
};

const certifications = {
  certifications: [
    {
      title: "OSCP",
      //subtitle: "- Jogesh Muppala",
      logo_path: "OSCP_Img.png",
      certificate_link:
        "https://www.credential.net/7dc376f1-55d7-4384-9076-19d641aef64b",
      alt_name: "OSCP",
      color_code: "#2A73CC",
    },
    {
      title: "CISSP",
      //subtitle: "- Andrew Ng",
      //iconifyClassname: "simple-icons:isc2",
      logo_path: "CISSP.png",
      certificate_link:
        "https://www.credly.com/badges/846da664-42cd-406b-8f40-5a6f7040f666/linked_in_profile",
      alt_name: "CISSP",
      color_code: "#8C151599",
    },
    {
      title: "CompTIA Security+ ce Certification",
      //subtitle: "- Andrew Ng",
      logo_path: "CompTiaSecPlus.png",
      certificate_link:
        "https://www.credly.com/badges/710e1c08-96cb-4739-97c0-587510162479/linked_in_profile",
      alt_name: "CompTIA",
      color_code: "#00000099",
    },
    {
      title: "LINALGxI: Linear Algebra I: Linear Equations",
      //subtitle: "- GCP Training",
      logo_path: "edxcourses.png",
      certificate_link:
        "https://courses.edx.org/certificates/b3eae8dc2a894e4487fb72e9747f9a30",
      alt_name: "LINALGxI",
      color_code: "#0C9D5899",
    },
    {
      title: "LINALGxII: Linear Algebra II: Matrix Algebra",
      //subtitle: "- Alex Aklson",
      logo_path: "edxcourses.png",
      certificate_link:
        "https://courses.edx.org/certificates/d4c3681e11e344afb0cc066aa3bfcebc",
      alt_name: "LINALGxII",
      color_code: "#1F70C199",
    },
    {
      title: "[PCEP-30-01] PCEP – Certified Entry-Level Python Programmer",
      //subtitle: "- Kim Akers",
      logo_path: "pythoncert.png",
      certificate_link:
        "https://www.credly.com/badges/bcbb41b6-98a1-4766-bcd2-73039da02d58/linked_in_profile",
      alt_name: "PCEP",
      color_code: "#D83B0199",
    },
    {
      title: "Professional Manager Certification",
      //subtitle: "- Romeo Kienzler",
      logo_path: "CCAF.png",
      certificate_link:
        "https://www.linkedin.com/school/community-college-of-the-air-force/",
      alt_name: "CCAF",
      color_code: "#1F70C199",
    },
    {
      title: "CCAF Master Instructor",
      //subtitle: "- GCP Training",
      logo_path: "CCAF.png",
      certificate_link:
        "https://www.linkedin.com/school/community-college-of-the-air-force/",
      alt_name: "CCAF",
      color_code: "#0C9D5899",
    },
    {
      title: "Part 107 Small Unmanned Aircraft Systems License",
      //subtitle: "- Laurence Moroney",
      logo_path: "FAA.jpg",
      certificate_link:
        "https://www.linkedin.com/company/faa/",
      alt_name: "FAA",
      color_code: "#00000099",
    },    
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  //subtitle: "Work, Internship and Volunteership",
  description:
    "Expertly spearheads cutting-edge cybersecurity, software development, and educational leadership initiatives within the U.S. Air Force, leveraging a dynamic blend of technical prowess and strategic foresight.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Cyber Master Instructor",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "May 2024 - Present",
          location: "Biloxi, MS",
          description:
            "Instructs at the Undergraduate Cyber Warfare Technical Training Schoolhouse, playing a crucial role in the development and readiness of thousands of new officers annually. Specializes in instructing cybersecurity principles and practices, preparing officers to combat and mitigate emerging cyber threats effectively. Focuses on delivering comprehensive technical training coupled with practical, scenario-based exercises, ensuring that graduates are proficient in advanced cybersecurity techniques and equipped to handle the strategic challenges associated with cyber warfare. This position underscores a commitment to fostering resilience and adaptability, essential traits for future leaders in the cyber defense arena.",
          color: "#0879bf",
        },        
        {
          title: "Recruiting Program Manager",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "Jun 2021 - May 2024",
          location: "San Antonio, TX",
          description:
            "Oversees an extensive network encompassing 58 flying wings and supervises a cadre of 700 rated officer mentors. The position is pivotal in shaping the strategic direction and operational execution of the AIM Wing Program, with a special focus on the AIM High Flight Academy. Responsibilities include providing critical leadership and guidance to mentors, directing their efforts in mentoring high school students in aviation. This involves ensuring that the program aligns with the broader objectives of the Air Force and fostering a robust mentorship environment that inspires and educates the next generation of aviators. The role spans coordinating large-scale mentorship initiatives, ensuring impactful youth engagement, and maintaining the high standards of the program in line with the Air Force's mission and goals.",
          color: "#0879bf",
        },
        {
          title: "Senior Duty Officer, Cyber Operations",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "Sep 2020 - Jun 2021",
          location: "San Antonio, TX",
          description:
            "Led 103 personnel in providing Command and Control of 10K cyberspace forces supporting 9 Combatant Commands, 11 Major Commands & 3 Geographically Separated Units. Interfaces w/joint cyber forces across DoD to ensure comm interoperability to achieve national/joint/AF objectives. Directed defensive cyber ops & msn assurance activities to secure vital operational networks for 638K joint warfighters. Integrated/deconflicted kinetic & non-kinetic strike capabilities to achieve unified global effects supporting Combatant Commander priorities.",
          color: "#9b1578",
        },
        {
          title: "Flight Commander, Cyber Operations",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "May 2017 - Sep 2020",
          location: "Charleston, SC",
          description:
            "Led a 51 Joint Air Force/Navy flight, maintaining the security and operation of a $54 Million dollar cyber weapon system for 3 Wings and 67 mission partners. Managed 80 Defense Information System Network circuits and 120 servers for 15 sites. Oversaw a $9.5 Million dollar radio network and a $1.2 Million base loudspeaker system.",
          color: "#fc1f20",
        },
        {
          title: "Cyber Officer Student",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "Jul 2016 - May 2017",
          location: "Biloxi, MS",
          description:
            "A 920-hour training course, providing fundamental cyber officer curriculum, to include: Operating Systems, Scripting, Networking, Cyber Attack & Exploitation, Network Structure and Defense, Industrial Control Systems, Telephony, Strategic Network Warfare, and DoD 8570.1M.",
          color: "#fc1f20",
        },
        {
          title: "Supervisor & Master Instructor",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "Oct 2011 - Jul 2016",
          location: "Warner Robins, GA",
          description:
            "Supervised 7 AETC instructors during formal instructional maintenance training. Instructed and developed courses for the E-8C Joint Surveillance Target Attack Radar System (JSTARS). Coordinated professional projects, supervised teaching methods, and education requirements. Provided 53 courses to 4 different wings.",
          color: "#fc1f20",
        },
        {
          title: "Information Assurance Officer",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "Jun 2010 - Oct 2011",
          location: "Lajes Field, Azores",
          description:
            "Oversaw IT/communications support for the Operations Support Squadron. Managed network security and identity/access management for 100 personnel. Additionally, managed asset classification and security controls on over 180 IT items. ",
          color: "#fc1f20",
        },
        {
          title: "Database Developer",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "Feb 2009 - Jun 2010",
          location: "Rota, Spain",
          description:
            "Developed and administered database systems for C-5/C-17 aircraft and supply operations. Managed secure coding guidelines while implementing a Visual Basic .Net front-end application for a 100+ user base. Designed and created a Microsoft SQL Server backend database for the storage and analysis of data supporting 10K aircraft sorties annually and $29 Million in assets. Managed database disaster recovery and data backup solutions.",
          color: "#fc1f20",
        },
        {
          title: "Aircraft Electrical and Environmental Technician",
          company: "United States Air Force",
          company_url: "https://www.airforce.com/",
          logo_path: "AFlogo.png",
          duration: "Jun 2001 - Feb 2009",
          location: "Rota, Spain",
          description:
            "Supervised and performed maintenance on C-5 and C-17 Electrical and Environmental systems. Inspected and troubleshot wiring and air system malfunctions. Managed the liquid oxygen cart fleet, ensuring available oxygen for over 200 aircraft.",
          color: "#fc1f20",
        },                                        
      ],
    },
    /*{
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },*/
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to develop malware countermeasures, expertly blending analytical rigor with innovative software solutions to fortify cybersecurity defenses.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "The United States Air Force pilot diversity dilemma",
      createdAt: "2023-02-28T16:26:54Z",
      description: "The United States Air Force pilot diversity dilemma",
      url:
        "https://www.emerald.com/insight/content/doi/10.1108/EDI-10-2022-0297/full/html",
    },
    /*{
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },*/
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "Brandon.jpg",
    description:
      "Got a question or just want to say hello? Feel free to shoot me a message anytime. I'm here to help and will get back to you as soon as possible with the answers or conversation you're looking for!",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "Take a moment to check out my blog - you won't regret it!",
    link: "https://medium.com/@drbwiz",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    //subtitle:
    //  "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    //locality: "Kanodar",
    country: "US",
    region: "San Antonio, TX",
    //postalCode: "385520",
    //streetAddress: "Ambavadi vas",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://www.google.com/maps/place/Biloxi,+MS/@30.426589,-88.9667928,13z/data=!3m1!4b1!4m6!3m5!1s0x889c0e5c20b742d7:0xc47a6051136f6842!8m2!3d30.3960318!4d-88.8853078!16zL20vMHdxMzY?entry=ttu",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
