import React, { Component } from "react";

class FeelingProud extends Component {
  render() {
    const theme = this.props.theme;
    return (
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="100%" viewBox="0 0 1024 1024" enable-background="new 0 0 1024 1024" xmlSpace="preserve">
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M1.000000,395.000000 
	C1.000000,263.333344 1.000000,132.166702 1.000000,1.000035 
	C342.333282,1.000023 683.666565,1.000023 1025.000000,1.000012 
	C1025.000000,342.333252 1025.000000,683.666504 1025.000000,1024.999756 
	C683.666809,1024.999878 342.333618,1024.999878 1.000207,1025.000000 
	C1.000000,815.166687 1.000000,605.333313 1.000000,395.000000 
M651.997925,125.999977 
	C651.997925,125.999977 652.009094,126.017097 651.469971,126.301224 
	C650.673035,127.210266 649.876099,128.119308 648.482239,129.227386 
	C647.672791,129.881210 646.863281,130.535049 645.242493,131.332169 
	C642.159607,131.698410 639.076660,132.064651 635.258667,132.179413 
	C633.502014,131.796631 631.745422,131.413849 629.452576,130.586227 
	C627.047485,129.779556 624.642456,128.972900 621.431885,127.896088 
	C622.127197,131.220032 622.622314,133.587006 623.136719,136.656479 
	C623.277161,137.101685 623.417603,137.546890 623.353149,138.791840 
	C623.979614,142.830994 624.606079,146.870132 625.258789,151.641266 
	C625.464722,152.079605 625.670654,152.517960 625.729370,153.613876 
	C626.440125,155.546112 627.150818,157.478333 627.827698,159.746078 
	C627.827698,159.746078 628.040833,160.007492 628.090942,160.759918 
	C629.983276,166.540665 631.547974,172.459854 633.906982,178.043427 
	C635.078003,180.815109 637.708252,182.970276 639.640808,185.405090 
	C639.640808,185.405090 639.646362,185.442291 639.930542,185.994171 
	C641.286926,186.996277 642.643372,187.998367 644.232605,189.449722 
	C644.666809,189.687653 645.101074,189.925583 645.874878,190.692200 
	C647.243713,191.111008 648.612549,191.529831 650.695618,191.972565 
	C650.933472,191.958633 651.171265,191.944717 651.485901,192.874344 
	C651.486145,206.669968 651.395874,220.466843 651.586060,234.259857 
	C651.613953,236.282715 652.975281,238.287170 653.789734,240.211884 
	C653.789734,240.211884 653.677917,240.198105 653.814941,240.747116 
	C654.222717,241.125488 654.630493,241.503860 655.265198,242.513336 
	C657.510132,244.679352 659.755005,246.845367 662.146179,249.500092 
	C662.423157,249.679886 662.700134,249.859695 663.165649,250.480713 
	C663.356140,250.755493 663.612122,250.938904 664.015381,251.756653 
	C662.962830,253.097031 661.943298,254.465057 660.849670,255.771072 
	C659.317444,257.600891 657.730103,259.384583 655.484863,261.029327 
	C653.544373,263.354523 651.603882,265.679749 649.663330,268.004974 
	C649.299011,267.896637 648.934692,267.788300 648.570374,267.679962 
	C648.570374,261.667206 648.570374,255.654449 648.570374,249.641708 
	C648.259216,249.604691 647.948059,249.567673 647.636902,249.530670 
	C643.521240,257.007507 639.405640,264.484375 634.559143,272.068481 
	C625.876892,276.620758 617.217957,281.218262 608.507324,285.715668 
	C591.462158,294.516327 591.506104,294.503967 587.872925,313.476501 
	C586.358215,321.386505 584.548523,329.240051 582.881653,337.761230 
	C582.577454,338.535797 582.273193,339.310364 581.987061,339.994507 
	C581.987061,339.994507 582.078369,340.007111 581.454041,340.383118 
	C571.505066,368.260040 561.563416,396.139587 551.578857,424.003754 
	C551.202209,425.054749 550.596191,426.163422 549.782898,426.896759 
	C539.802246,435.895721 529.721680,444.784149 519.773499,453.818390 
	C517.771057,455.636841 516.213196,457.944855 514.450378,460.027191 
	C514.878723,460.428955 515.307068,460.830719 515.735413,461.232513 
	C518.574646,459.170624 521.413879,457.108734 524.839661,455.126221 
	C525.558167,455.736542 526.276672,456.346832 526.521362,457.471802 
	C524.367065,459.103333 522.212769,460.734863 519.640991,462.871552 
	C515.554749,466.488647 511.468506,470.105774 506.666565,474.356415 
	C510.967590,475.601715 513.609253,476.366608 516.748352,477.549683 
	C516.658875,479.733276 516.569397,481.916840 515.556458,484.082367 
	C510.591248,483.732086 505.617523,483.066010 500.665344,483.199493 
	C498.884369,483.247498 496.314697,484.806366 495.539703,486.381592 
	C490.705353,496.207794 486.277618,506.234100 480.860565,516.027771 
	C467.462341,515.653259 454.064117,515.279480 440.665924,514.904053 
	C429.434357,514.589355 418.202820,514.272827 406.969910,513.299866 
	C407.009827,512.866272 407.049774,512.432678 407.902679,512.127747 
	C408.220764,512.224792 408.536560,512.398315 408.857269,512.407959 
	C426.427124,512.934814 443.996307,513.534546 461.570221,513.759033 
	C462.688202,513.773315 464.309509,510.986969 464.769348,509.256989 
	C464.974579,508.484741 462.958618,507.008850 461.802490,506.026886 
	C460.996246,505.342102 459.937927,504.954071 459.929047,504.393768 
	C464.692505,504.517273 469.455963,504.640778 474.219421,504.764282 
	C474.167725,504.549500 474.150116,504.317383 474.052643,504.125885 
	C473.984772,503.992523 473.807281,503.914948 473.414612,503.227814 
	C465.676483,499.988556 457.948639,496.724091 450.185974,493.544739 
	C449.305511,493.184143 448.231232,493.250397 447.242920,493.206268 
	C422.054901,492.082214 396.864288,491.014496 371.679413,489.824493 
	C365.268524,489.521606 358.875671,488.836792 353.464752,488.156769 
	C370.919312,487.437836 388.369995,486.391510 405.830597,486.186890 
	C411.537872,486.119965 415.303070,484.979767 417.922943,479.680267 
	C419.934967,475.610413 419.162109,473.146942 414.712738,472.081146 
	C412.399902,471.527130 410.262543,470.031586 407.947601,469.738953 
	C403.161316,469.133789 398.187256,468.351807 393.556183,469.161926 
	C392.107208,469.415375 390.511139,472.445557 387.620178,471.747925 
	C385.075684,471.133942 381.681183,471.591980 380.057556,470.079041 
	C375.469360,465.803589 369.610840,466.979431 364.623169,466.333130 
	C350.924652,464.558136 337.019409,464.387177 323.202759,463.477783 
	C322.760956,463.448730 322.393616,462.288483 322.921997,461.524628 
	C351.734528,463.476379 380.534576,465.668213 409.370758,467.164154 
	C414.719269,467.441589 420.213287,464.914673 426.256226,463.035156 
	C426.433685,461.925629 426.850983,460.794403 426.752838,459.709808 
	C425.088379,441.320007 423.459961,422.925995 421.621124,404.553162 
	C419.103546,379.398560 416.421295,354.260406 413.796936,329.116516 
	C412.903564,320.556976 411.971832,312.001434 411.051239,303.384583 
	C408.804718,302.980988 407.034119,302.587891 405.242859,302.352051 
	C363.703796,296.882263 322.166626,291.397736 280.619995,285.985901 
	C256.883118,282.893890 233.130844,279.920532 209.384277,276.903442 
	C197.023224,275.332977 184.659485,273.783508 171.634140,272.170746 
	C169.246185,273.270111 166.727173,274.163971 164.531433,275.564667 
	C163.583878,276.169128 162.718826,277.868591 162.808670,278.991211 
	C164.661819,302.144043 166.602905,325.290222 168.641846,348.427582 
	C170.665039,371.386139 172.766281,394.338165 174.931076,417.283813 
	C175.984100,428.445068 177.260178,439.585266 178.460144,450.970886 
	C197.963760,452.406952 217.115555,453.817108 236.800476,455.266510 
	C235.741287,460.679474 234.938370,465.565216 233.810730,470.374817 
	C231.770248,479.077728 229.536041,487.735199 226.556137,496.449432 
	C214.642609,496.022919 202.730179,495.561890 190.815231,495.179291 
	C178.199539,494.774200 165.582397,494.398499 152.963272,494.134369 
	C151.277664,494.099091 149.576797,494.793427 147.027817,495.000244 
	C144.723770,495.080353 142.419708,495.160492 139.888992,494.287659 
	C139.888992,483.349182 139.888992,472.410706 139.888992,460.620514 
	C133.340378,460.620514 127.671638,460.620514 121.219612,460.620514 
	C124.583855,450.997314 127.582611,442.395508 130.600449,433.800385 
	C131.646515,430.821075 132.956955,427.910980 133.696503,424.860168 
	C133.962494,423.762848 132.906845,422.345154 132.454376,421.073669 
	C131.047791,421.675201 129.495636,422.077209 128.291260,422.955902 
	C127.608604,423.453918 127.468620,424.723724 127.131363,425.662903 
	C123.504921,435.761169 119.887917,445.862885 116.256485,455.996643 
	C114.269127,438.885590 116.697906,421.260529 109.780769,403.888428 
	C108.440407,409.971588 107.284966,415.215485 106.049171,421.169556 
	C105.959793,421.987000 105.788132,422.804993 105.793373,423.621826 
	C105.871201,435.756439 105.972466,447.890930 105.571938,460.453217 
	C105.362328,460.514526 105.152718,460.575867 104.571350,459.954926 
	C101.040962,459.121826 100.189156,456.489410 100.028633,453.319214 
	C99.887184,450.525513 99.817757,447.708221 99.387802,444.952667 
	C97.979156,435.924683 96.420692,426.920044 95.024704,417.111694 
	C93.468246,411.678314 91.911797,406.244904 90.355339,400.811523 
	C89.809891,400.934998 89.264435,401.058472 88.718987,401.181946 
	C88.718987,404.704071 88.887505,408.236603 88.670731,411.745331 
	C88.461510,415.131989 89.895142,417.060486 93.036995,418.679291 
	C93.350655,422.003265 93.840385,425.325226 93.878952,428.652405 
	C93.891830,429.762360 92.750092,430.885742 91.591537,431.536377 
	C90.245651,428.665985 88.899757,425.795624 87.553871,422.925232 
	C87.017532,423.069031 86.481201,423.212830 85.944862,423.356628 
	C87.250244,435.680481 88.555626,448.004303 89.896736,460.665466 
	C84.780891,461.956024 79.886436,463.190765 74.886642,464.452087 
	C74.886642,478.864197 74.886642,492.790131 74.886642,507.032593 
	C71.152245,507.742889 67.597664,508.419006 63.558891,509.020264 
	C63.558891,509.020264 63.069283,509.029388 62.475056,509.611786 
	C62.319576,511.417023 62.028690,513.222168 62.028118,515.027466 
	C62.003838,591.637146 62.005409,668.246826 62.003841,744.856506 
	C62.002926,789.656494 61.934143,834.456787 62.103107,879.256165 
	C62.120579,883.888062 60.814823,885.281372 56.223511,885.104126 
	C47.409904,884.763916 38.573097,884.945862 29.747528,885.049194 
	C27.495131,885.075562 25.248789,885.618958 22.826920,886.609680 
	C22.874338,887.075012 22.921757,887.540283 22.447527,888.531860 
	C21.282045,892.103210 23.267912,892.886780 26.261797,893.015625 
	C30.841316,893.212646 35.414452,893.558105 39.061375,893.949524 
	C33.820019,893.949524 28.578663,893.949524 23.337309,893.949524 
	C23.354176,894.548096 23.371042,895.146667 23.387909,895.745239 
	C36.318756,895.745239 49.249607,895.745239 62.432713,896.432922 
	C62.610363,898.122498 62.788013,899.812073 62.052895,901.687073 
	C56.381851,901.717346 50.710808,901.747559 44.802723,901.559875 
	C44.471607,901.427185 44.191181,901.505249 43.030277,901.832642 
	C36.488327,901.832642 29.946379,901.832642 23.404430,901.832642 
	C23.426455,902.474670 23.448479,903.116699 23.470501,903.758728 
	C25.059509,903.758728 26.648518,903.757019 28.237524,903.758972 
	C38.216328,903.770874 48.195679,903.734070 58.173412,903.844055 
	C59.878654,903.862915 61.576954,904.511475 62.308956,904.945251 
	C50.657036,904.964661 39.003948,904.911194 27.354725,905.098816 
	C25.954319,905.121399 24.574972,906.451294 23.185873,907.175293 
	C24.525181,907.783081 25.828602,908.805359 27.210176,908.924988 
	C32.455502,909.379028 37.726101,909.541138 42.057011,909.914673 
	C35.836140,909.914673 29.615267,909.914673 23.394396,909.914673 
	C23.415489,910.478333 23.436583,911.041992 23.457676,911.605652 
	C36.740211,911.605652 50.022747,911.605652 63.554260,912.061218 
	C63.699024,912.375610 63.843788,912.690063 64.025948,913.829529 
	C64.053284,914.929626 64.080620,916.029663 63.879555,917.670166 
	C63.792492,918.050842 63.705433,918.431519 62.740486,918.905762 
	C50.272575,918.905762 37.804665,918.905762 25.336752,918.905762 
	C25.332216,919.172302 25.327681,919.438904 25.323143,919.705444 
	C27.595589,919.905273 29.868034,920.105042 32.089703,920.864502 
	C32.069176,921.238342 32.048653,921.612244 31.773293,922.529602 
	C31.527859,923.074463 31.282425,923.619385 30.904776,924.786804 
	C30.857880,925.206116 30.810986,925.625488 30.431787,926.625732 
	C32.152962,927.083801 33.873543,927.939514 35.595398,927.942078 
	C83.749268,928.014343 131.903275,927.995605 180.057236,928.018616 
	C181.828018,928.019470 183.598648,928.288330 185.369354,928.432251 
	C177.120605,931.179932 177.120560,931.179932 177.000015,940.424744 
	C176.997833,940.591370 177.002945,940.758057 176.998810,940.924622 
	C176.870270,946.105042 173.428192,948.176636 168.676956,946.184387 
	C166.457321,945.253662 163.805862,943.982910 161.692291,944.441833 
	C156.620865,945.543152 151.718552,947.487732 146.833466,949.319824 
	C146.005707,949.630310 145.603195,951.074646 144.066071,952.005249 
	C134.280945,952.007568 124.494766,951.938721 114.711761,952.084229 
	C113.130920,952.107727 111.564255,953.084412 109.310120,953.744019 
	C108.852203,953.707520 108.394295,953.670959 107.755943,953.429871 
	C107.475426,953.297668 107.248779,953.368347 106.496376,953.978882 
	C106.663116,954.713806 106.829865,955.448792 107.243568,956.930664 
	C109.115517,957.283447 110.987236,957.943420 112.859444,957.944763 
	C187.618042,957.999634 262.376648,957.999817 337.135284,957.969971 
	C339.089386,957.969177 341.043335,957.558044 343.695068,957.257996 
	C344.160522,957.298218 344.625977,957.338379 345.404968,957.674194 
	C345.910706,957.567017 346.416412,957.459900 347.611145,957.218445 
	C348.069946,957.133911 348.528717,957.049377 349.788147,957.070740 
	C350.862366,957.034424 351.936584,956.998108 353.933014,957.129456 
	C360.951721,957.069641 367.970428,957.009827 375.660034,957.045959 
	C376.109619,957.014099 376.559204,956.982300 377.927307,957.127869 
	C379.741821,957.140137 381.568848,957.293762 383.368134,957.134277 
	C386.799896,956.830017 390.215851,956.347168 393.776764,955.161316 
	C393.803284,951.253540 396.953033,952.591064 399.677887,952.355835 
	C406.084167,952.205383 412.490417,952.054993 419.319733,951.909119 
	C419.319733,951.909119 419.733521,951.997131 420.703674,951.956787 
	C543.478455,951.965332 666.253296,951.990906 789.028076,951.968750 
	C815.011169,951.964050 840.994263,951.784790 867.904358,951.858826 
	C906.373840,951.858826 944.843262,951.858826 983.312744,951.858826 
	C983.337402,951.092773 983.362000,950.326721 983.386658,949.560608 
	C973.349976,948.038940 963.125793,949.354370 952.343811,948.367798 
	C937.150452,948.274719 921.957092,948.172852 906.763550,948.121033 
	C906.167114,948.119019 905.569092,948.595886 904.039734,948.902161 
	C891.692993,948.951721 879.346191,949.001282 866.068726,948.929260 
	C828.026978,948.872375 789.985229,948.823547 751.943542,948.735901 
	C749.979187,948.731384 748.015686,948.395874 746.139404,947.640991 
	C746.403076,947.130249 746.666687,946.619507 747.859497,945.899841 
	C784.323181,945.857666 820.786865,945.815430 857.250549,945.773254 
	C857.247009,945.077148 857.243469,944.381042 857.239929,943.684937 
	C848.489075,943.684937 839.738281,943.684937 830.290894,943.270386 
	C822.194763,943.185364 814.098572,943.100342 806.002014,942.430298 
	C806.009033,941.850159 806.016052,941.269958 806.919495,940.434143 
	C823.604004,940.363037 840.288452,940.291931 857.220276,940.458313 
	C857.481323,940.323120 857.742432,940.187988 858.390503,939.630737 
	C858.260559,938.768738 858.130554,937.906799 857.819580,936.503845 
	C858.864380,935.757385 859.909119,935.010986 861.838806,934.169434 
	C863.912659,934.206116 865.986511,934.242737 868.733032,934.725525 
	C869.386169,934.819397 870.039368,934.995178 870.692505,934.995300 
	C906.401306,935.001221 942.110168,935.003967 977.818909,934.941956 
	C979.214172,934.939514 980.608398,934.303406 982.569885,933.556274 
	C982.730713,932.664490 982.891479,931.772644 983.351318,930.211304 
	C983.528442,928.997498 983.705566,927.783752 983.738953,926.335449 
	C983.738953,926.335449 983.819336,926.072327 984.189514,925.856445 
	C984.059265,925.544983 983.929016,925.233521 983.185791,924.391113 
	C981.383728,924.240479 979.581726,923.959656 977.779602,923.958618 
	C947.813354,923.942078 917.847046,923.989807 887.880798,923.963440 
	C855.587280,923.934998 823.293701,923.844604 791.682312,923.319824 
	C805.683533,922.879883 819.684692,922.438904 833.685974,922.001343 
	C835.346008,921.949463 837.011780,921.985168 838.666077,921.862000 
	C846.452576,921.281982 854.243286,920.086182 862.018860,920.185486 
	C874.501160,920.344910 886.973206,921.342468 899.448547,922.004089 
	C905.062683,922.301758 910.671509,922.732910 916.288818,922.923401 
	C918.735168,923.006348 921.969116,924.087524 923.066040,919.802795 
	C925.149719,920.086182 927.266235,920.741333 929.309021,920.561157 
	C932.009094,920.322998 934.651428,919.430603 937.901917,918.481873 
	C945.704041,916.234131 950.903564,909.443359 950.401855,902.156494 
	C949.826843,893.805237 943.355652,887.030151 935.206055,885.310669 
	C935.741577,884.387756 936.277100,883.464844 936.812622,882.541931 
	C935.838745,882.147278 934.860657,881.403870 933.891785,881.415771 
	C928.493286,881.481628 923.095154,881.877930 917.701599,881.807007 
	C916.446838,881.790527 915.210205,880.397217 914.882812,879.876892 
	C922.164978,879.876892 929.447205,879.876892 936.729370,879.876892 
	C936.726440,879.022583 936.723450,878.168335 936.720459,877.314026 
	C929.495728,877.186951 922.270996,877.059875 915.568298,876.261230 
	C919.204956,876.064819 922.843872,875.902405 926.477600,875.662109 
	C929.818481,875.441223 933.153870,875.136658 936.491638,874.869019 
	C936.449951,874.243042 936.408264,873.617004 936.366516,872.991028 
	C931.214600,872.991028 926.062683,872.991028 920.910828,872.991028 
	C920.902527,872.696838 920.894287,872.402649 920.886047,872.108521 
	C926.152832,871.580750 931.419678,871.053040 936.686462,870.525330 
	C936.708130,869.971802 936.729736,869.418335 936.751404,868.864807 
	C935.312439,868.661255 933.879578,868.364258 932.433472,868.269653 
	C924.642151,867.759766 916.775330,867.814941 909.066345,866.734436 
	C874.523376,861.893066 840.026917,856.720459 805.501770,851.750000 
	C804.454956,851.599304 803.277222,852.357849 801.419861,852.751282 
	C796.302429,851.522400 791.184998,850.293457 785.890564,848.392578 
	C785.829895,847.933533 785.769287,847.474487 785.844116,846.501587 
	C785.802734,846.329407 785.761353,846.157166 785.817688,845.055176 
	C785.774597,802.035583 785.731567,759.015930 785.897034,715.346741 
	C785.841980,714.899597 785.786926,714.452393 785.860840,713.434326 
	C785.920959,713.248779 785.981140,713.063171 786.746033,712.733643 
	C792.493591,710.296204 798.241211,707.858765 804.266846,705.303406 
	C804.924133,707.915833 805.434937,709.946045 806.228271,713.099182 
	C817.809753,711.435730 829.265015,709.790344 840.840149,707.712769 
	C841.050842,706.748230 841.502502,705.764648 841.427795,704.822754 
	C841.185425,701.767761 840.750305,698.727966 841.263184,695.498291 
	C848.862854,695.400696 856.479126,695.548706 864.050781,695.037109 
	C866.006714,694.905029 868.586182,693.129639 869.566833,691.375488 
	C873.189880,684.894592 876.263367,678.106445 879.980652,671.069336 
	C880.382019,670.429138 880.783447,669.788940 882.098145,669.117737 
	C892.731934,669.080811 903.365662,669.043884 914.592102,669.370667 
	C916.848206,668.943420 921.024597,671.598267 920.097900,665.041626 
	C920.083313,649.362610 920.068665,633.683533 920.427246,617.313721 
	C920.305054,613.873535 920.182861,610.433411 920.102356,606.057434 
	C920.090271,592.391663 920.078186,578.725952 920.408142,564.318115 
	C920.477051,563.826050 920.605164,563.334229 920.606445,562.841980 
	C920.692017,529.791809 920.772278,496.741669 920.818665,463.691498 
	C920.820007,462.730774 920.452820,461.769501 920.311401,460.155396 
	C920.297119,459.500671 920.282898,458.845917 920.594727,457.688416 
	C920.517700,457.122498 920.440674,456.556580 920.560486,455.129181 
	C920.674255,452.771179 920.787964,450.413208 921.145874,447.259247 
	C921.057678,443.125122 920.969482,438.990997 920.776611,433.991608 
	C921.552795,425.441711 922.328918,416.891815 923.137512,407.984680 
	C908.101379,409.591614 893.852722,411.114349 880.860840,412.502808 
	C878.601501,404.787384 877.539001,397.956970 874.655212,392.009705 
	C869.435425,381.244904 863.014771,371.069031 857.296936,360.537933 
	C836.638550,322.489197 816.086914,284.382477 795.370056,246.365814 
	C794.578369,244.913055 792.606262,243.439575 790.999878,243.197113 
	C774.228394,240.665863 757.412537,238.429581 740.629700,235.970627 
	C738.784058,235.700195 736.747864,234.928986 735.345093,233.744476 
	C728.604309,228.052521 722.050781,222.138840 716.326477,216.064590 
	C723.822815,215.949661 728.510681,213.128632 727.374573,204.645309 
	C727.338928,204.379456 727.688110,204.075424 727.817993,203.772110 
	C730.530273,197.438782 729.614258,195.678589 723.134888,194.680069 
	C722.917786,194.646591 722.764343,194.199860 722.040649,193.195450 
	C724.189331,192.556305 726.055603,191.536285 727.913513,191.551178 
	C731.170349,191.577286 732.523682,189.656128 731.708740,187.264877 
	C730.454041,183.583160 731.946899,181.009003 733.332336,177.934052 
	C734.089722,176.252823 733.928955,173.293365 732.882141,171.859436 
	C731.161621,169.502548 728.384399,167.917114 725.880737,165.678528 
	C725.880737,165.678528 725.895020,165.308548 725.955994,164.435715 
	C727.147339,159.080765 728.264526,153.730911 724.555359,148.419388 
	C724.269714,148.360077 724.028931,148.222290 723.834473,147.342178 
	C723.108826,143.326904 722.383179,139.311615 722.081177,134.945053 
	C722.224304,134.830368 722.367493,134.715668 722.933105,135.170258 
	C723.465210,136.668213 723.997375,138.166168 725.051880,141.134674 
	C726.680054,135.018616 727.895508,130.274979 729.213074,125.559860 
	C730.271301,121.773010 728.624268,119.513329 725.274780,118.097305 
	C723.968567,117.545090 722.672302,116.969040 721.420227,116.425034 
	C725.500427,102.315987 721.350891,92.431381 708.177063,86.374725 
	C693.902100,79.811798 679.213989,78.867409 664.815369,86.066277 
	C655.182312,90.882561 645.856567,96.314613 636.422791,101.524361 
	C636.144592,101.678009 636.197205,102.430725 636.329346,103.407417 
	C636.859192,103.906929 637.389038,104.406441 637.208069,104.951027 
	C636.583679,105.184418 635.959229,105.417816 635.334839,105.651215 
	C635.366821,105.915932 635.398804,106.180649 635.430786,106.445366 
	C636.956299,106.603294 638.481812,106.761230 640.014160,107.527138 
	C639.962158,107.927872 639.910156,108.328606 639.003967,108.773682 
	C637.279846,108.836639 635.555664,108.899597 633.360901,109.426155 
	C633.256775,110.295319 633.152649,111.164482 632.497864,112.359108 
	C632.644165,113.044891 632.790527,113.730675 632.741333,115.037796 
	C631.552429,115.922462 630.363525,116.807137 628.969666,117.844337 
	C631.976013,118.985130 634.451111,119.924332 636.036987,120.937218 
	C628.091370,121.234634 628.091370,121.234634 626.470825,123.551994 
	C635.243774,124.170959 643.482544,122.980934 651.339539,119.188301 
	C654.721985,117.555603 658.285400,116.297913 661.744995,115.542366 
	C658.830933,118.698563 655.916931,121.854759 652.516235,125.160240 
	C652.349854,125.442085 652.183472,125.723938 651.997925,125.999977 
M936.915955,440.291046 
	C935.538086,433.740143 934.160217,427.189240 932.782349,420.638336 
	C932.272827,420.750854 931.763245,420.863373 931.253723,420.975922 
	C932.616150,427.709229 933.978638,434.442566 935.374146,441.942108 
	C940.320496,467.283386 945.266785,492.624664 950.213135,517.965942 
	C950.816223,517.848816 951.419312,517.731750 952.022400,517.614685 
	C946.956726,492.115387 941.890991,466.616119 936.915955,440.291046 
z"/>
<path fill="#1D0303" opacity="1.000000" stroke="none" 
	d="
M353.010803,956.961792 
	C351.936584,956.998108 350.862366,957.034424 349.099792,956.770264 
	C345.946655,956.595337 343.481903,956.720825 340.548004,956.869202 
	C324.380737,956.849915 308.682556,956.807739 292.722382,956.557861 
	C290.978333,956.489746 289.496307,956.629272 287.547058,956.815552 
	C250.018860,956.792725 212.957733,956.762573 175.896942,956.630127 
	C164.270477,956.588562 152.644989,956.271851 140.872467,955.855225 
	C140.146790,955.769409 139.567673,955.909424 138.530441,956.092224 
	C134.388733,956.062134 130.705154,955.989319 127.007431,955.589355 
	C126.987892,954.825806 126.982506,954.389343 127.441772,953.905518 
	C185.106201,953.858215 242.305984,953.858215 299.864807,953.858215 
	C299.864807,949.265930 299.864807,946.145447 299.985352,942.791626 
	C300.086792,942.384216 300.067688,942.210144 300.449799,942.068054 
	C316.211060,945.448792 331.471344,949.422485 346.971008,951.896423 
	C355.372284,953.237305 364.183990,952.124451 372.805389,951.910217 
	C376.237518,951.825012 379.879761,951.799988 383.036591,950.664124 
	C391.303284,947.689941 399.679016,946.262939 408.434998,946.834106 
	C410.888275,946.994080 413.360809,946.858521 415.927368,946.858521 
	C415.927368,945.357422 415.927368,944.210266 415.979187,942.710266 
	C415.999115,941.885498 415.967224,941.413635 415.940796,940.655151 
	C415.684448,939.904907 415.422668,939.441345 415.428162,938.878784 
	C415.740021,938.235840 415.784546,937.691772 415.868774,936.767273 
	C415.904785,935.879028 415.901093,935.371216 415.919495,934.577148 
	C415.676086,933.815735 415.410553,933.340576 415.275787,932.603699 
	C415.335907,931.981445 415.265259,931.620850 415.376129,930.958679 
	C415.647614,930.440369 415.737610,930.223694 415.985077,929.658936 
	C415.443237,925.862549 414.743958,922.414246 413.582245,918.969116 
	C409.492218,918.972290 405.864624,918.972290 402.237030,918.972290 
	C402.132568,918.368835 402.028107,917.765381 401.923615,917.161926 
	C403.263397,916.721252 404.603180,916.280518 406.214722,916.099121 
	C408.705994,916.224060 410.925507,916.089600 413.145050,915.955200 
	C413.075287,915.273254 413.005554,914.591370 412.935822,913.909424 
	C410.619202,914.262451 408.302582,914.615417 405.645813,914.863281 
	C404.597443,914.710388 403.889160,914.662659 403.145111,914.364746 
	C402.789642,913.723206 402.469971,913.331909 402.136475,912.481384 
	C402.108978,910.361023 401.886810,908.669800 402.121033,907.044373 
	C402.801208,902.323425 401.350983,898.923645 397.095032,896.364197 
	C391.844727,893.206665 387.052490,889.280945 381.769775,886.186646 
	C379.269501,884.722168 376.089050,884.418945 373.337219,883.373230 
	C373.669800,882.757935 373.881927,882.358887 374.470001,882.001343 
	C380.661499,880.067078 386.558167,878.295227 392.258484,876.029358 
	C395.108612,874.896423 396.808441,875.554871 398.685883,877.664062 
	C404.661438,884.376526 410.816467,890.929016 416.809937,897.625854 
	C423.320587,904.900330 429.727905,912.267212 436.534790,919.996460 
	C443.874969,909.505493 451.213776,899.448242 458.100281,889.090332 
	C476.516510,861.390808 494.738647,833.562256 513.356201,805.914062 
	C522.250916,816.806885 530.827332,827.570557 539.867126,838.915833 
	C529.062622,849.936707 517.836487,861.387756 506.482452,872.969238 
	C503.456787,870.156372 501.316711,868.166748 498.866577,865.888977 
	C494.134857,872.738892 489.628540,879.262512 485.077026,886.097412 
	C485.005310,886.827026 484.978821,887.245483 484.585632,887.756287 
	C482.659454,889.786621 481.099976,891.724548 479.318909,893.815063 
	C478.127228,895.645630 474.273895,894.620422 475.181519,898.403564 
	C475.142090,899.114380 475.145508,899.520447 474.893860,900.125122 
	C474.755188,901.821777 474.871521,903.319641 474.570129,904.895996 
	C472.245422,905.128906 470.338470,905.283447 468.431488,905.437927 
	C468.472260,906.066406 468.513000,906.694885 468.553772,907.323364 
	C480.791718,907.323364 493.029694,907.323364 505.698486,907.414307 
	C509.745697,907.611755 513.362122,907.718323 517.215271,908.118530 
	C519.298767,908.252014 521.145569,908.091919 523.406494,907.863831 
	C525.214905,907.849915 526.609192,907.904053 527.986450,908.350891 
	C526.809692,909.498962 525.682373,910.311401 524.480896,910.993225 
	C523.346802,911.636597 522.134644,912.142395 520.498352,912.794189 
	C513.114868,913.058044 506.189911,913.236572 498.790344,913.282837 
	C490.782776,913.408386 483.249817,913.666199 475.716858,913.924011 
	C475.738953,914.460632 475.761047,914.997253 475.783142,915.533875 
	C483.617889,915.533875 491.452637,915.533875 499.734070,915.573120 
	C510.299042,915.728149 520.417664,915.818604 530.535278,915.975769 
	C533.345276,916.019409 536.152100,916.374207 538.960693,916.378296 
	C541.611877,916.382080 544.263428,916.112305 547.232849,915.971497 
	C547.974854,915.987915 548.398804,915.995728 549.070435,916.375610 
	C557.124451,916.747681 564.930786,916.747681 573.285950,916.747681 
	C568.974915,911.629456 566.646240,906.457703 567.640869,900.241516 
	C568.591064,894.302551 571.003418,889.274109 576.809021,886.318054 
	C574.883728,885.415588 573.316956,884.681152 571.823242,883.495239 
	C571.814392,879.362427 571.732605,875.681152 571.742920,871.627319 
	C571.766418,870.497070 571.697815,869.739502 571.742310,868.754639 
	C571.901001,868.364502 571.946655,868.201538 572.256714,867.994995 
	C572.684692,867.879517 572.848267,867.807617 573.426392,867.800659 
	C580.697144,867.064087 587.570862,866.386963 594.403992,865.422058 
	C600.037903,864.626587 605.623840,863.490417 611.324402,862.594666 
	C611.417786,862.684082 611.664001,862.605225 611.717773,862.609375 
	C611.771545,862.613586 611.877441,862.633606 612.103577,862.747192 
	C612.494873,862.826355 612.660034,862.792053 613.182739,862.822998 
	C616.711487,862.300171 619.882629,861.712097 623.390320,861.135254 
	C624.163269,861.047607 624.599792,860.948669 625.414124,860.897949 
	C627.520203,860.615784 629.248352,860.285339 631.327698,859.973633 
	C632.136414,859.891846 632.593994,859.791443 633.385254,859.750000 
	C634.807068,859.536621 635.895203,859.264221 637.329346,859.006714 
	C638.130127,858.947937 638.585022,858.874329 639.453125,858.846436 
	C643.743591,858.234192 647.620850,857.576172 651.196411,856.969360 
	C651.196411,807.614075 651.196411,758.784668 651.196411,709.469727 
	C640.398560,709.469727 630.119507,709.419373 619.841125,709.483154 
	C608.220581,709.555298 596.600769,709.729919 584.624146,709.776245 
	C583.490112,708.476196 582.570618,707.325806 581.966980,706.028564 
	C580.984985,703.917908 580.209473,701.711243 579.372192,699.195312 
	C580.302124,697.470520 581.158752,696.060913 582.118652,694.725647 
	C597.408081,673.457642 612.710693,652.199036 628.301636,630.782410 
	C634.389709,622.792114 640.211548,614.975952 645.947144,607.097107 
	C646.569702,606.241943 646.694153,605.024292 647.010986,603.975342 
	C646.972412,603.975830 646.965271,604.052734 647.336365,604.212830 
	C647.949890,603.897522 648.192322,603.422058 648.762146,602.894836 
	C652.473206,598.761536 656.143860,594.880676 659.171692,590.550415 
	C663.455811,584.423401 667.200562,577.922058 671.307861,571.667358 
	C673.091797,568.950562 675.435669,566.519409 673.969238,562.649780 
	C674.005615,562.427917 674.037231,561.979431 674.008301,561.980347 
	C673.979309,561.981201 673.979431,562.039124 674.241821,561.964844 
	C674.682861,561.587463 674.861450,561.284424 675.012451,560.983765 
	C674.984863,560.986084 674.992798,561.040894 675.289795,560.970703 
	C675.950012,560.429810 676.313171,559.959229 676.676392,559.488586 
	C676.150757,559.420593 675.625183,559.352539 675.074768,558.892883 
	C675.216125,557.221252 675.382263,555.941284 675.591675,554.327332 
	C673.939087,554.203430 672.482361,554.094238 671.104126,553.647095 
	C671.137817,552.870850 671.092896,552.432434 671.020020,551.994751 
	C670.992126,551.995483 671.006958,552.049316 671.323975,552.173950 
	C672.241455,551.684509 672.841919,551.070435 673.630066,550.301392 
	C674.061646,549.907410 674.209412,549.619080 674.488464,549.185181 
	C674.996460,548.895020 675.206055,548.642273 675.603882,548.228149 
	C676.044128,547.601624 676.225098,547.077515 676.406128,546.553467 
	C675.898193,546.999878 675.390198,547.446289 674.652283,547.987915 
	C674.121094,548.276489 673.951721,548.551270 673.684326,548.997375 
	C673.183655,549.295593 672.999023,549.563232 672.639038,549.939148 
	C672.063965,550.149231 671.750366,550.310425 671.032715,550.379883 
	C667.875671,551.156982 665.122742,552.025940 662.327881,552.443054 
	C662.242615,548.324585 662.199341,544.657898 662.367676,540.750854 
	C662.388062,539.353149 662.196716,538.195801 661.923218,536.612549 
	C661.029175,521.582336 660.109741,506.982574 659.495850,492.369934 
	C659.372803,489.439789 658.189209,488.163574 655.683838,487.498199 
	C648.790527,485.667664 641.898987,483.830933 634.657104,481.845093 
	C631.188660,480.854675 628.069824,480.015808 624.522644,479.061737 
	C626.728821,473.888153 628.655151,469.370728 630.947205,464.910706 
	C632.867920,465.044586 634.423035,465.121094 636.303284,465.178894 
	C637.057068,465.091339 637.485596,465.022522 638.277710,464.967438 
	C639.368591,464.995026 640.096069,465.008881 641.179565,465.000244 
	C645.385681,458.180023 649.235718,451.382355 653.443237,444.618408 
	C659.934937,451.423523 666.069092,458.194946 672.054199,465.302490 
	C669.789001,470.996521 667.672974,476.354370 665.415894,482.069183 
	C668.469482,483.265106 670.851624,484.198090 673.412964,485.201263 
	C674.133118,483.113770 674.975098,481.587433 675.159607,479.985382 
	C676.135132,471.514160 681.245422,467.241577 689.201172,465.663452 
	C690.169312,465.471405 691.229248,465.214844 691.998962,464.650360 
	C694.198303,463.037323 696.534973,461.486969 698.331238,459.477295 
	C701.201416,456.266113 703.706909,452.725067 706.309448,449.280365 
	C707.334412,447.923615 708.205994,446.450989 709.201050,444.690186 
	C709.147278,443.905243 709.039856,443.461212 708.973450,442.750916 
	C708.834473,441.988068 708.654480,441.491486 708.485352,440.593933 
	C707.682068,437.783417 706.867859,435.373810 706.260742,432.676147 
	C707.914551,431.973022 709.361328,431.557983 710.971436,431.484039 
	C712.764832,437.882629 714.395081,443.940094 716.000610,450.407776 
	C716.776733,454.658752 717.577454,458.499512 718.417175,462.527679 
	C733.102356,459.075958 747.297424,455.860870 761.425659,452.374603 
	C770.368835,450.167816 779.212280,447.556763 788.498169,445.068176 
	C790.958313,444.553833 793.020813,444.098358 795.456055,443.620667 
	C797.538940,443.073090 799.238831,442.511444 800.960693,442.027954 
	C816.636841,437.626007 832.316833,433.237640 848.386475,428.863586 
	C856.823853,426.972626 864.870300,425.063385 872.868042,423.570557 
	C870.466431,455.921295 868.032715,487.849945 865.793945,519.792236 
	C864.622742,536.502563 863.794128,553.236938 862.715210,570.314819 
	C862.530029,572.126587 862.440491,573.583984 862.272400,575.275024 
	C862.193848,575.508728 862.389771,575.961182 862.309631,576.423706 
	C862.417542,581.864502 862.605591,586.842896 863.100281,591.952332 
	C864.190247,593.125793 864.973572,594.168213 866.008057,595.444580 
	C867.507019,596.782349 868.754883,597.886169 870.124390,599.233276 
	C870.756653,599.582092 871.267334,599.687622 872.087463,599.925842 
	C872.986694,600.384155 873.576477,600.709656 874.207764,601.369995 
	C875.114990,602.000427 875.980591,602.296021 876.975830,602.846191 
	C877.566895,603.414185 878.028381,603.727539 878.547546,604.401245 
	C879.074646,605.223145 879.544067,605.684692 880.195435,606.340698 
	C880.874817,606.709717 881.372192,606.884277 882.001831,607.322571 
	C882.393677,607.879578 882.653259,608.172913 882.981384,608.763550 
	C883.963928,609.396118 884.877930,609.731201 885.854797,610.434814 
	C886.616943,611.367371 887.316284,611.931335 888.068604,612.789673 
	C889.301453,614.065979 890.481262,615.047913 891.896240,616.308533 
	C892.467773,615.086609 892.804199,613.585938 893.439087,612.031738 
	C894.340027,612.030701 894.942444,612.083313 895.523743,612.570068 
	C895.688721,615.459717 895.874939,617.915222 896.061096,620.370728 
	C896.901550,620.324036 897.742065,620.277344 898.582581,620.230652 
	C898.582581,615.420410 898.582581,610.610107 898.750000,605.427124 
	C899.608643,599.031128 900.299744,593.007812 901.130005,586.609497 
	C902.805847,574.489136 904.342590,562.743652 906.013489,550.643555 
	C906.862671,544.129944 907.595032,537.972900 908.289246,531.811584 
	C909.566650,520.473389 910.901306,509.140778 912.049011,497.789307 
	C912.545349,492.880554 912.636292,487.930847 912.995850,482.609924 
	C913.679871,480.459351 914.558228,478.740784 914.832703,476.930664 
	C916.294006,467.295258 917.604004,457.636932 919.289062,447.997681 
	C920.041748,448.024261 920.471741,448.039734 920.901733,448.055206 
	C920.787964,450.413208 920.674255,452.771179 920.322754,455.812500 
	C920.146179,457.060944 920.207397,457.626068 920.268616,458.191193 
	C920.282898,458.845917 920.297119,459.500671 920.161255,460.952179 
	C919.947998,470.899445 919.818237,480.050079 919.834351,489.200409 
	C919.878906,514.487061 919.984558,539.773621 920.066040,565.060181 
	C920.078186,578.725952 920.090271,592.391663 919.835205,606.841309 
	C919.730042,611.084961 919.892029,614.544739 920.054077,618.004517 
	C920.068665,633.683533 920.083313,649.362610 919.880615,665.820923 
	C917.775391,667.402466 915.887390,668.204712 913.999451,669.006958 
	C903.365662,669.043884 892.731934,669.080811 881.317566,669.180298 
	C880.205811,669.973145 879.874573,670.703491 879.543335,671.433838 
	C876.263367,678.106445 873.189880,684.894592 869.566833,691.375488 
	C868.586182,693.129639 866.006714,694.905029 864.050781,695.037109 
	C856.479126,695.548706 848.862854,695.400696 840.717468,695.209839 
	C837.046387,682.313354 833.921021,669.705444 831.114929,657.049316 
	C831.642273,656.945679 831.850281,656.890259 832.515503,656.922974 
	C843.705994,657.011108 854.439331,657.011108 865.172607,657.011108 
	C865.215698,656.722656 865.258728,656.434265 865.301758,656.145813 
	C855.884216,653.868347 846.466675,651.590942 836.733826,649.148560 
	C835.947998,648.961487 835.477478,648.939270 834.751343,648.704041 
	C832.991638,648.095093 831.487671,647.699097 829.638977,647.130615 
	C828.469360,645.686584 827.216187,644.517395 826.884216,643.128174 
	C822.550659,624.995544 818.379272,606.824219 814.048462,588.690918 
	C813.718567,587.309814 812.533569,585.077271 811.675171,585.043518 
	C804.276550,584.752686 796.861511,584.876343 789.048889,584.876343 
	C789.316711,586.715210 789.360840,587.347534 789.504272,587.956482 
	C795.149902,611.929016 800.772278,635.907166 806.470947,659.867126 
	C809.587769,672.971802 812.777283,686.060242 816.117188,699.109131 
	C816.441101,700.374512 817.904663,702.009644 819.105774,702.277405 
	C825.088135,703.611206 831.159668,704.545044 837.504639,705.821350 
	C838.779663,706.730408 839.749939,707.437744 840.720276,708.145020 
	C829.265015,709.790344 817.809753,711.435730 806.228271,713.099182 
	C805.434937,709.946045 804.924133,707.915833 804.266846,705.303406 
	C798.241211,707.858765 792.493591,710.296204 786.262451,712.363770 
	C785.727356,694.968201 785.675781,677.942505 785.721680,660.706177 
	C785.819214,660.495422 785.625427,660.073425 785.719910,659.740784 
	C785.919739,658.959900 786.025024,658.511658 786.556885,658.084717 
	C789.108582,658.106079 791.233582,658.106079 793.896179,658.106079 
	C792.869019,654.041565 792.004578,650.620850 791.164551,647.296875 
	C789.061340,647.516785 787.588379,647.670837 786.011963,647.377319 
	C785.868286,625.843933 785.787354,604.757935 785.886047,583.672852 
	C785.892151,582.363464 787.309570,581.060669 788.417664,579.860107 
	C789.481262,579.896240 790.197449,579.827087 791.382141,579.835571 
	C807.106689,579.913208 822.362793,579.913208 837.645691,579.913208 
	C837.645691,573.885315 837.645691,567.514954 837.645691,560.804138 
	C820.331970,560.804138 803.406311,560.804138 786.000977,560.804138 
	C786.000977,533.591125 786.000977,506.726105 786.000977,479.653748 
	C783.356445,479.929565 781.213501,480.153046 778.687012,480.222290 
	C770.869080,481.343567 763.434692,482.619110 755.995361,483.554077 
	C755.997620,482.532288 756.004822,481.851135 756.439697,481.113770 
	C763.666382,479.996155 770.472778,478.976959 777.252563,477.803589 
	C777.885132,477.694122 778.348755,476.608978 778.935303,475.769287 
	C779.251892,475.485016 779.524109,475.408325 779.796265,475.331604 
	C779.512451,475.550354 779.228577,475.769165 778.488281,476.059998 
	C773.063171,476.599487 768.094604,477.066956 763.104553,477.131714 
	C764.708679,475.541870 766.334290,474.354767 768.309692,473.152466 
	C778.536255,472.887024 785.200134,471.096924 786.010315,468.229553 
	C782.085388,468.932556 778.275818,469.614899 774.134521,470.113831 
	C772.883789,468.477417 771.964844,467.024414 771.217468,465.842682 
	C768.350891,466.435547 765.538757,466.860260 762.819092,467.617157 
	C758.933411,468.698608 754.739136,469.552643 753.562378,474.753204 
	C749.347961,475.529480 745.553772,476.191223 741.759583,476.852966 
	C741.822571,477.240051 741.885498,477.627106 741.948486,478.014191 
	C745.643372,477.618530 749.338318,477.222839 753.121338,477.098816 
	C753.449036,477.888611 753.688538,478.406677 753.561890,478.956207 
	C751.716492,479.573792 749.953125,480.993713 748.806519,480.603119 
	C743.540955,478.809357 742.600098,482.722504 740.597900,485.997467 
	C721.909241,489.064453 703.626282,492.062561 685.150452,495.092316 
	C692.209961,530.319458 699.175537,565.077820 706.136414,599.813049 
	C719.891785,598.191528 733.058716,596.639404 746.190063,595.549500 
	C746.048889,599.505493 745.851990,602.999207 745.851013,606.492920 
	C745.822266,710.205750 745.812927,813.918518 745.800537,917.631348 
	C745.800354,919.580078 745.800537,921.528809 745.800537,924.055420 
	C722.214172,924.055420 699.118164,924.055420 675.689148,923.928711 
	C674.896423,923.886536 674.436768,923.971130 673.511475,924.014160 
	C664.904907,923.977356 656.763062,923.916626 648.623535,924.028076 
	C646.273438,924.060242 643.930054,924.587463 641.410156,924.552002 
	C640.628113,924.441772 640.019531,924.667358 639.165833,924.506104 
	C619.631042,924.116821 600.341431,924.114380 580.596680,923.977173 
	C574.999451,923.802429 569.856812,923.698914 564.715088,923.732910 
	C538.181396,923.908569 511.647919,924.119507 484.661377,924.153687 
	C474.688690,924.105042 465.169006,924.220825 455.649292,924.336548 
	C455.665741,924.849915 455.682190,925.363220 455.698639,925.876587 
	C465.481659,925.876587 475.264679,925.876587 485.510132,925.934631 
	C506.241577,926.316223 526.512024,926.578430 546.777344,927.055908 
	C548.855225,927.104858 550.902710,928.441528 552.247681,929.149048 
	C515.843384,928.828857 480.173279,929.179565 444.544678,931.807678 
	C449.942261,932.831543 455.332764,932.826416 460.723297,932.832397 
	C484.178192,932.858521 507.633057,932.887939 531.547607,932.977295 
	C553.115479,933.268005 574.223389,933.626953 595.332214,933.699707 
	C637.217346,933.844116 679.103149,933.830627 721.449707,933.976562 
	C729.893738,934.149109 737.876770,934.227417 745.910889,934.487427 
	C745.962097,934.669189 746.252502,934.910522 746.262268,935.237488 
	C746.149414,935.983032 746.026794,936.401550 745.886719,936.864990 
	C745.869141,936.909912 745.948730,936.964355 745.485962,936.923828 
	C704.552979,936.763489 664.082825,936.591675 623.612549,936.541504 
	C594.803711,936.505737 565.994690,936.633728 536.770325,936.512329 
	C535.180847,936.464844 534.006897,936.593567 532.398621,936.654480 
	C524.643860,936.715271 517.323364,936.843872 509.647034,936.822876 
	C485.909210,937.117676 462.527252,937.562134 439.145294,938.006531 
	C439.142548,938.675354 439.139801,939.344116 439.137054,940.012939 
	C441.325348,940.012939 443.513672,940.014771 445.701965,940.012756 
	C468.143036,939.992432 490.584076,939.971497 513.489136,939.998047 
	C519.305725,940.031982 524.658264,940.018555 530.431030,940.140625 
	C532.896301,940.192078 534.941406,940.108032 537.453308,940.069946 
	C543.617249,940.086914 549.314331,940.057922 555.299438,940.324097 
	C559.729004,940.394409 563.870422,940.169678 568.468994,939.996582 
	C572.606689,940.106689 576.287231,940.165161 579.964111,940.601929 
	C577.961060,942.021362 575.961609,943.062500 573.498840,944.061646 
	C563.750427,944.055603 554.465393,944.091370 544.703369,944.069336 
	C531.847229,944.031860 519.468018,944.052185 506.611694,944.043701 
	C490.006714,944.419922 473.878784,944.824829 457.750854,945.229797 
	C457.765137,945.821228 457.779388,946.412659 457.793640,947.004089 
	C468.186554,947.004089 478.579498,947.004089 488.982178,947.004028 
	C488.991882,947.003967 488.997620,947.022583 488.995850,947.293213 
	C489.004944,947.743896 489.015869,947.923950 488.614288,948.212341 
	C465.415619,948.527954 442.629425,948.735291 419.530090,949.017456 
	C419.060181,949.707520 418.903381,950.322693 418.295837,950.980835 
	C411.559662,951.368469 405.274261,951.713196 398.988892,952.057861 
	C396.953033,952.591064 393.803284,951.253540 393.267242,955.575500 
	C387.508087,956.309937 382.258423,956.630127 377.008789,956.950439 
	C376.559204,956.982300 376.109619,957.014099 375.003540,956.739685 
	C367.234955,956.609558 360.122894,956.785645 353.010803,956.961792 
M678.418823,552.780518 
	C678.403870,552.432495 678.388977,552.084473 678.374023,551.736450 
	C678.232971,552.145020 678.091858,552.553650 677.708740,553.089905 
	C677.816833,553.074280 677.924927,553.058594 678.418823,552.780518 
M602.858276,892.861755 
	C607.809265,902.024536 605.011963,909.948425 598.111755,917.263062 
	C616.272583,917.263062 633.598694,917.263062 650.854492,917.263062 
	C650.854492,906.068115 650.854492,895.359741 650.854492,885.027710 
	C632.223450,885.027710 613.972290,885.027710 597.401123,885.027710 
	C598.892639,887.113831 600.703247,889.646484 602.858276,892.861755 
M778.508667,466.834839 
	C781.363098,466.095154 784.217468,465.355469 788.322754,464.291656 
	C783.595398,461.668152 777.044617,463.491241 774.734985,466.818451 
	C775.782349,466.889313 776.767822,466.955994 778.508667,466.834839 
M671.443115,545.718689 
	C671.709106,545.995972 671.972168,546.276367 672.254639,546.535706 
	C672.263123,546.543518 672.473389,546.331543 672.589539,546.222046 
	C672.323547,545.945251 672.057556,545.668518 671.443115,545.718689 
M678.547241,558.523499 
	C678.547241,558.523499 678.363525,558.425903 678.363525,558.425903 
	C678.363525,558.425903 678.392700,558.588257 678.547241,558.523499 
z"/>
<path fill="#FEFDED" opacity="1.000000" stroke="none" 
	d="
M172.296967,272.224548 
	C184.659485,273.783508 197.023224,275.332977 209.384277,276.903442 
	C233.130844,279.920532 256.883118,282.893890 280.619995,285.985901 
	C322.166626,291.397736 363.703796,296.882263 405.242859,302.352051 
	C407.034119,302.587891 408.804718,302.980988 411.051239,303.384583 
	C411.971832,312.001434 412.903564,320.556976 413.796936,329.116516 
	C416.421295,354.260406 419.103546,379.398560 421.621124,404.553162 
	C423.459961,422.925995 425.088379,441.320007 426.752838,459.709808 
	C426.850983,460.794403 426.433685,461.925629 425.676758,463.342224 
	C424.736084,463.628906 424.374878,463.608551 423.786377,463.444183 
	C423.392700,463.367096 423.226318,463.434052 422.600525,463.459045 
	C392.517975,461.158936 362.895020,458.897949 333.271606,456.643402 
	C307.967377,454.717590 282.661652,452.811493 257.358368,450.873566 
	C236.192215,449.252563 215.031021,447.565826 193.861450,445.991547 
	C190.445175,445.737488 188.596008,444.830536 188.229309,440.801361 
	C184.948532,404.752655 181.412354,368.727295 177.978531,332.692444 
	C176.058090,312.539001 174.189133,292.380676 172.296967,272.224548 
z"/>
<path fill="#FDFDED" opacity="1.000000" stroke="none" 
	d="
M513.037903,805.784973 
	C494.738647,833.562256 476.516510,861.390808 458.100281,889.090332 
	C451.213776,899.448242 443.874969,909.505493 436.534790,919.996460 
	C429.727905,912.267212 423.320587,904.900330 416.809937,897.625854 
	C410.816467,890.929016 404.661438,884.376526 398.685883,877.664062 
	C396.808441,875.554871 395.108612,874.896423 392.258484,876.029358 
	C386.558167,878.295227 380.661499,880.067078 374.567535,881.686096 
	C378.649628,876.324829 383.105316,871.399719 387.354248,866.302307 
	C406.263123,843.617310 425.112823,820.882935 444.278687,797.968201 
	C465.053284,768.752258 479.994629,736.652954 496.315460,704.939575 
	C496.755188,703.805603 496.962585,702.924072 497.125366,702.050537 
	C497.080750,702.058472 497.117584,702.141357 497.385010,701.982666 
	C497.852478,701.251099 498.052551,700.678223 498.206848,700.094482 
	C498.161102,700.083679 498.183929,700.174866 498.438843,699.998413 
	C498.901886,699.241882 499.110016,698.661865 499.537354,697.874634 
	C500.212616,696.558960 500.668640,695.450500 501.372467,694.126709 
	C501.829834,693.305542 502.039429,692.699585 502.201996,692.097290 
	C502.154999,692.100952 502.201477,692.182922 502.463928,692.010620 
	C503.150391,690.894714 503.574432,689.950989 504.092499,688.905273 
	C504.186523,688.803223 504.149384,688.528198 504.404907,688.285400 
	C504.822174,687.371460 504.983948,686.700378 505.104309,686.036316 
	C505.062897,686.043396 505.093506,686.121704 505.343445,685.942383 
	C505.792145,685.188721 505.990906,684.614441 506.417053,683.866333 
	C506.814880,683.153748 506.985291,682.614990 507.379028,681.893921 
	C507.788635,681.174805 507.974945,680.638000 508.127197,680.065186 
	C508.093109,680.029175 508.098969,680.128174 508.355438,679.958252 
	C508.812683,679.217224 509.013458,678.646118 509.167358,678.075745 
	C509.120514,678.076538 509.153381,678.164368 509.411255,677.995239 
	C509.855316,677.246033 510.041504,676.665833 510.181396,676.088013 
	C510.135101,676.090271 510.174713,676.174133 510.457886,675.983459 
	C511.907104,673.232849 513.073242,670.672913 514.189697,668.091553 
	C514.140137,668.070190 514.179138,668.170898 514.456421,667.971558 
	C516.292175,664.338989 517.850647,660.905762 519.415833,657.395142 
	C519.422546,657.317688 519.276672,657.263672 519.544250,657.190186 
	C519.942017,656.777283 520.072266,656.437988 520.152222,656.079224 
	C520.101868,656.059875 520.150513,656.156128 520.397217,655.971558 
	C520.814453,655.205200 520.984924,654.623352 521.114868,654.043091 
	C521.074402,654.044617 521.097351,654.122314 521.333374,653.930725 
	C521.780762,653.176697 521.992126,652.614258 522.421509,651.867065 
	C522.814087,651.145142 522.988708,650.607971 523.405029,649.816406 
	C526.942749,642.712097 530.238770,635.862061 533.762146,628.789551 
	C536.836243,622.202454 539.682922,615.837830 542.761353,609.291382 
	C543.339600,608.410400 543.686035,607.711304 544.254578,606.732300 
	C552.875305,587.957458 561.273987,569.462585 570.065308,550.102966 
	C564.434753,550.102966 560.324951,550.102966 555.764160,550.085815 
	C552.875732,549.481995 550.463745,548.630615 547.992615,548.392639 
	C546.021545,548.202820 543.978455,548.760803 541.502808,549.001343 
	C534.135193,548.778870 527.232056,548.541931 519.848816,548.210205 
	C513.857910,548.128235 508.347076,548.141113 502.411255,548.157349 
	C498.686401,548.421631 495.386597,548.682617 491.232391,549.011169 
	C493.366425,549.918091 494.680939,550.476685 496.001953,551.363037 
	C496.014404,552.127991 496.020386,552.565186 496.011169,553.224121 
	C495.996033,553.445862 495.967010,553.889465 495.628632,553.952576 
	C494.284180,554.311096 493.278137,554.606445 491.890686,554.895752 
	C490.170807,554.670959 488.832336,554.452332 486.799561,554.120239 
	C487.715729,556.324707 488.291016,557.709045 488.753021,558.820801 
	C484.626984,560.674011 480.816589,562.385376 476.764984,564.077026 
	C476.523773,564.057312 476.039825,564.046448 475.720459,564.079163 
	C474.930481,564.738403 474.459839,565.364990 473.649841,565.988403 
	C471.655884,566.071533 469.159912,564.328552 469.106476,568.248047 
	C469.100159,568.803284 469.102844,569.025452 468.776794,569.469849 
	C465.977661,569.821777 463.507294,569.951355 460.826477,569.890198 
	C459.584167,569.933533 458.552307,570.167603 457.504242,570.405396 
	C458.062103,572.119446 458.527863,573.550537 458.578888,575.014099 
	C456.041962,575.338135 453.919830,575.629639 451.439819,575.930847 
	C449.387726,577.622864 447.693481,579.305237 445.680298,581.019470 
	C444.518280,581.262756 443.675171,581.474121 442.832031,581.685486 
	C443.231720,582.117737 443.631378,582.549988 444.020752,583.295166 
	C443.987488,584.024902 443.964447,584.441711 443.930695,584.886963 
	C443.919983,584.915405 443.861023,584.919312 443.637939,584.938232 
	C443.414825,584.957092 442.969727,585.006165 442.600708,585.063782 
	C440.629608,585.646301 439.027557,586.171204 437.425476,586.696045 
	C437.487305,587.021240 437.549103,587.346375 437.610931,587.671509 
	C441.418945,587.403137 445.226990,587.134766 449.500763,586.888428 
	C457.952026,586.723755 465.937500,586.537048 473.942505,586.677246 
	C473.960693,587.658691 473.959351,588.313171 473.761505,589.034729 
	C473.564972,589.101746 473.158813,589.191895 472.784210,589.094849 
	C457.826996,589.781494 443.244354,590.565125 428.661743,591.348755 
	C428.686462,591.899170 428.711212,592.449585 428.735962,593.000000 
	C432.821106,593.000000 436.906281,593.000000 440.996704,593.327393 
	C440.986908,594.308899 440.971863,594.963013 440.549744,595.699341 
	C438.761719,595.809204 437.380829,595.836670 435.897278,595.731201 
	C435.529022,595.698059 435.263458,595.797791 434.568359,595.956238 
	C430.338440,596.409485 426.537994,596.804016 422.737579,597.198486 
	C422.753296,597.470032 422.769012,597.741516 422.784729,598.013062 
	C426.177551,598.164856 429.570343,598.316589 432.940186,598.851379 
	C431.446075,599.820679 430.016907,600.732666 428.495392,600.929016 
	C424.696381,601.419250 420.855408,601.584412 416.753540,601.944702 
	C416.042877,602.556030 415.609985,603.102783 415.124329,604.051392 
	C415.047516,604.991760 415.023438,605.530273 414.610870,606.106201 
	C412.746887,606.326599 411.271393,606.509644 409.795929,606.692627 
	C409.848175,607.289062 409.900421,607.885559 409.952667,608.481995 
	C422.335327,608.481995 434.717987,608.481995 447.534119,608.566833 
	C450.331512,608.696533 452.695465,608.741394 455.063812,609.107605 
	C454.879822,609.811951 454.691376,610.194946 454.073792,610.608887 
	C448.094910,611.086914 442.545227,611.533997 436.723206,611.794434 
	C434.971252,611.742920 433.491638,611.877930 431.554047,611.976074 
	C427.727173,611.986267 424.358246,612.033386 420.552551,611.996704 
	C417.748291,611.986206 415.380798,612.059509 412.575928,612.092896 
	C410.092194,612.101990 408.045837,612.151062 405.653961,612.111816 
	C404.407013,612.230896 403.505585,612.438293 402.200500,612.679932 
	C394.170776,613.835571 386.544647,614.956970 378.628448,615.872192 
	C378.952942,614.356934 379.256012,612.723877 380.229370,611.787781 
	C392.457245,600.028625 404.774628,588.362427 417.083771,576.687866 
	C434.926575,559.765076 452.781342,542.854858 470.876740,525.924133 
	C471.430054,525.370789 471.737640,524.832520 472.383240,524.179382 
	C475.719238,521.441040 478.717224,518.817566 481.715210,516.194092 
	C486.277618,506.234100 490.705353,496.207794 495.539703,486.381592 
	C496.314697,484.806366 498.884369,483.247498 500.665344,483.199493 
	C505.617523,483.066010 510.591248,483.732086 515.975708,484.494690 
	C515.433228,489.293488 514.471497,493.679962 513.313721,498.344299 
	C511.491913,503.911682 509.866150,509.201263 507.996216,515.285156 
	C515.367310,512.404602 521.827271,509.837494 528.318665,507.352448 
	C540.397217,502.728577 552.494202,498.152557 564.741150,493.723419 
	C564.898804,493.890198 565.226135,494.211945 565.132080,494.543335 
	C562.288086,499.142670 559.538147,503.410614 556.518555,507.887360 
	C553.757263,511.986359 551.265503,515.876587 548.557373,519.871338 
	C548.386780,520.241699 548.432556,520.507507 548.478333,520.773315 
	C548.670166,520.535767 548.862000,520.298218 549.358643,519.922241 
	C552.469177,516.209045 555.274963,512.634155 558.337463,508.849243 
	C565.891235,498.631592 573.266846,488.679565 580.415588,478.567139 
	C581.760620,476.664490 582.200562,474.121948 583.486023,471.773987 
	C595.489075,473.643951 607.064209,475.614655 618.790222,477.611023 
	C620.727478,472.378845 622.624573,467.255219 624.851868,462.137115 
	C627.093689,462.596863 629.005310,463.051117 630.757812,463.823639 
	C630.593018,464.379059 630.587280,464.616180 630.581543,464.853333 
	C628.655151,469.370728 626.728821,473.888153 624.522644,479.061737 
	C628.069824,480.015808 631.188660,480.854675 634.713135,482.271423 
	C636.857361,499.313934 638.349792,515.809937 640.442688,532.229370 
	C641.428528,539.963501 641.909973,547.304688 637.001160,554.130249 
	C636.897156,554.255005 636.891785,554.579651 636.656372,554.756470 
	C636.263672,555.285156 636.106445,555.637024 635.719116,556.161804 
	C635.226929,556.840393 634.964783,557.346008 634.455261,558.060059 
	C633.119080,560.166748 632.030212,562.065002 630.970093,563.979980 
	C630.998779,563.996643 630.962463,563.940918 630.713745,564.112366 
	C630.248047,564.835327 630.031128,565.386841 629.857971,565.932068 
	C629.901794,565.925781 629.863342,565.846008 629.593262,566.009277 
	C628.845032,567.093811 628.366821,568.015015 627.927124,568.961670 
	C627.965637,568.987122 627.932007,568.901123 627.672852,569.070557 
	C627.153381,569.797913 626.893066,570.355835 626.450073,571.032349 
	C626.068848,571.383667 625.974670,571.652771 625.747803,572.141968 
	C625.281006,572.870667 625.051147,573.415710 624.624573,574.075073 
	C624.208740,574.421997 624.097290,574.697998 623.920410,575.205322 
	C623.684143,575.551331 623.621033,575.709534 623.384155,576.013245 
	C623.109619,576.587097 623.008972,577.015442 622.637695,577.626282 
	C622.161072,578.185608 621.955017,578.562378 621.496338,579.155151 
	C612.430176,593.842957 603.666809,608.345581 594.790710,622.778931 
	C583.496338,641.144653 572.110718,659.454224 560.493530,677.983032 
	C557.509460,682.479553 554.796265,686.781006 552.083069,691.082458 
	C552.391479,691.302002 552.699951,691.521545 553.008362,691.741150 
	C553.562622,691.442871 554.116882,691.144653 554.950317,691.011414 
	C556.480347,692.635315 557.731262,694.094177 559.223694,695.353271 
	C559.930115,694.745361 560.394958,694.337280 561.174316,693.971741 
	C561.993958,694.355896 562.499146,694.697510 562.876343,695.329346 
	C562.492004,696.408630 562.235779,697.197632 561.698486,698.219238 
	C561.113831,699.642456 560.810242,700.833008 560.231689,702.102905 
	C559.673218,702.783325 559.389771,703.384460 558.984985,704.242859 
	C560.981567,703.746033 562.498291,703.368530 564.185669,703.241455 
	C565.241943,703.983887 566.127625,704.475891 567.009583,705.374207 
	C567.068420,709.007507 567.131104,712.234558 567.171387,715.616028 
	C567.149048,715.770508 566.932068,715.994934 566.597290,716.065308 
	C565.895874,716.886353 565.529236,717.637024 565.106079,718.725708 
	C559.720642,728.091919 554.391663,737.120117 548.858459,746.427979 
	C547.924561,748.431335 547.194946,750.155029 546.129456,751.831177 
	C534.875000,769.784058 523.956421,787.784485 513.037903,805.784973 
M490.563873,825.121521 
	C490.607269,825.339661 490.650635,825.557861 490.694031,825.776001 
	C490.799408,825.520325 490.904785,825.264709 491.373596,824.975952 
	C491.247345,824.981873 491.121094,824.987793 490.563873,825.121521 
M440.653839,887.997009 
	C441.094147,888.009521 441.534424,888.022095 441.704865,888.605591 
	C441.462311,889.400024 441.219757,890.194397 440.320648,891.004333 
	C433.860870,895.776184 432.267578,902.098450 435.536499,910.335999 
	C434.648621,910.754578 433.792206,911.158264 432.935791,911.561951 
	C435.189209,911.234741 438.692047,913.762329 439.662689,908.922302 
	C440.450439,907.289429 441.238190,905.656494 442.000000,904.000000 
	C442.000000,904.000000 442.028046,904.021606 442.688721,904.186035 
	C443.134491,903.464233 443.580261,902.742493 444.000000,902.000000 
	C444.000000,902.000000 444.024017,902.021912 444.668701,901.823425 
	C446.123932,899.549866 447.579163,897.276367 448.994812,894.996155 
	C448.994812,894.996155 449.007111,895.034302 449.620056,894.706055 
	C450.087372,893.480591 450.554688,892.255066 451.004913,891.003418 
	C451.004913,891.003418 451.034302,891.014221 451.772949,891.142578 
	C452.192413,890.096985 452.611877,889.051392 452.996399,887.998230 
	C452.996399,887.998230 453.013000,888.030029 453.687622,888.207214 
	C454.136200,887.472900 454.584808,886.738647 454.996460,885.997253 
	C454.996460,885.997253 455.009888,886.032227 455.682861,885.815002 
	C456.130371,884.548767 456.577881,883.282471 456.998779,882.002441 
	C456.998779,882.002441 457.022156,882.023621 457.717957,882.203308 
	C461.781769,876.612305 466.004028,871.128479 469.875763,865.407410 
	C477.366669,854.338684 484.660065,843.136230 491.986786,831.989807 
	C491.986786,831.989807 491.993591,832.037903 492.561005,831.745117 
	C493.031616,830.823975 493.502197,829.902771 494.276611,828.876892 
	C494.194122,828.929504 494.111633,828.982178 493.466919,828.946411 
	C493.103210,829.047913 492.739532,829.149414 491.482025,829.112732 
	C490.882050,829.053528 490.282074,828.994263 489.850220,828.291382 
	C488.584869,828.865540 487.319519,829.439758 485.229004,830.003479 
	C484.404480,829.973999 483.579987,829.944519 482.892212,829.291687 
	C482.906921,828.868347 482.921631,828.445007 483.618805,827.895691 
	C484.751617,825.934570 485.884430,823.973389 487.580658,821.708923 
	C488.047058,820.843323 488.513458,819.977722 488.979858,819.112122 
	C488.587952,818.964172 488.196045,818.816162 487.804138,818.668213 
	C487.529694,819.772278 487.255249,820.876404 486.289062,821.834534 
	C486.161041,819.425903 486.033051,817.017273 485.886688,814.263000 
	C484.892883,815.500793 483.930634,816.699158 482.690491,818.526184 
	C474.131592,831.399902 465.499054,844.225647 457.051147,857.171814 
	C453.805756,862.145325 450.981262,867.393433 448.004639,872.510681 
	C448.004639,872.510681 447.986359,872.475037 447.385376,872.904541 
	C446.250519,875.259644 445.115662,877.614807 443.993134,879.995911 
	C443.993134,879.995911 443.965729,879.985779 443.267578,879.922668 
	C441.818939,882.302917 440.370331,884.683167 438.667603,887.370361 
	C439.109283,887.576477 439.550995,887.782593 440.653839,887.997009 
M562.183838,715.565857 
	C562.188538,715.200439 562.193237,714.835083 562.197937,714.469604 
	C562.046265,714.467834 561.762878,714.455566 561.762085,714.465820 
	C561.733032,714.829651 561.730530,715.195679 562.183838,715.565857 
z"/>
<path fill="#FCFCEC" opacity="1.000000" stroke="none" 
	d="
M918.966431,447.986572 
	C917.604004,457.636932 916.294006,467.295258 914.832703,476.930664 
	C914.558228,478.740784 913.679871,480.459351 912.680359,482.607483 
	C911.859985,482.997467 911.439514,483.000153 910.735596,483.142395 
	C909.981140,484.189056 909.510071,485.096161 908.794983,486.103455 
	C908.090576,486.461548 907.630249,486.719452 907.026611,486.723694 
	C906.259521,486.648590 905.635803,486.827057 904.622498,487.005676 
	C903.454041,487.030975 902.675232,487.056122 901.615723,487.074005 
	C900.891235,487.382263 900.447510,487.697815 899.625427,488.021362 
	C898.744507,488.070404 898.241943,488.111420 897.544250,488.042480 
	C896.871399,487.954742 896.393616,487.976929 895.682007,488.106140 
	C894.648743,488.145538 893.849243,488.077972 892.704529,488.167114 
	C891.877197,490.870941 891.395142,493.418030 890.589111,495.985718 
	C889.832642,496.004761 889.400024,496.003235 888.932007,495.558746 
	C891.139404,469.559509 893.382263,444.003265 895.676147,417.865570 
	C887.741211,419.709412 880.328979,421.431793 872.916809,423.154175 
	C864.870300,425.063385 856.823853,426.972626 848.388184,428.497742 
	C847.800720,425.003235 846.495544,423.579773 843.162720,424.583954 
	C842.647034,424.739319 841.729370,423.560577 841.119385,422.947937 
	C841.161377,422.931946 841.081482,422.966797 840.602417,422.999207 
	C839.138733,422.998260 838.074280,422.999756 836.559143,422.996216 
	C829.741089,423.655670 823.373596,424.320190 816.540894,425.011292 
	C813.256897,425.074677 810.428223,424.971191 807.620972,425.170410 
	C784.162659,426.835175 760.708496,428.557556 737.252747,430.257812 
	C731.965759,430.641052 726.669556,430.924042 721.395203,431.434967 
	C720.032898,431.566986 717.025818,431.519989 720.127075,434.460999 
	C720.573853,435.298309 720.858887,435.862457 720.883057,436.565674 
	C719.807983,437.374390 718.993713,438.043976 718.216248,439.022522 
	C717.711731,440.695770 717.170471,442.060059 716.540283,443.648590 
	C717.347229,443.802673 718.126160,443.951416 718.924561,444.469849 
	C717.971008,446.558868 716.998108,448.278229 716.025269,449.997589 
	C714.395081,443.940094 712.764832,437.882629 711.010620,431.113190 
	C707.950989,427.230743 705.015320,424.060272 702.056824,420.921631 
	C702.033936,420.953461 702.111145,420.967163 702.254089,420.635254 
	C701.914246,419.805054 701.431519,419.306793 700.807617,418.510559 
	C699.715637,416.997223 698.764832,415.781830 697.674194,414.387695 
	C712.574585,410.152985 726.810120,406.107239 741.431091,402.027344 
	C747.663208,400.339966 753.543640,398.795685 759.344666,396.995361 
	C763.217163,395.793488 766.976624,394.227173 771.191895,392.968353 
	C776.891418,391.718079 782.201599,390.380524 787.473938,388.907562 
	C790.712280,388.002838 793.892700,386.890930 797.494263,385.928009 
	C802.353455,384.785400 806.847656,383.688049 811.275635,382.368500 
	C822.548462,379.009277 833.688110,374.899536 846.209045,377.032928 
	C851.158569,377.749634 855.703796,378.329559 860.248962,378.909454 
	C860.332397,378.342773 860.415833,377.776093 860.499268,377.209381 
	C856.363953,376.378937 852.228699,375.548462 847.973389,374.396912 
	C845.164673,372.771729 842.475952,371.467590 839.787231,370.163483 
	C839.819580,369.950439 839.851929,369.737427 839.884277,369.524384 
	C841.570923,369.706329 843.257568,369.888275 845.017456,370.196808 
	C845.187744,370.360168 845.284668,370.396881 845.381592,370.433594 
	C845.278809,370.269287 845.176025,370.104919 844.908936,369.649170 
	C843.461304,368.589417 842.177979,367.821075 840.571533,366.940918 
	C832.272888,361.266876 824.321289,355.669952 816.315430,350.151794 
	C807.359558,343.978882 798.354553,337.877350 789.326050,331.739685 
	C789.281128,331.734650 789.209656,331.789917 789.211548,331.472900 
	C788.516296,330.708130 787.819092,330.260376 787.081177,329.847656 
	C787.040466,329.882690 787.147766,329.876831 787.052673,329.648010 
	C786.737061,329.151917 786.456055,328.988617 785.895874,328.672668 
	C780.410645,325.014557 775.144165,321.613068 769.668152,317.930969 
	C768.219482,315.628815 766.635010,313.740051 765.798157,311.563782 
	C760.950745,298.957794 762.903564,286.065765 764.492371,273.210999 
	C764.997620,269.122314 765.577637,265.042847 766.123474,260.959167 
	C759.174011,273.378510 753.326294,286.010559 747.346069,298.579651 
	C746.711609,299.913300 745.453125,301.649078 744.226257,301.891144 
	C734.476257,303.814758 724.663879,305.422119 714.883789,306.742798 
	C717.887329,297.448120 720.877625,288.537140 723.867981,279.626160 
	C723.295959,279.418610 722.723938,279.211029 722.151917,279.003448 
	C721.770081,279.643921 721.309204,280.250427 721.017944,280.929718 
	C709.764526,307.174469 698.456787,333.396240 687.310303,359.686401 
	C679.407288,378.326233 671.739136,397.065613 663.881531,415.724884 
	C663.621033,416.343414 662.437256,416.573151 661.712280,416.703918 
	C661.624207,416.276398 661.507751,416.129089 661.495422,415.836060 
	C661.599548,415.690399 661.555237,415.335052 661.600952,414.864410 
	C661.540833,413.776306 661.434937,413.158813 661.465149,412.141846 
	C661.413330,408.806427 661.225464,405.870483 661.011841,402.612244 
	C660.696655,401.526489 660.407288,400.763000 660.470459,399.921814 
	C661.802185,398.167603 662.781433,396.491089 663.807739,394.409363 
	C664.860657,388.347321 665.866516,382.690552 667.095337,376.725739 
	C667.268799,375.487274 667.219299,374.556824 667.315247,373.259033 
	C667.696106,371.599731 667.931580,370.307709 668.396729,368.747192 
	C668.444580,366.635254 668.262695,364.791840 668.074341,362.561859 
	C668.114380,361.478485 668.160767,360.781677 668.161133,360.079041 
	C668.114990,360.073151 668.151794,360.158508 668.518555,360.198822 
	C668.896484,359.527069 668.907715,358.815033 669.027100,357.715515 
	C677.286194,326.389343 685.437134,295.450592 693.872559,263.431793 
	C691.122070,266.600128 689.052002,268.984589 686.697021,271.164917 
	C684.415771,268.923126 682.419556,266.885468 680.277649,264.534973 
	C677.874451,262.082458 675.452332,260.086700 673.414001,257.755310 
	C671.911621,256.036896 670.910645,253.880127 670.003723,251.779633 
	C675.803040,247.373398 681.361084,243.199844 686.749451,238.817810 
	C695.572083,231.642929 704.284363,224.332458 713.295166,217.001038 
	C714.174988,216.716522 714.802917,216.510193 715.430786,216.303864 
	C722.050781,222.138840 728.604309,228.052521 735.345093,233.744476 
	C736.747864,234.928986 738.784058,235.700195 740.629700,235.970627 
	C757.412537,238.429581 774.228394,240.665863 790.999878,243.197113 
	C792.606262,243.439575 794.578369,244.913055 795.370056,246.365814 
	C816.086914,284.382477 836.638550,322.489197 857.296936,360.537933 
	C863.014771,371.069031 869.435425,381.244904 874.655212,392.009705 
	C877.539001,397.956970 878.601501,404.787384 880.860840,412.502808 
	C893.852722,411.114349 908.101379,409.591614 923.137512,407.984680 
	C922.328918,416.891815 921.552795,425.441711 920.618408,434.744568 
	C919.962341,439.660553 919.464417,443.823578 918.966431,447.986572 
z"/>
<path fill="#FDFCEB" opacity="1.000000" stroke="none" 
	d="
M788.070312,579.754761 
	C787.309570,581.060669 785.892151,582.363464 785.886047,583.672852 
	C785.787354,604.757935 785.868286,625.843933 785.905396,647.666382 
	C785.859253,648.603577 785.816162,648.804077 785.549316,649.316467 
	C785.420227,652.416138 785.514893,655.204041 785.725098,658.116333 
	C785.840698,658.240784 786.130310,658.063416 786.130310,658.063416 
	C786.025024,658.511658 785.919739,658.959900 785.581665,659.808105 
	C785.182251,660.544800 785.273987,660.781067 785.624207,660.916870 
	C785.675781,677.942505 785.727356,694.968201 785.910095,712.435669 
	C785.981140,713.063171 785.920959,713.248779 785.626221,713.882324 
	C785.490540,714.885681 785.589539,715.441040 785.688477,715.996338 
	C785.731567,759.015930 785.774597,802.035583 785.646973,845.625488 
	C785.318359,846.526733 785.395874,846.799988 785.708679,847.015442 
	C785.769287,847.474487 785.829895,847.933533 785.922546,849.190186 
	C785.966980,859.999512 785.979370,870.011230 785.909790,880.263550 
	C785.800110,880.671448 785.772400,880.838684 785.575500,881.171387 
	C785.507019,881.891113 785.607727,882.445435 785.755920,883.465698 
	C785.811279,895.670898 785.774536,907.410461 785.877136,919.148865 
	C785.891846,920.834229 786.578308,922.513733 787.121399,924.503296 
	C786.136108,925.543640 784.994080,926.892700 783.828796,926.913025 
	C773.614685,927.091187 763.396057,926.988159 753.179871,927.086426 
	C751.452820,927.103027 749.731262,927.684082 747.701416,928.015747 
	C746.991943,927.980347 746.588196,927.933533 745.842041,927.628540 
	C722.334473,927.244629 699.169189,927.118958 675.901611,926.634644 
	C675.873535,925.535828 675.947876,924.795593 676.022217,924.055420 
	C699.118164,924.055420 722.214172,924.055420 745.800537,924.055420 
	C745.800537,921.528809 745.800354,919.580078 745.800537,917.631348 
	C745.812927,813.918518 745.822266,710.205750 745.851013,606.492920 
	C745.851990,602.999207 746.048889,599.505493 746.600525,595.578003 
	C749.013123,594.807861 750.973450,594.429443 752.947083,594.142029 
	C763.629089,592.586670 763.628723,592.594177 765.356873,582.110535 
	C765.572571,580.801575 765.859741,579.502930 766.035095,578.189026 
	C770.386719,545.585754 774.727722,512.981018 779.070557,480.376556 
	C781.213501,480.153046 783.356445,479.929565 786.000977,479.653748 
	C786.000977,506.726105 786.000977,533.591125 786.000977,560.804138 
	C803.406311,560.804138 820.331970,560.804138 837.645691,560.804138 
	C837.645691,567.514954 837.645691,573.885315 837.645691,579.913208 
	C822.362793,579.913208 807.106689,579.913208 791.198547,579.630005 
	C789.721130,579.482788 788.895691,579.618774 788.070312,579.754761 
z"/>
<path fill="#FAFBEB" opacity="1.000000" stroke="none" 
	d="
M640.007324,106.919159 
	C638.481812,106.761230 636.956299,106.603294 635.430786,106.445366 
	C635.398804,106.180649 635.366821,105.915932 635.334839,105.651215 
	C635.959229,105.417816 636.583679,105.184418 638.032837,105.001221 
	C643.803589,105.051414 648.749695,105.051414 653.695740,105.051414 
	C653.702087,104.631706 653.708435,104.211998 653.714783,103.792297 
	C647.841736,103.496239 641.968628,103.200180 636.095581,102.904129 
	C636.197205,102.430725 636.144592,101.678009 636.422791,101.524361 
	C645.856567,96.314613 655.182312,90.882561 664.815369,86.066277 
	C679.213989,78.867409 693.902100,79.811798 708.177063,86.374725 
	C721.350891,92.431381 725.500427,102.315987 721.420227,116.425034 
	C722.672302,116.969040 723.968567,117.545090 725.274780,118.097305 
	C728.624268,119.513329 730.271301,121.773010 729.213074,125.559860 
	C727.895508,130.274979 726.680054,135.018616 725.051880,141.134674 
	C723.997375,138.166168 723.465210,136.668213 722.664551,134.568588 
	C722.114563,133.214127 721.833191,132.461349 721.551758,131.708557 
	C721.291748,131.780273 721.031799,131.851974 720.771851,131.923691 
	C721.067078,133.047897 721.362305,134.172104 721.657593,135.296326 
	C722.383179,139.311615 723.108826,143.326904 723.450684,147.821198 
	C718.240051,149.212143 713.357971,149.908035 708.608521,151.122482 
	C706.036560,151.780167 704.207336,153.559265 705.969971,157.001678 
	C706.617615,157.804428 707.383118,158.732849 707.980896,158.635147 
	C712.361084,157.919327 716.707581,156.997910 721.392944,156.258652 
	C723.112488,159.353485 724.503723,162.331009 725.895020,165.308548 
	C725.895020,165.308548 725.880737,165.678528 725.885132,166.228989 
	C724.975830,171.841187 724.062012,176.902908 723.077759,182.219757 
	C723.007446,182.651321 723.007568,182.827774 722.773804,183.308975 
	C720.709656,192.405884 718.879395,201.198013 716.867432,210.256744 
	C716.368530,211.316696 716.051208,212.110062 715.338562,212.962189 
	C708.164734,213.490234 701.390381,214.068970 694.603882,214.331757 
	C693.325562,214.381241 691.494080,213.524857 690.769897,212.490128 
	C680.898499,198.385818 671.177246,184.176392 660.906982,169.248703 
	C665.258789,167.040268 668.222534,164.822540 671.525452,164.043823 
	C675.505798,163.105392 679.807922,163.631744 683.906799,163.063156 
	C686.931580,162.643616 687.971008,163.766647 688.707764,166.495789 
	C689.955811,171.119354 691.645142,175.623825 693.126953,180.553436 
	C695.462708,188.204742 701.893677,189.659668 708.985779,184.586029 
	C707.329468,183.760056 705.639282,182.917206 704.003906,181.658859 
	C702.138489,173.158066 700.218323,165.072754 698.235352,156.587952 
	C697.687134,151.827301 693.857727,152.916168 691.563904,152.777420 
	C684.522766,152.351593 677.439575,152.622421 670.572998,152.622421 
	C669.855408,149.136597 669.711060,146.574707 668.794373,144.327911 
	C665.940247,137.332230 667.078247,131.572449 673.550171,127.065460 
	C674.017151,126.910751 674.139771,126.686546 674.388245,126.249115 
	C674.873413,125.789513 675.089294,125.464737 675.238403,125.143929 
	C675.171509,125.147896 675.227539,125.269554 675.505554,125.155663 
	C676.523560,124.367577 677.263550,123.693375 678.242432,122.964249 
	C678.769104,122.527946 679.057007,122.146584 679.732605,121.603836 
	C684.727539,120.680412 689.334839,119.918381 694.089966,119.521988 
	C699.154358,120.328743 704.070801,120.769859 708.987183,121.210976 
	C709.037476,120.756142 709.087769,120.301308 709.138000,119.846474 
	C708.416443,119.585396 707.694885,119.324326 707.032593,118.729126 
	C711.568481,114.222366 711.570190,111.060219 706.813477,106.933899 
	C706.338989,106.918831 706.149109,106.945610 705.969482,106.999420 
	C705.979736,107.026443 706.036621,107.037010 705.986572,106.753403 
	C705.615479,106.303474 705.294312,106.137161 704.974060,105.997025 
	C704.974915,106.023201 705.027161,106.026825 705.060791,105.671616 
	C698.744995,96.836777 685.560547,94.270805 674.551025,99.552689 
	C669.163452,101.875031 664.158936,104.091255 658.713745,106.233398 
	C652.184509,106.412598 646.095947,106.665886 640.007324,106.919159 
M700.862061,195.279877 
	C702.875305,194.991425 704.888489,194.702988 707.606750,194.313522 
	C703.955627,191.603592 693.361755,193.064453 691.436401,196.197220 
	C694.475830,195.884552 697.235413,195.600677 700.862061,195.279877 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M171.965546,272.197632 
	C174.189133,292.380676 176.058090,312.539001 177.978531,332.692444 
	C181.412354,368.727295 184.948532,404.752655 188.229309,440.801361 
	C188.596008,444.830536 190.445175,445.737488 193.861450,445.991547 
	C215.031021,447.565826 236.192215,449.252563 257.358368,450.873566 
	C282.661652,452.811493 307.967377,454.717590 333.271606,456.643402 
	C362.895020,458.897949 392.517975,461.158936 422.649902,463.617065 
	C423.443634,463.740753 423.728638,463.664459 424.013641,463.588196 
	C424.374878,463.608551 424.736084,463.628906 425.368713,463.665649 
	C420.213287,464.914673 414.719269,467.441589 409.370758,467.164154 
	C380.534576,465.668213 351.734528,463.476379 322.135559,461.418884 
	C319.884918,461.264862 318.420715,461.216583 316.509766,461.064667 
	C311.353760,460.744965 306.644440,460.528931 301.618652,460.181458 
	C300.629608,460.132996 299.957031,460.215973 298.924377,460.237701 
	C298.321075,460.165192 298.077820,460.153900 297.502533,460.063660 
	C296.499634,460.037964 295.828735,460.091156 294.787628,460.173523 
	C293.927429,460.150055 293.437469,460.097443 292.634033,459.960144 
	C291.894623,459.927948 291.468658,459.980347 290.710022,460.034424 
	C290.165222,460.100647 289.953064,460.165192 289.386292,460.197449 
	C288.053711,460.455994 287.075806,460.746765 285.687500,461.070251 
	C284.214783,460.578949 283.152496,460.054932 281.471710,459.225861 
	C281.295624,461.560150 281.166931,463.266083 280.771057,464.987579 
	C280.152557,464.952271 279.801270,464.901398 279.369934,464.547668 
	C279.226349,463.833374 279.162842,463.421997 279.223236,462.603729 
	C278.965179,461.468475 278.583282,460.740051 278.201355,460.011658 
	C277.965332,461.084778 277.729279,462.157867 277.452942,463.666199 
	C277.171143,466.110046 276.929657,468.118622 276.361786,469.789978 
	C275.763031,469.300385 275.490662,469.148010 275.151062,468.550690 
	C275.083771,465.249786 275.083771,462.393860 275.083771,459.537933 
	C274.432526,459.555878 273.781250,459.573853 273.130005,459.591797 
	C273.130005,463.396484 273.130005,467.201172 273.058655,471.464996 
	C273.026245,475.982758 273.065216,480.041382 272.959686,484.385498 
	C272.211487,484.800507 271.607819,484.930023 270.889954,484.598175 
	C270.775787,476.553040 270.775787,468.969238 270.775787,464.587769 
	C270.129120,469.325745 269.058502,477.169586 267.786621,484.969604 
	C267.585327,484.925720 267.181824,484.842499 267.062592,484.408264 
	C266.539703,475.800446 266.136047,467.626892 265.732391,459.453308 
	C265.169800,459.466858 264.607178,459.480377 264.044586,459.493896 
	C264.044586,467.819366 264.044586,476.144806 263.630341,484.449799 
	C262.401489,484.358185 261.586853,484.287048 260.691650,483.765442 
	C260.611023,475.486969 260.611023,467.659027 260.611023,459.831055 
	C259.939758,459.840210 259.268463,459.849335 258.597198,459.858490 
	C258.597198,467.736725 258.597198,475.614990 258.293030,483.530029 
	C256.982880,483.410950 255.976898,483.255005 255.013504,482.625092 
	C255.056091,475.257294 255.056091,468.363495 255.056091,461.469666 
	C254.408493,461.482513 253.760910,461.495392 253.113312,461.508240 
	C253.113312,465.223938 252.959854,468.947815 253.157715,472.652924 
	C253.344940,476.158844 253.900925,479.645050 254.479248,483.418915 
	C254.878601,483.952179 255.044067,484.343384 255.315308,484.441162 
	C273.269379,490.913818 291.367889,496.638794 310.746368,496.924316 
	C324.535370,497.127502 338.319824,497.690491 352.103790,498.165192 
	C358.740143,498.393707 365.370758,498.789062 371.991913,499.482330 
	C371.944733,500.352631 371.909637,500.849487 371.416504,501.295593 
	C338.507263,500.143372 306.056244,499.037842 273.604828,497.942352 
	C258.197815,497.422211 242.790131,496.921265 227.382751,496.411621 
	C229.536041,487.735199 231.770248,479.077728 233.810730,470.374817 
	C234.938370,465.565216 235.741287,460.679474 236.800476,455.266510 
	C217.115555,453.817108 197.963760,452.406952 178.460144,450.970886 
	C177.260178,439.585266 175.984100,428.445068 174.931076,417.283813 
	C172.766281,394.338165 170.665039,371.386139 168.641846,348.427582 
	C166.602905,325.290222 164.661819,302.144043 162.808670,278.991211 
	C162.718826,277.868591 163.583878,276.169128 164.531433,275.564667 
	C166.727173,274.163971 169.246185,273.270111 171.965546,272.197632 
z"/>
<path fill="#1E0505" opacity="1.000000" stroke="none" 
	d="
M673.349121,127.074387 
	C667.078247,131.572449 665.940247,137.332230 668.794373,144.327911 
	C669.711060,146.574707 669.855408,149.136597 670.572998,152.622421 
	C677.439575,152.622421 684.522766,152.351593 691.563904,152.777420 
	C693.857727,152.916168 697.687134,151.827301 697.855591,156.708679 
	C694.187134,158.049179 690.851685,159.470657 687.480652,159.560867 
	C681.044434,159.733078 674.589355,159.064728 668.138611,158.956955 
	C667.080811,158.939301 665.823914,159.812531 664.981140,160.613113 
	C663.800842,161.734344 663.132996,163.446030 661.871460,164.420135 
	C659.253296,166.441742 658.778870,168.563782 659.791138,171.762589 
	C663.742920,184.249878 667.306824,196.860229 671.295776,209.335022 
	C671.762756,210.795273 673.795898,212.277206 675.402588,212.767426 
	C687.664795,216.508850 700.072693,219.370331 713.043152,217.079178 
	C704.284363,224.332458 695.572083,231.642929 686.749451,238.817810 
	C681.361084,243.199844 675.803040,247.373398 669.635315,251.797165 
	C667.277466,251.646149 665.605469,251.338562 663.933533,251.030960 
	C663.612122,250.938904 663.356140,250.755493 662.968750,250.037582 
	C662.578552,249.315292 662.321289,249.120941 661.999939,249.011383 
	C659.755005,246.845367 657.510132,244.679352 655.103821,241.901825 
	C654.520935,240.926239 654.099426,240.562164 653.677917,240.198090 
	C653.677917,240.198105 653.789734,240.211884 653.795593,239.815552 
	C653.532715,237.129471 653.046448,234.841095 653.031555,232.549698 
	C652.954163,220.601929 653.055725,208.652740 652.927002,196.705872 
	C652.909851,195.108917 651.938538,193.522232 651.409058,191.930801 
	C651.171265,191.944717 650.933472,191.958633 650.111694,191.693604 
	C648.196960,190.997589 646.866089,190.580551 645.535278,190.163513 
	C645.101074,189.925583 644.666809,189.687653 644.073242,188.873566 
	C642.491394,187.345703 641.068909,186.394012 639.646362,185.442291 
	C639.646362,185.442291 639.640808,185.405090 639.647339,185.030807 
	C637.083923,178.635437 634.597229,172.576736 631.902222,166.612183 
	C630.857849,164.300919 629.341919,162.202744 628.040833,160.007492 
	C628.040833,160.007492 627.827698,159.746078 627.882812,159.151840 
	C627.250793,156.690506 626.563660,154.823425 625.876587,152.956329 
	C625.670654,152.517960 625.464722,152.079605 625.475464,150.963715 
	C624.980774,146.188141 624.269409,142.090118 623.558044,137.992081 
	C623.417603,137.546890 623.277161,137.101685 623.368713,136.038467 
	C623.564270,130.805313 628.129150,132.886734 629.988770,131.031067 
	C631.745422,131.413849 633.502014,131.796631 635.764526,132.636353 
	C639.962646,134.672974 643.315308,134.508423 646.053833,131.188873 
	C646.863281,130.535049 647.672791,129.881210 649.141357,129.110672 
	C650.536682,128.001678 651.272888,127.009384 652.009094,126.017097 
	C652.009094,126.017097 651.997925,125.999977 652.218750,125.888321 
	C652.703003,125.577118 652.890747,125.321877 653.002869,125.010956 
	C655.916931,121.854759 658.830933,118.698563 662.002319,115.047806 
	C663.019592,113.956055 663.779541,113.358864 664.835449,112.763290 
	C665.421021,112.510040 665.710510,112.255165 666.098389,111.873260 
	C666.196716,111.746223 666.517578,111.763824 666.995483,111.857307 
	C671.338440,112.144432 675.203552,112.338074 678.903076,112.523422 
	C678.669495,111.513756 678.392639,110.317123 678.485718,109.140686 
	C679.587952,109.095039 680.320312,109.029205 681.378357,108.956459 
	C682.129028,109.036438 682.554016,109.123337 682.934814,109.512878 
	C680.450806,115.164177 684.709045,113.600487 687.428223,114.026131 
	C688.467468,114.475021 689.213135,114.825775 689.991089,115.573059 
	C689.059265,119.478493 686.053833,118.530037 684.673950,117.399513 
	C680.408325,113.904762 678.378662,115.606155 676.582153,119.933830 
	C675.819580,120.572594 675.354736,121.152275 674.526367,121.777870 
	C671.428955,123.733307 668.695068,125.642822 665.961121,127.552330 
	C666.197693,127.953171 666.434265,128.354004 666.670837,128.754852 
	C668.896912,128.194687 671.123047,127.634537 673.349121,127.074387 
z"/>
<path fill="#1C0505" opacity="1.000000" stroke="none" 
	d="
M140.115662,495.240601 
	C142.419708,495.160492 144.723770,495.080353 147.493317,495.524536 
	C147.909683,500.005829 147.860550,503.962860 147.419647,508.007751 
	C127.879082,511.379120 108.708946,514.544678 89.592430,518.006287 
	C82.327225,519.321899 75.185349,521.318542 67.707199,523.312256 
	C67.285271,524.426880 67.020012,525.234009 67.019394,526.041382 
	C66.997047,555.090088 66.988922,584.138855 67.033791,613.187500 
	C67.035744,614.444702 67.557053,615.701050 67.901001,617.423462 
	C67.928787,622.932800 67.891998,627.976562 67.722626,633.115356 
	C67.413208,633.540527 67.504486,633.797546 67.929077,634.424927 
	C67.948189,637.258057 67.902077,639.647705 67.604111,642.360657 
	C67.235016,643.498535 67.015533,644.313110 67.015198,645.127747 
	C66.998558,685.674500 66.991074,726.221191 67.041130,766.767883 
	C67.042870,768.173523 67.639648,769.578430 68.041229,771.409363 
	C68.062073,773.563599 68.000778,775.292114 67.672249,777.325684 
	C67.585663,781.414062 67.766319,785.197449 68.037048,789.443481 
	C68.066681,798.274475 68.006233,806.642822 67.565094,815.071289 
	C63.664726,818.751038 64.032417,823.434448 68.048141,825.366333 
	C68.078026,826.497375 68.018478,827.255981 67.727722,828.206055 
	C67.651222,829.260925 67.805916,830.124329 68.051682,831.399048 
	C68.085182,833.207397 68.027611,834.604492 67.640488,836.006042 
	C66.031334,836.661682 64.751717,837.312805 63.373413,837.508118 
	C63.277691,768.891174 63.277245,700.730103 63.284889,632.569031 
	C63.289467,591.740723 63.306667,550.912476 63.495365,509.967896 
	C63.857910,509.629791 63.981426,509.377625 64.043091,509.095123 
	C67.597664,508.419006 71.152245,507.742889 74.886642,507.032593 
	C74.886642,492.790131 74.886642,478.864197 74.886642,464.452087 
	C79.886436,463.190765 84.780891,461.956024 89.896736,460.665466 
	C88.555626,448.004303 87.250244,435.680481 85.944862,423.356628 
	C86.481201,423.212830 87.017532,423.069031 87.553871,422.925232 
	C88.899757,425.795624 90.245651,428.665985 91.920647,432.132782 
	C94.362885,442.192444 96.476013,451.655701 98.445129,461.559052 
	C98.301109,475.729980 98.301109,489.460815 98.301109,503.896576 
	C112.980812,500.857727 126.548233,498.049164 140.115662,495.240601 
z"/>
<path fill="#6898A5" opacity="1.000000" stroke="none" 
	d="
M300.048584,942.036133 
	C300.067688,942.210144 300.086792,942.384216 299.738953,943.076721 
	C297.945831,951.992432 297.945831,951.992493 289.157532,951.992615 
	C241.106415,951.993469 193.055313,951.994324 145.004196,951.995117 
	C145.603195,951.074646 146.005707,949.630310 146.833466,949.319824 
	C151.718552,947.487732 156.620865,945.543152 161.692291,944.441833 
	C163.805862,943.982910 166.457321,945.253662 168.676956,946.184387 
	C173.428192,948.176636 176.870270,946.105042 176.998810,940.924622 
	C177.002945,940.758057 176.997833,940.591370 177.000015,940.424744 
	C177.120560,931.179932 177.120605,931.179932 185.369354,928.432251 
	C183.598648,928.288330 181.828018,928.019470 180.057236,928.018616 
	C131.903275,927.995605 83.749268,928.014343 35.595398,927.942078 
	C33.873543,927.939514 32.152962,927.083801 31.015669,926.445068 
	C33.744152,926.433105 35.888672,926.748169 38.033371,926.749390 
	C132.737106,926.802979 227.440842,926.827942 322.144592,926.870483 
	C323.184113,926.870972 324.223541,927.058105 325.695984,927.199768 
	C324.696259,927.993591 324.178070,928.405090 323.313721,928.879639 
	C316.646423,931.794495 310.247681,934.491699 304.055786,937.600769 
	C302.396118,938.434082 301.367126,940.523438 300.048584,942.036133 
z"/>
<path fill="#F9F7E8" opacity="1.000000" stroke="none" 
	d="
M147.811417,507.919861 
	C147.860550,503.962860 147.909683,500.005829 147.920914,495.599365 
	C149.576797,494.793427 151.277664,494.099091 152.963272,494.134369 
	C165.582397,494.398499 178.199539,494.774200 190.815231,495.179291 
	C202.730179,495.561890 214.642609,496.022919 226.969452,496.430542 
	C242.790131,496.921265 258.197815,497.422211 273.604828,497.942352 
	C306.056244,499.037842 338.507263,500.143372 371.718872,501.601257 
	C384.320953,502.313019 396.162628,502.668335 408.010010,503.357300 
	C408.034241,504.134460 408.052765,504.577972 407.947266,505.392517 
	C407.791229,506.545502 407.759247,507.327362 407.553253,508.397034 
	C407.282715,509.789581 407.186218,510.894348 407.089691,511.999115 
	C407.049774,512.432678 407.009827,512.866272 406.508514,513.701416 
	C392.898834,513.794922 379.751190,513.454773 366.602112,513.184448 
	C317.349152,512.171997 268.096313,511.150116 218.842148,510.198120 
	C195.223465,509.741577 171.601913,509.433350 148.025772,508.760498 
	C147.983734,508.281952 147.897583,508.100922 147.811417,507.919861 
z"/>
<path fill="#1F0807" opacity="1.000000" stroke="none" 
	d="
M661.037659,402.934509 
	C661.225464,405.870483 661.413330,408.806427 661.094727,412.178345 
	C657.274048,413.696411 653.868408,414.559479 650.661743,415.898895 
	C637.301453,421.479187 624.321167,428.214783 610.579529,432.559814 
	C599.192993,436.160217 595.561768,445.441681 589.056641,454.250061 
	C598.694580,455.998993 607.356934,457.450836 615.959290,459.198273 
	C618.891052,459.793762 621.671875,461.132507 624.521667,462.131622 
	C622.624573,467.255219 620.727478,472.378845 618.790222,477.611023 
	C607.064209,475.614655 595.489075,473.643951 583.253052,471.505005 
	C580.112671,471.552307 577.091614,470.934937 575.250305,472.132111 
	C568.438904,476.560608 561.574158,481.097290 555.563354,486.517883 
	C548.430969,492.949921 541.588501,495.708069 533.057678,489.468109 
	C529.881714,487.145020 526.917480,488.320190 524.032654,490.575470 
	C520.643982,493.224609 517.027466,495.582336 513.509827,498.066467 
	C514.471497,493.679962 515.433228,489.293488 516.437439,484.503723 
	C516.569397,481.916840 516.658875,479.733276 516.876038,477.369537 
	C519.287903,477.640320 521.735168,477.713898 523.823975,478.616791 
	C528.953735,480.834137 533.315796,479.314697 537.972778,477.017639 
	C550.043457,471.063995 562.305054,465.498566 574.433960,459.660889 
	C576.006653,458.903961 577.508789,457.664917 578.600525,456.295868 
	C589.893555,442.133911 601.372620,428.105957 612.194092,413.589081 
	C616.899902,407.276276 620.202209,399.917267 624.396606,392.798492 
	C633.336487,372.477448 642.016968,352.389252 650.709473,332.306244 
	C654.100159,324.472412 657.517761,316.650238 661.047119,309.234314 
	C655.667358,328.079651 650.299011,346.555298 644.604492,364.929871 
	C641.622498,374.552063 638.068176,383.996826 634.521362,393.799377 
	C632.992859,397.233154 631.715393,400.389252 630.253418,403.788971 
	C630.126770,404.328278 630.184509,404.623932 630.242249,404.919586 
	C630.541199,404.662048 630.840210,404.404510 631.339783,404.098999 
	C631.540405,404.050995 631.942505,403.958435 632.391663,403.972931 
	C633.737244,404.046722 634.633728,404.106049 635.363342,404.468018 
	C634.475586,405.831055 633.754639,406.891418 632.903381,408.252716 
	C633.826416,410.216553 634.879822,411.879425 636.372498,414.235687 
	C637.745361,411.746002 638.354675,410.640991 639.239746,409.473145 
	C639.691711,409.343262 639.867981,409.276215 640.315552,409.426086 
	C641.691956,409.917511 642.843262,410.074249 643.894287,410.486572 
	C649.108398,412.531891 659.851685,409.621552 661.037659,402.934509 
M628.379150,407.064758 
	C628.737122,407.183624 629.095032,407.302490 629.839111,406.952026 
	C629.788025,406.654663 629.736877,406.357269 629.685730,406.059875 
	C629.373840,406.290466 629.061951,406.521057 628.379150,407.064758 
z"/>
<path fill="#FAFBEB" opacity="1.000000" stroke="none" 
	d="
M624.141968,393.033417 
	C620.202209,399.917267 616.899902,407.276276 612.194092,413.589081 
	C601.372620,428.105957 589.893555,442.133911 578.600525,456.295868 
	C577.508789,457.664917 576.006653,458.903961 574.433960,459.660889 
	C562.305054,465.498566 550.043457,471.063995 537.972778,477.017639 
	C533.315796,479.314697 528.953735,480.834137 523.823975,478.616791 
	C521.735168,477.713898 519.287903,477.640320 516.627319,477.160431 
	C513.609253,476.366608 510.967590,475.601715 506.666565,474.356415 
	C511.468506,470.105774 515.554749,466.488647 520.216431,462.523315 
	C524.621582,459.896210 528.451416,457.617371 532.235107,455.264648 
	C532.189087,455.190765 532.210632,455.363556 532.546875,455.391296 
	C533.974243,454.652954 535.065369,453.886871 536.120178,453.143005 
	C536.083862,453.165192 536.156982,453.208740 536.492065,453.117310 
	C541.690613,449.858978 546.554199,446.692108 551.739990,443.373230 
	C554.657410,441.566040 557.649231,440.310333 559.783020,438.190735 
	C573.239624,424.823883 585.615906,410.541656 595.796448,394.453796 
	C596.889832,392.725952 598.563660,391.365417 600.010010,390.239746 
	C598.153748,395.986908 596.255798,401.328857 594.243347,406.992859 
	C601.719666,403.163330 608.782593,399.545563 616.233215,395.951202 
	C617.747742,395.295715 618.874512,394.616821 620.169983,393.806030 
	C620.645325,393.390900 620.951965,393.107605 621.738464,392.869202 
	C622.859497,392.953888 623.500732,392.993652 624.141968,393.033417 
z"/>
<path fill="#1F0807" opacity="1.000000" stroke="none" 
	d="
M785.991821,880.022949 
	C785.979370,870.011230 785.966980,859.999512 786.011047,849.526184 
	C791.184998,850.293457 796.302429,851.522400 802.176025,852.834717 
	C810.308838,854.591858 817.686584,856.260254 825.061768,857.940186 
	C841.067993,861.585999 857.072937,865.237793 872.836060,869.198242 
	C870.960999,869.581604 869.325867,869.621277 867.696167,869.737244 
	C866.550293,869.818726 865.411743,870.003784 864.269958,870.142639 
	C864.327454,870.608643 864.384949,871.074707 864.442444,871.540771 
	C872.923279,873.035217 881.404175,874.529663 890.269653,876.136475 
	C894.657532,876.948242 898.660828,877.647583 902.755371,878.719971 
	C898.208008,879.092957 893.569336,879.092957 888.930664,879.092957 
	C888.893799,879.611328 888.856873,880.129639 888.819946,880.648010 
	C896.559509,881.405457 904.299072,882.162964 912.437866,882.993530 
	C914.208923,886.530518 917.181824,885.982849 919.973083,885.935791 
	C922.113586,885.899719 924.264221,885.816162 926.388672,886.002136 
	C926.839844,886.041565 927.196716,887.157715 927.283936,887.880615 
	C921.752441,892.025696 917.957581,896.657776 918.911438,903.954895 
	C919.666077,909.727600 923.172546,913.445618 926.951904,917.024963 
	C925.067261,917.785400 923.529419,918.412598 921.662964,919.025513 
	C920.899109,919.052612 920.463928,919.093933 919.737793,919.014648 
	C918.442261,918.216370 917.437683,917.538635 916.208252,916.578979 
	C910.269043,913.411072 908.529907,908.313477 908.309448,902.406189 
	C908.167725,898.608093 908.204773,894.799988 907.929260,891.013306 
	C907.881714,890.359314 906.672119,889.789917 905.676880,889.106445 
	C904.914612,888.995605 904.474854,888.959778 903.790527,888.574707 
	C892.798035,887.390320 882.054443,886.490479 871.299316,885.760742 
	C868.197998,885.550293 865.063477,885.829102 861.629761,885.907593 
	C860.895691,885.911804 860.476074,885.892090 859.791016,885.543701 
	C856.007690,885.128113 852.489868,885.041016 848.647766,884.724243 
	C847.560242,884.638245 846.796936,884.781921 845.765625,884.594849 
	C835.307251,883.469727 825.122742,882.587646 814.923950,881.925293 
	C810.953125,881.667419 806.950195,881.904663 802.662598,881.934753 
	C801.963501,881.947815 801.563721,881.942566 800.900635,881.588806 
	C795.755554,880.834534 790.873657,880.428772 785.991821,880.022949 
M846.709473,865.977966 
	C844.815674,865.790222 842.921936,865.602478 841.028137,865.414734 
	C840.991333,865.872192 840.954468,866.329590 840.917664,866.787048 
	C845.759277,867.694580 850.600891,868.602112 855.442505,869.509644 
	C855.546631,868.984375 855.650696,868.459167 855.754822,867.933899 
	C853.014160,867.277161 850.273499,866.620422 846.709473,865.977966 
z"/>
<path fill="#1F0606" opacity="1.000000" stroke="none" 
	d="
M599.968262,389.834534 
	C598.563660,391.365417 596.889832,392.725952 595.796448,394.453796 
	C585.615906,410.541656 573.239624,424.823883 559.783020,438.190735 
	C557.649231,440.310333 554.657410,441.566040 551.383911,443.206421 
	C545.474731,445.525818 540.243896,447.860016 534.657227,450.223236 
	C530.952026,451.850464 527.602539,453.448639 524.253113,455.046875 
	C521.413879,457.108734 518.574646,459.170624 515.735413,461.232513 
	C515.307068,460.830719 514.878723,460.428955 514.450378,460.027191 
	C516.213196,457.944855 517.771057,455.636841 519.773499,453.818390 
	C529.721680,444.784149 539.802246,435.895721 549.782898,426.896759 
	C550.596191,426.163422 551.202209,425.054749 551.578857,424.003754 
	C561.563416,396.139587 571.505066,368.260040 581.828979,340.660461 
	C582.203918,361.394714 582.203918,381.851654 582.203918,400.890808 
	C588.414673,382.773712 595.029785,363.072968 601.955383,343.482025 
	C604.566650,336.095551 607.896973,328.963318 610.946594,322.156494 
	C611.046326,323.483093 611.098999,324.368439 611.117249,325.641479 
	C608.613464,329.799713 608.201233,333.879913 608.789185,338.293396 
	C610.017090,347.511597 610.995361,356.763062 612.009033,366.368835 
	C611.323059,368.247559 610.829468,369.825165 610.063965,371.257416 
	C606.742065,377.472900 603.340759,383.645996 599.968262,389.834534 
z"/>
<path fill="#679BA9" opacity="1.000000" stroke="none" 
	d="
M801.163940,881.937256 
	C801.563721,881.942566 801.963501,881.947815 802.912842,882.209351 
	C805.309082,882.977051 807.155762,883.488525 809.003296,884.404053 
	C808.823975,887.646973 810.071167,888.846741 812.992249,889.093262 
	C815.364929,889.293457 817.666748,890.332825 820.009644,891.363586 
	C822.012817,893.151428 824.006409,894.575745 826.000000,896.421509 
	C825.725769,900.457336 826.129395,903.583740 830.380188,904.884399 
	C831.164429,905.124390 831.472290,906.921570 831.538574,908.000000 
	C827.473267,908.000000 823.869385,908.000000 820.265564,908.000000 
	C820.223022,908.562073 820.180481,909.124207 820.137939,909.686279 
	C820.736938,910.102112 821.348572,910.892944 821.932800,910.873291 
	C825.840881,910.741089 829.070801,911.600830 832.327515,914.277771 
	C835.693970,917.044922 838.786255,914.771790 839.250305,909.706299 
	C841.716736,908.941772 843.938965,908.036743 846.148010,908.067749 
	C866.442078,908.352539 886.803650,906.676819 907.043335,910.358643 
	C908.916809,914.720581 911.379639,917.761353 916.433044,916.860840 
	C917.437683,917.538635 918.442261,918.216370 919.945984,919.232056 
	C921.028137,919.663086 921.611206,919.756104 922.203247,919.838684 
	C922.212280,919.828247 922.230713,919.802124 922.230713,919.802124 
	C921.969116,924.087524 918.735168,923.006348 916.288818,922.923401 
	C910.671509,922.732910 905.062683,922.301758 899.448547,922.004089 
	C886.973206,921.342468 874.501160,920.344910 862.018860,920.185486 
	C854.243286,920.086182 846.452576,921.281982 838.666077,921.862000 
	C837.011780,921.985168 835.346008,921.949463 833.685974,922.001343 
	C819.684692,922.438904 805.683533,922.879883 790.963257,923.614868 
	C789.480469,923.874512 788.716675,923.839111 787.779968,923.444580 
	C787.737610,918.053040 787.937683,913.021362 787.982117,907.988281 
	C788.046875,900.659363 787.999573,893.329468 788.417480,886.000000 
	C791.688843,885.901855 795.173828,886.777222 795.437073,882.003662 
	C797.639038,881.980713 799.401489,881.958984 801.163940,881.937256 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M316.956512,461.168335 
	C318.420715,461.216583 319.884918,461.264862 321.670349,461.484375 
	C322.393616,462.288483 322.760956,463.448730 323.202759,463.477783 
	C337.019409,464.387177 350.924652,464.558136 364.623169,466.333130 
	C369.610840,466.979431 375.469360,465.803589 380.057556,470.079041 
	C381.681183,471.591980 385.075684,471.133942 387.620178,471.747925 
	C390.511139,472.445557 392.107208,469.415375 393.556183,469.161926 
	C398.187256,468.351807 403.161316,469.133789 407.947601,469.738953 
	C410.262543,470.031586 412.399902,471.527130 414.712738,472.081146 
	C419.162109,473.146942 419.934967,475.610413 417.922943,479.680267 
	C415.303070,484.979767 411.537872,486.119965 405.830597,486.186890 
	C388.369995,486.391510 370.919312,487.437836 352.747986,488.296204 
	C351.712616,488.477051 351.411041,488.423248 350.838623,487.944031 
	C344.569702,487.075989 338.596252,486.281616 332.604431,486.102631 
	C329.349518,486.005402 326.063354,486.954895 322.479309,487.369385 
	C321.765991,487.169739 321.364624,487.032898 320.647858,486.635986 
	C316.546234,486.248230 312.759338,486.138031 308.973999,485.987976 
	C305.136047,485.835876 302.725952,484.403809 302.953918,479.971558 
	C303.174530,475.682159 303.130646,471.367432 302.961029,467.073364 
	C302.823273,463.584503 303.944672,461.848938 307.741089,461.932922 
	C310.805756,462.000702 313.884186,461.446564 316.956512,461.168335 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M147.981659,509.057983 
	C171.601913,509.433350 195.223465,509.741577 218.842148,510.198120 
	C268.096313,511.150116 317.349152,512.171997 366.602112,513.184448 
	C379.751190,513.454773 392.898834,513.794922 406.509216,514.030090 
	C418.202820,514.272827 429.434357,514.589355 440.665924,514.904053 
	C454.064117,515.279480 467.462341,515.653259 481.287872,516.110962 
	C478.717224,518.817566 475.719238,521.441040 471.921875,524.080322 
	C464.659882,523.740662 458.200958,523.287720 451.734131,523.046448 
	C422.227081,521.945557 392.722748,520.732178 363.207977,519.890381 
	C334.604797,519.074463 305.991272,518.596924 277.380157,518.090515 
	C245.270432,517.522217 213.158340,517.088135 180.626373,516.455078 
	C177.561874,516.375916 174.918259,516.437317 171.884277,516.311340 
	C163.974213,516.077637 156.454514,516.031372 148.576080,515.910156 
	C146.999298,512.129456 144.066406,511.632080 141.037933,511.602661 
	C143.542236,510.592224 145.761948,509.825104 147.981659,509.057983 
z"/>
<path fill="#FBFAEB" opacity="1.000000" stroke="none" 
	d="
M610.899536,321.715210 
	C607.896973,328.963318 604.566650,336.095551 601.955383,343.482025 
	C595.029785,363.072968 588.414673,382.773712 582.203918,400.890808 
	C582.203918,381.851654 582.203918,361.394714 582.141113,340.472443 
	C582.078369,340.007111 581.987061,339.994507 582.269409,339.873047 
	C582.658813,338.874115 582.765869,337.996674 582.872925,337.119232 
	C584.548523,329.240051 586.358215,321.386505 587.872925,313.476501 
	C591.506104,294.503967 591.462158,294.516327 608.507324,285.715668 
	C617.217957,281.218262 625.876892,276.620758 634.821533,272.365692 
	C628.601318,285.743530 622.118652,298.824158 615.388367,312.124512 
	C613.727051,315.467926 612.313293,318.591583 610.899536,321.715210 
z"/>
<path fill="#F8F9EB" opacity="1.000000" stroke="none" 
	d="
M140.002319,494.764130 
	C126.548233,498.049164 112.980812,500.857727 98.301109,503.896576 
	C98.301109,489.460815 98.301109,475.729980 98.808548,461.556091 
	C101.191689,460.954407 103.067398,460.795776 104.943100,460.637177 
	C105.152718,460.575867 105.362328,460.514526 106.137947,460.361267 
	C107.144173,460.386169 107.584396,460.503052 108.102493,460.756500 
	C108.180374,460.893066 108.639282,460.937042 108.639282,460.937042 
	C108.639282,460.937042 108.957031,460.638306 109.409401,460.431152 
	C109.860367,456.827637 110.166672,453.397675 109.799599,450.041351 
	C108.719467,440.165009 107.372208,430.317932 106.129532,420.459381 
	C107.284966,415.215485 108.440407,409.971588 109.780769,403.888428 
	C116.697906,421.260529 114.269127,438.885590 116.256485,455.996643 
	C119.887917,445.862885 123.504921,435.761169 127.131363,425.662903 
	C127.468620,424.723724 127.608604,423.453918 128.291260,422.955902 
	C129.495636,422.077209 131.047791,421.675201 132.454376,421.073669 
	C132.906845,422.345154 133.962494,423.762848 133.696503,424.860168 
	C132.956955,427.910980 131.646515,430.821075 130.600449,433.800385 
	C127.582611,442.395508 124.583855,450.997314 121.219612,460.620514 
	C127.671638,460.620514 133.340378,460.620514 139.888992,460.620514 
	C139.888992,472.410706 139.888992,483.349182 140.002319,494.764130 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M320.963257,486.896057 
	C321.364624,487.032898 321.765991,487.169739 322.863342,487.545807 
	C332.748383,487.948090 341.937378,488.111115 351.126404,488.274139 
	C351.411041,488.423248 351.712616,488.477051 352.252869,488.381531 
	C358.875671,488.836792 365.268524,489.521606 371.679413,489.824493 
	C396.864288,491.014496 422.054901,492.082214 447.242920,493.206268 
	C448.231232,493.250397 449.305511,493.184143 450.185974,493.544739 
	C457.948639,496.724091 465.676483,499.988556 473.172028,503.583221 
	C464.627136,503.276886 456.324860,502.615112 447.610443,501.851227 
	C443.798096,501.563782 440.397827,501.378479 436.585754,501.063721 
	C434.452728,500.977448 432.731537,501.020660 430.546753,500.981842 
	C423.104767,500.927307 416.118195,501.183044 409.149811,500.932159 
	C396.762054,500.486237 384.385315,499.733368 372.003967,499.108887 
	C365.370758,498.789062 358.740143,498.393707 352.103790,498.165192 
	C338.319824,497.690491 324.535370,497.127502 310.746368,496.924316 
	C291.367889,496.638794 273.269379,490.913818 255.315308,484.441162 
	C255.044067,484.343384 254.878601,483.952179 254.656158,483.541168 
	C254.648529,483.384430 254.970917,483.099060 254.970917,483.099060 
	C255.976898,483.255005 256.982880,483.410950 258.634979,483.715790 
	C259.778137,483.981781 260.275208,484.098816 260.772247,484.215881 
	C261.586853,484.287048 262.401489,484.358185 263.903259,484.623596 
	C265.454224,484.826050 266.318024,484.834259 267.181824,484.842468 
	C267.181824,484.842499 267.585327,484.925720 267.975494,485.187866 
	C269.245148,485.319855 270.124634,485.189697 271.004120,485.059570 
	C271.607819,484.930023 272.211487,484.800507 273.215149,484.466553 
	C274.095581,484.493225 274.575989,484.724335 275.501221,485.079834 
	C276.300842,480.796539 276.655609,476.388824 277.110046,471.666656 
	C277.052612,470.981781 276.895447,470.611389 276.720276,470.188171 
	C276.702271,470.135315 276.688141,470.127197 276.688141,470.127197 
	C276.929657,468.118622 277.171143,466.110046 277.725830,463.666748 
	C278.392456,463.158173 278.745880,463.084381 279.099365,463.010590 
	C279.162842,463.421997 279.226349,463.833374 279.238342,465.012177 
	C279.191742,471.243134 279.103302,476.709686 279.278442,482.167786 
	C279.314545,483.293365 280.333191,484.387451 281.272705,485.412842 
	C281.691315,483.220520 281.736145,481.111389 281.829712,478.540161 
	C281.844147,473.735138 281.809845,469.392273 281.888550,464.705444 
	C282.159271,464.093384 282.261688,463.740173 282.495026,463.588409 
	C282.639496,463.494507 283.201813,463.696167 283.206085,463.783295 
	C283.489563,469.569794 283.702698,475.359802 284.005585,481.145142 
	C284.087128,482.702698 284.431458,484.246490 284.655151,485.796600 
	C285.136078,485.741364 285.616974,485.686157 286.097900,485.630920 
	C286.097900,477.433136 286.097900,469.235321 286.097900,461.037537 
	C287.075806,460.746765 288.053711,460.455994 289.329010,460.606873 
	C289.256592,469.212952 288.886749,477.377380 288.516937,485.541809 
	C289.358856,485.551270 290.200745,485.560760 291.042664,485.570221 
	C291.042664,477.057739 291.042664,468.545227 291.042664,460.032745 
	C291.468658,459.980347 291.894623,459.927948 292.701172,460.421997 
	C293.081726,469.195099 293.081726,477.421692 293.081726,485.648285 
	C293.773773,485.641144 294.465790,485.633972 295.157837,485.626831 
	C295.157837,477.132660 295.157837,468.638489 295.157837,460.144348 
	C295.828735,460.091156 296.499634,460.037964 297.489014,460.522705 
	C297.807526,469.171600 297.807526,477.282532 297.807526,485.393433 
	C298.299835,485.389740 298.792145,485.386017 299.284454,485.382294 
	C299.284454,477.021179 299.284454,468.660034 299.284454,460.298920 
	C299.957031,460.215973 300.629608,460.132996 301.585632,460.625824 
	C301.848969,467.855896 302.084137,474.524231 301.719910,481.159637 
	C301.472107,485.674255 303.405121,487.130829 307.500610,487.024292 
	C311.986206,486.907593 316.475555,486.932709 320.963257,486.896057 
z"/>
<path fill="#ECF5F6" opacity="1.000000" stroke="none" 
	d="
M419.843262,948.942688 
	C442.629425,948.735291 465.415619,948.527954 489.059570,948.364258 
	C500.289276,948.322693 510.661255,948.237549 521.481812,948.283447 
	C543.287720,948.339294 564.645020,948.264099 586.251709,948.352539 
	C587.672058,948.436768 588.842957,948.357422 590.477539,948.349243 
	C605.575806,948.311218 620.210449,948.201904 635.059814,948.203125 
	C635.896790,948.238708 636.519165,948.163879 637.596252,948.163940 
	C674.051270,948.230774 710.051514,948.222656 746.051758,948.214478 
	C748.015686,948.395874 749.979187,948.731384 751.943542,948.735901 
	C789.985229,948.823547 828.026978,948.872375 866.535095,949.319702 
	C866.993408,950.368774 866.985352,951.027344 866.977295,951.685913 
	C840.994263,951.784790 815.011169,951.964050 789.028076,951.968750 
	C666.253296,951.990906 543.478455,951.965332 420.149933,951.594849 
	C419.678558,950.469482 419.760895,949.706116 419.843262,948.942688 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M713.295166,217.001007 
	C700.072693,219.370331 687.664795,216.508850 675.402588,212.767426 
	C673.795898,212.277206 671.762756,210.795273 671.295776,209.335022 
	C667.306824,196.860229 663.742920,184.249878 659.791138,171.762589 
	C658.778870,168.563782 659.253296,166.441742 661.871460,164.420135 
	C663.132996,163.446030 663.800842,161.734344 664.981140,160.613113 
	C665.823914,159.812531 667.080811,158.939301 668.138611,158.956955 
	C674.589355,159.064728 681.044434,159.733078 687.480652,159.560867 
	C690.851685,159.470657 694.187134,158.049179 697.918335,157.108185 
	C700.218323,165.072754 702.138489,173.158066 703.599915,181.755188 
	C699.810425,181.570816 696.479675,180.874588 693.148987,180.178360 
	C691.645142,175.623825 689.955811,171.119354 688.707764,166.495789 
	C687.971008,163.766647 686.931580,162.643616 683.906799,163.063156 
	C679.807922,163.631744 675.505798,163.105392 671.525452,164.043823 
	C668.222534,164.822540 665.258789,167.040268 660.906982,169.248703 
	C671.177246,184.176392 680.898499,198.385818 690.769897,212.490128 
	C691.494080,213.524857 693.325562,214.381241 694.603882,214.331757 
	C701.390381,214.068970 708.164734,213.490234 715.709229,212.957535 
	C718.078796,211.436325 719.682434,209.978531 721.286011,208.520737 
	C720.900391,208.127457 720.514709,207.734177 720.129089,207.340881 
	C719.102417,208.223969 718.075806,209.107056 717.049072,209.990143 
	C718.879395,201.198013 720.709656,192.405884 723.109009,183.456665 
	C725.053406,183.299606 726.428833,183.299606 727.804199,183.299606 
	C727.750244,182.638062 727.696289,181.976501 727.642334,181.314957 
	C726.144287,181.531509 724.646301,181.748077 723.148193,181.964630 
	C724.062012,176.902908 724.975830,171.841187 725.971191,166.392929 
	C728.384399,167.917114 731.161621,169.502548 732.882141,171.859436 
	C733.928955,173.293365 734.089722,176.252823 733.332336,177.934052 
	C731.946899,181.009003 730.454041,183.583160 731.708740,187.264877 
	C732.523682,189.656128 731.170349,191.577286 727.913513,191.551178 
	C726.055603,191.536285 724.189331,192.556305 722.040649,193.195450 
	C722.764343,194.199860 722.917786,194.646591 723.134888,194.680069 
	C729.614258,195.678589 730.530273,197.438782 727.817993,203.772110 
	C727.688110,204.075424 727.338928,204.379456 727.374573,204.645309 
	C728.510681,213.128632 723.822815,215.949661 715.878662,216.184235 
	C714.802917,216.510193 714.174988,216.716522 713.295166,217.001007 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M323.659912,928.816528 
	C324.178070,928.405090 324.696259,927.993591 325.695984,927.199768 
	C324.223541,927.058105 323.184113,926.870972 322.144592,926.870483 
	C227.440842,926.827942 132.737106,926.802979 38.033371,926.749390 
	C35.888672,926.748169 33.744152,926.433105 31.181820,926.154602 
	C30.810986,925.625488 30.857880,925.206116 31.436291,924.447083 
	C49.601692,924.016418 67.235573,923.925476 85.244057,923.879028 
	C86.119698,923.895691 86.620735,923.868042 87.579102,923.882446 
	C97.966423,923.877930 107.896416,923.831238 118.301041,923.856201 
	C128.895020,923.804138 139.014374,923.680481 149.566437,923.616455 
	C173.764908,923.467285 197.531464,923.022278 221.296204,923.104309 
	C258.527191,923.232727 295.756927,923.717346 332.744965,924.302490 
	C329.555115,925.974792 326.607483,927.395691 323.659912,928.816528 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M873.078369,868.887207 
	C857.072937,865.237793 841.067993,861.585999 825.061768,857.940186 
	C817.686584,856.260254 810.308838,854.591858 802.546387,852.805420 
	C803.277222,852.357849 804.454956,851.599304 805.501770,851.750000 
	C840.026917,856.720459 874.523376,861.893066 909.066345,866.734436 
	C916.775330,867.814941 924.642151,867.759766 932.433472,868.269653 
	C933.879578,868.364258 935.312439,868.661255 936.751404,868.864807 
	C936.729736,869.418335 936.708130,869.971802 936.686462,870.525330 
	C931.419678,871.053040 926.152832,871.580750 920.886047,872.108521 
	C920.894287,872.402649 920.902527,872.696838 920.910828,872.991028 
	C926.062683,872.991028 931.214600,872.991028 936.366516,872.991028 
	C936.408264,873.617004 936.449951,874.243042 936.491638,874.869019 
	C933.153870,875.136658 929.818481,875.441223 926.477600,875.662109 
	C922.843872,875.902405 919.204956,876.064819 915.267822,876.896606 
	C914.650452,878.016174 914.333435,878.500366 913.647217,879.055786 
	C912.765564,879.636597 912.253174,880.146240 911.370972,880.484741 
	C908.677490,879.513855 906.353760,878.714172 904.013794,877.724121 
	C903.997498,877.533875 904.044373,877.154785 904.424927,877.245972 
	C905.707153,877.254883 906.608826,877.172668 907.510437,877.090454 
	C907.538269,876.781372 907.566040,876.472351 907.593872,876.163269 
	C902.048035,875.151123 896.502136,874.138916 890.601013,872.985352 
	C884.523254,871.525024 878.800842,870.206116 873.078369,868.887207 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M787.952881,923.803711 
	C788.716675,923.839111 789.480469,923.874512 790.622192,923.845581 
	C823.293701,923.844604 855.587280,923.934998 887.880798,923.963440 
	C917.847046,923.989807 947.813354,923.942078 977.779602,923.958618 
	C979.581726,923.959656 981.383728,924.240479 983.466125,924.945190 
	C983.770752,925.690247 983.795044,925.881287 983.819336,926.072327 
	C983.819336,926.072327 983.738953,926.335449 983.475220,926.716125 
	C981.096191,927.329956 978.981445,927.761169 976.865601,927.766907 
	C934.577393,927.880920 892.289185,927.945923 849.535522,927.959351 
	C815.382446,927.933411 781.694763,927.968933 748.007141,928.004395 
	C749.731262,927.684082 751.452820,927.103027 753.179871,927.086426 
	C763.396057,926.988159 773.614685,927.091187 783.828796,926.913025 
	C784.994080,926.892700 786.136108,925.543640 787.370117,924.402588 
	C787.618469,923.930969 787.785645,923.867371 787.952881,923.803711 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M746.252502,934.910522 
	C746.252502,934.910522 745.962097,934.669189 745.938599,934.072876 
	C745.931213,932.646118 745.947327,931.815674 745.914795,930.953186 
	C745.866150,930.921143 745.909790,930.813049 746.381104,930.856384 
	C779.610413,930.887329 812.368408,930.880127 845.126465,930.847412 
	C846.777893,930.845764 848.429077,930.623901 850.535706,930.585571 
	C852.983887,930.688232 854.976685,930.726440 856.969543,930.728943 
	C898.997070,930.781982 941.024658,930.830994 983.052246,930.880798 
	C982.891479,931.772644 982.730713,932.664490 981.819824,933.706665 
	C956.007751,933.881348 930.945557,933.872253 905.883667,933.943970 
	C893.275696,933.980042 880.668091,934.162964 868.060364,934.279419 
	C865.986511,934.242737 863.912659,934.206116 860.941040,934.116333 
	C822.112976,934.345703 784.182739,934.628113 746.252502,934.910522 
z"/>
<path fill="#FAFBEB" opacity="1.000000" stroke="none" 
	d="
M927.298584,916.891663 
	C923.172546,913.445618 919.666077,909.727600 918.911438,903.954895 
	C917.957581,896.657776 921.752441,892.025696 927.647949,887.789307 
	C930.539551,887.062866 932.753967,886.530579 934.968384,885.998291 
	C943.355652,887.030151 949.826843,893.805237 950.401855,902.156494 
	C950.903564,909.443359 945.704041,916.234131 937.224731,918.544128 
	C933.464600,918.034790 930.381592,917.463257 927.298584,916.891663 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M615.635986,311.904785 
	C622.118652,298.824158 628.601318,285.743530 635.187012,272.312073 
	C639.405640,264.484375 643.521240,257.007507 647.636902,249.530670 
	C647.948059,249.567673 648.259216,249.604691 648.570374,249.641708 
	C648.570374,255.654449 648.570374,261.667206 648.570374,267.679962 
	C648.934692,267.788300 649.299011,267.896637 649.663330,268.004974 
	C651.603882,265.679749 653.544373,263.354523 655.810059,261.085693 
	C656.135193,261.142059 656.111511,261.091858 656.218933,261.535614 
	C655.889771,268.615997 655.453247,275.252625 654.770386,282.138611 
	C653.716858,284.221497 652.909607,286.055023 651.996399,287.505188 
	C651.323853,283.489197 650.757202,279.856598 650.183228,276.176758 
	C645.016113,279.785431 640.398560,282.944855 635.871765,286.229401 
	C635.009521,286.855042 634.421875,287.924561 633.850159,288.875061 
	C628.830505,297.219513 623.843201,305.583435 618.590454,313.734558 
	C617.434998,312.986633 616.535461,312.445709 615.635986,311.904785 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M408.071289,505.021454 
	C408.052765,504.577972 408.034241,504.134460 408.340576,503.275421 
	C409.113464,502.921021 409.561462,502.982208 410.309204,503.311523 
	C414.055054,503.452240 417.501190,503.324799 421.289246,503.132721 
	C422.093536,503.032104 422.555939,502.996185 423.237122,503.086060 
	C424.300232,503.165924 425.144470,503.119965 426.375641,503.130615 
	C428.529663,503.371552 430.296753,503.555847 432.311432,504.076660 
	C437.020264,504.333466 441.481567,504.253754 446.398743,504.122009 
	C450.900513,504.191101 454.946381,504.312286 458.992218,504.433502 
	C459.937927,504.954071 460.996246,505.342102 461.802490,506.026886 
	C462.958618,507.008850 464.974579,508.484741 464.769348,509.256989 
	C464.309509,510.986969 462.688202,513.773315 461.570221,513.759033 
	C443.996307,513.534546 426.427124,512.934814 408.857269,512.407959 
	C408.536560,512.398315 408.220764,512.224792 407.496185,512.063416 
	C407.186218,510.894348 407.282715,509.789581 407.831543,508.220001 
	C408.213013,506.843933 408.142151,505.932709 408.071289,505.021454 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M664.539490,112.761665 
	C663.779541,113.358864 663.019592,113.956055 662.013062,114.711540 
	C658.285400,116.297913 654.721985,117.555603 651.339539,119.188301 
	C643.482544,122.980934 635.243774,124.170959 626.470825,123.551994 
	C628.091370,121.234634 628.091370,121.234634 636.856079,120.862206 
	C645.161621,119.090874 651.635864,115.579453 656.980652,110.015106 
	C656.989441,110.003433 656.966492,110.024345 657.285400,110.097244 
	C658.082581,109.776756 658.560791,109.383377 659.021362,108.993912 
	C659.003784,108.997818 658.990540,109.031364 659.351135,109.009155 
	C666.162170,107.006172 672.612610,105.025406 679.485779,103.058853 
	C684.849182,103.722046 689.789917,104.371025 694.730591,105.020012 
	C694.813904,104.564384 694.897278,104.108757 694.980591,103.653130 
	C690.355225,102.744873 685.729919,101.836617 681.373413,100.621185 
	C690.299133,98.689926 697.949402,101.186737 705.027161,106.026825 
	C705.027161,106.026825 704.974915,106.023201 705.083618,106.233490 
	C705.473694,106.641510 705.755188,106.839256 706.036621,107.037003 
	C706.036621,107.037010 705.979736,107.026443 706.087158,107.161728 
	C706.495667,107.189926 706.796875,107.082832 707.098022,106.975739 
	C711.570190,111.060219 711.568481,114.222366 706.614868,118.732346 
	C704.053345,118.691643 701.968811,118.313591 699.937988,117.958572 
	C699.991638,117.981598 699.894287,117.916382 700.202881,117.968185 
	C701.011780,117.892906 701.512085,117.765808 702.012329,117.638718 
	C701.979614,117.459740 701.946899,117.280762 701.914185,117.101791 
	C701.161072,117.171104 700.408020,117.240417 699.290039,117.225113 
	C695.936401,116.485847 692.947571,115.831200 689.958801,115.176544 
	C689.213135,114.825775 688.467468,114.475021 687.551941,113.690926 
	C690.144775,113.257591 692.907410,113.257591 695.670105,113.257591 
	C695.697144,112.857391 695.724121,112.457191 695.751099,112.056992 
	C691.493713,111.108070 687.236328,110.159149 682.978943,109.210236 
	C682.554016,109.123337 682.129028,109.036438 681.427490,108.521370 
	C686.111938,108.454819 691.072998,108.816437 696.034119,109.178062 
	C688.342896,105.772507 680.605713,103.385933 672.367676,107.906868 
	C670.199768,109.258430 668.358704,110.511131 666.517578,111.763824 
	C666.517578,111.763824 666.196716,111.746223 665.797852,111.861359 
	C665.112427,112.238213 664.825989,112.499931 664.539490,112.761665 
M695.997986,105.523254 
	C696.219849,105.600105 696.441711,105.676956 696.663513,105.753822 
	C696.591003,105.527023 696.518494,105.300224 695.997986,105.523254 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M634.845154,948.092590 
	C620.210449,948.201904 605.575806,948.311218 590.676392,948.032471 
	C592.273193,947.130859 594.134644,946.617249 596.183716,945.983887 
	C596.622925,945.696350 596.819275,945.478699 597.426270,945.137695 
	C647.189453,945.087463 696.486694,945.110840 745.928589,945.348145 
	C746.358887,945.744324 746.644653,945.926575 746.930298,946.108765 
	C746.666687,946.619507 746.403076,947.130249 746.095581,947.927734 
	C710.051514,948.222656 674.051270,948.230774 637.214539,948.118530 
	C635.867065,948.029663 635.356140,948.061096 634.845154,948.092590 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M63.318192,510.084167 
	C63.306667,550.912476 63.289467,591.740723 63.284889,632.569031 
	C63.277245,700.730103 63.277691,768.891174 63.269852,837.931763 
	C63.334652,840.542664 63.404324,842.273987 63.374908,844.463745 
	C63.427700,857.686890 63.579575,870.451721 63.729919,883.544556 
	C63.748222,884.524048 63.768066,885.175598 63.593967,885.890442 
	C63.400028,885.953796 63.045189,886.155334 62.576996,886.095947 
	C49.072422,885.999329 36.036041,885.962097 22.999664,885.924805 
	C25.248789,885.618958 27.495131,885.075562 29.747528,885.049194 
	C38.573097,884.945862 47.409904,884.763916 56.223511,885.104126 
	C60.814823,885.281372 62.120579,883.888062 62.103107,879.256165 
	C61.934143,834.456787 62.002926,789.656494 62.003841,744.856506 
	C62.005409,668.246826 62.003838,591.637146 62.028118,515.027466 
	C62.028690,513.222168 62.319576,511.417023 62.780670,509.596802 
	C63.163586,509.749237 63.240887,509.916718 63.318192,510.084167 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M983.201782,930.546021 
	C941.024658,930.830994 898.997070,930.781982 856.969543,930.728943 
	C854.976685,930.726440 852.983887,930.688232 850.516907,930.276855 
	C850.028809,929.264893 850.014893,928.642883 850.000916,928.020752 
	C892.289185,927.945923 934.577393,927.880920 976.865601,927.766907 
	C978.981445,927.761169 981.096191,927.329956 983.546997,926.833374 
	C983.705566,927.783752 983.528442,928.997498 983.201782,930.546021 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M856.972961,940.220825 
	C840.288452,940.291931 823.604004,940.363037 806.055298,940.435181 
	C786.130432,940.627686 767.069702,940.819092 747.683899,940.956055 
	C746.920776,940.920959 746.482788,940.940369 746.001892,940.916443 
	C745.959045,940.873169 746.017944,940.766663 746.014160,940.386230 
	C745.989807,938.991943 745.969238,937.978149 745.948730,936.964355 
	C745.948730,936.964355 745.869141,936.909912 746.351746,936.958862 
	C783.889771,937.020203 820.945251,937.032532 858.000610,937.044800 
	C858.130554,937.906799 858.260559,938.768738 857.934082,939.851318 
	C857.309448,940.121521 857.141235,940.171204 856.972961,940.220825 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M867.440796,951.772339 
	C866.985352,951.027344 866.993408,950.368774 867.000488,949.380493 
	C879.346191,949.001282 891.692993,948.951721 904.971436,948.954468 
	C921.609680,948.949280 937.316345,948.891785 953.022949,948.834290 
	C963.125793,949.354370 973.349976,948.038940 983.386658,949.560608 
	C983.362000,950.326721 983.337402,951.092773 983.312744,951.858826 
	C944.843262,951.858826 906.373840,951.858826 867.440796,951.772339 
z"/>
<path fill="#1C0607" opacity="1.000000" stroke="none" 
	d="
M63.988556,913.004517 
	C63.843788,912.690063 63.699024,912.375610 63.548767,911.618774 
	C63.615273,910.695618 63.687279,910.214783 63.898750,909.526123 
	C64.333000,909.172974 64.627785,909.027710 65.260918,908.961670 
	C71.042770,909.035339 76.486526,909.060791 81.929756,909.018799 
	C98.328613,908.892334 114.727272,908.739624 131.528931,908.657898 
	C133.287415,908.825195 134.642975,908.931702 135.999542,909.418457 
	C135.995209,911.056763 135.989883,912.314941 135.524994,913.660706 
	C111.373138,913.500366 87.680855,913.252441 63.988556,913.004517 
z"/>
<path fill="#1F0807" opacity="1.000000" stroke="none" 
	d="
M63.618366,918.812195 
	C63.705433,918.431519 63.792492,918.050842 64.377136,917.414185 
	C65.848137,916.977051 66.821930,916.636108 67.794937,916.638306 
	C88.161713,916.684692 108.528648,916.854980 128.894974,916.797424 
	C135.311050,916.779297 141.725464,916.170776 148.261307,916.229858 
	C148.410217,917.460022 148.438477,918.292786 148.291611,919.529724 
	C147.469223,920.301697 146.822128,920.990662 146.174637,920.990967 
	C118.811058,921.005066 91.447433,920.957764 64.020920,920.564026 
	C63.844803,919.744080 63.731583,919.278137 63.618366,918.812195 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M62.965664,901.501648 
	C62.788013,899.812073 62.610363,898.122498 62.545464,896.017151 
	C62.821419,895.566650 62.984627,895.531860 63.500290,895.663940 
	C66.002304,895.918457 68.192406,895.781677 70.289406,896.160339 
	C72.187828,896.503235 73.982689,897.973816 75.826584,897.980225 
	C100.044205,898.064819 124.262428,897.977783 148.580811,898.314636 
	C148.612869,899.200195 148.544617,899.704895 148.099457,900.261719 
	C146.257263,900.509705 144.792160,900.874512 143.326675,900.875916 
	C120.451591,900.898193 97.576225,900.818481 74.701553,900.912964 
	C70.815140,900.928955 66.931610,901.645325 63.024414,901.904297 
	C63.002075,901.770447 62.965664,901.501648 62.965664,901.501648 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M830.795654,657.097534 
	C833.921021,669.705444 837.046387,682.313354 840.280518,695.301880 
	C840.750305,698.727966 841.185425,701.767761 841.427795,704.822754 
	C841.502502,705.764648 841.050842,706.748230 840.780212,707.928894 
	C839.749939,707.437744 838.779663,706.730408 837.593933,705.444641 
	C836.728455,702.631897 836.203369,700.352051 835.407715,698.170898 
	C830.941467,685.926575 827.537659,673.504822 829.057678,660.280212 
	C829.185608,659.167419 830.195557,658.156067 830.795654,657.097534 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M292.984406,956.765564 
	C308.682556,956.807739 324.380737,956.849915 340.870544,956.979736 
	C342.107239,957.157654 342.552277,957.247986 342.997345,957.338257 
	C341.043335,957.558044 339.089386,957.969177 337.135284,957.969971 
	C262.376648,957.999817 187.618042,957.999634 112.859444,957.944763 
	C110.987236,957.943420 109.115517,957.283447 107.584793,956.494629 
	C114.291206,956.011230 120.656387,955.963867 127.021568,955.916504 
	C130.705154,955.989319 134.388733,956.062134 138.696426,956.255737 
	C139.886719,956.278076 140.452896,956.179565 141.019073,956.081055 
	C152.644989,956.271851 164.270477,956.588562 175.896942,956.630127 
	C212.957733,956.762573 250.018860,956.792725 287.955200,956.903259 
	C290.215149,956.884583 291.599792,956.825073 292.984406,956.765564 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M148.140656,915.832397 
	C141.725464,916.170776 135.311050,916.779297 128.894974,916.797424 
	C108.528648,916.854980 88.161713,916.684692 67.794937,916.638306 
	C66.821930,916.636108 65.848137,916.977051 64.491333,917.143982 
	C64.080620,916.029663 64.053284,914.929626 64.007256,913.416992 
	C87.680855,913.252441 111.373138,913.500366 135.922684,913.732117 
	C140.551270,913.850708 144.322617,913.985535 148.234833,914.390869 
	C148.297379,915.051697 148.219009,915.442017 148.140656,915.832397 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M64.083817,920.918030 
	C91.447433,920.957764 118.811058,921.005066 146.174637,920.990967 
	C146.822128,920.990662 147.469223,920.301697 148.492142,919.707153 
	C148.915085,920.020874 148.962372,920.561462 149.025848,921.410278 
	C149.072617,922.331238 149.103165,922.944031 149.133713,923.556824 
	C139.014374,923.680481 128.895020,923.804138 117.981148,923.626709 
	C112.393044,923.226318 107.596764,922.979126 102.806633,923.067078 
	C97.575378,923.162964 92.349815,923.569275 87.121780,923.840332 
	C86.620735,923.868042 86.119698,923.895691 84.933411,923.626221 
	C66.841492,922.881348 49.434807,922.433716 32.028126,921.986084 
	C32.048653,921.612244 32.069176,921.238342 32.566864,920.513428 
	C43.390621,920.414246 53.737221,920.666138 64.083817,920.918030 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M857.910156,936.774292 
	C820.945251,937.032532 783.889771,937.020203 746.369263,936.914001 
	C746.026794,936.401550 746.149414,935.983032 746.262268,935.237488 
	C784.182739,934.628113 822.112976,934.345703 860.498535,934.163940 
	C859.909119,935.010986 858.864380,935.757385 857.910156,936.774292 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M144.535126,952.000183 
	C193.055313,951.994324 241.106415,951.993469 289.157532,951.992615 
	C297.945831,951.992493 297.945831,951.992432 299.618408,943.310059 
	C299.864807,946.145447 299.864807,949.265930 299.864807,953.858215 
	C242.305984,953.858215 185.106201,953.858215 126.981834,953.824097 
	C120.701851,953.733521 115.346443,953.677002 109.991043,953.620483 
	C111.564255,953.084412 113.130920,952.107727 114.711761,952.084229 
	C124.494766,951.938721 134.280945,952.007568 144.535126,952.000183 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M39.990299,893.840454 
	C35.414452,893.558105 30.841316,893.212646 26.261797,893.015625 
	C23.267912,892.886780 21.282045,892.103210 23.179615,888.264587 
	C36.939407,887.998840 49.967106,888.000366 63.029255,888.365723 
	C63.805244,890.410461 64.546791,892.091309 65.370445,893.958313 
	C64.653389,894.022888 63.845722,894.095581 62.577271,894.063232 
	C54.741093,893.918945 47.365696,893.879700 39.990299,893.840454 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M747.394897,946.004272 
	C746.644653,945.926575 746.358887,945.744324 745.967957,945.055237 
	C745.880432,944.351746 745.898132,944.155212 745.948425,943.961060 
	C745.980957,943.963501 745.927612,943.925781 746.292358,943.945435 
	C747.137207,943.888428 747.617310,943.811768 748.557678,943.778320 
	C765.342834,943.806091 781.667725,943.790771 798.455933,943.852417 
	C809.608582,943.847961 820.298035,943.766418 830.987427,943.684937 
	C839.738281,943.684937 848.489075,943.684937 857.239929,943.684937 
	C857.243469,944.381042 857.247009,945.077148 857.250549,945.773254 
	C820.786865,945.815430 784.323181,945.857666 747.394897,946.004272 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M64.922592,908.882568 
	C64.627785,909.027710 64.333000,909.172974 63.461636,909.575378 
	C59.890659,909.817627 56.896263,909.802673 53.601379,909.558838 
	C50.562492,909.492310 47.824100,909.654663 44.736984,909.865295 
	C43.921261,909.879883 43.454258,909.846130 42.987255,909.812378 
	C37.726101,909.541138 32.455502,909.379028 27.210176,908.924988 
	C25.828602,908.805359 24.525181,907.783081 23.185867,907.175293 
	C24.574972,906.451294 25.954319,905.121399 27.354725,905.098816 
	C39.003948,904.911194 50.657036,904.964661 62.944519,904.968384 
	C63.580078,904.991577 63.884541,905.109253 63.884216,905.421509 
	C64.228172,906.202759 64.572441,906.671692 64.942749,907.430664 
	C64.953392,908.107971 64.937996,908.495239 64.922592,908.882568 
z"/>
<path fill="#6998A5" opacity="1.000000" stroke="none" 
	d="
M797.992676,943.775391 
	C781.667725,943.790771 765.342834,943.806091 748.526184,943.440186 
	C748.025940,942.376038 748.017456,941.693298 748.008972,941.010498 
	C767.069702,940.819092 786.130432,940.627686 805.607178,940.563049 
	C806.016052,941.269958 806.009033,941.850159 805.577393,942.730530 
	C802.766052,943.278931 800.379395,943.527161 797.992676,943.775391 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M936.825317,441.116821 
	C941.890991,466.616119 946.956726,492.115387 952.022400,517.614685 
	C951.419312,517.731750 950.816223,517.848816 950.213135,517.965942 
	C945.266785,492.624664 940.320496,467.283386 935.602783,441.535706 
	C935.831360,441.129303 936.328125,441.104248 936.328125,441.104248 
	C936.328125,441.104248 936.825317,441.116821 936.825317,441.116821 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M656.971863,110.026779 
	C651.635864,115.579453 645.161621,119.090874 637.300720,120.825363 
	C634.451111,119.924332 631.976013,118.985130 628.969666,117.844337 
	C630.363525,116.807137 631.552429,115.922462 633.294556,114.591751 
	C639.385193,113.994957 644.922668,113.844200 650.460144,113.693451 
	C650.425659,113.140182 650.391235,112.586914 650.356812,112.033646 
	C644.587341,112.033646 638.817932,112.033646 633.048462,112.033646 
	C633.152649,111.164482 633.256775,110.295319 633.879028,109.529144 
	C641.922119,109.763680 649.446960,109.895226 656.971863,110.026779 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M104.757225,460.296051 
	C103.067398,460.795776 101.191689,460.954407 98.952568,461.115967 
	C96.476013,451.655701 94.362885,442.192444 92.192955,432.366089 
	C92.750092,430.885742 93.891830,429.762360 93.878952,428.652405 
	C93.840385,425.325226 93.350655,422.003265 93.355621,418.311340 
	C94.089447,417.931061 94.504631,417.918732 94.919823,417.906433 
	C96.420692,426.920044 97.979156,435.924683 99.387802,444.952667 
	C99.817757,447.708221 99.887184,450.525513 100.028633,453.319214 
	C100.189156,456.489410 101.040962,459.121826 104.757225,460.296051 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M663.974487,251.393799 
	C665.605469,251.338562 667.277466,251.646149 669.317810,251.936203 
	C670.910645,253.880127 671.911621,256.036896 673.414001,257.755310 
	C675.452332,260.086700 677.874451,262.082458 679.863281,264.574799 
	C678.762512,264.943939 677.930481,264.960388 676.825562,264.737213 
	C674.938538,263.253571 673.393188,261.065887 671.697083,260.941864 
	C666.533142,260.564362 661.310974,260.983093 656.111511,261.091858 
	C656.111511,261.091858 656.135193,261.142059 656.150757,261.165039 
	C657.730103,259.384583 659.317444,257.600891 660.849670,255.771072 
	C661.943298,254.465057 662.962830,253.097031 663.974487,251.393799 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M106.089355,420.814453 
	C107.372208,430.317932 108.719467,440.165009 109.799599,450.041351 
	C110.166672,453.397675 109.860367,456.827637 109.179535,460.298584 
	C108.339737,460.455444 108.182175,460.537720 108.024612,460.619934 
	C107.584396,460.503052 107.144173,460.386169 106.386070,460.147339 
	C105.972466,447.890930 105.871201,435.756439 105.793373,423.621826 
	C105.788132,422.804993 105.959793,421.987000 106.089355,420.814453 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M868.396729,934.502502 
	C880.668091,934.162964 893.275696,933.980042 905.883667,933.943970 
	C930.945557,933.872253 956.007751,933.881348 981.536499,933.909790 
	C980.608398,934.303406 979.214172,934.939514 977.818909,934.941956 
	C942.110168,935.003967 906.401306,935.001221 870.692505,934.995300 
	C870.039368,934.995178 869.386169,934.819397 868.396729,934.502502 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M935.087219,885.654480 
	C932.753967,886.530579 930.539551,887.062866 927.961060,887.686523 
	C927.196716,887.157715 926.839844,886.041565 926.388672,886.002136 
	C924.264221,885.816162 922.113586,885.899719 919.973083,885.935791 
	C917.181824,885.982849 914.208923,886.530518 912.417847,882.705017 
	C911.912720,881.780823 911.826721,881.218384 911.740784,880.655884 
	C912.253174,880.146240 912.765564,879.636597 913.643677,879.219788 
	C914.009338,879.312622 913.965271,879.637390 913.965271,879.637390 
	C915.210205,880.397217 916.446838,881.790527 917.701599,881.807007 
	C923.095154,881.877930 928.493286,881.481628 933.891785,881.415771 
	C934.860657,881.403870 935.838745,882.147278 936.812622,882.541931 
	C936.277100,883.464844 935.741577,884.387756 935.087219,885.654480 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M920.237061,564.689148 
	C919.984558,539.773621 919.878906,514.487061 919.834351,489.200409 
	C919.818237,480.050079 919.947998,470.899445 920.134277,461.278748 
	C920.452820,461.769501 920.820007,462.730774 920.818665,463.691498 
	C920.772278,496.741669 920.692017,529.791809 920.606445,562.841980 
	C920.605164,563.334229 920.477051,563.826050 920.237061,564.689148 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M651.447510,192.402573 
	C651.938538,193.522232 652.909851,195.108917 652.927002,196.705872 
	C653.055725,208.652740 652.954163,220.601929 653.031555,232.549698 
	C653.046448,234.841095 653.532715,237.129471 653.760498,239.859680 
	C652.975281,238.287170 651.613953,236.282715 651.586060,234.259857 
	C651.395874,220.466843 651.486145,206.669968 651.447510,192.402573 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M706.010193,156.652252 
	C704.207336,153.559265 706.036560,151.780167 708.608521,151.122482 
	C713.357971,149.908035 718.240051,149.212143 723.449890,148.153107 
	C724.028931,148.222290 724.269714,148.360077 724.445007,148.754211 
	C722.505859,150.614883 720.677063,152.140747 718.463501,153.744858 
	C714.055908,154.766144 710.033081,155.709198 706.010193,156.652252 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M31.900709,922.257812 
	C49.434807,922.433716 66.841492,922.881348 84.558815,923.581787 
	C67.235573,923.925476 49.601692,924.016418 31.502399,924.135803 
	C31.282425,923.619385 31.527859,923.074463 31.900709,922.257812 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M63.884537,905.109253 
	C63.884541,905.109253 63.580078,904.991577 63.429268,904.930298 
	C61.576954,904.511475 59.878654,903.862915 58.173412,903.844055 
	C48.195679,903.734070 38.216328,903.770874 28.237524,903.758972 
	C26.648518,903.757019 25.059509,903.758728 23.470501,903.758728 
	C23.448479,903.116699 23.426455,902.474670 23.404430,901.832642 
	C29.946379,901.832642 36.488327,901.832642 43.766052,901.856567 
	C44.681137,901.846191 44.860451,901.811951 45.039764,901.777832 
	C50.710808,901.747559 56.381851,901.717346 62.509277,901.594360 
	C62.965664,901.501648 63.002075,901.770447 63.150169,902.205811 
	C63.493687,903.463867 63.689110,904.286560 63.884537,905.109253 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M53.901867,909.787720 
	C56.896263,909.802673 59.890659,909.817627 63.322170,909.783325 
	C63.687279,910.214783 63.615273,910.695618 63.424278,911.390991 
	C50.022747,911.605652 36.740211,911.605652 23.457676,911.605652 
	C23.436583,911.041992 23.415489,910.478333 23.394396,909.914673 
	C29.615267,909.914673 35.836140,909.914673 42.522133,909.863525 
	C43.454258,909.846130 43.921261,909.879883 45.174683,909.916382 
	C48.608025,909.875305 51.254944,909.831543 53.901867,909.787720 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M62.994804,888.001892 
	C49.967106,888.000366 36.939407,887.998840 23.440441,888.001465 
	C22.921757,887.540283 22.874338,887.075012 22.913292,886.267212 
	C36.036041,885.962097 49.072422,885.999329 62.577957,886.403931 
	C63.029682,887.181519 63.012241,887.591675 62.994804,888.001892 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M39.525837,893.895020 
	C47.365696,893.879700 54.741093,893.918945 62.592247,894.285645 
	C63.100800,894.907288 63.127407,895.201904 63.147831,895.497131 
	C62.984627,895.531860 62.821419,895.566650 62.419334,895.673340 
	C49.249607,895.745239 36.318756,895.745239 23.387909,895.745239 
	C23.371042,895.146667 23.354176,894.548096 23.337309,893.949524 
	C28.578663,893.949524 33.820019,893.949524 39.525837,893.895020 
z"/>
<path fill="#ECF5F6" opacity="1.000000" stroke="none" 
	d="
M94.972260,417.509064 
	C94.504631,417.918732 94.089447,417.931061 93.362328,417.928040 
	C89.895142,417.060486 88.461510,415.131989 88.670731,411.745331 
	C88.887505,408.236603 88.718987,404.704071 88.718987,401.181946 
	C89.264435,401.058472 89.809891,400.934998 90.355339,400.811523 
	C91.911797,406.244904 93.468246,411.678314 94.972260,417.509064 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M459.460632,504.413635 
	C454.946381,504.312286 450.900513,504.191101 446.026398,504.054382 
	C444.452301,503.932861 443.706512,503.826843 443.252594,503.527435 
	C445.037170,502.873810 446.529877,502.413574 448.022552,501.953369 
	C456.324860,502.615112 464.627136,503.276886 473.304108,503.875549 
	C473.807281,503.914948 473.984772,503.992523 474.052643,504.125885 
	C474.150116,504.317383 474.167725,504.549500 474.219421,504.764282 
	C469.455963,504.640778 464.692505,504.517273 459.460632,504.413635 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M914.424072,879.757141 
	C913.965271,879.637390 914.009338,879.312622 914.012878,879.148560 
	C914.333435,878.500366 914.650452,878.016174 915.006836,877.232300 
	C922.270996,877.059875 929.495728,877.186951 936.720459,877.314026 
	C936.723450,878.168335 936.726440,879.022583 936.729370,879.876892 
	C929.447205,879.876892 922.164978,879.876892 914.424072,879.757141 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M718.848267,153.666626 
	C720.677063,152.140747 722.505859,150.614883 724.604736,148.889771 
	C728.264526,153.730911 727.147339,159.080765 725.925537,164.872131 
	C724.503723,162.331009 723.112488,159.353485 721.205444,155.979401 
	C720.075928,154.944092 719.462097,154.305359 718.848267,153.666626 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M628.065918,160.383698 
	C629.341919,162.202744 630.857849,164.300919 631.902222,166.612183 
	C634.597229,172.576736 637.083923,178.635437 639.666138,185.030426 
	C637.708252,182.970276 635.078003,180.815109 633.906982,178.043427 
	C631.547974,172.459854 629.983276,166.540665 628.065918,160.383698 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M952.683350,948.601074 
	C937.316345,948.891785 921.609680,948.949280 905.437439,948.928223 
	C905.569092,948.595886 906.167114,948.119019 906.763550,948.121033 
	C921.957092,948.172852 937.150452,948.274719 952.683350,948.601074 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M109.650581,953.682251 
	C115.346443,953.677002 120.701851,953.733521 126.517181,953.871460 
	C126.982506,954.389343 126.987892,954.825806 127.007431,955.589355 
	C120.656387,955.963867 114.291206,956.011230 107.461319,956.121155 
	C106.829865,955.448792 106.663116,954.713806 107.001556,953.858398 
	C107.506737,953.737976 107.936378,953.634460 107.936378,953.634460 
	C108.394295,953.670959 108.852203,953.707520 109.650581,953.682251 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M936.870605,440.703918 
	C936.825317,441.116821 936.328125,441.104248 936.328125,441.104248 
	C936.328125,441.104248 935.831360,441.129303 935.586243,441.152588 
	C933.978638,434.442566 932.616150,427.709229 931.253723,420.975922 
	C931.763245,420.863373 932.272827,420.750854 932.782349,420.638336 
	C934.160217,427.189240 935.538086,433.740143 936.870605,440.703918 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M926.951904,917.024963 
	C930.381592,917.463257 933.464600,918.034790 936.933105,918.712891 
	C934.651428,919.430603 932.009094,920.322998 929.309021,920.561157 
	C927.266235,920.741333 925.149719,920.086182 922.648376,919.802490 
	C922.230713,919.802124 922.212280,919.828247 922.166748,919.633057 
	C922.121155,919.437927 921.991577,919.039795 921.991577,919.039795 
	C923.529419,918.412598 925.067261,917.785400 926.951904,917.024963 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M64.020920,920.564026 
	C53.737221,920.666138 43.390621,920.414246 32.592251,920.233643 
	C29.868034,920.105042 27.595589,919.905273 25.323143,919.705444 
	C25.327681,919.438904 25.332216,919.172302 25.336752,918.905762 
	C37.804665,918.905762 50.272575,918.905762 63.179424,918.859009 
	C63.731583,919.278137 63.844803,919.744080 64.020920,920.564026 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M656.980652,110.015106 
	C649.446960,109.895226 641.922119,109.763680 634.114380,109.297348 
	C635.555664,108.899597 637.279846,108.836639 639.877319,108.847099 
	C646.747192,108.330894 652.743713,107.741280 658.665649,107.490089 
	C658.724243,108.229477 658.857361,108.630424 658.990479,109.031372 
	C658.990540,109.031364 659.003784,108.997818 658.699951,108.811066 
	C657.919556,109.090988 657.442993,109.557663 656.966492,110.024345 
	C656.966492,110.024345 656.989441,110.003433 656.980652,110.015106 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M632.773193,112.196381 
	C638.817932,112.033646 644.587341,112.033646 650.356812,112.033646 
	C650.391235,112.586914 650.425659,113.140182 650.460144,113.693451 
	C644.922668,113.844200 639.385193,113.994957 633.392334,114.281082 
	C632.790527,113.730675 632.644165,113.044891 632.773193,112.196381 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M919.289124,447.997681 
	C919.464417,443.823578 919.962341,439.660553 920.670776,435.177216 
	C920.969482,438.990997 921.057678,443.125122 921.023804,447.657227 
	C920.471741,448.039734 920.041748,448.024261 919.289124,447.997681 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M629.720703,130.808655 
	C628.129150,132.886734 623.564270,130.805313 623.359070,135.687225 
	C622.622314,133.587006 622.127197,131.220032 621.431885,127.896088 
	C624.642456,128.972900 627.047485,129.779556 629.720703,130.808655 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M798.455933,943.852417 
	C800.379395,943.527161 802.766052,943.278931 805.577637,943.023071 
	C814.098572,943.100342 822.194763,943.185364 830.639160,943.477661 
	C820.298035,943.766418 809.608582,943.847961 798.455933,943.852417 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M524.546387,455.086548 
	C527.602539,453.448639 530.952026,451.850464 534.811768,450.506592 
	C535.600403,451.576843 535.878662,452.392792 536.156982,453.208740 
	C536.156982,453.208740 536.083862,453.165192 535.747192,453.109863 
	C534.343933,453.824219 533.277283,454.593903 532.210632,455.363586 
	C532.210632,455.363556 532.189087,455.190765 531.878479,455.178955 
	C530.043640,455.763794 528.519409,456.360474 526.995178,456.957153 
	C526.276672,456.346832 525.558167,455.736542 524.546387,455.086548 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M658.740295,107.151657 
	C652.743713,107.741280 646.747192,108.330894 640.304321,108.824921 
	C639.910156,108.328606 639.962158,107.927872 640.010742,107.223152 
	C646.095947,106.665886 652.184509,106.412598 658.642456,106.459946 
	C659.011841,106.760582 658.740295,107.151657 658.740295,107.151657 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M636.212463,103.155769 
	C641.968628,103.200180 647.841736,103.496239 653.714783,103.792297 
	C653.708435,104.211998 653.702087,104.631706 653.695740,105.051414 
	C648.749695,105.051414 643.803589,105.051414 638.388184,104.978683 
	C637.389038,104.406441 636.859192,103.906929 636.212463,103.155769 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M399.333374,952.206848 
	C405.274261,951.713196 411.559662,951.368469 418.349823,951.219910 
	C418.854614,951.416016 418.896698,951.904480 418.896698,951.904541 
	C412.490417,952.054993 406.084167,952.205383 399.333374,952.206848 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M526.758301,457.214478 
	C528.519409,456.360474 530.043640,455.763794 531.924561,455.252808 
	C528.451416,457.617371 524.621582,459.896210 520.425171,462.270721 
	C522.212769,460.734863 524.367065,459.103333 526.758301,457.214478 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M623.455566,138.391968 
	C624.269409,142.090118 624.980774,146.188141 625.462341,150.597717 
	C624.606079,146.870132 623.979614,142.830994 623.455566,138.391968 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M920.240662,617.659119 
	C919.892029,614.544739 919.730042,611.084961 919.814331,607.309204 
	C920.182861,610.433411 920.305054,613.873535 920.240662,617.659119 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M353.471924,957.045654 
	C360.122894,956.785645 367.234955,956.609558 374.668091,956.691711 
	C367.970428,957.009827 360.951721,957.069641 353.471924,957.045654 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M645.648193,131.260529 
	C643.315308,134.508423 639.962646,134.672974 636.132080,132.762085 
	C639.076660,132.064651 642.159607,131.698410 645.648193,131.260529 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M377.468048,957.039124 
	C382.258423,956.630127 387.508087,956.309937 393.198059,955.964111 
	C390.215851,956.347168 386.799896,956.830017 383.368134,957.134277 
	C381.568848,957.293762 379.741821,957.140137 377.468048,957.039124 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M914.295776,669.188843 
	C915.887390,668.204712 917.775391,667.402466 919.853149,666.287354 
	C921.024597,671.598267 916.848206,668.943420 914.295776,669.188843 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M625.802979,153.285095 
	C626.563660,154.823425 627.250793,156.690506 627.899719,158.984085 
	C627.150818,157.478333 626.440125,155.546112 625.802979,153.285095 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M639.788452,185.718231 
	C641.068909,186.394012 642.491394,187.345703 643.956848,188.648926 
	C642.643372,187.998367 641.286926,186.996277 639.788452,185.718231 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M645.705078,190.427856 
	C646.866089,190.580551 648.196960,190.997589 649.754578,191.681641 
	C648.612549,191.529831 647.243713,191.111008 645.705078,190.427856 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M343.346191,957.298096 
	C342.552277,957.247986 342.107239,957.157654 341.339661,956.956909 
	C343.481903,956.720825 345.946655,956.595337 348.699463,956.717346 
	C348.528717,957.049377 348.069946,957.133911 346.963074,957.221008 
	C345.907135,957.275208 345.499268,957.326904 345.091431,957.378540 
	C344.625977,957.338379 344.160522,957.298218 343.346191,957.298096 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M651.739502,126.159164 
	C651.272888,127.009384 650.536682,128.001678 649.439819,129.011154 
	C649.876099,128.119308 650.673035,127.210266 651.739502,126.159164 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M419.108215,951.906799 
	C418.896698,951.904480 418.854614,951.416016 418.800598,951.177002 
	C418.903381,950.322693 419.060181,949.707520 419.530090,949.017456 
	C419.760895,949.706116 419.678558,950.469482 419.664856,951.614990 
	C419.733521,951.997131 419.319733,951.909119 419.108215,951.906799 
z"/>
<path fill="#1F0606" opacity="1.000000" stroke="none" 
	d="
M582.877258,337.440247 
	C582.765869,337.996674 582.658813,338.874115 582.260376,339.918213 
	C582.273193,339.310364 582.577454,338.535797 582.877258,337.440247 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M879.761963,671.251587 
	C879.874573,670.703491 880.205811,669.973145 880.860962,669.195801 
	C880.783447,669.788940 880.382019,670.429138 879.761963,671.251587 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M345.248199,957.526367 
	C345.499268,957.326904 345.907135,957.275208 346.618530,957.288147 
	C346.416412,957.459900 345.910706,957.567017 345.248199,957.526367 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M785.792786,715.671509 
	C785.589539,715.441040 785.490540,714.885681 785.561707,714.167847 
	C785.786926,714.452393 785.841980,714.899597 785.792786,715.671509 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M920.431641,457.939819 
	C920.207397,457.626068 920.146179,457.060944 920.224365,456.243225 
	C920.440674,456.556580 920.517700,457.122498 920.431641,457.939819 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M653.746460,240.472595 
	C654.099426,240.562164 654.520935,240.926239 654.990356,241.586273 
	C654.630493,241.503860 654.222717,241.125488 653.746460,240.472595 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M984.004395,925.964355 
	C983.795044,925.881287 983.770752,925.690247 983.772644,925.210693 
	C983.929016,925.233521 984.059265,925.544983 984.004395,925.964355 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M107.846161,953.532166 
	C107.936378,953.634460 107.506737,953.737976 107.291367,953.689941 
	C107.248779,953.368347 107.475426,953.297668 107.846161,953.532166 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M662.073059,249.255737 
	C662.321289,249.120941 662.578552,249.315292 662.874451,249.816971 
	C662.700134,249.859695 662.423157,249.679886 662.073059,249.255737 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M652.759521,125.085602 
	C652.890747,125.321877 652.703003,125.577118 652.228333,125.891235 
	C652.183472,125.723938 652.349854,125.442085 652.759521,125.085602 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M63.495365,509.967896 
	C63.240887,509.916718 63.163586,509.749237 63.077782,509.305603 
	C63.069283,509.029388 63.558891,509.020264 63.800991,509.057678 
	C63.981426,509.377625 63.857910,509.629791 63.495365,509.967896 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M857.096619,940.339600 
	C857.141235,940.171204 857.309448,940.121521 857.740601,940.062317 
	C857.742432,940.187988 857.481323,940.323120 857.096619,940.339600 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M785.776367,846.758545 
	C785.395874,846.799988 785.318359,846.526733 785.598083,846.090332 
	C785.761353,846.157166 785.802734,846.329407 785.776367,846.758545 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M44.921242,901.668823 
	C44.860451,901.811951 44.681137,901.846191 44.231636,901.837341 
	C44.191181,901.505249 44.471607,901.427185 44.921242,901.668823 
z"/>
<path fill="#6899A6" opacity="1.000000" stroke="none" 
	d="
M149.566437,923.616455 
	C149.103165,922.944031 149.072617,922.331238 149.482697,921.423279 
	C152.831863,921.128113 155.740433,921.128113 159.107925,921.128113 
	C159.107925,879.437317 159.107925,838.669983 159.564728,797.977783 
	C252.494919,798.051453 344.968323,798.067688 437.441498,797.922424 
	C439.320465,797.919495 441.196503,796.035522 443.319153,795.263184 
	C443.704254,796.387024 443.844086,797.276733 443.983917,798.166443 
	C425.112823,820.882935 406.263123,843.617310 387.354248,866.302307 
	C383.105316,871.399719 378.649628,876.324829 374.191620,881.644531 
	C373.881927,882.358887 373.669800,882.757935 373.063965,883.611572 
	C369.516174,887.390137 366.362091,890.714050 363.178772,893.775757 
	C363.100403,893.344543 363.051208,893.175659 362.695190,892.724731 
	C361.080658,892.296753 359.772980,892.023621 358.465240,892.023132 
	C295.558929,892.000732 232.652649,891.995422 169.746368,892.037048 
	C168.157181,892.038086 166.568359,892.586243 164.873444,893.036621 
	C164.767548,893.193970 164.561523,893.512451 164.345123,893.824585 
	C166.159149,894.635864 168.188660,895.566956 170.220123,895.571228 
	C231.598740,895.700195 292.977570,895.731812 354.356323,895.812500 
	C356.657806,895.815552 358.958832,896.156250 361.230072,896.679077 
	C351.795776,906.029419 342.391510,915.040283 332.987213,924.051086 
	C295.756927,923.717346 258.527191,923.232727 221.296204,923.104309 
	C197.531464,923.022278 173.764908,923.467285 149.566437,923.616455 
z"/>
<path fill="#6798A6" opacity="1.000000" stroke="none" 
	d="
M566.932007,715.994934 
	C566.932068,715.994934 567.149048,715.770508 567.463501,715.428833 
	C571.634277,709.906433 575.490540,704.725708 579.346863,699.545044 
	C580.209473,701.711243 580.984985,703.917908 581.966980,706.028564 
	C582.570618,707.325806 583.490112,708.476196 584.910583,710.085083 
	C587.353943,710.648560 589.154175,710.963928 590.955078,710.967834 
	C608.437805,711.005920 625.920776,710.990051 643.403625,710.993652 
	C649.995544,710.994995 650.003113,710.999817 650.003662,717.417908 
	C650.007507,761.541321 650.007996,805.664795 650.009033,849.788208 
	C650.009033,850.787231 650.331421,851.955750 649.923584,852.745972 
	C649.290955,853.971863 648.364929,855.380493 647.196289,855.927368 
	C644.598267,857.143188 641.772705,857.872803 639.039856,858.800659 
	C638.585022,858.874329 638.130127,858.947937 637.139343,858.772461 
	C635.419434,858.912598 634.235474,859.301819 633.051575,859.691040 
	C632.593994,859.791443 632.136414,859.891846 631.086182,859.725342 
	C628.674500,859.922058 626.855347,860.385864 625.036255,860.849670 
	C624.599792,860.948669 624.163269,861.047607 623.122681,860.845093 
	C619.287415,861.281677 616.056274,862.019714 612.825195,862.757751 
	C612.660034,862.792053 612.494873,862.826355 612.102783,862.512939 
	C611.726868,862.184082 611.577759,862.202881 611.428711,862.221680 
	C611.507141,862.349487 611.585571,862.477356 611.664001,862.605225 
	C611.664001,862.605225 611.417786,862.684082 610.894165,862.434204 
	C600.917480,863.290771 591.452271,864.304871 582.018616,865.557190 
	C578.973633,865.961365 576.011719,866.991760 573.011841,867.735718 
	C572.848267,867.807617 572.684692,867.879517 572.035889,867.992981 
	C571.290649,868.127075 571.030762,868.219543 570.770874,868.312012 
	C571.057007,868.535278 571.343079,868.758606 571.629211,868.981873 
	C571.697815,869.739502 571.766418,870.497070 571.393799,871.764404 
	C569.721558,876.267273 568.713013,880.245239 571.750244,883.946777 
	C573.316956,884.681152 574.883728,885.415588 576.809021,886.318054 
	C571.003418,889.274109 568.591064,894.302551 567.640869,900.241516 
	C566.646240,906.457703 568.974915,911.629456 573.285950,916.747681 
	C564.930786,916.747681 557.124451,916.747681 549.150818,915.913452 
	C549.001648,905.210938 549.019775,895.342651 549.036865,885.317505 
	C549.035095,884.951172 549.035583,884.741821 549.100403,884.032104 
	C549.140442,870.695435 549.117310,857.859253 549.266541,844.655212 
	C549.547302,812.340271 549.660522,780.393066 549.734253,748.445862 
	C549.736023,747.680542 549.296509,746.914246 549.062744,746.148376 
	C554.391663,737.120117 559.720642,728.091919 565.377686,718.570923 
	C566.114624,717.383728 566.523315,716.689331 566.932007,715.994934 
z"/>
<path fill="#699AA8" opacity="1.000000" stroke="none" 
	d="
M778.687012,480.222290 
	C774.727722,512.981018 770.386719,545.585754 766.035095,578.189026 
	C765.859741,579.502930 765.572571,580.801575 765.356873,582.110535 
	C763.628723,592.594177 763.629089,592.586670 752.947083,594.142029 
	C750.973450,594.429443 749.013123,594.807861 746.636108,595.115723 
	C733.058716,596.639404 719.891785,598.191528 706.136414,599.813049 
	C699.175537,565.077820 692.209961,530.319458 685.150452,495.092316 
	C703.626282,492.062561 721.909241,489.064453 741.016296,486.085693 
	C746.560364,485.368286 751.280334,484.631470 756.000305,483.894653 
	C763.434692,482.619110 770.869080,481.343567 778.687012,480.222290 
z"/>
<path fill="#6898A5" opacity="1.000000" stroke="none" 
	d="
M714.870605,307.126495 
	C724.663879,305.422119 734.476257,303.814758 744.226257,301.891144 
	C745.453125,301.649078 746.711609,299.913300 747.346069,298.579651 
	C753.326294,286.010559 759.174011,273.378510 766.123474,260.959167 
	C765.577637,265.042847 764.997620,269.122314 764.492371,273.210999 
	C762.903564,286.065765 760.950745,298.957794 765.798157,311.563782 
	C766.635010,313.740051 768.219482,315.628815 769.519104,318.193298 
	C768.097107,318.836151 766.614624,318.936066 764.891846,318.765991 
	C763.096558,315.252258 761.541687,312.008575 759.986877,308.764862 
	C759.487549,308.827240 758.988220,308.889587 758.488892,308.951935 
	C758.488892,320.388123 758.568115,331.825165 758.445557,343.260040 
	C758.403137,347.218536 758.610596,351.419312 757.403625,355.080780 
	C753.816406,365.963196 749.505310,376.604858 745.636597,387.397766 
	C743.909729,392.215240 742.564209,397.169403 741.045654,402.061523 
	C726.810120,406.107239 712.574585,410.152985 697.674194,414.387695 
	C698.764832,415.781830 699.715637,416.997223 700.829468,418.842834 
	C701.365356,419.971100 701.738281,420.469116 702.111145,420.967163 
	C702.111145,420.967163 702.033936,420.953461 702.033936,421.308289 
	C703.373901,425.430115 704.713806,429.197144 706.053711,432.964203 
	C706.867859,435.373810 707.682068,437.783417 708.071533,440.594604 
	C706.113892,440.668365 704.580872,440.340485 702.870361,439.675903 
	C700.553162,431.968658 698.413452,424.598083 696.402527,417.671295 
	C687.515869,421.248413 679.270508,424.567383 671.044800,427.528076 
	C680.314148,402.197968 689.631165,377.250824 698.788086,352.245087 
	C704.278198,337.252869 709.518188,322.169128 714.870605,307.126495 
z"/>
<path fill="#FBFAEB" opacity="1.000000" stroke="none" 
	d="
M332.744965,924.302490 
	C342.391510,915.040283 351.795776,906.029419 361.461365,896.486084 
	C362.217773,895.315063 362.712860,894.676514 363.208008,894.037964 
	C366.362091,890.714050 369.516174,887.390137 372.943542,883.827881 
	C376.089050,884.418945 379.269501,884.722168 381.769775,886.186646 
	C387.052490,889.280945 391.844727,893.206665 397.095032,896.364197 
	C401.350983,898.923645 402.801208,902.323425 402.121033,907.044373 
	C401.886810,908.669800 402.108978,910.361023 402.033295,912.795044 
	C402.356262,913.916870 402.768585,914.265869 403.180908,914.614868 
	C403.889160,914.662659 404.597443,914.710388 405.574219,915.078247 
	C405.842773,915.398376 405.942963,915.839783 405.942963,915.839783 
	C404.603180,916.280518 403.263397,916.721252 401.923615,917.161926 
	C402.028107,917.765381 402.132568,918.368835 402.237030,918.972290 
	C405.864624,918.972290 409.492218,918.972290 413.405518,919.369873 
	C409.914001,920.017822 406.136749,920.268311 402.054626,920.539001 
	C401.912506,925.014526 405.158295,923.495789 407.166565,923.719177 
	C409.596344,923.989441 412.051788,924.028687 414.496155,924.167908 
	C414.480408,924.653259 414.464661,925.138550 414.448914,925.623840 
	C411.347595,925.623840 408.240540,925.518677 405.148438,925.687439 
	C404.209229,925.738708 403.311340,926.546448 402.394531,927.007751 
	C403.345398,927.592957 404.237823,928.530029 405.257721,928.699341 
	C408.756927,929.280457 412.300781,929.592468 415.827637,930.006958 
	C415.737610,930.223694 415.647614,930.440369 414.944153,930.730225 
	C410.461517,930.972168 406.592407,931.141052 402.723267,931.309998 
	C402.769257,931.828430 402.815216,932.346924 402.861206,932.865356 
	C406.955811,932.865356 411.050446,932.865356 415.145020,932.865356 
	C415.410553,933.340576 415.676086,933.815735 415.465515,934.641846 
	C411.354980,935.048523 407.716614,935.037720 404.091400,935.250427 
	C403.673584,935.274902 403.328766,936.544067 402.918274,937.539917 
	C403.241608,938.413696 403.606018,939.483398 403.950500,939.476990 
	C407.689117,939.407776 411.424561,939.169739 415.160889,938.977722 
	C415.422668,939.441345 415.684448,939.904907 415.484680,940.719910 
	C410.906921,941.071289 406.790710,941.071289 402.674500,941.071289 
	C402.707794,941.735229 402.741089,942.399170 402.774353,943.063110 
	C407.158691,943.063110 411.543030,943.063110 415.927368,943.063110 
	C415.927368,944.210266 415.927368,945.357422 415.927368,946.858521 
	C413.360809,946.858521 410.888275,946.994080 408.434998,946.834106 
	C399.679016,946.262939 391.303284,947.689941 383.036591,950.664124 
	C379.879761,951.799988 376.237518,951.825012 372.805389,951.910217 
	C364.183990,952.124451 355.372284,953.237305 346.971008,951.896423 
	C331.471344,949.422485 316.211060,945.448792 300.449829,942.068054 
	C301.367126,940.523438 302.396118,938.434082 304.055786,937.600769 
	C310.247681,934.491699 316.646423,931.794495 323.313721,928.879639 
	C326.607483,927.395691 329.555115,925.974792 332.744965,924.302490 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M549.037231,884.532532 
	C549.035583,884.741821 549.035095,884.951172 548.577576,885.105469 
	C546.860962,885.383179 545.602234,886.006775 544.344299,886.005188 
	C524.603577,885.979675 504.862885,885.873657 485.122192,885.786133 
	C489.628540,879.262512 494.134857,872.738892 498.866577,865.888977 
	C501.316711,868.166748 503.456787,870.156372 506.482452,872.969238 
	C517.836487,861.387756 529.062622,849.936707 539.867126,838.915833 
	C530.827332,827.570557 522.250916,816.806885 513.356201,805.914062 
	C523.956421,787.784485 534.875000,769.784058 546.266724,752.286621 
	C546.777710,754.616150 546.843384,756.442688 546.849365,758.269348 
	C546.948608,788.513977 547.040405,818.758606 547.080261,849.468872 
	C547.038574,860.168457 546.980042,870.403503 547.149353,880.634766 
	C547.171082,881.944092 548.378906,883.233765 549.037231,884.532532 
z"/>
<path fill="#679BA9" opacity="1.000000" stroke="none" 
	d="
M872.868042,423.570587 
	C880.328979,421.431793 887.741211,419.709412 895.676147,417.865570 
	C893.382263,444.003265 891.139404,469.559509 888.794556,495.947327 
	C886.501465,514.523682 884.306152,532.268005 882.135437,550.015320 
	C882.056396,550.661499 882.196228,551.339844 882.272217,551.998718 
	C882.811035,556.670776 883.359863,561.341736 883.718384,566.187378 
	C883.488831,566.689941 883.446289,567.018066 883.426697,567.538940 
	C883.449646,567.731750 883.388977,568.115234 883.105774,568.235107 
	C881.942505,571.900940 879.082092,569.106323 877.155640,569.830078 
	C876.324280,569.498962 875.727112,569.389526 875.060059,568.902466 
	C874.221069,567.343933 873.452026,566.163025 872.682983,564.982117 
	C872.235535,565.124939 871.788147,565.267761 871.340759,565.410645 
	C871.232910,566.981445 871.125061,568.552246 870.922241,570.217468 
	C870.827271,570.311890 870.573608,570.225830 870.222046,570.091675 
	C868.953918,569.652222 868.037476,569.346985 866.921509,568.914429 
	C866.053406,569.242798 865.384827,569.698425 864.240356,570.100708 
	C863.446655,570.018494 863.128784,569.989502 862.810852,569.960449 
	C863.794128,553.236938 864.622742,536.502563 865.793945,519.792236 
	C868.032715,487.849945 870.466431,455.921295 872.868042,423.570587 
z"/>
<path fill="#679BA9" opacity="1.000000" stroke="none" 
	d="
M831.114929,657.049377 
	C830.195557,658.156067 829.185608,659.167419 829.057678,660.280212 
	C827.537659,673.504822 830.941467,685.926575 835.407715,698.170898 
	C836.203369,700.352051 836.728455,702.631897 837.289185,705.242920 
	C831.159668,704.545044 825.088135,703.611206 819.105774,702.277405 
	C817.904663,702.009644 816.441101,700.374512 816.117188,699.109131 
	C812.777283,686.060242 809.587769,672.971802 806.470947,659.867126 
	C800.772278,635.907166 795.149902,611.929016 789.504272,587.956482 
	C789.360840,587.347534 789.316711,586.715210 789.048889,584.876343 
	C796.861511,584.876343 804.276550,584.752686 811.675171,585.043518 
	C812.533569,585.077271 813.718567,587.309814 814.048462,588.690918 
	C818.379272,606.824219 822.550659,624.995544 826.884216,643.128174 
	C827.216187,644.517395 828.469360,645.686584 829.532043,647.384033 
	C831.515503,648.178955 833.261230,648.547974 835.006958,648.916992 
	C835.477478,648.939270 835.947998,648.961487 836.808594,649.465820 
	C840.039368,653.040222 838.784424,654.849182 835.306580,655.934692 
	C834.234619,656.269287 833.141663,656.536682 832.058289,656.834839 
	C831.850281,656.890259 831.642273,656.945679 831.114929,657.049377 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M637.105164,554.005554 
	C641.909973,547.304688 641.428528,539.963501 640.442688,532.229370 
	C638.349792,515.809937 636.857361,499.313934 635.062744,482.422974 
	C641.898987,483.830933 648.790527,485.667664 655.683838,487.498199 
	C658.189209,488.163574 659.372803,489.439789 659.495850,492.369934 
	C660.109741,506.982574 661.029175,521.582336 661.859009,537.006592 
	C661.969971,538.881470 662.062988,539.936340 662.156006,540.991272 
	C662.199341,544.657898 662.242615,548.324585 662.202026,552.825195 
	C661.130676,555.995483 660.596802,558.693665 659.073975,560.602966 
	C653.645020,567.409180 647.879944,573.947266 642.179199,580.150391 
	C641.910034,578.088074 641.703613,576.462280 641.497253,574.836426 
	C641.885986,575.036438 642.274719,575.236389 642.663513,575.436340 
	C644.231140,573.069946 646.258179,570.881348 647.259033,568.295593 
	C648.768677,564.395142 650.402527,561.348633 655.407898,562.791504 
	C655.651001,561.852539 656.052063,561.182190 655.927246,560.630859 
	C655.430969,558.437988 655.363892,554.962341 654.053833,554.373413 
	C650.817566,552.918701 650.683472,556.394165 649.418762,558.438477 
	C648.394470,560.094116 645.866577,561.657715 643.998474,561.665955 
	C642.698792,561.671692 641.381958,558.977844 640.082581,557.481567 
	C639.082214,556.329712 638.097046,555.164734 637.105164,554.005554 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M602.686096,892.520386 
	C600.703247,889.646484 598.892639,887.113831 597.401123,885.027710 
	C613.972290,885.027710 632.223450,885.027710 650.854492,885.027710 
	C650.854492,895.359741 650.854492,906.068115 650.854492,917.263062 
	C633.598694,917.263062 616.272583,917.263062 598.111755,917.263062 
	C605.011963,909.948425 607.809265,902.024536 602.686096,892.520386 
z"/>
<path fill="#FCFCEC" opacity="1.000000" stroke="none" 
	d="
M671.025085,427.886322 
	C679.270508,424.567383 687.515869,421.248413 696.402527,417.671295 
	C698.413452,424.598083 700.553162,431.968658 702.840942,440.041473 
	C702.281860,441.978485 701.803040,443.444824 700.827148,444.407074 
	C697.056946,448.124664 693.466858,452.164307 689.172546,455.175446 
	C683.846436,458.910156 677.888367,461.743561 672.203247,464.966309 
	C666.069092,458.194946 659.934937,451.423523 653.480530,444.298279 
	C652.074463,442.627258 650.988708,441.310120 649.881775,439.621796 
	C651.960632,437.682037 654.060608,436.113403 656.534912,434.447601 
	C661.614502,432.195740 666.319824,430.041016 671.025085,427.886322 
z"/>
<path fill="#699AA8" opacity="1.000000" stroke="none" 
	d="
M624.851868,462.137115 
	C621.671875,461.132507 618.891052,459.793762 615.959290,459.198273 
	C607.356934,457.450836 598.694580,455.998993 589.056641,454.250061 
	C595.561768,445.441681 599.192993,436.160217 610.579529,432.559814 
	C624.321167,428.214783 637.301453,421.479187 650.661743,415.898895 
	C653.868408,414.559479 657.274048,413.696411 660.958618,412.577820 
	C661.434937,413.158813 661.540833,413.776306 661.628174,414.990479 
	C661.594299,415.766052 661.527161,415.922729 661.065430,415.954895 
	C660.159729,416.241791 659.596863,416.630890 659.018799,417.008148 
	C659.003479,416.996277 659.018372,417.031311 658.693298,417.132263 
	C653.247864,421.163910 648.127441,425.094543 642.698486,429.140015 
	C640.767517,430.646606 639.145081,432.038361 637.210754,433.570007 
	C633.979675,435.951874 631.060486,438.193848 628.455627,440.194336 
	C629.817749,440.710297 631.264038,441.258179 632.306152,441.829529 
	C630.279907,442.110352 628.658020,442.367737 627.036072,442.625122 
	C627.105103,443.088226 627.174194,443.551331 627.243225,444.014435 
	C630.631409,443.646362 634.019592,443.278290 637.407715,442.910248 
	C637.488159,443.361816 637.568542,443.813416 637.648926,444.265015 
	C634.838135,445.231628 632.027405,446.198242 629.216614,447.164886 
	C629.267456,447.392242 629.318359,447.619629 629.369202,447.847015 
	C631.327759,447.967102 633.286255,448.087219 636.340332,448.274536 
	C633.622314,449.610901 631.953918,450.431213 629.493286,451.641022 
	C633.029907,452.698944 635.705566,453.499329 638.381226,454.299683 
	C638.318848,454.596619 638.256409,454.893555 638.194031,455.190491 
	C636.028748,455.190491 633.863464,455.190491 631.698181,455.190491 
	C631.603027,455.586121 631.507935,455.981720 631.412781,456.377350 
	C633.823853,457.096100 636.234863,457.814850 638.645874,458.533600 
	C638.646973,458.769928 638.648010,459.006256 638.649109,459.242584 
	C636.495850,459.467224 634.342651,459.691864 631.888794,459.947845 
	C634.223450,461.887451 636.068787,463.420563 637.914185,464.953674 
	C637.485596,465.022522 637.057068,465.091339 635.985596,464.960999 
	C633.867493,464.343018 632.392212,463.924225 630.916931,463.505402 
	C629.005310,463.051117 627.093689,462.596863 624.851868,462.137115 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M716.000610,450.407776 
	C716.998108,448.278229 717.971008,446.558868 718.963379,444.429016 
	C718.982788,444.018494 719.059631,443.939240 719.487488,443.947693 
	C733.893555,442.543518 747.893005,441.311401 761.844727,439.673248 
	C779.895630,437.553741 797.908569,435.110107 816.359863,432.862274 
	C821.156006,431.630951 825.530029,430.343414 830.183228,429.039978 
	C830.648804,429.018707 830.835083,429.013336 831.188599,429.291626 
	C832.544373,429.391998 833.732910,429.208710 835.289673,429.009216 
	C838.562195,427.986298 840.090759,426.611237 837.003296,423.748962 
	C837.011414,423.499817 837.009766,423.001221 837.009766,423.001221 
	C838.074280,422.999756 839.138733,422.998260 840.601624,422.998383 
	C841.000000,423.000000 840.997559,422.998779 840.997559,422.998779 
	C841.729370,423.560577 842.647034,424.739319 843.162720,424.583954 
	C846.495544,423.579773 847.800720,425.003235 847.997375,428.479431 
	C832.316833,433.237640 816.636841,437.626007 800.960693,442.027954 
	C799.238831,442.511444 797.538940,443.073090 795.334229,443.269287 
	C790.449951,442.991150 786.060181,443.042175 781.670471,443.093231 
	C781.705383,443.559448 781.740234,444.025665 781.775085,444.491882 
	C783.883545,444.703583 785.992004,444.915283 788.100464,445.127014 
	C779.212280,447.556763 770.368835,450.167816 761.425659,452.374603 
	C747.297424,455.860870 733.102356,459.075958 718.417175,462.527679 
	C717.577454,458.499512 716.776733,454.658752 716.000610,450.407776 
M762.833313,443.919952 
	C777.225159,441.885590 791.616943,439.851196 806.008789,437.816833 
	C805.950195,437.394043 805.891602,436.971222 805.833008,436.548431 
	C777.263855,440.796265 748.177612,441.116638 719.970398,448.361237 
	C720.913452,449.169769 721.807129,449.303528 722.648010,449.149414 
	C732.750854,447.297760 742.820801,445.246582 752.958923,443.618835 
	C755.887024,443.148712 759.005432,443.863739 761.183472,444.010742 
	C747.603638,446.666595 734.023865,449.322449 720.444031,451.978302 
	C720.577637,452.587494 720.711182,453.196686 720.844788,453.805878 
	C725.650940,452.874603 730.504578,452.136414 735.253113,450.970459 
	C744.200806,448.773407 753.088074,446.329926 762.833313,443.919952 
M740.437683,451.992950 
	C737.507874,452.559875 734.578003,453.126801 731.648193,453.693756 
	C731.778259,454.294312 731.908386,454.894867 732.038452,455.495422 
	C747.546082,452.140930 763.053711,448.786407 778.561340,445.431915 
	C778.450562,444.879425 778.339783,444.326904 778.229004,443.774414 
	C765.914368,446.488892 753.599792,449.203339 740.437683,451.992950 
M812.814209,436.118896 
	C811.899719,436.184631 810.985229,436.250366 810.070679,436.316071 
	C810.093384,436.533752 810.116028,436.751434 810.138733,436.969116 
	C811.184753,436.818298 812.230713,436.667480 812.814209,436.118896 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M745.485962,936.923828 
	C745.969238,937.978149 745.989807,938.991943 745.552368,940.375000 
	C719.816284,940.518982 694.538574,940.171448 669.260010,940.100220 
	C639.496277,940.016357 609.731934,940.171936 579.967834,940.223633 
	C576.287231,940.165161 572.606689,940.106689 568.260254,939.621033 
	C563.400146,939.472229 559.205750,939.750549 555.011414,940.028931 
	C549.314331,940.057922 543.617249,940.086914 537.421631,939.735352 
	C537.010620,938.466125 537.098206,937.577332 537.185791,936.688599 
	C565.994690,936.633728 594.803711,936.505737 623.612549,936.541504 
	C664.082825,936.591675 704.552979,936.763489 745.485962,936.923828 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M579.964111,940.601929 
	C609.731934,940.171936 639.496277,940.016357 669.260010,940.100220 
	C694.538574,940.171448 719.816284,940.518982 745.556152,940.755432 
	C746.017944,940.766663 745.959045,940.873169 746.035034,941.287781 
	C746.049866,942.443542 745.988770,943.184631 745.927612,943.925781 
	C745.927612,943.925781 745.980957,943.963501 745.487427,943.939697 
	C690.878662,943.915894 636.763428,943.915894 582.648254,943.915894 
	C582.640137,944.219788 582.632019,944.523743 582.623962,944.827637 
	C585.401001,944.959412 588.177979,945.091248 590.943726,945.364258 
	C590.932434,945.505493 590.906372,945.787598 590.446777,945.828430 
	C584.687012,945.835205 579.386902,945.801147 574.060303,945.553711 
	C574.033875,945.340332 573.949829,944.918579 573.955078,944.714844 
	C573.960266,944.511169 573.962219,944.103577 573.962219,944.103577 
	C575.961609,943.062500 577.961060,942.021362 579.964111,940.601929 
z"/>
<path fill="#FAF4DE" opacity="1.000000" stroke="none" 
	d="
M720.988647,933.882385 
	C679.103149,933.830627 637.217346,933.844116 595.332214,933.699707 
	C574.223389,933.626953 553.115479,933.268005 531.830322,932.678345 
	C550.177124,932.171082 568.701172,932.056519 587.223633,931.817444 
	C588.771729,931.797485 590.309082,930.940491 592.300049,930.513672 
	C593.559204,930.312622 594.369690,930.070312 595.580261,930.050537 
	C603.255981,930.344788 610.531677,930.416504 618.255371,930.673462 
	C628.802429,930.861938 638.901489,930.865112 649.466309,930.922241 
	C673.615662,931.025940 697.299072,931.075623 720.981323,931.469971 
	C720.982910,932.503906 720.985779,933.193115 720.988647,933.882385 
z"/>
<path fill="#679BA9" opacity="1.000000" stroke="none" 
	d="
M672.054199,465.302490 
	C677.888367,461.743561 683.846436,458.910156 689.172546,455.175446 
	C693.466858,452.164307 697.056946,448.124664 700.827148,444.407074 
	C701.803040,443.444824 702.281860,441.978485 703.018433,440.378143 
	C704.580872,440.340485 706.113892,440.668365 708.060669,440.995575 
	C708.654480,441.491486 708.834473,441.988068 708.767944,442.920105 
	C708.730164,443.914093 708.938782,444.472595 709.147461,445.031128 
	C708.205994,446.450989 707.334412,447.923615 706.309448,449.280365 
	C703.706909,452.725067 701.201416,456.266113 698.331238,459.477295 
	C696.534973,461.486969 694.198303,463.037323 691.998962,464.650360 
	C691.229248,465.214844 690.169312,465.471405 689.201172,465.663452 
	C681.245422,467.241577 676.135132,471.514160 675.159607,479.985382 
	C674.975098,481.587433 674.133118,483.113770 673.412964,485.201263 
	C670.851624,484.198090 668.469482,483.265106 665.415894,482.069183 
	C667.672974,476.354370 669.789001,470.996521 672.054199,465.302490 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M639.453125,858.846436 
	C641.772705,857.872803 644.598267,857.143188 647.196289,855.927368 
	C648.364929,855.380493 649.290955,853.971863 649.923584,852.745972 
	C650.331421,851.955750 650.009033,850.787231 650.009033,849.788208 
	C650.007996,805.664795 650.007507,761.541321 650.003662,717.417908 
	C650.003113,710.999817 649.995544,710.994995 643.403625,710.993652 
	C625.920776,710.990051 608.437805,711.005920 590.955078,710.967834 
	C589.154175,710.963928 587.353943,710.648560 585.266968,710.168884 
	C596.600769,709.729919 608.220581,709.555298 619.841125,709.483154 
	C630.119507,709.419373 640.398560,709.469727 651.196411,709.469727 
	C651.196411,758.784668 651.196411,807.614075 651.196411,856.969360 
	C647.620850,857.576172 643.743591,858.234192 639.453125,858.846436 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M673.932922,562.871704 
	C675.435669,566.519409 673.091797,568.950562 671.307861,571.667358 
	C667.200562,577.922058 663.455811,584.423401 659.171692,590.550415 
	C656.143860,594.880676 652.473206,598.761536 648.398621,602.894287 
	C647.460266,603.314636 647.212769,603.683716 646.965271,604.052734 
	C646.965271,604.052734 646.972412,603.975830 646.666077,604.000977 
	C643.570190,606.362610 640.780640,608.699219 637.679932,610.772827 
	C640.671326,606.763489 643.973938,603.017029 647.527710,599.138916 
	C648.385803,598.299194 648.992615,597.591125 649.859009,596.670837 
	C651.026184,595.466003 651.933716,594.473389 652.841248,593.480713 
	C652.126343,592.785645 651.411377,592.090515 650.696411,591.395447 
	C650.076111,592.879822 649.455811,594.364197 648.574341,595.971558 
	C647.678101,596.805298 647.042847,597.516052 646.119995,598.444214 
	C644.884277,599.121643 643.936279,599.581665 643.011902,599.698242 
	C645.053772,596.914001 647.071960,594.473145 649.067505,592.014221 
	C649.044861,591.996155 649.026245,592.051147 649.327271,592.058655 
	C649.768250,591.721497 649.908264,591.376831 650.024170,591.016113 
	C650.000000,591.000000 650.048096,591.033020 650.425171,590.934570 
	C650.887939,590.226624 650.973694,589.617188 651.030273,589.003418 
	C651.001038,588.999207 651.024475,589.053406 651.412109,589.245605 
	C651.881104,588.973572 651.962341,588.509399 652.021851,588.022583 
	C652.000000,588.000000 652.046021,588.043518 652.422546,588.229858 
	C652.878723,587.961609 652.958313,587.506958 653.023193,587.026855 
	C653.008484,587.001343 653.051270,587.043030 653.363770,587.280640 
	C656.042725,584.874146 658.409119,582.230103 661.005981,579.351746 
	C662.088379,578.077759 662.940308,577.038086 663.958862,575.859009 
	C664.270142,575.315369 664.414917,574.911255 664.805664,574.351990 
	C665.488708,573.577942 665.925720,572.959106 666.608521,572.128784 
	C667.286743,571.167969 667.719299,570.418640 668.151855,569.669312 
	C667.941956,569.516357 667.731995,569.363342 667.522095,569.210327 
	C666.836243,569.965637 666.150391,570.721008 665.219971,571.639648 
	C664.549133,572.444580 664.122864,573.086243 663.523560,573.871155 
	C663.161743,574.411560 662.972900,574.808594 662.541992,575.441101 
	C661.467346,576.720703 660.634705,577.764954 659.463135,578.875732 
	C657.011780,581.288818 654.899353,583.635498 652.535278,585.844604 
	C651.775085,585.447693 651.266541,585.188354 650.901978,584.627319 
	C651.707886,583.234070 652.369873,582.142517 653.022217,581.031311 
	C653.012573,581.011658 653.052490,581.029602 653.410767,581.090820 
	C656.147522,577.777954 658.525940,574.403870 661.184937,570.833862 
	C663.985107,568.098145 666.504761,565.558350 669.148071,562.958252 
	C669.173828,562.922607 669.075867,562.947205 668.657349,562.999878 
	C666.572083,564.359253 664.809998,564.798889 663.157837,562.660278 
	C663.312866,562.152405 663.435730,561.978455 663.401245,561.845154 
	C663.327087,561.558533 663.171936,561.292786 663.026367,561.007568 
	C663.002869,560.996643 663.021362,561.044983 663.362793,561.124451 
	C663.819458,560.801880 663.934814,560.399841 664.021301,559.995117 
	C663.992432,559.992432 663.997620,560.050110 664.321167,560.097290 
	C664.779907,559.760620 664.915100,559.376709 665.025146,558.998047 
	C665.000000,559.003296 665.050171,559.015259 665.379211,559.070740 
	C666.145630,558.431396 666.583069,557.736511 667.020081,557.029236 
	C667.019653,557.016846 667.042114,557.006287 667.410522,556.868408 
	C667.866272,555.987488 667.953491,555.244446 668.136841,554.448242 
	C668.232849,554.395081 668.467712,554.031982 668.885620,554.165649 
	C669.877625,554.194519 670.451599,554.089783 671.025635,553.985046 
	C672.482361,554.094238 673.939087,554.203430 675.591675,554.327332 
	C675.382263,555.941284 675.216125,557.221252 675.010986,559.186279 
	C674.978882,560.261169 674.985840,560.651001 674.992798,561.040894 
	C674.992798,561.040894 674.984863,560.986084 674.721924,561.016113 
	C674.299133,561.377197 674.139282,561.708130 673.979431,562.039124 
	C673.979431,562.039124 673.979309,561.981201 673.728760,562.000061 
	C673.294556,562.321167 673.110962,562.623413 672.651611,562.947876 
	C672.219788,563.283264 672.063782,563.596497 671.596130,563.918823 
	C670.685486,564.482483 670.086487,565.036926 669.306335,565.803040 
	C669.180908,566.285583 669.236633,566.556396 669.292297,566.827271 
	C669.632141,566.657227 669.972046,566.487183 670.534302,566.129761 
	C671.177734,565.300415 671.598816,564.658386 672.261353,563.938354 
	C672.673157,563.579041 672.843567,563.297791 673.244812,562.987732 
	C673.475708,562.958923 673.932922,562.871704 673.932922,562.871704 
z"/>
<path fill="#46382E" opacity="1.000000" stroke="none" 
	d="
M720.982544,931.125366 
	C697.299072,931.075623 673.615662,931.025940 649.367371,930.542603 
	C644.154114,929.619385 639.505737,929.129822 634.886230,928.378967 
	C634.937988,927.769104 634.960754,927.420532 635.437988,926.972412 
	C637.002441,926.101318 639.907776,928.519836 639.410950,924.892883 
	C640.019531,924.667358 640.628113,924.441772 641.063477,924.983154 
	C642.190918,926.136536 643.487488,926.842102 644.792664,926.858398 
	C654.525757,926.979614 664.260559,926.964417 674.324585,927.093018 
	C675.104187,927.127136 675.554077,927.060181 676.003967,926.993286 
	C699.169189,927.118958 722.334473,927.244629 745.858032,927.996704 
	C746.114197,929.353149 746.011963,930.083069 745.909790,930.813049 
	C745.909790,930.813049 745.866150,930.921143 745.450317,930.927124 
	C737.017212,930.997253 728.999878,931.061279 720.982544,931.125366 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M591.851440,930.472412 
	C590.309082,930.940491 588.771729,931.797485 587.223633,931.817444 
	C568.701172,932.056519 550.177124,932.171082 531.370728,932.617188 
	C507.633057,932.887939 484.178192,932.858521 460.723297,932.832397 
	C455.332764,932.826416 449.942261,932.831543 444.544678,931.807678 
	C480.173279,929.179565 515.843384,928.828857 552.242798,929.170288 
	C554.472717,929.812012 555.979553,930.873108 557.511353,930.910522 
	C565.448975,931.104797 573.393494,931.090149 581.334412,931.015869 
	C582.886597,931.001404 584.433350,930.402039 586.363403,929.994263 
	C588.446594,930.101562 590.149048,930.287048 591.851440,930.472412 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M485.077026,886.097412 
	C504.862885,885.873657 524.603577,885.979675 544.344299,886.005188 
	C545.602234,886.006775 546.860962,885.383179 548.578613,885.262451 
	C549.019775,895.342651 549.001648,905.210938 548.903198,915.541382 
	C548.398804,915.995728 547.974854,915.987915 547.280273,915.555542 
	C547.516846,911.848083 545.167114,912.226196 543.222900,912.257385 
	C535.800354,912.376221 528.378784,912.553406 520.956848,912.708740 
	C522.134644,912.142395 523.346802,911.636597 524.480896,910.993225 
	C525.682373,910.311401 526.809692,909.498962 528.448486,908.264038 
	C534.794373,907.784546 540.661316,907.784546 546.528198,907.784546 
	C546.837891,907.448120 547.147644,907.111694 547.457397,906.775330 
	C546.419800,905.829590 545.500305,904.299744 544.319763,904.061096 
	C541.947754,903.581604 539.433289,903.806580 536.980591,903.422119 
	C536.982422,902.466187 536.981445,901.829041 537.437073,901.132568 
	C540.825806,901.073364 543.757935,901.073364 546.844849,901.073364 
	C546.844849,899.060547 546.844849,897.671387 546.844849,895.844482 
	C535.377075,895.844482 524.175598,895.844482 512.980042,895.562195 
	C512.985352,894.717163 512.984680,894.154358 513.434021,893.456665 
	C524.790710,893.321655 535.697388,893.321655 546.812317,893.321655 
	C546.812317,891.294556 546.812317,889.899597 546.812317,887.964417 
	C544.612488,887.964417 542.664368,887.973267 540.716370,887.963013 
	C522.128357,887.865112 503.540314,887.764038 484.952301,887.663879 
	C484.978821,887.245483 485.005310,886.827026 485.077026,886.097412 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M579.372192,699.195312 
	C575.490540,704.725708 571.634277,709.906433 567.485840,715.274292 
	C567.131104,712.234558 567.068420,709.007507 567.201050,705.166870 
	C569.417236,705.342468 571.169617,705.517883 572.346252,703.102966 
	C574.146667,699.407715 576.102539,695.788025 578.246826,692.021057 
	C578.682617,691.597778 578.863953,691.289734 579.021973,690.992249 
	C578.998657,691.002808 578.978027,691.049622 579.273499,690.905884 
	C579.722473,690.160889 579.875977,689.559631 580.006348,688.972168 
	C579.983154,688.986084 579.965942,689.037354 580.273560,689.047852 
	C580.734863,688.695923 580.888611,688.333435 581.018921,687.983887 
	C580.995422,687.996826 580.981201,688.048645 581.294922,687.916260 
	C581.747375,687.175293 581.885986,686.566589 582.002869,685.970947 
	C581.981140,685.983887 581.967957,686.032776 582.302368,686.121094 
	C583.104797,685.468018 583.572876,684.726624 584.017334,683.990479 
	C583.993713,683.995850 583.990723,684.044189 584.312073,683.934998 
	C589.097046,677.552551 593.560669,671.279297 598.010803,665.002197 
	C597.997314,664.998352 598.007751,665.024780 598.362305,664.937866 
	C598.818115,664.222778 598.919373,663.594482 599.001221,662.976440 
	C598.981873,662.986694 598.971924,663.029419 599.296692,663.110779 
	C599.760986,662.786438 599.900574,662.380676 600.017517,661.985779 
	C599.994873,661.996643 599.984924,662.045776 600.298462,661.925842 
	C600.749451,661.191589 600.886841,660.577271 601.005371,659.975891 
	C600.986389,659.988831 600.968994,660.031128 601.292358,660.068237 
	C601.754822,659.728088 601.893860,659.350952 602.012207,658.982666 
	C601.991394,658.991577 601.973938,659.033203 602.276550,659.027710 
	C602.731140,658.673645 602.883118,658.325012 603.015381,657.986328 
	C602.995667,657.996338 602.985535,658.039368 603.334351,657.936829 
	C603.794739,657.209167 603.906311,656.583984 603.998901,655.971802 
	C603.979919,655.984802 603.965027,656.028320 604.315186,656.139648 
	C604.785706,655.827271 604.906189,655.403503 605.008057,654.984253 
	C604.989563,654.988708 604.981262,655.025879 605.316040,655.122009 
	C606.111267,654.475220 606.571777,653.732300 607.012695,652.990967 
	C606.993103,652.992493 606.994690,653.031860 607.289978,653.006104 
	C608.075195,652.177917 608.565125,651.375488 609.355591,650.408569 
	C609.785095,649.827332 609.914062,649.410461 610.018372,648.995728 
	C609.993713,648.997864 609.995850,649.047302 610.331238,648.977722 
	C616.450378,641.932007 622.234131,634.955933 628.034668,628.350525 
	C628.037781,629.460205 628.024048,630.199280 628.010315,630.938293 
	C612.710693,652.199036 597.408081,673.457642 582.118652,694.725647 
	C581.158752,696.060913 580.302124,697.470520 579.372192,699.195312 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M638.277710,464.967438 
	C636.068787,463.420563 634.223450,461.887451 631.888794,459.947845 
	C634.342651,459.691864 636.495850,459.467224 638.649109,459.242584 
	C638.648010,459.006256 638.646973,458.769928 638.645874,458.533600 
	C636.234863,457.814850 633.823853,457.096100 631.412781,456.377350 
	C631.507935,455.981720 631.603027,455.586121 631.698181,455.190491 
	C633.863464,455.190491 636.028748,455.190491 638.194031,455.190491 
	C638.256409,454.893555 638.318848,454.596619 638.381226,454.299683 
	C635.705566,453.499329 633.029907,452.698944 629.493286,451.641022 
	C631.953918,450.431213 633.622314,449.610901 636.340332,448.274536 
	C633.286255,448.087219 631.327759,447.967102 629.369202,447.847015 
	C629.318359,447.619629 629.267456,447.392242 629.216614,447.164886 
	C632.027405,446.198242 634.838135,445.231628 637.648926,444.265015 
	C637.568542,443.813416 637.488159,443.361816 637.407715,442.910248 
	C634.019592,443.278290 630.631409,443.646362 627.243225,444.014435 
	C627.174194,443.551331 627.105103,443.088226 627.036072,442.625122 
	C628.658020,442.367737 630.279907,442.110352 632.692017,441.853882 
	C634.394714,441.413391 635.307190,440.972015 636.560669,440.533203 
	C639.466919,439.573578 642.032104,438.611389 644.890625,437.552612 
	C646.162720,436.679199 647.141541,435.902344 648.355957,435.051147 
	C648.902588,434.827148 649.131104,434.595978 649.503479,434.195404 
	C650.025208,433.953217 650.257629,433.732727 650.682068,433.339203 
	C651.372620,432.906281 651.808289,432.580139 652.520142,432.172180 
	C653.334473,431.559540 653.872559,431.028748 654.655884,430.367249 
	C655.251221,430.111694 655.601257,429.986877 655.962891,430.330750 
	C656.036499,432.047882 656.098511,433.296295 656.160583,434.544739 
	C654.060608,436.113403 651.960632,437.682037 649.643616,439.886902 
	C646.558899,448.689667 643.691223,456.856201 640.823486,465.022736 
	C640.096069,465.008881 639.368591,464.995026 638.277710,464.967438 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M484.585632,887.756287 
	C503.540314,887.764038 522.128357,887.865112 540.716370,887.963013 
	C542.664368,887.973267 544.612488,887.964417 546.812317,887.964417 
	C546.812317,889.899597 546.812317,891.294556 546.812317,893.321655 
	C535.697388,893.321655 524.790710,893.321655 513.050415,893.337708 
	C511.159546,893.420471 510.102295,893.487183 508.704712,893.433472 
	C507.895081,893.386230 507.425842,893.459412 506.504028,893.423340 
	C497.214478,893.430237 488.377472,893.546326 479.540527,893.662354 
	C481.099976,891.724548 482.659454,889.786621 484.585632,887.756287 
z"/>
<path fill="#BBAFA8" opacity="1.000000" stroke="none" 
	d="
M585.982605,930.072388 
	C584.433350,930.402039 582.886597,931.001404 581.334412,931.015869 
	C573.393494,931.090149 565.448975,931.104797 557.511353,930.910522 
	C555.979553,930.873108 554.472717,929.812012 552.959351,929.201904 
	C550.902710,928.441528 548.855225,927.104858 546.777344,927.055908 
	C526.512024,926.578430 506.241577,926.316223 485.537628,925.675171 
	C485.106567,925.011108 485.110443,924.664612 485.114349,924.318115 
	C511.647919,924.119507 538.181396,923.908569 564.715088,923.732910 
	C569.856812,923.698914 574.999451,923.802429 580.567993,924.302368 
	C580.995911,925.413086 580.997437,926.063904 581.031494,926.824890 
	C581.063965,926.935059 581.028259,927.162048 580.558838,927.128784 
	C571.658020,927.150391 563.226562,927.205322 554.310059,927.326660 
	C554.220642,927.958618 554.616455,928.524231 555.456421,929.197388 
	C564.236084,929.407349 572.571594,929.509766 581.316528,929.729004 
	C583.144836,929.921326 584.563721,929.996826 585.982605,930.072388 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M512.974060,895.844482 
	C524.175598,895.844482 535.377075,895.844482 546.844849,895.844482 
	C546.844849,897.671387 546.844849,899.060547 546.844849,901.073364 
	C543.757935,901.073364 540.825806,901.073364 536.973022,901.075195 
	C515.751221,900.693481 495.450043,900.309998 475.148895,899.926514 
	C475.145508,899.520447 475.142090,899.114380 475.533386,898.200073 
	C486.280029,897.107727 496.631958,896.523621 507.323792,895.994385 
	C508.116730,895.992371 508.569794,895.935486 509.416534,895.930786 
	C510.864838,895.936829 511.919464,895.890625 512.974060,895.844482 
z"/>
<path fill="#AFA299" opacity="1.000000" stroke="none" 
	d="
M536.770325,936.512329 
	C537.098206,937.577332 537.010620,938.466125 536.954773,939.689392 
	C534.941406,940.108032 532.896301,940.192078 530.134888,939.845215 
	C523.954102,939.593079 518.489624,939.771851 513.025146,939.950684 
	C490.584076,939.971497 468.143036,939.992432 445.701965,940.012756 
	C443.513672,940.014771 441.325348,940.012939 439.137054,940.012939 
	C439.139801,939.344116 439.142548,938.675354 439.145294,938.006531 
	C462.527252,937.562134 485.909210,937.117676 509.942535,937.117554 
	C517.052490,937.718384 523.510742,937.931091 529.970093,937.970215 
	C530.921814,937.976013 531.878540,937.158752 532.832886,936.722290 
	C534.006897,936.593567 535.180847,936.464844 536.770325,936.512329 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M474.893860,900.125183 
	C495.450043,900.309998 515.751221,900.693481 536.516418,901.134399 
	C536.981445,901.829041 536.982422,902.466187 536.527710,903.498474 
	C532.390381,903.979187 528.708923,904.064758 524.564087,904.062866 
	C507.729767,904.256042 491.358795,904.536804 474.987854,904.817566 
	C474.871521,903.319641 474.755188,901.821777 474.893860,900.125183 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M911.018982,483.002808 
	C911.439514,483.000153 911.859985,482.997467 912.595947,482.997253 
	C912.636292,487.930847 912.545349,492.880554 912.049011,497.789307 
	C910.901306,509.140778 909.566650,520.473389 908.289246,531.811584 
	C907.595032,537.972900 906.862671,544.129944 905.717407,550.639771 
	C904.892273,550.998169 904.497498,551.005676 904.103027,550.593750 
	C905.409302,537.480652 906.714294,524.786926 908.021057,512.093323 
	C909.019287,502.396393 910.019592,492.699646 911.018982,483.002808 
z"/>
<path fill="#FDFCEB" opacity="1.000000" stroke="none" 
	d="
M580.999023,926.714722 
	C580.997437,926.063904 580.995911,925.413086 581.023071,924.437134 
	C600.341431,924.114380 619.631042,924.116821 639.165833,924.506104 
	C639.907776,928.519836 637.002441,926.101318 634.974365,926.907104 
	C617.700378,926.952759 601.344482,926.964355 584.601440,926.866821 
	C583.142578,926.743469 582.070801,926.729065 580.999023,926.714722 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M520.498352,912.794189 
	C528.378784,912.553406 535.800354,912.376221 543.222900,912.257385 
	C545.167114,912.226196 547.516846,911.848083 546.962280,915.546936 
	C544.263428,916.112305 541.611877,916.382080 538.960693,916.378296 
	C536.152100,916.374207 533.345276,916.019409 530.535278,915.975769 
	C520.417664,915.818604 510.299042,915.728149 499.667297,915.312439 
	C499.190826,914.479980 499.227875,913.947510 499.264954,913.415039 
	C506.189911,913.236572 513.114868,913.058044 520.498352,912.794189 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M668.467712,554.031982 
	C668.467712,554.031982 668.232849,554.395081 667.901184,554.721741 
	C667.393738,555.701050 667.217957,556.353638 667.042114,557.006287 
	C667.042114,557.006287 667.019653,557.016846 666.683716,556.962158 
	C665.915161,557.609985 665.482666,558.312622 665.050171,559.015259 
	C665.050171,559.015259 665.000000,559.003296 664.730347,559.019897 
	C664.306274,559.374329 664.151917,559.712219 663.997620,560.050110 
	C663.997620,560.050110 663.992432,559.992432 663.727783,560.019775 
	C663.315918,560.379761 663.168640,560.712402 663.021362,561.044983 
	C663.021362,561.044983 663.002869,560.996643 662.702271,560.993530 
	C661.929932,561.662720 661.458130,562.334961 660.993164,563.003357 
	C661.000000,562.999573 661.005981,562.984680 660.662964,563.083130 
	C660.209961,563.795532 660.099915,564.409485 659.996216,565.012512 
	C660.002625,565.001648 660.021179,564.984741 659.678833,564.895325 
	C659.217163,565.213013 659.097778,565.620056 658.989868,566.014648 
	C659.001282,566.002075 659.026611,565.979492 658.690247,565.909546 
	C657.898926,566.562195 657.444031,567.284790 656.994568,568.003601 
	C657.000000,567.999817 657.005615,567.987000 656.642700,568.073242 
	C656.190613,568.762085 656.101379,569.364685 656.004456,569.981201 
	C655.996704,569.995178 655.973267,570.016968 655.666138,569.795654 
	C654.577087,570.713074 653.795044,571.851807 653.006714,572.995239 
	C653.000366,573.000000 652.992432,573.012878 652.690918,573.031006 
	C651.882935,573.665344 651.376343,574.281494 650.588257,575.075317 
	C644.852173,581.826172 639.397583,588.399353 633.679077,595.139160 
	C632.624207,595.932495 631.833191,596.559204 630.761963,596.938843 
	C631.021912,595.628601 631.562073,594.565308 632.353516,593.320190 
	C632.777100,592.770935 632.949402,592.403564 633.080627,592.036743 
	C633.039551,592.037354 633.052856,592.118469 633.289795,592.008545 
	C633.696472,591.602356 633.866089,591.306152 634.016235,591.005432 
	C633.996643,591.000916 634.018860,591.034363 634.275452,590.863647 
	C634.840271,590.157104 635.148499,589.621277 635.695190,588.898804 
	C636.634216,587.519348 637.334717,586.326660 638.286072,585.028748 
	C638.739624,584.644165 638.942383,584.364807 639.102051,584.086670 
	C639.058960,584.087891 639.099792,584.163757 639.363953,584.085510 
	C639.795288,583.689941 639.962463,583.372620 640.090759,583.049133 
	C640.051880,583.042969 640.057861,583.121460 640.304321,583.025879 
	C640.719666,582.630005 640.888489,582.329712 641.029419,582.016296 
	C641.001526,582.003174 641.032043,582.056885 641.251465,581.950745 
	C641.741211,581.629578 641.892517,581.350220 642.033081,580.923401 
	C642.141296,580.840332 642.242065,580.586853 642.242065,580.586853 
	C647.879944,573.947266 653.645020,567.409180 659.073975,560.602966 
	C660.596802,558.693665 661.130676,555.995483 662.244019,553.277039 
	C665.122742,552.025940 667.875671,551.156982 670.932617,550.633728 
	C671.160156,551.336060 671.083557,551.692688 671.006958,552.049316 
	C671.006958,552.049316 670.992126,551.995483 670.714355,552.120361 
	C669.780273,552.840759 669.124023,553.436401 668.467712,554.031982 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M474.570129,904.895996 
	C491.358795,904.536804 507.729767,904.256042 524.270996,904.307129 
	C519.514099,905.104980 514.579224,905.505615 509.664185,906.076782 
	C508.170532,906.250366 506.731628,906.894592 505.267670,907.323364 
	C493.029694,907.323364 480.791718,907.323364 468.553772,907.323364 
	C468.513000,906.694885 468.472260,906.066406 468.431488,905.437927 
	C470.338470,905.283447 472.245422,905.128906 474.570129,904.895996 
z"/>
<path fill="#46382E" opacity="1.000000" stroke="none" 
	d="
M573.949829,944.918579 
	C573.949829,944.918579 574.033875,945.340332 573.588867,945.634277 
	C570.331909,945.954407 567.515503,945.889587 564.708923,946.026611 
	C559.504822,946.280762 554.306458,946.652893 548.704407,946.980225 
	C547.500549,946.981140 546.697998,946.977661 545.686523,946.707153 
	C544.029175,946.616638 542.580688,946.793152 540.655579,946.977783 
	C533.768982,947.003479 527.359070,947.020996 520.483704,947.031433 
	C509.678070,947.023804 499.337860,947.023193 488.997620,947.022583 
	C488.997620,947.022583 488.991882,947.003967 489.294189,946.724060 
	C497.274750,946.444153 504.953003,946.444153 512.631287,946.444153 
	C512.615845,946.118103 512.600403,945.792053 512.585022,945.466003 
	C510.752930,945.001526 508.920837,944.536987 507.088776,944.072510 
	C519.468018,944.052185 531.847229,944.031860 544.992188,944.340942 
	C555.155273,944.753052 564.552551,944.835815 573.949829,944.918579 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M753.928040,478.924744 
	C753.688538,478.406677 753.449036,477.888611 753.452454,476.948547 
	C755.329529,476.180084 756.963684,475.833649 758.597839,475.487183 
	C758.556702,475.204346 758.515503,474.921509 758.474365,474.638672 
	C756.977051,474.638672 755.479797,474.638672 753.982544,474.638672 
	C754.739136,469.552643 758.933411,468.698608 762.819092,467.617157 
	C765.538757,466.860260 768.350891,466.435547 771.217468,465.842682 
	C771.964844,467.024414 772.883789,468.477417 773.804565,470.316772 
	C771.857544,471.524689 769.908752,472.346161 767.959900,473.167664 
	C766.334290,474.354767 764.708679,475.541870 762.758179,477.271362 
	C759.797485,478.387115 757.161682,478.960541 754.376343,479.381775 
	C754.226746,479.229553 753.928040,478.924744 753.928040,478.924744 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M505.698486,907.414307 
	C506.731628,906.894592 508.170532,906.250366 509.664185,906.076782 
	C514.579224,905.505615 519.514099,905.104980 524.734375,904.394592 
	C528.708923,904.064758 532.390381,903.979187 536.524780,903.817139 
	C539.433289,903.806580 541.947754,903.581604 544.319763,904.061096 
	C545.500305,904.299744 546.419800,905.829590 547.457397,906.775330 
	C547.147644,907.111694 546.837891,907.448120 546.528198,907.784546 
	C540.661316,907.784546 534.794373,907.784546 528.465454,907.871338 
	C526.609192,907.904053 525.214905,907.849915 522.987000,907.762207 
	C520.428406,907.760742 518.703491,907.792847 516.978577,907.824890 
	C513.362122,907.718323 509.745697,907.611755 505.698486,907.414307 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M832.515503,656.922974 
	C833.141663,656.536682 834.234619,656.269287 835.306580,655.934692 
	C838.784424,654.849182 840.039368,653.040222 837.123901,649.630676 
	C846.466675,651.590942 855.884216,653.868347 865.301758,656.145813 
	C865.258728,656.434265 865.215698,656.722656 865.172607,657.011108 
	C854.439331,657.011108 843.705994,657.011108 832.515503,656.922974 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M415.985077,929.658936 
	C412.300781,929.592468 408.756927,929.280457 405.257721,928.699341 
	C404.237823,928.530029 403.345398,927.592957 402.394531,927.007751 
	C403.311340,926.546448 404.209229,925.738708 405.148438,925.687439 
	C408.240540,925.518677 411.347595,925.623840 414.448914,925.623840 
	C414.464661,925.138550 414.480408,924.653259 414.496155,924.167908 
	C412.051788,924.028687 409.596344,923.989441 407.166565,923.719177 
	C405.158295,923.495789 401.912506,925.014526 402.054626,920.539001 
	C406.136749,920.268311 409.914001,920.017822 413.867981,919.366638 
	C414.743958,922.414246 415.443237,925.862549 415.985077,929.658936 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M506.611694,944.043762 
	C508.920837,944.536987 510.752930,945.001526 512.585022,945.466003 
	C512.600403,945.792053 512.615845,946.118103 512.631287,946.444153 
	C504.953003,946.444153 497.274750,946.444153 489.284485,946.724121 
	C478.579498,947.004089 468.186554,947.004089 457.793640,947.004089 
	C457.779388,946.412659 457.765137,945.821228 457.750854,945.229797 
	C473.878784,944.824829 490.006714,944.419922 506.611694,944.043762 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M506.983917,895.939575 
	C496.631958,896.523621 486.280029,897.107727 475.576202,897.895264 
	C474.273895,894.620422 478.127228,895.645630 479.318909,893.815063 
	C488.377472,893.546326 497.214478,893.430237 506.513428,893.723267 
	C506.978241,894.734741 506.981079,895.337158 506.983917,895.939575 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M904.102661,551.013123 
	C904.497498,551.005676 904.892273,550.998169 905.583252,550.994446 
	C904.342590,562.743652 902.805847,574.489136 900.867798,586.521851 
	C899.987427,586.534607 899.508301,586.260132 899.037598,585.532715 
	C899.464233,581.388550 900.054504,577.704895 900.216431,574.002563 
	C900.281799,572.508423 899.410400,570.973206 898.925720,569.300476 
	C898.861450,568.926575 898.886108,568.714600 899.398682,568.609863 
	C900.558960,567.167053 901.673340,565.683960 901.925781,564.066406 
	C902.586853,559.831604 902.871582,555.538025 903.504456,551.201355 
	C903.702637,551.134827 904.102661,551.013123 904.102661,551.013123 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M877.389771,570.051697 
	C879.082092,569.106323 881.942505,571.900940 883.108643,568.478882 
	C883.407898,568.931885 883.382935,569.258057 883.229980,569.951660 
	C882.066284,572.931152 880.992310,575.540161 879.569336,578.298584 
	C878.429016,586.495911 877.637634,594.543762 876.846252,602.591614 
	C875.980591,602.296021 875.114990,602.000427 874.419861,601.015259 
	C875.003540,593.237610 875.416748,586.149475 875.932983,578.663452 
	C876.487244,575.527588 876.938538,572.789673 877.389771,570.051697 
z"/>
<path fill="#FDFCEB" opacity="1.000000" stroke="none" 
	d="
M673.994812,926.992004 
	C664.260559,926.964417 654.525757,926.979614 644.792664,926.858398 
	C643.487488,926.842102 642.190918,926.136536 641.236938,925.318970 
	C643.930054,924.587463 646.273438,924.060242 648.623535,924.028076 
	C656.763062,923.916626 664.904907,923.977356 673.570251,924.381287 
	C674.061340,925.523987 674.028076,926.257996 673.994812,926.992004 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M641.179565,465.000244 
	C643.691223,456.856201 646.558899,448.689667 649.664734,440.258057 
	C650.988708,441.310120 652.074463,442.627258 653.123047,444.264557 
	C649.235718,451.382355 645.385681,458.180023 641.179565,465.000244 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M882.912903,608.466187 
	C882.653259,608.172913 882.393677,607.879578 882.083252,606.895142 
	C882.968933,597.844116 883.905457,589.484375 885.046875,580.767944 
	C885.526062,577.946228 885.800293,575.481140 886.364319,572.862671 
	C887.464233,572.437622 888.274475,572.165894 889.051025,572.329956 
	C888.612610,576.412354 888.207947,580.058899 887.724121,584.103333 
	C887.025146,587.898438 886.448669,591.304260 885.775024,594.690735 
	C884.859985,599.290344 883.870483,603.875061 882.912903,608.466187 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M628.301636,630.782410 
	C628.024048,630.199280 628.037781,629.460205 628.203979,628.159668 
	C631.238708,622.732971 634.121094,617.867859 637.161316,612.749329 
	C637.219666,612.323608 637.120239,612.151245 637.008911,611.991455 
	C636.996887,612.004089 636.974976,612.031189 637.328857,612.102661 
	C637.797485,611.775085 637.912354,611.376038 638.015564,610.990234 
	C638.003906,611.003418 637.991150,611.035828 637.991150,611.035828 
	C640.780640,608.699219 643.570190,606.362610 646.704712,604.000488 
	C646.694153,605.024292 646.569702,606.241943 645.947144,607.097107 
	C640.211548,614.975952 634.389709,622.792114 628.301636,630.782410 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M871.017212,570.123047 
	C871.125061,568.552246 871.232910,566.981445 871.340759,565.410645 
	C871.788147,565.267761 872.235535,565.124939 872.682983,564.982117 
	C873.452026,566.163025 874.221069,567.343933 874.980164,569.279419 
	C874.684082,573.030945 874.397888,576.027771 874.055359,579.438965 
	C873.258667,586.499939 872.518311,593.146545 871.777954,599.793091 
	C871.267334,599.687622 870.756653,599.582092 870.213135,598.820801 
	C870.459229,588.817749 870.738220,579.470398 871.017212,570.123047 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M720.981323,931.469971 
	C728.999878,931.061279 737.017212,930.997253 745.499023,930.959229 
	C745.947327,931.815674 745.931213,932.646118 745.887451,933.891113 
	C737.876770,934.227417 729.893738,934.149109 721.449707,933.976562 
	C720.985779,933.193115 720.982910,932.503906 720.981323,931.469971 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M870.922241,570.217468 
	C870.738220,579.470398 870.459229,588.817749 870.091431,598.577515 
	C868.754883,597.886169 867.507019,596.782349 866.059692,595.401123 
	C865.860168,595.123718 865.968994,595.046265 866.346802,594.909546 
	C868.007629,586.590515 869.290649,578.408142 870.573608,570.225830 
	C870.573608,570.225830 870.827271,570.311890 870.922241,570.217468 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M785.773071,649.004639 
	C785.816162,648.804077 785.859253,648.603577 786.008850,648.114014 
	C787.588379,647.670837 789.061340,647.516785 791.164551,647.296875 
	C792.004578,650.620850 792.869019,654.041565 793.896179,658.106079 
	C791.233582,658.106079 789.108582,658.106079 786.556885,658.084717 
	C786.130310,658.063416 785.840698,658.240784 785.856018,657.684082 
	C785.838562,654.419800 785.805786,651.712219 785.773071,649.004639 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M864.716248,570.154053 
	C865.384827,569.698425 866.053406,569.242798 866.892822,569.365967 
	C866.698730,578.311951 866.333862,586.679077 865.968994,595.046265 
	C865.968994,595.046265 865.860168,595.123718 865.808533,595.167175 
	C864.973572,594.168213 864.190247,593.125793 863.125061,591.529419 
	C863.467590,584.034912 864.091919,577.094543 864.716248,570.154053 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M755.995361,483.554077 
	C751.280334,484.631470 746.560364,485.368286 741.421997,486.016846 
	C742.600098,482.722504 743.540955,478.809357 748.806519,480.603119 
	C749.953125,480.993713 751.716492,479.573792 753.561890,478.956207 
	C753.928040,478.924744 754.226746,479.229553 754.246460,479.653534 
	C754.848083,480.441650 755.430054,480.805817 756.012085,481.169983 
	C756.004822,481.851135 755.997620,482.532288 755.995361,483.554077 
z"/>
<path fill="#BBAFA8" opacity="1.000000" stroke="none" 
	d="
M756.439697,481.113800 
	C755.430054,480.805817 754.848083,480.441650 754.395996,479.805725 
	C757.161682,478.960541 759.797485,478.387115 762.779663,477.674042 
	C768.094604,477.066956 773.063171,476.599487 778.478760,476.054077 
	C778.925842,475.976105 778.890808,475.976837 778.890808,475.976837 
	C778.348755,476.608978 777.885132,477.694122 777.252563,477.803589 
	C770.472778,478.976959 763.666382,479.996155 756.439697,481.113800 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M498.790344,913.282776 
	C499.227875,913.947510 499.190826,914.479980 499.220581,915.273193 
	C491.452637,915.533875 483.617889,915.533875 475.783142,915.533875 
	C475.761047,914.997253 475.738953,914.460632 475.716858,913.924011 
	C483.249817,913.666199 490.782776,913.408386 498.790344,913.282776 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M484.661377,924.153687 
	C485.110443,924.664612 485.106567,925.011108 485.075195,925.617065 
	C475.264679,925.876587 465.481659,925.876587 455.698639,925.876587 
	C455.682190,925.363220 455.665741,924.849915 455.649292,924.336548 
	C465.169006,924.220825 474.688690,924.105042 484.661377,924.153687 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M573.426392,867.800659 
	C576.011719,866.991760 578.973633,865.961365 582.018616,865.557190 
	C591.452271,864.304871 600.917480,863.290771 610.800781,862.344727 
	C605.623840,863.490417 600.037903,864.626587 594.403992,865.422058 
	C587.570862,866.386963 580.697144,867.064087 573.426392,867.800659 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M884.841919,581.124512 
	C883.905457,589.484375 882.968933,597.844116 881.951050,606.631348 
	C881.372192,606.884277 880.874817,606.709717 880.254272,605.918457 
	C880.417969,602.196533 880.704773,599.091248 881.045166,595.607483 
	C881.278320,594.226868 881.458008,593.224731 881.807495,591.940674 
	C882.015808,590.787292 882.054382,589.915894 882.156982,588.659241 
	C882.588013,585.938477 882.955078,583.602966 883.574707,581.234131 
	C884.165527,581.175415 884.503723,581.149963 884.841919,581.124512 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M899.029114,585.985657 
	C899.508301,586.260132 899.987427,586.534607 900.728760,586.896790 
	C900.299744,593.007812 899.608643,599.031128 898.424194,605.447510 
	C897.500732,605.914062 897.070618,605.987549 896.707703,605.609497 
	C897.086792,600.837097 897.398682,596.516296 897.831909,591.794189 
	C898.311829,589.590576 898.670471,587.788086 899.029114,585.985657 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M897.710632,592.195435 
	C897.398682,596.516296 897.086792,600.837097 896.459717,605.898499 
	C895.944641,608.471375 895.744751,610.303650 895.544922,612.135986 
	C894.942444,612.083313 894.340027,612.030701 893.369385,611.614929 
	C893.629089,606.139771 894.256958,601.027710 894.961182,595.533386 
	C895.196045,594.122864 895.354675,593.094727 895.786865,592.109131 
	C896.610474,592.166382 897.160583,592.180908 897.710632,592.195435 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M876.975830,602.846191 
	C877.637634,594.543762 878.429016,586.495911 879.615601,578.750854 
	C879.825928,584.628052 879.640991,590.202454 879.321289,596.222168 
	C878.954285,599.125366 878.722046,601.583191 878.489868,604.040955 
	C878.028381,603.727539 877.566895,603.414185 876.975830,602.846191 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M882.981384,608.763550 
	C883.870483,603.875061 884.859985,599.290344 885.775024,594.690735 
	C886.448669,591.304260 887.025146,587.898438 888.062622,584.216492 
	C888.716370,583.952637 888.952332,583.973450 889.104004,584.415833 
	C888.632385,589.214783 888.244995,593.592041 887.646729,598.296387 
	C886.887878,602.437683 886.339905,606.252014 885.791992,610.066284 
	C884.877930,609.731201 883.963928,609.396118 882.981384,608.763550 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M706.260742,432.676147 
	C704.713806,429.197144 703.373901,425.430115 702.056885,421.276428 
	C705.015320,424.060272 707.950989,427.230743 710.847412,430.772095 
	C709.361328,431.557983 707.914551,431.973022 706.260742,432.676147 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M872.087463,599.925903 
	C872.518311,593.146545 873.258667,586.499939 874.341553,579.412354 
	C875.065979,579.001404 875.447998,579.031372 875.829956,579.061340 
	C875.416748,586.149475 875.003540,593.237610 874.378296,600.680420 
	C873.576477,600.709656 872.986694,600.384155 872.087463,599.925903 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M768.309692,473.152435 
	C769.908752,472.346161 771.857544,471.524689 774.136353,470.500214 
	C778.275818,469.614899 782.085388,468.932556 786.010315,468.229553 
	C785.200134,471.096924 778.536255,472.887024 768.309692,473.152435 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M894.884827,595.915649 
	C894.256958,601.027710 893.629089,606.139771 893.070923,611.668579 
	C892.804199,613.585938 892.467773,615.086609 891.930115,615.904297 
	C891.799133,610.144165 891.869446,605.066833 892.046082,599.632812 
	C892.439209,597.837891 892.726074,596.399658 893.250366,595.075928 
	C893.953430,595.432129 894.419128,595.673889 894.884827,595.915649 
z"/>
<path fill="#BBAFA8" opacity="1.000000" stroke="none" 
	d="
M778.130981,466.928772 
	C776.767822,466.955994 775.782349,466.889313 774.734985,466.818451 
	C777.044617,463.491241 783.595398,461.668152 788.322754,464.291656 
	C784.217468,465.355469 781.363098,466.095154 778.130981,466.928772 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M895.523743,612.570068 
	C895.744751,610.303650 895.944641,608.471375 896.392517,606.350037 
	C897.070618,605.987549 897.500732,605.914062 898.256714,605.820190 
	C898.582581,610.610107 898.582581,615.420410 898.582581,620.230652 
	C897.742065,620.277344 896.901550,620.324036 896.061096,620.370728 
	C895.874939,617.915222 895.688721,615.459717 895.523743,612.570068 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M891.939819,599.989563 
	C891.869446,605.066833 891.799133,610.144165 891.694946,615.625610 
	C890.481262,615.047913 889.301453,614.065979 888.228882,612.387817 
	C888.921082,607.803589 889.506104,603.915649 890.396423,599.976074 
	C891.114441,599.946106 891.527100,599.967834 891.939819,599.989563 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M890.091125,600.027710 
	C889.506104,603.915649 888.921082,607.803589 888.175781,612.093384 
	C887.316284,611.931335 886.616943,611.367371 885.854797,610.434814 
	C886.339905,606.252014 886.887878,602.437683 887.977112,598.318115 
	C888.955688,597.960938 889.392944,597.908997 889.880249,598.218872 
	C889.983826,599.063049 890.037476,599.545349 890.091125,600.027710 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M864.240356,570.100769 
	C864.091919,577.094543 863.467590,584.034912 862.818481,591.398315 
	C862.605591,586.842896 862.417542,581.864502 862.427368,576.330078 
	C862.758606,575.450867 862.667175,575.206726 862.350952,575.041382 
	C862.440491,573.583984 862.530029,572.126587 862.715210,570.314819 
	C863.128784,569.989502 863.446655,570.018494 864.240356,570.100769 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M532.398621,936.654541 
	C531.878540,937.158752 530.921814,937.976013 529.970093,937.970215 
	C523.510742,937.931091 517.052490,937.718384 510.298401,937.267090 
	C517.323364,936.843872 524.643860,936.715271 532.398621,936.654541 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M488.995850,947.293213 
	C499.337860,947.023193 509.678070,947.023804 520.506714,947.309631 
	C521.007874,947.780701 521.020569,947.966614 521.033203,948.152466 
	C510.661255,948.237549 500.289276,948.322693 489.472046,948.255981 
	C489.015869,947.923950 489.004944,947.743896 488.995850,947.293213 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M753.562378,474.753204 
	C755.479797,474.638672 756.977051,474.638672 758.474365,474.638672 
	C758.515503,474.921509 758.556702,475.204346 758.597839,475.487183 
	C756.963684,475.833649 755.329529,476.180084 753.364258,476.676849 
	C749.338318,477.222839 745.643372,477.618530 741.948486,478.014191 
	C741.885498,477.627106 741.822571,477.240051 741.759583,476.852966 
	C745.553772,476.191223 749.347961,475.529480 753.562378,474.753204 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M573.955078,944.714844 
	C564.552551,944.835815 555.155273,944.753052 545.469177,944.398743 
	C554.465393,944.091370 563.750427,944.055603 573.498840,944.061646 
	C573.962219,944.103577 573.960266,944.511169 573.955078,944.714844 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M415.979187,942.710266 
	C411.543030,943.063110 407.158691,943.063110 402.774353,943.063110 
	C402.741089,942.399170 402.707794,941.735229 402.674500,941.071289 
	C406.790710,941.071289 410.906921,941.071289 415.479248,941.006470 
	C415.967224,941.413635 415.999115,941.885498 415.979187,942.710266 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M402.950134,937.236145 
	C403.328766,936.544067 403.673584,935.274902 404.091400,935.250427 
	C407.716614,935.037720 411.354980,935.048523 415.443390,934.928101 
	C415.901093,935.371216 415.904785,935.879028 415.416382,936.771484 
	C410.932892,937.182739 406.941498,937.209473 402.950134,937.236145 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M402.918274,937.539917 
	C406.941498,937.209473 410.932892,937.182739 415.376678,937.151855 
	C415.784546,937.691772 415.740021,938.235840 415.428162,938.878784 
	C411.424561,939.169739 407.689117,939.407776 403.950500,939.476990 
	C403.606018,939.483398 403.241608,938.413696 402.918274,937.539917 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M788.498169,445.068176 
	C785.992004,444.915283 783.883545,444.703583 781.775085,444.491882 
	C781.740234,444.025665 781.705383,443.559448 781.670471,443.093231 
	C786.060181,443.042175 790.449951,442.991150 794.961487,443.291504 
	C793.020813,444.098358 790.958313,444.553833 788.498169,445.068176 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M555.299438,940.324036 
	C559.205750,939.750549 563.400146,939.472229 567.803223,939.569336 
	C563.870422,940.169678 559.729004,940.394409 555.299438,940.324036 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M415.275818,932.603699 
	C411.050446,932.865356 406.955811,932.865356 402.861206,932.865356 
	C402.815216,932.346924 402.769257,931.828430 402.723267,931.309998 
	C406.592407,931.141052 410.461517,930.972168 414.762634,931.031738 
	C415.265259,931.620850 415.335907,931.981445 415.275818,932.603699 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M571.823242,883.495239 
	C568.713013,880.245239 569.721558,876.267273 571.301758,872.136963 
	C571.732605,875.681152 571.814392,879.362427 571.823242,883.495239 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M406.214722,916.099121 
	C405.942963,915.839783 405.842773,915.398376 405.914368,915.183350 
	C408.302582,914.615417 410.619202,914.262451 412.935822,913.909424 
	C413.005554,914.591370 413.075287,915.273254 413.145050,915.955200 
	C410.925507,916.089600 408.705994,916.224060 406.214722,916.099121 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M613.182739,862.822998 
	C616.056274,862.019714 619.287415,861.281677 622.786133,860.833801 
	C619.882629,861.712097 616.711487,862.300171 613.182739,862.822998 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M513.489136,939.998047 
	C518.489624,939.771851 523.954102,939.593079 529.714722,939.709717 
	C524.658264,940.018555 519.305725,940.031982 513.489136,939.998047 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M878.547485,604.401245 
	C878.722046,601.583191 878.954285,599.125366 879.576721,596.269287 
	C880.308472,595.909241 880.650024,595.947632 880.991577,595.985962 
	C880.704773,599.091248 880.417969,602.196533 880.072266,605.723999 
	C879.544067,605.684692 879.074646,605.223145 878.547485,604.401245 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M834.751343,648.704041 
	C833.261230,648.547974 831.515503,648.178955 829.876709,647.556580 
	C831.487671,647.699097 832.991638,648.095093 834.751343,648.704041 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M625.414124,860.897949 
	C626.855347,860.385864 628.674500,859.922058 630.735107,859.706604 
	C629.248352,860.285339 627.520203,860.615784 625.414124,860.897949 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M630.757812,463.823669 
	C632.392212,463.924225 633.867493,464.343018 635.660400,464.979706 
	C634.423035,465.121094 632.867920,465.044586 630.947205,464.910706 
	C630.587280,464.616180 630.593018,464.379059 630.757812,463.823669 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M633.385254,859.750000 
	C634.235474,859.301819 635.419434,858.912598 636.793335,858.757568 
	C635.895203,859.264221 634.807068,859.536621 633.385254,859.750000 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M517.215271,908.118530 
	C518.703491,907.792847 520.428406,907.760742 522.572876,907.830261 
	C521.145569,908.091919 519.298767,908.252014 517.215271,908.118530 
z"/>
<path fill="#BBAFA8" opacity="1.000000" stroke="none" 
	d="
M674.324585,927.093018 
	C674.028076,926.257996 674.061340,925.523987 674.035889,924.422852 
	C674.436768,923.971130 674.896423,923.886536 675.689148,923.928711 
	C675.947876,924.795593 675.873535,925.535828 675.901611,926.634644 
	C675.554077,927.060181 675.104187,927.127136 674.324585,927.093018 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M292.722382,956.557861 
	C291.599792,956.825073 290.215149,956.884583 288.422424,956.856445 
	C289.496307,956.629272 290.978333,956.489746 292.722382,956.557861 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M140.872482,955.855225 
	C140.452896,956.179565 139.886719,956.278076 139.154556,956.213013 
	C139.567673,955.909424 140.146790,955.769409 140.872482,955.855225 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M671.323975,552.173950 
	C671.083557,551.692688 671.160156,551.336060 671.336731,550.725586 
	C671.750366,550.310425 672.063965,550.149231 672.771729,550.083435 
	C673.165955,550.178833 673.442383,550.456421 673.442383,550.456421 
	C672.841919,551.070435 672.241455,551.684509 671.323975,552.173950 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M674.882202,547.892700 
	C675.390198,547.446289 675.898193,546.999878 676.406128,546.553467 
	C676.225098,547.077515 676.044128,547.601624 675.486938,548.117432 
	C675.110718,548.109009 674.882141,547.892700 674.882202,547.892700 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M671.617310,545.555176 
	C672.057556,545.668518 672.323547,545.945251 672.589539,546.222046 
	C672.473389,546.331543 672.263123,546.543518 672.254639,546.535706 
	C671.972168,546.276367 671.709106,545.995972 671.617310,545.555176 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M662.367676,540.750854 
	C662.062988,539.936340 661.969971,538.881470 661.941162,537.432495 
	C662.196716,538.195801 662.388062,539.353149 662.367676,540.750854 
z"/>
<path fill="#BBAFA8" opacity="1.000000" stroke="none" 
	d="
M778.935303,475.982025 
	C779.228577,475.769165 779.512451,475.550354 779.796265,475.331604 
	C779.524109,475.408325 779.251892,475.485016 778.935303,475.769287 
	C778.890808,475.976837 778.925842,475.976105 778.935303,475.982025 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M668.885620,554.165649 
	C669.124023,553.436401 669.780273,552.840759 670.742310,552.119629 
	C671.092896,552.432434 671.137817,552.870850 671.104126,553.647095 
	C670.451599,554.089783 669.877625,554.194519 668.885620,554.165649 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M788.417664,579.860107 
	C788.895691,579.618774 789.721130,579.482788 790.730103,579.552368 
	C790.197449,579.827087 789.481262,579.896240 788.417664,579.860107 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M675.289795,560.970703 
	C674.985840,560.651001 674.978882,560.261169 675.035767,559.577881 
	C675.625183,559.352539 676.150757,559.420593 676.676392,559.488586 
	C676.313171,559.959229 675.950012,560.429810 675.289795,560.970703 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M571.742310,868.754639 
	C571.343079,868.758606 571.057007,868.535278 570.770874,868.312012 
	C571.030762,868.219543 571.290649,868.127075 571.771484,868.036621 
	C571.946655,868.201538 571.901001,868.364502 571.742310,868.754639 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M403.145142,914.364685 
	C402.768585,914.265869 402.356262,913.916870 402.047119,913.254272 
	C402.469971,913.331909 402.789642,913.723206 403.145142,914.364685 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M677.950745,552.962219 
	C678.091858,552.553650 678.232971,552.145020 678.374023,551.736450 
	C678.388977,552.084473 678.403870,552.432495 678.206787,552.890076 
	C677.994751,552.999634 677.950745,552.962219 677.950745,552.962219 
z"/>
<path fill="#6898A5" opacity="1.000000" stroke="none" 
	d="
M709.201050,444.690186 
	C708.938782,444.472595 708.730164,443.914093 708.726929,443.186371 
	C709.039856,443.461212 709.147278,443.905243 709.201050,444.690186 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M674.261108,549.281494 
	C674.209412,549.619080 674.061646,549.907410 673.630066,550.301392 
	C673.442383,550.456421 673.165955,550.178833 673.033203,550.034546 
	C672.999023,549.563232 673.183655,549.295593 673.771667,549.090332 
	C674.088989,549.093201 674.261108,549.281494 674.261108,549.281494 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M674.001648,549.000244 
	C673.951721,548.551270 674.121094,548.276489 674.652222,547.987915 
	C674.882141,547.892700 675.110718,548.109009 675.227661,548.219788 
	C675.206055,548.642273 674.996460,548.895020 674.488464,549.185181 
	C674.261108,549.281494 674.088989,549.093201 674.001648,549.000244 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M678.013916,553.021301 
	C677.924927,553.058594 677.816833,553.074280 677.829712,553.026062 
	C677.950745,552.962219 677.994751,552.999634 678.013916,553.021301 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M678.469971,558.555908 
	C678.392700,558.588257 678.363525,558.425903 678.363525,558.425903 
	C678.363525,558.425903 678.547241,558.523499 678.469971,558.555908 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M674.241821,561.964844 
	C674.139282,561.708130 674.299133,561.377197 674.749512,561.013794 
	C674.861450,561.284424 674.682861,561.587463 674.241821,561.964844 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M673.969238,562.649780 
	C673.932922,562.871704 673.475708,562.958923 673.223389,562.964783 
	C672.971069,562.970642 672.927429,562.925720 672.927429,562.925720 
	C673.110962,562.623413 673.294556,562.321167 673.757690,561.999146 
	C674.037231,561.979431 674.005615,562.427917 673.969238,562.649780 
z"/>
<path fill="#679BA9" opacity="1.000000" stroke="none" 
	d="
M862.272400,575.275024 
	C862.667175,575.206726 862.758606,575.450867 862.507446,575.867554 
	C862.389771,575.961182 862.193848,575.508728 862.272400,575.275024 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M785.721680,660.706177 
	C785.273987,660.781067 785.182251,660.544800 785.487183,660.140747 
	C785.625427,660.073425 785.819214,660.495422 785.721680,660.706177 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M611.717773,862.609375 
	C611.585571,862.477356 611.507141,862.349487 611.428711,862.221680 
	C611.577759,862.202881 611.726868,862.184082 611.876709,862.399414 
	C611.877441,862.633606 611.771545,862.613586 611.717773,862.609375 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M647.336365,604.212830 
	C647.212769,603.683716 647.460266,603.314636 648.071228,602.946106 
	C648.192322,603.422058 647.949890,603.897522 647.336365,604.212830 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M423.786377,463.444153 
	C423.728638,463.664459 423.443634,463.740753 423.109283,463.658997 
	C423.226318,463.434052 423.392700,463.367096 423.786377,463.444153 
z"/>
<path fill="#1C0101" opacity="1.000000" stroke="none" 
	d="
M443.073914,795.029114 
	C441.196503,796.035522 439.320465,797.919495 437.441498,797.922424 
	C344.968323,798.067688 252.494919,798.051453 159.531860,797.516174 
	C159.019302,743.701904 158.966949,690.424377 158.983429,637.146912 
	C158.995270,598.855530 159.098953,560.564087 159.589417,522.258667 
	C162.012375,522.154419 164.007568,522.064209 166.464523,522.057373 
	C173.284622,522.095764 179.642944,522.050903 186.454529,522.112732 
	C191.228958,522.275757 195.550156,522.332031 200.271637,522.513245 
	C209.883606,522.690857 219.095245,522.743530 228.738678,522.908997 
	C234.160797,523.061584 239.151566,523.073975 244.141464,523.145264 
	C310.492554,524.093506 376.843262,525.067444 443.194794,525.983948 
	C452.338257,526.110229 461.485504,525.960999 470.631012,525.939331 
	C452.781342,542.854858 434.926575,559.765076 417.083771,576.687866 
	C404.774628,588.362427 392.457245,600.028625 380.229370,611.787781 
	C379.256012,612.723877 378.952942,614.356934 378.690338,616.215515 
	C399.967255,649.930908 420.892212,683.096863 442.456177,717.275635 
	C446.675507,709.052856 450.225281,701.939514 453.984192,694.938477 
	C455.002808,693.041321 456.653839,691.483704 458.430084,689.883118 
	C462.613464,690.364197 466.377441,690.793213 470.153351,691.037292 
	C470.744446,691.075562 471.389862,690.273621 472.006317,689.543457 
	C472.654846,688.254150 473.307251,687.281677 474.304199,686.161865 
	C474.420624,685.245667 474.192505,684.476685 473.717041,683.498657 
	C472.616791,683.221375 471.763885,683.153198 470.960266,683.078735 
	C471.009613,683.072510 470.919281,683.115051 470.988129,682.778870 
	C472.382568,681.756592 473.708130,681.070435 475.306000,680.209839 
	C475.392731,679.321838 475.207184,678.608337 475.012939,677.506836 
	C474.965851,676.344116 474.927429,675.569336 474.902527,674.516724 
	C474.917969,673.868042 474.919891,673.497253 474.946259,672.796692 
	C474.655731,671.441895 474.340790,670.416870 474.113953,669.037354 
	C474.217865,668.440552 474.233673,668.198181 474.336395,667.775269 
	C473.978271,667.384338 473.533264,667.174072 473.063751,666.984619 
	C473.039246,667.005493 473.016632,666.945251 473.007111,666.538696 
	C472.583557,664.463074 472.169525,662.793945 471.565552,661.115356 
	C471.375580,661.105835 471.020935,660.968323 471.007385,660.546143 
	C470.924744,659.000732 470.855652,657.877502 470.633606,656.501465 
	C469.255066,655.638184 468.029480,655.027771 466.790039,654.142700 
	C467.197754,653.006287 467.619293,652.144470 468.345276,651.166626 
	C468.868378,650.690552 469.086975,650.330627 469.402649,649.826660 
	C469.281494,649.476379 469.063293,649.270020 468.552155,648.990479 
	C467.503693,648.645935 466.748199,648.374634 465.993652,647.725464 
	C465.996490,646.592346 465.998352,645.836975 466.253174,644.970215 
	C466.708801,644.564697 466.911407,644.270569 467.240814,643.840088 
	C467.116577,643.529358 466.865570,643.354919 466.243286,643.148132 
	C459.989258,641.740967 454.100128,640.392517 448.229858,638.966370 
	C447.188721,638.713440 446.226166,638.136902 445.227325,637.709778 
	C447.204193,634.695190 443.606628,634.315552 443.224915,632.661926 
	C446.041199,632.508484 448.448853,632.302002 451.226837,632.220764 
	C455.410919,632.132935 459.224640,631.919983 463.401154,631.766113 
	C464.489807,631.747253 465.215668,631.669250 466.378510,631.663208 
	C468.763489,631.628967 470.759186,631.745850 472.643860,631.343140 
	C474.053253,631.041992 475.319519,630.070923 476.751282,629.039917 
	C477.874054,627.714661 478.895081,626.747742 480.128540,625.660522 
	C480.626160,625.382385 480.919464,625.241455 481.501099,625.082825 
	C481.865204,624.327209 481.949036,623.606201 482.019958,622.573914 
	C482.022430,621.641174 482.037750,621.019653 482.404663,620.281860 
	C483.915283,619.891663 485.074310,619.617798 486.233307,619.343933 
	C486.194397,619.034180 486.155457,618.724426 486.116547,618.414673 
	C484.745697,618.414673 483.374847,618.414673 481.807007,618.083130 
	C480.943939,617.893555 480.277863,618.035583 479.409973,618.219604 
	C479.208191,618.261597 478.844604,618.067322 479.159729,617.507324 
	C479.620026,616.555481 479.765198,616.163513 480.341553,615.670288 
	C484.964691,615.170654 489.156616,614.772400 493.348541,614.374084 
	C493.290161,613.926819 493.231781,613.479492 493.173401,613.032227 
	C491.442963,613.032227 489.712555,613.032227 487.978790,612.656311 
	C488.001251,611.529114 488.027069,610.777893 488.439636,609.995483 
	C490.066956,609.726440 491.307495,609.488464 492.548004,609.250488 
	C492.494659,608.885986 492.441284,608.521545 492.387909,608.157043 
	C489.566864,608.157043 486.745850,608.157043 483.575012,608.041199 
	C482.959473,607.771912 482.674622,607.642639 482.436310,607.454407 
	C482.323090,607.364929 482.298584,607.163269 482.233887,607.012390 
	C484.488495,606.239441 486.743073,605.466492 489.342651,604.687622 
	C490.360016,604.533203 491.032440,604.384705 492.004364,604.415894 
	C493.320953,604.208984 494.338043,603.822327 495.741364,603.471069 
	C497.937347,602.652588 499.747131,601.798706 501.850067,600.906189 
	C502.439423,599.967896 502.735596,599.068237 503.390564,598.097839 
	C504.499969,597.593689 505.250610,597.160339 506.636200,596.360352 
	C504.295807,595.721436 502.686707,595.282227 501.054749,594.600220 
	C501.031921,594.357422 500.885406,593.892273 500.935608,593.534546 
	C500.563477,592.121826 500.141174,591.066895 499.761322,589.589783 
	C502.146576,588.338440 504.484619,587.495483 506.833740,586.684631 
	C508.461212,586.122864 510.103912,585.605164 511.739777,585.067749 
	C511.628510,584.732056 511.517212,584.396301 511.405945,584.060608 
	C507.624115,584.338745 503.842255,584.616882 499.918091,584.591431 
	C499.928040,583.653564 500.080261,583.019287 500.661621,582.297485 
	C505.057434,581.770691 509.024109,581.331360 513.307983,580.887207 
	C514.026855,580.130188 514.428589,579.378113 514.830261,578.625977 
	C514.286072,578.566589 513.741821,578.507141 512.810486,578.523926 
	C511.637115,578.631042 510.850922,578.661926 510.055054,578.353027 
	C510.022308,577.335327 509.999237,576.657471 510.369080,575.880798 
	C512.974976,575.378784 515.187927,574.975525 517.400879,574.572327 
	C517.313477,574.099304 517.226013,573.626221 517.138611,573.153198 
	C515.055115,573.153198 512.971558,573.153198 510.799255,572.817627 
	C510.831299,572.038269 510.952209,571.594482 511.486084,571.057190 
	C513.584656,570.837769 515.270264,570.711975 517.295837,570.722290 
	C518.371216,570.200745 519.106567,569.543091 519.841919,568.885437 
	C518.929199,568.839417 518.016418,568.793335 516.674744,568.682190 
	C514.232422,568.656128 512.218994,568.695190 510.101440,568.456909 
	C510.119080,567.650879 510.240845,567.122192 510.734375,566.588623 
	C512.074524,566.404663 513.042969,566.225525 514.442871,566.098816 
	C517.159912,565.958069 519.445312,565.764893 521.730774,565.571777 
	C521.696716,565.164062 521.662659,564.756409 521.628662,564.348755 
	C519.080627,564.348755 516.532654,564.348755 513.605835,564.232300 
	C512.177124,564.040894 511.127167,563.966003 510.112122,563.643677 
	C510.147064,563.396179 509.974457,562.927185 510.152832,562.601929 
	C511.552002,562.127136 512.772766,561.977722 514.434204,561.905334 
	C517.882385,561.982361 520.889954,561.982361 523.897461,561.982361 
	C523.955994,561.563416 524.014465,561.144470 524.072998,560.725525 
	C523.414062,560.171021 522.755188,559.616577 522.447876,558.900024 
	C525.981140,557.769592 529.162781,556.801208 532.370789,555.801514 
	C532.397095,555.770142 532.377136,555.724792 532.756348,555.919434 
	C535.372070,556.432495 537.608582,556.750916 540.113831,557.406738 
	C538.695374,563.429993 537.199707,569.181152 535.286255,574.789734 
	C528.655884,594.224487 521.880188,613.609741 514.929749,633.284912 
	C511.774445,641.303040 508.849213,649.049988 505.672729,657.058594 
	C504.819183,659.153992 504.216858,660.987915 503.667542,662.838257 
	C503.720520,662.854736 503.686584,662.749084 503.424591,662.954834 
	C502.714752,664.419128 502.266876,665.677551 501.862732,666.937012 
	C501.906464,666.937988 501.883759,666.853516 501.630005,667.043945 
	C501.213715,667.836609 501.051117,668.438782 500.811768,669.125977 
	C500.735016,669.210999 500.855621,669.405823 500.572296,669.648071 
	C499.487335,671.905884 498.685730,673.921509 497.925720,675.959229 
	C497.967346,675.981384 497.925293,675.897034 497.656342,676.127930 
	C496.803192,677.937561 496.218964,679.516296 495.622955,681.168762 
	C495.611145,681.242493 495.741028,681.316223 495.453583,681.524841 
	C494.769775,682.822266 494.373413,683.911072 493.873352,685.088867 
	C493.769684,685.177795 493.844666,685.440491 493.573181,685.691162 
	C492.816528,687.305176 492.331360,688.668579 491.785400,690.113525 
	C491.724609,690.195068 491.843811,690.359863 491.578613,690.553345 
	C491.213745,691.163879 491.114136,691.580994 490.832550,692.215881 
	C490.469299,692.960144 490.288025,693.486694 489.970764,694.213379 
	C489.834808,694.413513 489.800232,694.896301 489.844513,694.915161 
	C489.888794,694.934021 489.866058,694.840454 489.622498,695.029053 
	C489.224365,695.804443 489.069794,696.391235 488.721252,697.093628 
	C488.291046,697.437073 488.198090,697.713196 488.116760,698.254639 
	C487.976257,698.640686 487.967407,698.809692 487.715179,699.229126 
	C485.490967,704.057190 483.625000,708.688538 481.510498,713.203552 
	C468.729889,740.493530 455.892395,767.756836 443.073914,795.029114 
z"/>
<path fill="#6998A5" opacity="1.000000" stroke="none" 
	d="
M473.964355,683.707764 
	C474.192505,684.476685 474.420624,685.245667 473.929260,686.001343 
	C469.143250,685.345215 465.090271,684.575806 461.001801,684.137573 
	C459.793823,684.008057 458.485657,684.813354 457.223389,685.190247 
	C458.249603,685.969299 459.275818,686.748413 460.142395,687.814392 
	C459.326691,688.657593 458.670624,689.213928 458.014587,689.770264 
	C456.653839,691.483704 455.002808,693.041321 453.984192,694.938477 
	C450.225281,701.939514 446.675507,709.052856 442.456177,717.275635 
	C420.892212,683.096863 399.967255,649.930908 378.980438,616.421631 
	C386.544647,614.956970 394.170776,613.835571 402.557312,612.783020 
	C404.211639,612.634644 405.105560,612.417419 405.999481,612.200195 
	C408.045837,612.151062 410.092194,612.101990 412.871033,612.331116 
	C416.065460,612.433105 418.527374,612.256836 420.989319,612.080505 
	C424.358246,612.033386 427.727173,611.986267 431.797699,612.223694 
	C433.998047,612.332581 435.496796,612.156799 436.995544,611.981079 
	C442.545227,611.533997 448.094910,611.086914 454.499329,610.703796 
	C457.858734,610.403381 460.363434,610.039062 463.217255,609.623962 
	C461.329742,608.698792 459.685791,607.892944 458.456055,607.039551 
	C462.265778,606.641357 465.661346,606.290771 469.098724,606.278076 
	C470.160980,607.443909 471.181427,608.271851 471.960266,609.286743 
	C470.839050,610.174133 469.959412,610.874512 468.791748,611.808105 
	C466.486267,612.351318 464.468872,612.661316 462.472290,612.958374 
	C462.493103,612.945312 462.531250,612.976318 462.074036,613.011353 
	C457.464233,613.726318 453.302490,614.358704 449.171814,615.152710 
	C448.711426,615.241211 448.427856,616.249390 448.050720,617.134399 
	C448.035583,617.842285 448.032043,618.246948 447.602631,618.759033 
	C441.451904,619.590088 435.727081,620.313660 429.700317,621.039917 
	C427.925507,621.531921 426.452606,622.021362 424.576538,622.603821 
	C422.490295,622.905457 420.807251,623.114014 419.124207,623.322571 
	C419.141205,623.632202 419.158173,623.941772 419.175171,624.251404 
	C426.188171,624.251404 433.201172,624.251404 440.621399,624.350708 
	C446.691772,624.288818 452.354950,624.127686 458.332886,624.183228 
	C461.569580,624.079224 464.491516,623.758423 467.609802,623.466980 
	C468.072510,623.515442 468.335571,623.493958 468.741089,623.646118 
	C469.713165,623.696228 470.539459,623.532227 471.785645,623.383606 
	C475.002991,623.408081 477.800476,623.417053 480.737000,623.695251 
	C480.990967,624.348755 481.105927,624.733093 481.220856,625.117432 
	C480.919464,625.241455 480.626160,625.382385 479.666687,625.650696 
	C462.183777,626.437866 445.375153,627.114563 428.566559,627.791321 
	C428.583862,628.569458 428.601166,629.347656 428.618500,630.125793 
	C437.737183,630.125793 446.855865,630.125793 455.883179,630.478638 
	C454.146667,631.252869 452.501556,631.674255 450.856476,632.095581 
	C448.448853,632.302002 446.041199,632.508484 442.958374,632.490723 
	C440.191620,632.596375 438.100037,632.926270 435.730682,633.299988 
	C437.562561,635.334473 439.272003,637.233032 440.775879,639.202515 
	C440.715698,639.533203 440.861084,639.792847 441.008911,640.021973 
	C441.011353,639.991394 440.950012,639.991150 440.935120,640.335815 
	C441.929718,641.560669 442.939240,642.440918 443.955139,643.640381 
	C444.190063,644.946045 444.418579,645.932495 444.869324,647.177002 
	C445.717743,648.078613 446.343933,648.722351 447.026917,649.679077 
	C447.460907,650.753113 447.838196,651.514099 448.298126,652.453003 
	C448.517426,652.881836 448.727722,653.022766 449.017029,653.028931 
	C449.022430,653.004028 448.972290,653.006653 448.967773,653.344482 
	C450.108673,655.616760 451.254120,657.551086 452.520996,659.814331 
	C459.433807,662.410522 466.225220,664.677917 473.016632,666.945312 
	C473.016632,666.945251 473.039246,667.005493 473.167999,667.258057 
	C473.201965,667.681213 473.107178,667.851746 472.650604,668.299744 
	C466.383575,667.379272 460.478333,666.181458 454.573120,664.983582 
	C454.410278,665.445374 454.247406,665.907166 454.084564,666.369019 
	C460.151733,670.886902 468.009125,670.788635 474.921814,673.126404 
	C474.919891,673.497253 474.917969,673.868042 474.518433,674.513306 
	C468.207245,673.555298 462.293701,672.322815 455.605255,670.928833 
	C456.264008,672.797607 456.623932,673.818726 457.008728,675.218506 
	C457.046967,676.102661 457.060333,676.608093 457.040100,677.493042 
	C457.985321,678.932434 458.791748,680.604492 459.975159,680.937805 
	C463.544525,681.942932 467.260498,682.427490 470.919281,683.115051 
	C470.919281,683.115051 471.009613,683.072510 471.217773,683.281860 
	C472.272064,683.563416 473.118195,683.635620 473.964355,683.707764 
z"/>
<path fill="#5F9BAC" opacity="1.000000" stroke="none" 
	d="
M632.102295,593.502075 
	C631.562073,594.565308 631.021912,595.628601 630.745972,596.905457 
	C631.010193,597.119080 631.057678,597.060669 630.724609,596.867920 
	C629.737366,597.430847 629.083130,598.186523 628.191162,599.041504 
	C627.567078,599.697693 627.180664,600.254578 626.567749,600.969360 
	C626.080627,601.608398 625.820068,602.089600 625.367004,602.710022 
	C624.938538,603.069824 624.741272,603.320679 624.294556,603.767273 
	C622.672607,605.973022 621.338684,608.013367 619.999512,610.024353 
	C619.994263,609.994995 620.053894,609.996338 619.756958,610.015015 
	C619.306396,610.374268 619.152710,610.714844 618.996216,611.024597 
	C618.993286,610.993774 619.055115,610.996155 618.753784,611.035889 
	C618.308716,611.437378 618.164856,611.799133 617.853516,612.444336 
	C615.506409,615.151978 613.326843,617.576355 610.820984,620.104736 
	C608.671753,622.133911 606.848694,624.059021 604.766907,626.060303 
	C604.332764,626.440491 604.157288,626.744446 603.978516,627.014771 
	C603.975098,626.981140 604.041321,626.967468 603.796265,627.151367 
	C603.364380,627.907593 603.177673,628.479858 602.989502,629.020508 
	C602.988098,628.988953 603.051331,628.988159 602.694092,629.014526 
	C601.898926,630.044373 601.460938,631.047852 601.011475,632.025757 
	C601.000000,632.000061 601.055237,632.010315 600.737122,631.977295 
	C600.284302,632.314575 600.149658,632.684875 600.007874,633.027344 
	C600.000732,632.999512 600.054321,633.020447 599.695190,632.968628 
	C598.231873,634.628113 597.127686,636.339417 596.011719,638.025391 
	C596.000000,638.000000 596.054993,638.010132 595.725830,637.963379 
	C595.274170,638.294983 595.151733,638.673279 595.017334,639.024597 
	C595.005310,638.997559 595.048157,639.038879 594.707153,638.906494 
	C590.574707,643.178894 586.783264,647.583618 582.716736,652.175293 
	C575.620789,661.247925 568.799866,670.133667 561.727051,678.869385 
	C561.238281,678.408936 561.001404,678.098450 560.764404,677.787964 
	C572.110718,659.454224 583.496338,641.144653 594.790710,622.778931 
	C603.666809,608.345581 612.430176,593.842957 621.790833,578.999268 
	C622.528076,578.232788 622.718201,577.838318 622.908325,577.443787 
	C623.008972,577.015442 623.109619,576.587097 623.585327,576.004150 
	C624.078064,575.796631 624.302185,575.724121 624.296570,575.694031 
	C624.254150,575.463867 624.166870,575.242004 624.093567,575.017517 
	C624.097290,574.697998 624.208740,574.421997 624.896851,573.924683 
	C625.572144,573.092712 625.778442,572.525513 625.984802,571.958374 
	C625.974670,571.652771 626.068848,571.383667 626.757019,570.909302 
	C627.475159,570.078735 627.703613,569.489929 627.932007,568.901123 
	C627.932007,568.901123 627.965637,568.987122 628.286621,568.919800 
	C629.026184,567.850342 629.444763,566.848145 629.863281,565.846008 
	C629.863342,565.846008 629.901794,565.925781 630.134949,565.786621 
	C630.566223,565.078552 630.764343,564.509766 630.962463,563.940918 
	C630.962463,563.940918 630.998779,563.996643 631.364624,563.989868 
	C636.134949,563.593994 635.716003,560.866882 634.702637,557.851624 
	C634.964783,557.346008 635.226929,556.840393 635.963745,555.995544 
	C636.589539,555.297424 636.740662,554.938538 636.891785,554.579651 
	C636.891785,554.579651 636.897156,554.255005 637.001160,554.130249 
	C638.097046,555.164734 639.082214,556.329712 640.082581,557.481567 
	C641.381958,558.977844 642.698792,561.671692 643.998474,561.665955 
	C645.866577,561.657715 648.394470,560.094116 649.418762,558.438477 
	C650.683472,556.394165 650.817566,552.918701 654.053833,554.373413 
	C655.363892,554.962341 655.430969,558.437988 655.927246,560.630859 
	C656.052063,561.182190 655.651001,561.852539 655.407898,562.791504 
	C650.402527,561.348633 648.768677,564.395142 647.259033,568.295593 
	C646.258179,570.881348 644.231140,573.069946 642.663513,575.436340 
	C642.274719,575.236389 641.885986,575.036438 641.497253,574.836426 
	C641.703613,576.462280 641.910034,578.088074 642.179199,580.150391 
	C642.242065,580.586853 642.141296,580.840332 641.832520,581.049438 
	C641.277161,581.473816 641.113281,581.739929 641.032043,582.056885 
	C641.032043,582.056885 641.001526,582.003174 640.800537,582.133789 
	C640.419006,582.550110 640.238403,582.835754 640.057861,583.121460 
	C640.057861,583.121460 640.051880,583.042969 639.847839,583.158875 
	C639.462463,583.571106 639.281128,583.867432 639.099792,584.163757 
	C639.099792,584.163757 639.058960,584.087891 638.853638,584.185791 
	C638.443970,584.567200 638.239624,584.850586 638.035278,585.133972 
	C637.334717,586.326660 636.634216,587.519348 635.377380,589.007935 
	C634.553650,589.880676 634.286255,590.457520 634.018860,591.034363 
	C634.018860,591.034363 633.996643,591.000916 633.791992,591.130005 
	C633.409180,591.545593 633.231018,591.832031 633.052856,592.118469 
	C633.052856,592.118469 633.039551,592.037354 632.816772,592.204224 
	C632.430115,592.748047 632.266174,593.125061 632.102295,593.502075 
z"/>
<path fill="#5F9BAC" opacity="1.000000" stroke="none" 
	d="
M443.319153,795.263184 
	C455.892395,767.756836 468.729889,740.493530 481.510498,713.203552 
	C483.625000,708.688538 485.490967,704.057190 487.874329,699.169800 
	C488.355011,698.779907 488.511505,698.679810 488.497101,698.623169 
	C488.445465,698.420044 488.336060,698.231567 488.248444,698.037598 
	C488.198090,697.713196 488.291046,697.437073 488.980286,696.915100 
	C489.577545,696.027466 489.721802,695.433960 489.866089,694.840454 
	C489.866058,694.840454 489.888794,694.934021 490.025330,694.882690 
	C490.254120,694.755188 490.437317,694.643921 490.423676,694.608398 
	C490.343536,694.400208 490.217346,694.209656 490.106750,694.013184 
	C490.288025,693.486694 490.469299,692.960144 491.069214,692.002686 
	C491.606476,691.167786 491.725128,690.763794 491.843811,690.359863 
	C491.843811,690.359863 491.724609,690.195068 492.130798,689.938232 
	C492.972900,688.267822 493.408783,686.854126 493.844666,685.440491 
	C493.844666,685.440491 493.769684,685.177795 494.255127,684.999634 
	C495.074036,683.653015 495.407532,682.484619 495.741028,681.316223 
	C495.741028,681.316223 495.611145,681.242493 495.985474,681.006714 
	C496.881622,679.146301 497.403442,677.521667 497.925293,675.897034 
	C497.925293,675.897034 497.967346,675.981384 498.298218,675.906860 
	C499.371277,673.690186 500.113434,671.547974 500.855591,669.405823 
	C500.855621,669.405823 500.735016,669.210999 501.073486,668.937744 
	C501.569244,668.060852 501.726501,667.457214 501.883759,666.853516 
	C501.883759,666.853516 501.906464,666.937988 502.205322,666.802734 
	C502.898315,665.361328 503.292480,664.055237 503.686584,662.749084 
	C503.686584,662.749084 503.720520,662.854736 504.023773,662.717163 
	C504.937958,660.958923 505.548889,659.338135 506.405457,657.559570 
	C507.412720,656.250183 508.174316,655.098633 509.262665,654.099121 
	C510.656158,654.099548 511.722839,653.947937 512.789551,653.796265 
	C512.152344,652.515869 511.515198,651.235535 510.957825,649.593506 
	C512.733582,645.153564 514.429565,641.075195 516.464539,637.085693 
	C517.149658,639.222412 517.495728,641.270386 517.990601,644.198914 
	C520.187378,640.711304 522.120178,637.642761 524.053040,634.574280 
	C524.517883,634.803223 524.982666,635.032227 525.447510,635.261230 
	C524.673340,637.640137 523.585083,639.971008 523.212280,642.411194 
	C522.830750,644.908630 523.150513,647.513184 523.163330,650.070801 
	C522.988708,650.607971 522.814087,651.145142 522.141235,652.019287 
	C521.461121,652.944885 521.279236,653.533630 521.097351,654.122314 
	C521.097351,654.122314 521.074402,654.044617 520.840332,654.206787 
	C520.454407,654.964722 520.302429,655.560425 520.150513,656.156128 
	C520.150513,656.156128 520.101868,656.059875 519.896179,656.179443 
	C519.552551,656.620544 519.414612,656.942078 519.276672,657.263672 
	C519.276672,657.263672 519.422546,657.317688 519.016968,657.253540 
	C517.474548,657.850952 515.563354,658.320740 515.343201,659.209473 
	C514.623474,662.114502 514.519775,665.171997 514.179138,668.170898 
	C514.179138,668.170898 514.140137,668.070190 513.842224,668.200500 
	C512.421082,670.945251 511.297882,673.559692 510.174683,676.174133 
	C510.174713,676.174133 510.135101,676.090271 509.890594,676.232056 
	C509.481842,676.970642 509.317627,677.567505 509.153381,678.164368 
	C509.153381,678.164368 509.120514,678.076538 508.880920,678.220642 
	C508.460571,678.952576 508.279755,679.540344 508.098938,680.128174 
	C508.098969,680.128174 508.093109,680.029175 507.819031,680.067993 
	C507.415192,680.763245 507.285400,681.419678 507.155670,682.076172 
	C506.985291,682.614990 506.814880,683.153748 506.138733,684.022278 
	C505.453156,684.941956 505.273346,685.531799 505.093506,686.121704 
	C505.093506,686.121704 505.062897,686.043396 504.813629,686.249023 
	C504.426056,687.145813 504.287720,687.837036 504.149384,688.528198 
	C504.149384,688.528198 504.186523,688.803223 503.743317,688.971313 
	C502.933899,690.153931 502.567657,691.168457 502.201477,692.182922 
	C502.201477,692.182922 502.154999,692.100952 501.895996,692.258301 
	C501.466217,693.057739 501.295410,693.699829 501.124634,694.341980 
	C500.668640,695.450500 500.212616,696.558960 499.236725,698.012573 
	C498.539215,698.963440 498.361572,699.569153 498.183929,700.174866 
	C498.183929,700.174866 498.161102,700.083679 497.912689,700.228882 
	C497.482056,700.963196 497.299805,701.552246 497.117584,702.141357 
	C497.117584,702.141357 497.080750,702.058472 496.830627,702.253052 
	C496.414703,703.362427 496.248932,704.277161 496.083130,705.191956 
	C479.994629,736.652954 465.053284,768.752258 444.278687,797.968201 
	C443.844086,797.276733 443.704254,796.387024 443.319153,795.263184 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M199.871338,522.388306 
	C195.550156,522.332031 191.228958,522.275757 186.377045,521.744019 
	C184.859589,519.081360 179.962387,520.910278 181.047272,516.595642 
	C213.158340,517.088135 245.270432,517.522217 277.380157,518.090515 
	C305.991272,518.596924 334.604797,519.074463 363.207977,519.890381 
	C392.722748,520.732178 422.227081,521.945557 451.734131,523.046448 
	C458.200958,523.287720 464.659882,523.740662 471.583862,524.195190 
	C471.737640,524.832520 471.430054,525.370789 470.876740,525.924194 
	C461.485504,525.960999 452.338257,526.110229 443.194794,525.983948 
	C376.843262,525.067444 310.492554,524.093506 244.141464,523.145264 
	C239.151566,523.073975 234.160797,523.061584 228.496887,522.497253 
	C227.252731,521.672180 226.684616,521.115479 226.111160,521.109924 
	C218.185181,521.032776 210.258057,520.990723 202.332550,521.071838 
	C201.507736,521.080322 200.691544,521.929321 199.871338,522.388306 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M513.313721,498.344299 
	C517.027466,495.582336 520.643982,493.224609 524.032654,490.575470 
	C526.917480,488.320190 529.881714,487.145020 533.057678,489.468109 
	C541.588501,495.708069 548.430969,492.949921 555.563354,486.517883 
	C561.574158,481.097290 568.438904,476.560608 575.250305,472.132111 
	C577.091614,470.934937 580.112671,471.552307 582.825195,471.605713 
	C582.200562,474.121948 581.760620,476.664490 580.415588,478.567139 
	C573.266846,488.679565 565.891235,498.631592 558.068787,508.674255 
	C557.291687,508.365784 557.039917,508.022186 556.788147,507.678589 
	C559.538147,503.410614 562.288086,499.142670 565.320007,494.404541 
	C565.262451,493.808502 564.922974,493.682587 564.583496,493.556641 
	C552.494202,498.152557 540.397217,502.728577 528.318665,507.352448 
	C521.827271,509.837494 515.367310,512.404602 507.996216,515.285156 
	C509.866150,509.201263 511.491913,503.911682 513.313721,498.344299 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M523.404968,649.816406 
	C523.150513,647.513184 522.830750,644.908630 523.212280,642.411194 
	C523.585083,639.971008 524.673340,637.640137 525.447510,635.261230 
	C524.982666,635.032227 524.517883,634.803223 524.053040,634.574280 
	C522.120178,637.642761 520.187378,640.711304 517.990601,644.198914 
	C517.495728,641.270386 517.149658,639.222412 516.535706,636.781006 
	C516.505798,635.599243 516.743774,634.810974 517.043091,633.651123 
	C518.403870,630.075378 519.842590,626.919861 520.980103,623.659302 
	C528.374268,602.465759 535.690125,581.244934 543.096313,560.055664 
	C543.417786,559.135803 544.372375,558.437256 545.379639,557.871826 
	C545.602417,559.672119 545.775574,561.332092 545.307800,562.785767 
	C539.709534,580.182068 534.016174,597.547791 528.156494,615.177002 
	C525.516418,622.037231 523.067505,628.642029 520.618652,635.246826 
	C521.036377,635.398071 521.454163,635.549316 521.871948,635.700562 
	C524.550659,628.798584 527.229431,621.896667 530.090210,614.682617 
	C537.017761,595.409790 543.763367,576.448975 550.685059,556.993103 
	C548.192688,556.563599 546.598755,556.288940 544.658569,555.853455 
	C542.280457,555.436951 540.248657,555.181213 537.881287,554.717163 
	C535.697144,554.329285 533.848633,554.149597 531.650391,553.865173 
	C529.866577,553.511108 528.432434,553.261719 526.951660,552.725342 
	C526.897278,552.244629 526.889465,552.051025 527.338623,551.863159 
	C536.748169,552.798035 545.809753,553.203064 553.974670,556.429810 
	C546.680847,577.790283 539.653687,598.369751 532.404907,619.206055 
	C530.719116,623.833801 529.255005,628.204773 527.790833,632.575684 
	C528.104858,632.722656 528.418823,632.869568 528.732788,633.016541 
	C529.445984,631.637878 530.159180,630.259277 531.314819,628.907776 
	C532.349731,628.960632 532.942261,628.986389 533.534790,629.012085 
	C530.238770,635.862061 526.942749,642.712097 523.404968,649.816406 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M577.992432,692.136292 
	C576.102539,695.788025 574.146667,699.407715 572.346252,703.102966 
	C571.169617,705.517883 569.417236,705.342468 567.204834,704.760498 
	C566.127625,704.475891 565.241943,703.983887 564.435181,703.026855 
	C564.677734,701.709167 564.841309,700.856506 565.306396,699.869690 
	C567.056213,698.815308 568.504578,697.895081 569.959412,696.649658 
	C569.614441,695.711365 569.263062,695.098389 568.911621,694.485352 
	C568.596069,694.986694 568.280579,695.487976 567.587463,695.991211 
	C566.454102,695.979370 565.698364,695.965698 564.989624,695.629761 
	C565.018250,694.879089 564.999817,694.450806 564.990784,694.010925 
	C565.000183,693.999390 565.015564,693.973816 565.307739,693.858154 
	C565.735779,693.157104 565.871643,692.571838 566.003723,691.993286 
	C566.000000,692.000000 565.988953,692.010132 566.266602,691.987183 
	C566.692993,691.646057 566.841797,691.327881 566.995300,691.004883 
	C567.000000,691.000000 567.009338,690.990784 567.278625,690.959595 
	C567.700012,690.617493 567.852173,690.306702 568.002197,689.997925 
	C568.000000,690.000000 567.995972,690.006409 568.285034,689.881226 
	C568.713501,689.175354 568.852844,688.594604 568.996033,688.007202 
	C568.999878,688.000549 569.010620,687.990479 569.189697,688.050842 
	C569.642456,688.079102 569.827087,687.934143 570.191589,687.424072 
	C578.075745,677.545349 585.691101,667.918823 593.571350,658.117493 
	C594.870789,656.617126 595.905273,655.291626 597.168213,653.788269 
	C597.823181,652.985107 598.249817,652.359802 598.949524,651.527222 
	C600.802979,650.196472 602.383423,649.073181 604.019409,648.341675 
	C604.225342,649.229980 604.375671,649.726501 604.526001,650.223022 
	C605.431641,649.223633 606.337280,648.224182 607.547119,646.994629 
	C608.620300,646.533264 609.389343,646.301941 610.065308,646.480469 
	C609.980164,647.609375 609.988037,648.328369 609.995850,649.047302 
	C609.995850,649.047302 609.993713,648.997864 609.767273,648.734253 
	C609.340332,648.605530 609.139771,648.740417 608.646729,649.082642 
	C607.901062,650.537170 607.447876,651.784546 606.994690,653.031860 
	C606.994690,653.031860 606.993103,652.992493 606.676270,652.832153 
	C605.900085,653.456482 605.440674,654.241150 604.981262,655.025879 
	C604.981262,655.025879 604.989563,654.988708 604.709351,654.987549 
	C604.274475,655.333679 604.119751,655.681030 603.965027,656.028320 
	C603.965027,656.028320 603.979919,655.984802 603.683960,656.109802 
	C603.253906,656.836304 603.119690,657.437866 602.985535,658.039368 
	C602.985535,658.039368 602.995667,657.996338 602.686340,657.929871 
	C602.242676,658.253296 602.108276,658.643250 601.973877,659.033203 
	C601.973938,659.033203 601.991394,658.991577 601.695740,658.955750 
	C601.256409,659.290344 601.112671,659.660706 600.968994,660.031128 
	C600.968994,660.031128 600.986389,659.988831 600.668457,660.071472 
	C600.228638,660.784668 600.106750,661.415222 599.984924,662.045776 
	C599.984924,662.045776 599.994873,661.996643 599.695923,661.926147 
	C599.255249,662.246948 599.113525,662.638184 598.971924,663.029419 
	C598.971924,663.029419 598.981873,662.986694 598.643555,663.080566 
	C598.206116,663.791199 598.106934,664.407959 598.007812,665.024780 
	C598.007751,665.024780 597.997314,664.998352 597.700806,664.769043 
	C593.255615,669.870667 589.056091,675.163391 585.008484,680.569824 
	C584.330994,681.474731 584.313232,682.873596 583.990723,684.044189 
	C583.990723,684.044189 583.993713,683.995850 583.681396,683.832642 
	C582.901978,684.457214 582.434937,685.244995 581.967896,686.032776 
	C581.967957,686.032776 581.981140,685.983887 581.691711,686.097290 
	C581.261902,686.823303 581.121521,687.435974 580.981201,688.048645 
	C580.981201,688.048645 580.995422,687.996826 580.703125,687.955566 
	C580.262512,688.288696 580.114258,688.663025 579.965942,689.037354 
	C579.965942,689.037354 579.983154,688.986084 579.686401,689.078308 
	C579.252441,689.796875 579.115234,690.423279 578.978027,691.049622 
	C578.978027,691.049622 578.998657,691.002808 578.756836,690.862915 
	C578.340759,691.194092 578.166626,691.665222 577.992432,692.136292 
M575.520630,686.825623 
	C575.818787,686.371582 576.116943,685.917480 576.819885,685.184387 
	C577.059753,684.951355 577.251953,684.684082 577.910278,684.100769 
	C578.352539,683.474731 578.794800,682.848694 579.724304,681.851746 
	C579.709167,681.561707 579.693970,681.271729 579.678833,680.981689 
	C579.297791,681.154724 578.916687,681.327759 578.029480,681.821838 
	C577.617920,682.482178 577.206299,683.142456 576.351929,684.032776 
	C576.079224,684.259277 575.931824,684.549438 575.366760,685.242188 
	C575.240845,685.823792 575.114990,686.405457 574.427673,686.844727 
	C574.174011,687.125793 573.920410,687.406799 573.124329,687.807007 
	C572.959534,688.116577 572.794800,688.426208 572.630066,688.735840 
	C573.113831,688.514893 573.597595,688.293945 574.558472,687.889832 
	C574.706299,687.594055 574.854126,687.298279 575.520630,686.825623 
M591.595886,664.094543 
	C587.592896,669.223328 583.589966,674.352051 579.587036,679.480835 
	C580.041992,679.820312 580.496948,680.159790 580.951965,680.499207 
	C587.413696,672.434814 593.875427,664.370422 600.337219,656.306030 
	C599.946838,655.982056 599.556458,655.658020 599.166077,655.334045 
	C596.786804,658.065674 594.407532,660.797241 591.595886,664.094543 
M603.784607,652.101868 
	C603.616882,651.652649 603.449219,651.203369 603.281494,650.754150 
	C602.086609,652.051086 600.891663,653.347961 599.696716,654.644897 
	C600.290100,655.209778 600.883545,655.774719 601.476929,656.339600 
	C602.098999,655.142639 602.721069,653.945679 603.784607,652.101868 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M489.682098,828.935059 
	C490.282074,828.994263 490.882050,829.053528 491.797485,829.516602 
	C492.073120,830.626221 492.033356,831.332092 491.993591,832.037903 
	C491.993591,832.037903 491.986786,831.989807 491.655396,831.943787 
	C486.350220,838.644836 481.158661,845.244080 476.455017,852.174438 
	C469.788849,861.996216 463.482361,872.062073 457.022156,882.023621 
	C457.022156,882.023621 456.998779,882.002441 456.680542,882.129150 
	C455.911499,883.514587 455.460693,884.773438 455.009888,886.032227 
	C455.009888,886.032227 454.996460,885.997253 454.677429,885.953125 
	C453.909882,886.616028 453.461426,887.322998 453.013000,888.030029 
	C453.013000,888.030029 452.996399,887.998230 452.700134,888.097046 
	C451.947357,889.135315 451.490845,890.074768 451.034302,891.014221 
	C451.034302,891.014221 451.004913,891.003418 450.663635,891.099670 
	C449.883942,892.475403 449.445526,893.754822 449.007111,895.034302 
	C449.007111,895.034302 448.994812,894.996155 448.660278,894.900940 
	C446.891815,897.211121 445.457886,899.616516 444.023987,902.021912 
	C444.024017,902.021912 444.000000,902.000000 443.677704,901.921753 
	C442.912964,902.569458 442.470490,903.295532 442.028015,904.021606 
	C442.028046,904.021606 442.000000,904.000000 441.692108,904.074707 
	C440.597290,905.848938 439.810364,907.548523 439.023438,909.248108 
	C438.692047,913.762329 435.189209,911.234741 432.935791,911.561951 
	C433.792206,911.158264 434.648621,910.754578 435.536499,910.335999 
	C432.267578,902.098450 433.860870,895.776184 440.986816,890.943176 
	C443.112213,889.196960 444.571472,887.511841 446.137238,885.703735 
	C446.521637,886.307190 446.854004,886.828918 447.115601,887.623413 
	C446.866058,888.435059 446.687286,888.973816 446.258423,889.656677 
	C445.581451,890.449646 445.154572,891.098572 444.556427,891.929688 
	C444.409424,892.349609 444.433746,892.587219 444.458038,892.824890 
	C444.771667,892.699341 445.085327,892.573792 445.675140,892.329590 
	C446.413574,891.577454 446.875885,890.943909 447.587189,890.075195 
	C448.561798,888.226135 449.287415,886.612305 450.005310,884.998047 
	C449.997559,884.997620 450.001038,885.013184 450.352844,885.062378 
	C452.288879,883.032898 453.963776,881.016663 455.442841,878.865723 
	C466.877228,862.236877 478.273804,845.581909 489.682098,828.935059 
M442.825348,896.040466 
	C443.125946,895.510132 443.426544,894.979736 443.727112,894.449402 
	C443.499756,894.322510 443.272400,894.195618 443.045044,894.068787 
	C442.670258,894.691467 442.295441,895.314209 441.434296,896.214722 
	C441.147797,896.690430 440.861267,897.166199 440.047913,897.907715 
	C439.651733,898.563660 439.255585,899.219604 438.482300,900.110413 
	C438.525543,900.352112 438.568787,900.593811 438.612061,900.835510 
	C438.822723,900.640747 439.033356,900.445984 439.810516,900.049316 
	C440.285095,899.432983 440.759674,898.816650 441.663300,897.808044 
	C441.858643,897.273682 442.053955,896.739319 442.825348,896.040466 
M437.261597,903.436462 
	C437.346680,903.054993 437.431763,902.673462 437.516876,902.291992 
	C437.248688,902.313721 436.761597,902.295044 436.748871,902.364014 
	C436.680847,902.732788 436.731110,903.123474 437.261597,903.436462 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M526.998230,553.012329 
	C528.432434,553.261719 529.866577,553.511108 531.725098,554.333008 
	C532.214355,555.214661 532.279419,555.523743 532.344482,555.832825 
	C529.162781,556.801208 525.981140,557.769592 522.007935,558.885010 
	C518.800354,559.942322 514.780762,556.710449 513.993530,561.828247 
	C512.772766,561.977722 511.552002,562.127136 509.691711,562.688477 
	C500.309235,563.291016 491.566315,563.481628 482.823364,563.672302 
	C482.843506,564.345276 482.863647,565.018188 482.883759,565.691162 
	C486.222870,565.514832 489.564819,565.380066 492.900452,565.152893 
	C498.628113,564.762756 504.351837,564.314758 510.077209,563.891113 
	C511.127167,563.966003 512.177124,564.040894 513.599487,564.515381 
	C513.985107,565.292053 513.998230,565.669250 514.011353,566.046387 
	C513.042969,566.225525 512.074524,566.404663 510.339569,566.411011 
	C503.564392,566.577393 497.555786,566.916626 491.195862,567.207764 
	C490.230377,567.427490 489.616211,567.695312 488.608521,568.044312 
	C485.158295,568.430237 482.101532,568.735046 478.592163,568.983398 
	C475.128235,569.033813 472.116882,569.140686 469.105530,569.247559 
	C469.102844,569.025452 469.100159,568.803284 469.501099,568.200684 
	C473.405060,567.345703 476.905457,566.871277 481.427917,566.258301 
	C479.238220,565.187927 478.122223,564.642395 477.006195,564.096863 
	C480.816589,562.385376 484.626984,560.674011 488.753021,558.820801 
	C488.291016,557.709045 487.715729,556.324707 486.799561,554.120239 
	C488.832336,554.452332 490.170807,554.670959 492.295380,554.917847 
	C499.496216,555.329346 505.908325,555.767944 512.326843,556.071533 
	C515.333557,556.213867 518.352478,556.095886 521.365906,556.095886 
	C521.380371,555.531189 521.394836,554.966492 521.409363,554.401794 
	C519.565918,554.264771 517.704163,554.245850 515.883362,553.963013 
	C513.193420,553.545227 510.532715,552.939758 508.520569,552.284912 
	C515.120422,552.441162 521.059326,552.726746 526.998230,553.012329 
M494.758789,558.542847 
	C498.772308,563.442810 503.667053,560.015442 508.118591,560.776428 
	C508.180542,560.285950 508.242493,559.795410 508.304443,559.304932 
	C504.002747,558.919678 499.701019,558.534424 494.758789,558.542847 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M547.133789,849.003235 
	C547.040405,818.758606 546.948608,788.513977 546.849365,758.269348 
	C546.843384,756.442688 546.777710,754.616150 546.602539,752.334106 
	C547.194946,750.155029 547.924561,748.431335 548.858459,746.427979 
	C549.296509,746.914246 549.736023,747.680542 549.734253,748.445862 
	C549.660522,780.393066 549.547302,812.340271 548.995605,844.844604 
	C548.079407,846.602295 547.606567,847.802734 547.133789,849.003235 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M447.186340,887.350647 
	C446.854004,886.828918 446.521637,886.307190 446.137238,885.703735 
	C444.571472,887.511841 443.112213,889.196960 441.315063,890.935425 
	C441.219757,890.194397 441.462311,889.400024 441.840088,887.909790 
	C441.931274,886.666748 441.887177,886.119385 441.925079,885.247437 
	C442.659943,883.277161 443.312805,881.631470 443.965698,879.985779 
	C443.965729,879.985779 443.993134,879.995911 444.328247,879.885437 
	C445.771057,877.341675 446.878693,874.908325 447.986359,872.475037 
	C447.986359,872.475037 448.004639,872.510681 448.354492,872.450134 
	C448.990814,872.226990 449.386475,872.136475 449.548279,871.891479 
	C460.690857,855.016968 471.835052,838.143433 482.875397,821.202148 
	C483.388519,820.414856 482.958923,819.013123 482.968384,817.897583 
	C483.930634,816.699158 484.892883,815.500793 485.886688,814.263000 
	C486.033051,817.017273 486.161041,819.425903 486.643616,821.916260 
	C486.998199,821.997925 487.017242,822.012268 487.017242,822.012268 
	C485.884430,823.973389 484.751617,825.934570 482.964294,827.996460 
	C481.865814,828.737732 481.421844,829.378174 480.986206,830.006104 
	C480.994537,829.993591 481.016327,829.972900 480.709686,830.116943 
	C477.930145,834.180420 475.457275,838.099792 472.989929,842.007629 
	C472.995483,841.996033 473.015808,841.980103 472.703369,842.082520 
	C471.922394,843.130066 471.453827,844.075256 470.990143,845.008057 
	C470.994995,844.995667 471.016785,844.980225 470.691040,845.082520 
	C467.574768,849.797729 464.784241,854.410583 461.995117,859.010559 
	C461.996490,858.997681 462.020996,858.989807 461.651428,858.955322 
	C460.851288,859.956360 460.420715,860.991821 459.993347,862.012634 
	C459.996613,861.997925 460.023102,861.983765 459.730469,861.992065 
	C459.289459,862.337952 459.141083,862.675598 458.995361,863.004761 
	C458.998016,862.996277 459.013977,862.988220 458.694763,863.084717 
	C458.247375,863.796387 458.119141,864.411621 457.993774,865.012451 
	C457.996643,864.997986 458.022522,864.984009 457.730682,864.992188 
	C457.287201,865.340393 457.135529,865.680298 456.989197,866.007812 
	C456.994537,865.995422 457.015717,865.978516 456.724121,866.107056 
	C456.284637,866.831726 456.136719,867.427979 455.992035,868.010498 
	C455.995270,867.996887 456.018982,867.981750 455.693115,868.061157 
	C454.907562,869.100952 454.447876,870.061401 453.991547,871.009277 
	C453.994904,870.996704 454.016418,870.981750 453.701355,871.077515 
	C452.805298,872.324646 452.224304,873.475952 451.447144,874.766357 
	C451.024200,875.137817 450.837494,875.398865 450.477661,875.874146 
	C450.046417,876.577759 449.828247,877.095764 449.423767,877.768799 
	C449.023834,878.174683 448.870605,878.458191 448.557373,878.952637 
	C448.105896,879.640869 447.874817,880.150940 447.473206,880.843750 
	C447.369232,881.311462 447.435730,881.596375 447.502197,881.881287 
	C447.731110,881.654785 447.959991,881.428284 448.421722,881.042603 
	C448.890411,880.379333 449.126282,879.875366 449.557709,879.230835 
	C449.987122,878.861938 450.168579,878.597656 450.503296,878.104492 
	C450.931519,877.396729 451.154175,876.881958 451.554993,876.200806 
	C451.937378,875.773804 452.077179,875.482239 452.355713,875.092407 
	C452.558838,875.024963 452.963409,874.884949 453.117920,875.129517 
	C453.796570,875.581238 454.320679,875.788330 454.740417,876.309814 
	C453.091034,879.420532 451.546021,882.216858 450.001038,885.013184 
	C450.001038,885.013184 449.997559,884.997620 449.695984,885.053894 
	C448.658386,885.856995 447.922363,886.603821 447.186340,887.350647 
M445.812164,883.086853 
	C445.808624,883.321411 445.805115,883.555908 445.801575,883.790466 
	C445.959625,883.779175 446.246979,883.792419 446.254517,883.752563 
	C446.300201,883.511780 446.281891,883.258850 445.812164,883.086853 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M561.978943,679.019409 
	C568.799866,670.133667 575.620789,661.247925 583.034424,652.176636 
	C584.050659,651.986511 584.474182,651.982056 584.834839,652.204224 
	C584.620850,652.703613 584.487610,652.984680 584.121643,653.497986 
	C579.533264,659.772583 575.195557,665.823425 570.647339,671.989441 
	C570.192444,672.325012 570.022583,672.592651 569.685120,673.094177 
	C569.136902,674.027527 568.830872,674.774353 568.267456,675.658325 
	C567.587036,676.454956 567.164001,677.114563 566.535889,677.967773 
	C566.127014,678.682251 565.923157,679.203186 565.523865,679.865234 
	C565.107239,680.248352 564.947021,680.525452 564.612427,680.995422 
	C564.146240,681.663025 563.915222,682.173035 563.489746,682.825806 
	C563.064636,683.202393 562.896912,683.474976 562.601013,683.929565 
	C562.463440,684.337585 562.517029,684.602356 562.570679,684.867065 
	C562.802063,684.664490 563.033508,684.461975 563.465698,684.126343 
	C563.904297,683.765198 564.072693,683.494019 564.402466,683.019836 
	C564.871948,682.357544 565.110657,681.855103 565.537720,681.200928 
	C565.946716,680.806030 566.109314,680.528870 566.443787,680.051147 
	C566.945740,679.395691 567.217712,678.906799 567.739441,678.275024 
	C568.435547,677.523071 568.882019,676.914062 569.545654,676.113647 
	C570.164917,675.274597 570.566956,674.626892 571.116455,673.750244 
	C571.381714,673.183838 571.499451,672.846313 571.926025,672.362915 
	C572.831909,672.138611 573.428955,672.060181 574.053589,672.323303 
	C574.046570,673.115356 574.012024,673.565735 573.987732,674.006592 
	C573.997986,673.997070 574.012268,673.972961 573.689880,674.048706 
	C572.235901,675.731445 571.104248,677.338501 569.722839,679.127075 
	C569.309692,679.876831 569.146301,680.445190 568.991577,681.005859 
	C569.000183,680.998169 569.010986,680.977722 568.689819,681.084839 
	C568.241211,681.803650 568.113708,682.415344 567.994385,683.014343 
	C568.002563,683.001587 568.023010,682.979248 567.722778,682.974731 
	C567.272827,683.322083 567.123169,683.673889 566.986755,684.012817 
	C567.000000,683.999878 567.025513,683.973022 566.745178,684.013367 
	C566.299805,684.377686 566.134827,684.701599 565.982727,685.011047 
	C565.995605,684.996582 566.017151,684.964355 565.731567,685.105957 
	C565.290466,685.843201 565.134888,686.438843 564.990723,687.018677 
	C565.002197,687.002991 565.027283,686.973389 564.736877,686.997986 
	C564.288452,687.357239 564.130432,687.691956 563.986328,688.012817 
	C564.000183,687.999023 564.026672,687.970093 563.757019,688.029663 
	C563.314270,688.401245 563.141174,688.713196 562.981750,689.010864 
	C562.995422,688.996521 563.018799,688.964478 562.699219,689.104370 
	C561.247131,691.347229 560.114624,693.450134 558.982117,695.553040 
	C557.731262,694.094177 556.480347,692.635315 555.089355,690.691040 
	C557.292480,686.476868 559.635742,682.748169 561.978943,679.019409 
M561.229004,686.422241 
	C561.381531,686.431091 561.534058,686.439941 561.686584,686.448792 
	C561.615417,686.358765 561.544312,686.268799 561.229004,686.422241 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M510.888062,573.153198 
	C512.971558,573.153198 515.055115,573.153198 517.138611,573.153198 
	C517.226013,573.626221 517.313477,574.099304 517.400879,574.572327 
	C515.187927,574.975525 512.974976,575.378784 509.948364,575.770996 
	C504.818848,576.214844 500.503021,576.669739 495.718079,577.027527 
	C488.817017,577.323547 482.368042,577.539062 475.957428,578.154175 
	C467.775635,578.939087 459.624756,580.046875 451.476929,580.992432 
	C451.493286,580.969360 451.541290,580.999390 451.199707,580.895020 
	C449.238495,580.856384 447.618896,580.921997 445.999268,580.987671 
	C447.693481,579.305237 449.387726,577.622864 451.543030,576.299438 
	C452.658813,577.024780 453.352386,577.763367 453.962280,577.699768 
	C459.673645,577.103821 465.370636,576.370483 471.502136,575.713989 
	C479.983307,575.160461 488.034119,574.569824 496.538879,574.002563 
	C501.624542,573.734985 506.256287,573.444092 510.888062,573.153198 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M415.177063,603.649536 
	C415.609985,603.102783 416.042877,602.556030 417.215881,602.000977 
	C440.288544,600.903809 462.621155,599.814941 485.165283,598.818237 
	C485.696503,599.002502 485.915741,599.203857 486.076294,599.869629 
	C486.151489,600.460449 486.184814,600.696106 485.805237,600.977051 
	C483.578064,601.648254 481.763855,602.274109 479.484375,602.902344 
	C472.707275,603.170044 466.396637,603.621643 460.083313,603.663330 
	C445.115234,603.762207 430.145905,603.666504 415.177063,603.649536 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M451.541290,580.999390 
	C451.541290,580.999390 451.493286,580.969360 451.910400,581.095703 
	C462.667633,581.143433 473.012604,581.223694 483.345642,580.910889 
	C487.720093,580.778381 492.069550,579.819885 496.654358,579.259033 
	C497.177307,579.308350 497.475739,579.340271 498.130096,579.524658 
	C498.732330,579.596375 498.978088,579.520447 499.613403,579.434082 
	C503.356903,579.180054 506.710815,578.936462 510.064697,578.692871 
	C510.850922,578.661926 511.637115,578.631042 512.765259,578.930908 
	C513.068420,579.805176 513.029602,580.348633 512.990784,580.892029 
	C509.024109,581.331360 505.057434,581.770691 500.231750,582.167725 
	C491.593628,582.766113 483.814514,583.406799 475.571899,583.955200 
	C466.058472,584.226501 457.008606,584.590027 447.559692,584.912598 
	C446.087555,584.867310 445.014496,584.862915 443.941406,584.858521 
	C443.964447,584.441711 443.987488,584.024902 444.406189,583.238037 
	C447.048370,582.245178 449.294830,581.622314 451.541290,580.999390 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M556.215088,550.102905 
	C560.324951,550.102966 564.434753,550.102966 570.065308,550.102966 
	C561.273987,569.462585 552.875305,587.957458 543.878235,606.715820 
	C543.028870,606.970459 542.777893,606.961548 542.680786,606.606934 
	C543.221741,604.840027 543.608826,603.418884 544.249023,601.780396 
	C544.662598,600.702393 544.822998,599.841614 545.287231,598.811096 
	C551.886780,584.377075 558.164307,570.104675 564.492065,555.854614 
	C565.952881,552.564880 564.747437,551.271057 561.411377,551.051941 
	C559.666016,550.937439 557.946350,550.431885 556.215088,550.102905 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M484.953735,598.725952 
	C462.621155,599.814941 440.288544,600.903809 417.493622,601.936401 
	C420.855408,601.584412 424.696381,601.419250 428.495392,600.929016 
	C430.016907,600.732666 431.446075,599.820679 433.398712,598.731079 
	C436.204681,598.167603 438.529694,598.122498 440.853607,598.044434 
	C452.278046,597.660828 463.702209,597.268433 475.571289,596.893433 
	C479.030182,596.881836 482.044250,596.855896 485.035034,597.146118 
	C484.992462,597.883545 484.973083,598.304749 484.953735,598.725952 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M555.764160,550.085815 
	C557.946350,550.431885 559.666016,550.937439 561.411377,551.051941 
	C564.747437,551.271057 565.952881,552.564880 564.492065,555.854614 
	C558.164307,570.104675 551.886780,584.377075 545.297729,598.820923 
	C545.004395,599.000610 545.027466,599.016235 544.958679,598.622559 
	C550.358032,583.726318 555.826172,569.223755 561.612671,553.876770 
	C555.365845,552.559692 548.797791,551.174805 542.146484,549.594971 
	C542.063293,549.399963 541.967346,548.986816 541.967346,548.986816 
	C543.978455,548.760803 546.021545,548.202820 547.992615,548.392639 
	C550.463745,548.630615 552.875732,549.481995 555.764160,550.085815 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M499.718842,590.011902 
	C500.141174,591.066895 500.563477,592.121826 500.621155,593.464111 
	C499.664948,593.290466 499.038574,592.391968 498.487091,592.435730 
	C492.353760,592.922913 486.232025,593.555908 479.700806,594.053467 
	C478.482941,594.094604 477.671448,594.231750 476.597321,594.379395 
	C475.987579,594.424927 475.640472,594.460022 474.871460,594.353088 
	C463.285339,594.679810 452.121094,595.148438 440.956848,595.617065 
	C440.971863,594.963013 440.986908,594.308899 441.464203,593.296509 
	C452.356659,592.519104 462.786896,592.100037 473.624146,591.688293 
	C481.367950,591.132324 488.704742,590.568970 496.409241,590.014893 
	C497.757629,590.020142 498.738220,590.015991 499.718842,590.011902 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M454.844788,875.995422 
	C454.320679,875.788330 453.796570,875.581238 453.242920,874.805664 
	C453.481049,873.152039 453.748749,872.066895 454.016418,870.981750 
	C454.016418,870.981750 453.994904,870.996704 454.326569,870.950317 
	C455.111816,869.929871 455.565399,868.955811 456.018982,867.981750 
	C456.018982,867.981750 455.995270,867.996887 456.312592,867.914612 
	C456.758514,867.214417 456.887115,866.596436 457.015717,865.978516 
	C457.015717,865.978516 456.994537,865.995422 457.280182,865.995361 
	C457.718048,865.658142 457.870270,865.321045 458.022522,864.984009 
	C458.022522,864.984009 457.996643,864.997986 458.274261,864.864197 
	C458.705902,864.149658 458.859924,863.568970 459.013977,862.988220 
	C459.013977,862.988220 458.998016,862.996277 459.280060,862.985229 
	C459.715759,862.644043 459.869446,862.313904 460.023102,861.983765 
	C460.023102,861.983765 459.996613,861.997925 460.290283,861.884460 
	C461.062958,860.843933 461.541992,859.916870 462.020996,858.989807 
	C462.020996,858.989807 461.996490,858.997681 462.321045,858.909302 
	C465.435974,854.207275 468.226349,849.593750 471.016785,844.980225 
	C471.016785,844.980225 470.994995,844.995667 471.355347,845.022217 
	C472.149048,844.025879 472.582428,843.002991 473.015808,841.980103 
	C473.015808,841.980103 472.995483,841.996033 473.342010,842.038330 
	C476.131134,838.044800 478.573730,834.008850 481.016357,829.972900 
	C481.016327,829.972900 480.994537,829.993591 481.279358,830.099670 
	C481.961243,830.108826 482.358368,830.011902 482.755463,829.915039 
	C483.579987,829.944519 484.404480,829.973999 485.502045,830.317688 
	C484.370056,832.489868 482.875031,834.287170 481.574432,836.215576 
	C472.641785,849.460144 463.749847,862.732239 454.844788,875.995422 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M454.740417,876.309814 
	C463.749847,862.732239 472.641785,849.460144 481.574432,836.215576 
	C482.875031,834.287170 484.370056,832.489868 485.914612,830.322937 
	C487.319519,829.439758 488.584869,828.865540 489.766174,828.613220 
	C478.273804,845.581909 466.877228,862.236877 455.442841,878.865723 
	C453.963776,881.016663 452.288879,883.032898 450.352844,885.062378 
	C451.546021,882.216858 453.091034,879.420532 454.740417,876.309814 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M510.205627,568.734253 
	C512.218994,568.695190 514.232422,568.656128 516.662842,568.991089 
	C517.038574,569.772095 516.997253,570.179077 516.955872,570.586121 
	C515.270264,570.711975 513.584656,570.837769 511.061035,570.932617 
	C505.568176,571.413330 500.913422,571.925049 495.816132,572.320374 
	C486.644226,572.873474 477.914825,573.542908 468.750214,574.162109 
	C465.207886,574.401794 462.100769,574.691711 458.993652,574.981628 
	C458.527863,573.550537 458.062103,572.119446 457.504242,570.405396 
	C458.552307,570.167603 459.584167,569.933533 461.092773,570.162964 
	C467.437164,570.673035 473.304810,570.719543 479.595093,570.761841 
	C490.080414,570.083130 500.143005,569.408691 510.205627,568.734253 
z"/>
<path fill="#ECF5F6" opacity="1.000000" stroke="none" 
	d="
M415.124329,604.051392 
	C430.145905,603.666504 445.115234,603.762207 460.083313,603.663330 
	C466.396637,603.621643 472.707275,603.170044 479.863953,602.936157 
	C482.128967,603.255737 483.549133,603.544006 484.529114,603.872986 
	C479.078278,604.589172 474.067566,605.264648 469.056885,605.940186 
	C465.661346,606.290771 462.265778,606.641357 458.249573,606.979065 
	C457.628937,606.966187 457.199249,606.941895 456.899811,606.644531 
	C453.432434,606.336426 450.264496,606.325745 446.630829,606.219849 
	C435.776520,606.106018 425.387970,606.087402 414.999390,606.068726 
	C415.023438,605.530273 415.047516,604.991760 415.124329,604.051392 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M482.829437,818.211914 
	C482.958923,819.013123 483.388519,820.414856 482.875397,821.202148 
	C471.835052,838.143433 460.690857,855.016968 449.548279,871.891479 
	C449.386475,872.136475 448.990814,872.226990 448.334656,872.453003 
	C450.981262,867.393433 453.805756,862.145325 457.051147,857.171814 
	C465.499054,844.225647 474.131592,831.399902 482.829437,818.211914 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M457.370056,882.113464 
	C463.482361,872.062073 469.788849,861.996216 476.455017,852.174438 
	C481.158661,845.244080 486.350220,838.644836 491.679688,831.943481 
	C484.660065,843.136230 477.366669,854.338684 469.875763,865.407410 
	C466.004028,871.128479 461.781769,876.612305 457.370056,882.113464 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M440.549744,595.699341 
	C452.121094,595.148438 463.285339,594.679810 474.836243,594.649902 
	C475.190765,595.685425 475.158630,596.282288 475.126465,596.879089 
	C463.702209,597.268433 452.278046,597.660828 440.853607,598.044434 
	C438.529694,598.122498 436.204681,598.167603 433.421631,598.348145 
	C429.570343,598.316589 426.177551,598.164856 422.784729,598.013062 
	C422.769012,597.741516 422.753296,597.470032 422.737579,597.198486 
	C426.537994,596.804016 430.338440,596.409485 434.820190,595.986694 
	C435.667633,595.927002 435.833771,595.895569 435.999908,595.864136 
	C437.380829,595.836670 438.761719,595.809204 440.549744,595.699341 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M499.761322,589.589783 
	C498.738220,590.015991 497.757629,590.020142 496.336487,589.612366 
	C493.384003,588.881531 490.869904,588.284119 488.360474,588.303101 
	C483.558258,588.339417 478.758698,588.726379 473.958038,588.967712 
	C473.959351,588.313171 473.960693,587.658691 474.312012,586.557495 
	C475.156403,585.962524 475.650818,585.814148 476.574341,585.703247 
	C484.689087,585.458862 492.374756,585.176941 500.060425,584.895081 
	C503.842255,584.616882 507.624115,584.338745 511.405945,584.060608 
	C511.517212,584.396301 511.628510,584.732056 511.739777,585.067749 
	C510.103912,585.605164 508.461212,586.122864 506.833740,586.684631 
	C504.484619,587.495483 502.146576,588.338440 499.761322,589.589783 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M473.217102,591.680908 
	C462.786896,592.100037 452.356659,592.519104 441.458954,592.969116 
	C436.906281,593.000000 432.821106,593.000000 428.735962,593.000000 
	C428.711212,592.449585 428.686462,591.899170 428.661743,591.348755 
	C443.244354,590.565125 457.826996,589.781494 472.744812,589.406738 
	C473.125732,590.437439 473.171417,591.059204 473.217102,591.680908 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M563.004395,695.039124 
	C562.499146,694.697510 561.993958,694.355896 561.317627,693.657043 
	C561.770630,691.854675 562.394714,690.409607 563.018799,688.964478 
	C563.018799,688.964478 562.995422,688.996521 563.285217,689.012573 
	C563.725586,688.675842 563.876160,688.322998 564.026672,687.970093 
	C564.026672,687.970093 564.000183,687.999023 564.278503,687.989685 
	C564.713623,687.644714 564.870483,687.309021 565.027283,686.973389 
	C565.027283,686.973389 565.002197,687.002991 565.291626,686.885254 
	C565.726440,686.166504 565.871765,685.565430 566.017151,684.964355 
	C566.017151,684.964355 565.995605,684.996582 566.285767,685.011047 
	C566.725830,684.674683 566.875671,684.323853 567.025513,683.973022 
	C567.025513,683.973022 567.000000,683.999878 567.277588,683.992615 
	C567.711121,683.649963 567.867065,683.314636 568.023010,682.979248 
	C568.023010,682.979248 568.002563,683.001587 568.320068,682.906982 
	C568.762024,682.200806 568.886475,681.589233 569.010986,680.977722 
	C569.010986,680.977722 569.000183,680.998169 569.286987,681.125000 
	C569.995239,680.807007 570.416748,680.362061 571.072876,679.659790 
	C572.209045,677.592529 573.110657,675.782715 574.012207,673.972961 
	C574.012268,673.972961 573.997986,673.997070 574.317078,674.165649 
	C575.945068,672.722351 577.253906,671.110413 578.774902,669.312500 
	C579.412598,668.499634 579.838257,667.872742 580.490601,667.098633 
	C581.012207,666.510132 581.307068,666.068970 581.864990,665.442017 
	C583.425049,664.163696 584.722107,663.071045 586.264160,662.293091 
	C585.617554,663.969177 584.773315,665.364319 583.826416,666.685974 
	C579.214905,673.122131 574.575989,679.538635 569.704529,686.139099 
	C569.311768,686.874268 569.161194,687.432373 569.010620,687.990479 
	C569.010620,687.990479 568.999878,688.000549 568.715454,688.138672 
	C568.286072,688.853394 568.141052,689.429871 567.995972,690.006409 
	C567.995972,690.006409 568.000000,690.000000 567.730835,690.028564 
	C567.310913,690.368286 567.160156,690.679565 567.009338,690.990784 
	C567.009338,690.990784 567.000000,691.000000 566.731262,691.046631 
	C566.304688,691.398926 566.146851,691.704529 565.988953,692.010132 
	C565.988953,692.010132 566.000000,692.000000 565.729553,692.134888 
	C565.311279,692.837769 565.163391,693.405762 565.015503,693.973816 
	C565.015564,693.973816 565.000183,693.999390 564.700562,694.006958 
	C563.935364,694.356018 563.469849,694.697571 563.004395,695.039124 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M414.610870,606.106201 
	C425.387970,606.087402 435.776520,606.106018 446.614777,606.490845 
	C447.076508,607.398743 447.088593,607.940369 447.100647,608.481995 
	C434.717987,608.481995 422.335327,608.481995 409.952667,608.481995 
	C409.900421,607.885559 409.848175,607.289062 409.795929,606.692627 
	C411.271393,606.509644 412.746887,606.326599 414.610870,606.106201 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M533.762146,628.789551 
	C532.942261,628.986389 532.349731,628.960632 531.324463,628.537659 
	C531.891113,625.085388 532.890503,622.030457 534.054199,618.654053 
	C535.726562,614.791077 537.234741,611.249634 538.903320,607.964905 
	C539.365112,608.478394 539.666382,608.735107 540.097290,609.159363 
	C540.695557,609.119385 541.164124,608.911865 541.853394,608.900513 
	C542.226074,609.222229 542.377869,609.347717 542.529602,609.473206 
	C539.682922,615.837830 536.836243,622.202454 533.762146,628.789551 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M476.145264,585.665771 
	C475.650818,585.814148 475.156403,585.962524 474.292480,586.230591 
	C465.937500,586.537048 457.952026,586.723755 449.362610,586.651855 
	C448.492004,585.913391 448.225342,585.433533 447.958710,584.953613 
	C457.008606,584.590027 466.058472,584.226501 475.579773,584.225708 
	C476.082520,584.947571 476.113892,585.306702 476.145264,585.665771 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M507.859589,552.414246 
	C510.532715,552.939758 513.193420,553.545227 515.883362,553.963013 
	C517.704163,554.245850 519.565918,554.264771 521.409363,554.401794 
	C521.394836,554.966492 521.380371,555.531189 521.365906,556.095886 
	C518.352478,556.095886 515.333557,556.213867 512.326843,556.071533 
	C505.908325,555.767944 499.496216,555.329346 492.676788,554.923950 
	C493.278137,554.606445 494.284180,554.311096 496.076660,554.019897 
	C499.561279,553.967468 502.259521,553.910706 505.363983,553.895203 
	C506.361023,553.313538 506.951843,552.690674 507.556458,552.152710 
	C507.570282,552.237549 507.859589,552.414246 507.859589,552.414246 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M508.520569,552.284912 
	C507.859589,552.414246 507.570282,552.237549 507.210266,551.867920 
	C503.231964,551.343994 499.613739,551.189636 495.995483,551.035278 
	C494.680939,550.476685 493.366425,549.918091 491.232391,549.011169 
	C495.386597,548.682617 498.686401,548.421631 502.569000,548.512207 
	C507.493683,549.489624 511.835632,550.115356 516.603577,550.787659 
	C520.313599,551.175293 523.597595,551.516357 526.881592,551.857422 
	C526.889465,552.051025 526.897278,552.244629 526.951660,552.725342 
	C521.059326,552.726746 515.120422,552.441162 508.520569,552.284912 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M473.624146,591.688354 
	C473.171417,591.059204 473.125732,590.437439 473.119415,589.503784 
	C473.158813,589.191895 473.564972,589.101746 473.761505,589.034729 
	C478.758698,588.726379 483.558258,588.339417 488.360474,588.303101 
	C490.869904,588.284119 493.384003,588.881531 495.968750,589.603027 
	C488.704742,590.568970 481.367950,591.132324 473.624146,591.688354 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M516.177551,550.741089 
	C511.835632,550.115356 507.493683,549.489624 502.994019,548.508911 
	C508.347076,548.141113 513.857910,548.128235 519.797791,548.456909 
	C520.241638,549.137634 520.256470,549.476807 519.904907,549.872803 
	C518.418213,550.200195 517.297913,550.470642 516.177551,550.741089 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M458.578888,575.014099 
	C462.100769,574.691711 465.207886,574.401794 468.890076,574.357117 
	C470.000702,574.960571 470.536255,575.318726 471.071808,575.676941 
	C465.370636,576.370483 459.673645,577.103821 453.962280,577.699768 
	C453.352386,577.763367 452.658813,577.024780 451.900879,576.289734 
	C453.919830,575.629639 456.041962,575.338135 458.578888,575.014099 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M561.727051,678.869385 
	C559.635742,682.748169 557.292480,686.476868 554.810181,690.526001 
	C554.116882,691.144653 553.562622,691.442871 553.008362,691.741150 
	C552.699951,691.521545 552.391479,691.302002 552.083069,691.082458 
	C554.796265,686.781006 557.509460,682.479553 560.493591,677.983093 
	C561.001404,678.098450 561.238281,678.408936 561.727051,678.869385 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M479.172424,570.766113 
	C473.304810,570.719543 467.437164,570.673035 461.303223,570.353760 
	C463.507294,569.951355 465.977661,569.821777 468.776794,569.469849 
	C472.116882,569.140686 475.128235,569.033813 478.580078,569.272827 
	C479.071198,570.001221 479.121796,570.383667 479.172424,570.766113 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M556.518555,507.887360 
	C557.039917,508.022186 557.291687,508.365784 557.812073,508.884338 
	C555.274963,512.634155 552.469177,516.209045 549.288635,519.848755 
	C548.913879,519.913696 548.773743,519.766846 548.773743,519.766846 
	C551.265503,515.876587 553.757263,511.986359 556.518555,507.887360 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M520.271240,549.815918 
	C520.256470,549.476807 520.241638,549.137634 520.277893,548.551758 
	C527.232056,548.541931 534.135193,548.778870 541.502808,549.001343 
	C541.967346,548.986816 542.063293,549.399963 541.811523,549.659180 
	C540.745117,550.208740 539.930542,550.499146 538.690674,550.815796 
	C532.267395,550.500061 526.269287,550.158020 520.271240,549.815918 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M514.456421,667.971558 
	C514.519775,665.171997 514.623474,662.114502 515.343201,659.209473 
	C515.563354,658.320740 517.474548,657.850952 519.010254,657.330933 
	C517.850647,660.905762 516.292175,664.338989 514.456421,667.971558 
z"/>
<path fill="#6998A5" opacity="1.000000" stroke="none" 
	d="
M447.534119,608.566833 
	C447.088593,607.940369 447.076508,607.398743 447.080505,606.586060 
	C450.264496,606.325745 453.432434,606.336426 456.635010,606.765381 
	C456.132874,607.717834 455.596130,608.252075 455.059387,608.786255 
	C452.695465,608.741394 450.331512,608.696533 447.534119,608.566833 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M496.001953,551.363037 
	C499.613739,551.189636 503.231964,551.343994 507.196442,551.783081 
	C506.951843,552.690674 506.361023,553.313538 505.055542,553.649109 
	C501.569336,553.242004 498.797852,553.122192 496.026337,553.002441 
	C496.020386,552.565186 496.014404,552.127991 496.001953,551.363037 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M447.559692,584.912659 
	C448.225342,585.433533 448.492004,585.913391 448.896851,586.629883 
	C445.226990,587.134766 441.418945,587.403137 437.610931,587.671509 
	C437.549103,587.346375 437.487305,587.021240 437.425476,586.696045 
	C439.027557,586.171204 440.629608,585.646301 442.697174,585.209106 
	C443.395447,585.170959 443.628235,585.045166 443.861023,584.919312 
	C443.861023,584.919312 443.919983,584.915405 443.930695,584.886963 
	C445.014496,584.862915 446.087555,584.867310 447.559692,584.912659 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M476.764984,564.077148 
	C478.122223,564.642395 479.238220,565.187927 481.427917,566.258301 
	C476.905457,566.871277 473.405060,567.345703 469.510071,567.867554 
	C469.159912,564.328552 471.655884,566.071533 473.966217,565.947327 
	C475.094604,565.288391 475.567230,564.667419 476.039825,564.046448 
	C476.039825,564.046448 476.523773,564.057312 476.764984,564.077148 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M455.063812,609.107666 
	C455.596130,608.252075 456.132874,607.717834 456.934448,607.062744 
	C457.199249,606.941895 457.628937,606.966187 457.835388,607.026611 
	C459.685791,607.892944 461.329742,608.698792 463.217255,609.623962 
	C460.363434,610.039062 457.858734,610.403381 454.928497,610.672852 
	C454.691376,610.194946 454.879822,609.811951 455.063812,609.107666 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M634.455200,558.060059 
	C635.716003,560.866882 636.134949,563.593994 631.335938,563.973145 
	C632.030212,562.065002 633.119080,560.166748 634.455200,558.060059 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M444.346344,901.922668 
	C445.457886,899.616516 446.891815,897.211121 448.680054,894.904297 
	C447.579163,897.276367 446.123932,899.549866 444.346344,901.922668 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M443.616638,879.954224 
	C443.312805,881.631470 442.659943,883.277161 441.653625,885.370483 
	C440.846588,886.176453 440.392975,886.534790 439.684784,886.934937 
	C439.260742,887.005554 439.091248,887.034485 438.921692,887.063416 
	C440.370331,884.683167 441.818939,882.302917 443.616638,879.954224 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M565.004883,700.003906 
	C564.841309,700.856506 564.677734,701.709167 564.264648,702.776428 
	C562.498291,703.368530 560.981567,703.746033 558.984985,704.242859 
	C559.389771,703.384460 559.673218,702.783325 560.600342,702.077148 
	C561.911377,701.306763 562.578735,700.641418 563.535583,699.984924 
	C564.215088,699.994202 564.605103,699.994690 564.997559,699.997559 
	C565.000000,700.000000 565.004883,700.003906 565.004883,700.003906 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M451.199707,580.895081 
	C449.294830,581.622314 447.048370,582.245178 444.416473,582.925171 
	C443.631378,582.549988 443.231720,582.117737 442.832031,581.685486 
	C443.675171,581.474121 444.518280,581.262756 445.680328,581.019531 
	C447.618896,580.921997 449.238495,580.856384 451.199707,580.895081 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M447.685852,872.689819 
	C446.878693,874.908325 445.771057,877.341675 444.322083,879.872437 
	C445.115662,877.614807 446.250519,875.259644 447.685852,872.689819 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M510.457855,675.983459 
	C511.297882,673.559692 512.421082,670.945251 513.891846,668.221924 
	C513.073242,670.672913 511.907104,673.232849 510.457855,675.983459 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M436.723206,611.794434 
	C435.496796,612.156799 433.998047,612.332581 432.255646,612.260620 
	C433.491638,611.877930 434.971252,611.742920 436.723206,611.794434 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M439.343048,909.085205 
	C439.810364,907.548523 440.597290,905.848938 441.705078,904.086487 
	C441.238190,905.656494 440.450439,907.289429 439.343048,909.085205 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M562.699219,689.104370 
	C562.394714,690.409607 561.770630,691.854675 561.003174,693.614502 
	C560.394958,694.337280 559.930115,694.745361 559.223694,695.353271 
	C560.114624,693.450134 561.247131,691.347229 562.699219,689.104370 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M496.011169,553.224121 
	C498.797852,553.122192 501.569336,553.242004 504.649292,553.607910 
	C502.259521,553.910706 499.561279,553.967468 496.415039,553.956787 
	C495.967010,553.889465 495.996033,553.445862 496.011169,553.224121 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M420.552551,611.996704 
	C418.527374,612.256836 416.065460,612.433105 413.308411,612.371094 
	C415.380798,612.059509 417.748291,611.986206 420.552551,611.996704 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M562.876343,695.329346 
	C563.469849,694.697571 563.935364,694.356018 564.691162,694.018494 
	C564.999817,694.450806 565.018250,694.879089 565.005432,695.639160 
	C564.974121,695.970825 564.963806,695.957886 564.702393,696.135132 
	C563.624329,696.872437 562.807678,697.432556 561.994324,697.995728 
	C561.997620,697.998779 561.979614,697.986633 561.979614,697.986633 
	C562.235779,697.197632 562.492004,696.408630 562.876343,695.329346 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M455.346375,885.923584 
	C455.460693,884.773438 455.911499,883.514587 456.693848,882.135986 
	C456.577881,883.282471 456.130371,884.548767 455.346375,885.923584 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M486.989502,821.989197 
	C487.255249,820.876404 487.529694,819.772278 487.804138,818.668213 
	C488.196045,818.816162 488.587952,818.964172 488.979858,819.112122 
	C488.513458,819.977722 488.047058,820.843323 487.298950,821.860596 
	C487.017242,822.012268 486.998199,821.997925 486.989502,821.989197 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M449.313599,894.870178 
	C449.445526,893.754822 449.883942,892.475403 450.672180,891.112793 
	C450.554688,892.255066 450.087372,893.480591 449.313599,894.870178 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M563.246094,699.976074 
	C562.578735,700.641418 561.911377,701.306763 560.875366,701.997864 
	C560.810242,700.833008 561.113831,699.642456 561.698486,698.219238 
	C561.979614,697.986633 561.997620,697.998779 562.078979,698.292725 
	C562.522217,699.049744 562.884155,699.512939 563.246094,699.976074 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M502.463928,692.010620 
	C502.567657,691.168457 502.933899,690.153931 503.649292,689.073364 
	C503.574432,689.950989 503.150391,690.894714 502.463928,692.010620 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M492.277283,831.891479 
	C492.033356,831.332092 492.073120,830.626221 492.244385,829.585693 
	C492.739532,829.149414 493.103210,829.047913 493.735046,828.976074 
	C494.003204,829.005676 493.972809,828.981628 493.972809,828.981628 
	C493.502197,829.902771 493.031616,830.823975 492.277283,831.891479 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M451.403625,891.078369 
	C451.490845,890.074768 451.947357,889.135315 452.717590,888.100830 
	C452.611877,889.051392 452.192413,890.096985 451.403625,891.078369 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M629.593262,566.009277 
	C629.444763,566.848145 629.026184,567.850342 628.248169,568.894409 
	C628.366821,568.015015 628.845032,567.093811 629.593262,566.009277 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M442.358368,904.103821 
	C442.470490,903.295532 442.912964,902.569458 443.690735,901.932129 
	C443.580261,902.742493 443.134491,903.464233 442.358368,904.103821 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M507.378967,681.893921 
	C507.285400,681.419678 507.415192,680.763245 507.853088,680.104004 
	C507.974945,680.638000 507.788635,681.174805 507.378967,681.893921 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M482.823853,829.603333 
	C482.358368,830.011902 481.961243,830.108826 481.271027,830.112183 
	C481.421844,829.378174 481.865814,828.737732 482.623047,828.059448 
	C482.921631,828.445007 482.906921,828.868347 482.823853,829.603333 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M542.526917,606.952576 
	C542.777893,606.961548 543.028870,606.970459 543.656128,606.995789 
	C543.686035,607.711304 543.339600,608.410400 542.761353,609.291382 
	C542.377869,609.347717 542.226074,609.222229 541.937622,608.664551 
	C542.042969,607.805786 542.284973,607.379150 542.526917,606.952576 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M475.720459,564.079163 
	C475.567230,564.667419 475.094604,565.288391 474.305603,565.950439 
	C474.459839,565.364990 474.930481,564.738403 475.720459,564.079163 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M439.939331,886.893066 
	C440.392975,886.534790 440.846588,886.176453 441.571655,885.695068 
	C441.887177,886.119385 441.931274,886.666748 441.975037,887.624329 
	C441.534424,888.022095 441.094147,888.009521 440.315247,887.718750 
	C439.964203,887.257996 439.951752,887.075500 439.939331,886.893066 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M453.350281,888.118652 
	C453.461426,887.322998 453.909882,886.616028 454.695862,885.956665 
	C454.584808,886.738647 454.136200,887.472900 453.350281,888.118652 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M561.954102,715.563599 
	C561.730530,715.195679 561.733032,714.829651 561.762085,714.465820 
	C561.762878,714.455566 562.046265,714.467834 562.197937,714.469604 
	C562.193237,714.835083 562.188538,715.200439 561.954102,715.563599 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M566.597229,716.065308 
	C566.523315,716.689331 566.114624,717.383728 565.434265,718.232910 
	C565.529236,717.637024 565.895874,716.886353 566.597229,716.065308 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M405.653961,612.111816 
	C405.105560,612.417419 404.211639,612.634644 402.960938,612.748779 
	C403.505585,612.438293 404.407013,612.230896 405.653961,612.111816 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M496.315491,704.939575 
	C496.248932,704.277161 496.414703,703.362427 496.875244,702.245117 
	C496.962585,702.924072 496.755188,703.805603 496.315491,704.939575 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M497.385010,701.982666 
	C497.299805,701.552246 497.482056,700.963196 497.958435,700.239685 
	C498.052551,700.678223 497.852478,701.251099 497.385010,701.982666 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M439.684753,886.934875 
	C439.951752,887.075500 439.964203,887.257996 439.984680,887.714600 
	C439.550995,887.782593 439.109283,887.576477 438.794678,887.216919 
	C439.091248,887.034485 439.260742,887.005554 439.684753,886.934875 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M498.438843,699.998413 
	C498.361572,699.569153 498.539215,698.963440 499.017487,698.219727 
	C499.110016,698.661865 498.901886,699.241882 498.438843,699.998413 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M509.411255,677.995239 
	C509.317627,677.567505 509.481842,676.970642 509.936890,676.229736 
	C510.041504,676.665833 509.855316,677.246033 509.411255,677.995239 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M508.355408,679.958252 
	C508.279755,679.540344 508.460571,678.952576 508.927795,678.219849 
	C509.013458,678.646118 508.812683,679.217224 508.355408,679.958252 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M630.713745,564.112366 
	C630.764343,564.509766 630.566223,565.078552 630.091125,565.792847 
	C630.031128,565.386841 630.248047,564.835327 630.713745,564.112366 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M521.333374,653.930725 
	C521.279236,653.533630 521.461121,652.944885 521.923218,652.203979 
	C521.992126,652.614258 521.780762,653.176697 521.333374,653.930725 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M520.397217,655.971558 
	C520.302429,655.560425 520.454407,654.964722 520.880859,654.205261 
	C520.984924,654.623352 520.814453,655.205200 520.397217,655.971558 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M627.672852,569.070557 
	C627.703613,569.489929 627.475159,570.078735 626.939697,570.790649 
	C626.893066,570.355835 627.153381,569.797913 627.672852,569.070557 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M625.747803,572.141968 
	C625.778442,572.525513 625.572144,573.092712 625.093506,573.810303 
	C625.051147,573.415710 625.281006,572.870667 625.747803,572.141968 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M501.372437,694.126709 
	C501.295410,693.699829 501.466217,693.057739 501.942993,692.254639 
	C502.039429,692.699585 501.829834,693.305542 501.372437,694.126709 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M504.404907,688.285400 
	C504.287720,687.837036 504.426056,687.145813 504.855042,686.241943 
	C504.983948,686.700378 504.822174,687.371460 504.404907,688.285400 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M505.343445,685.942383 
	C505.273346,685.531799 505.453156,684.941956 505.911346,684.196167 
	C505.990906,684.614441 505.792145,685.188721 505.343445,685.942383 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M548.983887,519.987183 
	C548.862000,520.298218 548.670166,520.535767 548.478333,520.773315 
	C548.432556,520.507507 548.386780,520.241699 548.557373,519.871338 
	C548.773743,519.766846 548.913879,519.913696 548.983887,519.987183 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M636.656372,554.756470 
	C636.740662,554.938538 636.589539,555.297424 636.193848,555.822571 
	C636.106445,555.637024 636.263672,555.285156 636.656372,554.756470 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M623.920410,575.205322 
	C624.166870,575.242004 624.254150,575.463867 624.296570,575.694031 
	C624.302185,575.724121 624.078064,575.796631 623.759155,575.858643 
	C623.621033,575.709534 623.684143,575.551331 623.920410,575.205322 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M622.637695,577.626282 
	C622.718201,577.838318 622.528076,578.232788 622.043457,578.783264 
	C621.955017,578.562378 622.161072,578.185608 622.637695,577.626282 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M519.544250,657.190186 
	C519.414612,656.942078 519.552551,656.620544 519.946533,656.198792 
	C520.072266,656.437988 519.942017,656.777283 519.544250,657.190186 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M491.010162,825.009033 
	C490.904785,825.264709 490.799408,825.520325 490.694031,825.776001 
	C490.650635,825.557861 490.607269,825.339661 490.782227,825.061035 
	C491.000610,825.000549 491.010162,825.009033 491.010162,825.009033 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M490.997742,824.997131 
	C491.121094,824.987793 491.247345,824.981873 491.191895,824.992493 
	C491.010162,825.009033 491.000610,825.000549 490.997742,824.997131 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M494.016174,829.020264 
	C494.111633,828.982178 494.194122,828.929504 494.124695,828.929260 
	C493.972809,828.981628 494.003204,829.005676 494.016174,829.020264 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M435.897278,595.731262 
	C435.833771,595.895569 435.667633,595.927002 435.249664,595.927979 
	C435.263458,595.797791 435.529022,595.698059 435.897278,595.731262 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M443.637939,584.938232 
	C443.628235,585.045166 443.395447,585.170959 443.066193,585.151489 
	C442.969727,585.006165 443.414825,584.957092 443.637939,584.938232 
z"/>
<path fill="#1C0607" opacity="1.000000" stroke="none" 
	d="
M741.431091,402.027344 
	C742.564209,397.169403 743.909729,392.215240 745.636597,387.397766 
	C749.505310,376.604858 753.816406,365.963196 757.403625,355.080780 
	C758.610596,351.419312 758.403137,347.218536 758.445557,343.260040 
	C758.568115,331.825165 758.488892,320.388123 758.488892,308.951935 
	C758.988220,308.889587 759.487549,308.827240 759.986877,308.764862 
	C761.541687,312.008575 763.096558,315.252258 764.849731,319.096985 
	C765.608032,320.828369 766.168030,321.958740 766.706604,323.428833 
	C767.444153,324.521515 768.203125,325.274445 769.011963,326.429810 
	C768.336731,332.063202 772.807800,330.556824 775.150391,331.242004 
	C773.894714,332.231445 772.753906,333.130341 771.613098,334.029236 
	C772.410522,334.411224 773.208008,334.793213 773.996704,335.611145 
	C773.989258,336.337677 773.990601,336.628204 773.712830,337.080566 
	C773.934326,337.956726 774.434814,338.671021 775.014893,339.691895 
	C775.472107,340.756256 775.849731,341.514008 776.375977,342.614990 
	C777.979553,343.232941 779.444702,343.779907 780.887329,343.726013 
	C783.947021,343.611786 786.994934,343.184509 790.326172,343.075684 
	C788.395874,344.089600 786.115051,344.764832 784.003784,345.789398 
	C782.591614,346.474640 781.420288,347.656281 780.141846,348.806885 
	C780.419922,349.077911 780.697205,349.157867 781.307373,349.200500 
	C782.059326,349.016174 782.478394,348.869080 782.929565,349.139709 
	C782.980652,350.395538 782.999573,351.233643 783.106079,352.414429 
	C785.115540,352.783936 787.037354,352.810791 789.087219,353.095154 
	C789.468750,353.866852 789.722290,354.381012 789.615723,354.974701 
	C788.186401,355.693756 787.117249,356.333282 786.048157,356.972809 
	C786.223389,357.270294 786.398682,357.567780 786.573914,357.865265 
	C788.371948,357.390137 790.169983,356.914978 792.031616,356.890930 
	C792.120056,357.644623 792.144897,357.947296 791.950073,358.484192 
	C790.912537,359.247498 790.094788,359.776550 789.277039,360.305603 
	C789.818909,360.431885 790.360840,360.558167 791.009399,361.003601 
	C791.787781,363.305908 792.459534,365.289032 793.189819,367.644501 
	C794.252380,368.283325 795.266846,368.809662 796.258484,368.769531 
	C799.836121,368.624817 803.406006,368.289337 807.054138,368.460571 
	C807.105591,370.657196 807.081665,372.419128 806.730103,374.286865 
	C805.291199,374.647980 804.179993,374.903320 802.737671,375.098938 
	C801.532166,375.531189 800.657715,376.023132 799.783264,376.515045 
	C799.848816,376.708679 799.914429,376.902283 799.979980,377.095917 
	C801.324768,376.739197 802.669556,376.382477 804.469116,375.996643 
	C806.244568,375.935822 807.571472,375.953705 808.875977,375.791809 
	C808.999207,375.776550 808.982300,374.632019 809.029297,374.077789 
	C809.028198,374.143829 809.142212,374.240509 809.281860,374.929535 
	C810.731262,375.667816 812.042297,375.781647 813.350647,375.756134 
	C824.184631,375.544769 835.018188,375.307617 846.050293,375.356018 
	C846.100769,376.054565 845.952759,376.475342 845.804749,376.896118 
	C833.688110,374.899536 822.548462,379.009277 811.275635,382.368500 
	C806.847656,383.688049 802.353455,384.785400 797.501221,385.578308 
	C793.336914,380.012238 789.560852,374.851807 784.996155,368.613678 
	C779.904236,377.289368 775.345459,385.056671 770.786682,392.823944 
	C766.976624,394.227173 763.217163,395.793488 759.344666,396.995361 
	C753.543640,398.795685 747.663208,400.339966 741.431091,402.027344 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M634.772339,393.521729 
	C638.068176,383.996826 641.622498,374.552063 644.604492,364.929871 
	C650.299011,346.555298 655.667358,328.079651 661.073669,309.291748 
	C660.976257,308.937531 660.864929,308.998566 661.259460,308.858185 
	C661.775452,307.619537 661.896912,306.521271 661.922852,305.071228 
	C662.829346,301.288574 663.831360,297.857758 664.912354,294.060608 
	C666.213501,290.719452 667.435730,287.744629 668.869019,284.506805 
	C669.852356,283.394440 670.638672,282.557343 671.394592,281.693695 
	C675.824524,276.632690 679.691162,270.360474 688.130066,273.967438 
	C688.171875,273.985321 688.323730,273.745605 688.617615,273.396271 
	C688.097107,272.751160 687.539551,272.060120 686.981995,271.369019 
	C689.052002,268.984589 691.122070,266.600128 693.872559,263.431793 
	C685.437134,295.450592 677.286194,326.389343 668.776978,357.894165 
	C668.329712,359.026306 668.240723,359.592407 668.151733,360.158508 
	C668.151794,360.158508 668.114990,360.073151 667.809143,360.082886 
	C657.107422,372.126465 646.711548,384.160339 635.995056,395.956604 
	C635.373779,394.986603 635.073059,394.254150 634.772339,393.521729 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M656.534912,434.447571 
	C656.098511,433.296295 656.036499,432.047882 656.006775,430.112854 
	C656.039124,429.426239 656.008484,428.982452 655.987061,428.990662 
	C655.965698,428.998840 655.978699,429.044403 656.236694,428.997864 
	C656.628723,428.648071 656.762634,428.344757 657.224121,427.870972 
	C658.028809,426.116058 658.506042,424.531616 659.104736,422.692780 
	C659.335693,422.285706 659.445190,422.132996 659.820557,421.890472 
	C659.730347,420.210876 659.374329,418.621094 659.018311,417.031311 
	C659.018372,417.031311 659.003479,416.996277 659.349976,417.005005 
	C660.358948,417.003845 661.021423,416.993988 661.683899,416.984131 
	C662.437256,416.573151 663.621033,416.343414 663.881531,415.724884 
	C671.739136,397.065613 679.407288,378.326233 687.310303,359.686401 
	C698.456787,333.396240 709.764526,307.174469 721.017944,280.929718 
	C721.309204,280.250427 721.770081,279.643921 722.151917,279.003448 
	C722.723938,279.211029 723.295959,279.418610 723.867981,279.626160 
	C720.877625,288.537140 717.887329,297.448120 714.883789,306.742798 
	C709.518188,322.169128 704.278198,337.252869 698.788086,352.245087 
	C689.631165,377.250824 680.314148,402.197968 671.044800,427.528046 
	C666.319824,430.041016 661.614502,432.195740 656.534912,434.447571 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M661.011841,402.612244 
	C659.851685,409.621552 649.108398,412.531891 643.894287,410.486572 
	C642.843262,410.074249 641.691956,409.917511 640.362427,409.115753 
	C640.099304,408.382904 640.060608,408.177307 640.012207,407.986328 
	C640.002441,408.000885 639.985046,408.031433 640.340698,407.945740 
	C640.802673,407.224548 640.909058,406.589050 641.001892,405.972778 
	C640.988342,405.992065 640.956787,406.026978 641.295959,406.105896 
	C641.767151,405.776154 641.899231,405.367462 642.013977,404.977264 
	C641.996704,404.995758 641.960999,405.031525 642.285889,405.075989 
	C642.750610,404.738220 642.890320,404.356018 643.015686,403.987671 
	C643.001343,404.001495 642.973999,404.030609 643.266235,404.016052 
	C643.720154,403.657928 643.881897,403.314362 644.027222,402.989594 
	C644.010925,403.008392 643.981689,403.048767 644.296753,402.902924 
	C644.747070,402.157104 644.882385,401.557098 645.004761,400.975525 
	C644.991821,400.993927 644.961914,401.027557 645.299500,401.101013 
	C645.767700,400.771973 645.898254,400.369537 646.013672,399.982330 
	C645.998596,399.997559 645.967224,400.026947 646.312134,400.099335 
	C647.447449,399.107574 648.237915,398.043427 649.015015,396.991150 
	C649.001587,397.003021 648.977173,397.029205 649.275940,397.023743 
	C649.729065,396.671082 649.883423,396.323883 650.023865,395.991638 
	C650.009888,396.006561 649.989258,396.041901 650.328979,395.899719 
	C650.781860,395.164246 650.895020,394.570953 651.003235,393.987732 
	C650.998291,393.997833 650.980957,394.011963 651.293457,394.226379 
	C652.741882,392.957520 653.877808,391.474243 655.006836,389.995850 
	C654.999939,390.000671 654.989380,390.013977 655.292847,390.021423 
	C655.738953,389.680389 655.881592,389.331879 656.013184,388.992737 
	C656.002075,389.002106 655.983826,389.024628 656.271912,389.000366 
	C656.718445,388.644165 656.876831,388.312195 657.022156,387.992615 
	C657.009033,388.004944 656.992493,388.036987 657.330566,387.908691 
	C657.781982,387.180267 657.895264,386.580078 658.003662,385.989258 
	C657.998840,385.998596 657.982361,386.011780 658.306885,386.192139 
	C659.430298,385.241394 660.229248,384.110321 661.015930,382.991516 
	C661.003662,383.003723 660.984741,383.032684 661.361328,382.940308 
	C661.828857,382.217865 661.919678,381.587799 661.999756,380.975586 
	C661.988953,380.993408 661.960144,381.023529 662.323730,381.158661 
	C662.799316,380.851898 662.911377,380.410034 663.010742,379.982117 
	C662.997986,379.996094 662.969788,380.021240 663.305420,380.164429 
	C664.104248,379.531555 664.567444,378.755524 665.017578,377.990845 
	C665.004517,378.002167 664.983154,378.029358 665.263306,378.036987 
	C665.986450,377.707672 666.429443,377.370728 666.872437,377.033752 
	C665.866516,382.690552 664.860657,388.347321 663.453552,394.412170 
	C661.546387,395.974548 660.040405,397.128876 658.534424,398.283234 
	C659.062256,398.855347 659.590088,399.427460 660.117981,399.999573 
	C660.407288,400.763000 660.696655,401.526489 661.011841,402.612244 
M650.446228,406.114288 
	C650.428162,406.334320 650.410095,406.554352 650.392029,406.774384 
	C650.711365,406.654419 651.030640,406.534485 651.803406,406.208374 
	C652.084167,406.016022 652.304565,405.769623 652.855896,405.269806 
	C653.104797,405.112762 653.321289,404.919464 654.019470,404.344910 
	C654.585876,403.752197 655.152283,403.159485 656.145264,402.210205 
	C656.286865,401.845917 656.428467,401.481628 656.570129,401.117371 
	C656.011169,401.370605 655.452271,401.623871 654.360535,402.107880 
	C653.830627,402.662476 653.300659,403.217072 652.315491,403.969635 
	C652.030762,404.170868 651.851440,404.441833 651.317322,404.997681 
	C651.174744,405.293671 651.032166,405.589630 650.446228,406.114288 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M771.191895,392.968353 
	C775.345459,385.056671 779.904236,377.289368 784.996155,368.613678 
	C789.560852,374.851807 793.336914,380.012238 797.106079,385.522369 
	C793.892700,386.890930 790.712280,388.002838 787.473938,388.907562 
	C782.201599,390.380524 776.891418,391.718079 771.191895,392.968353 
z"/>
<path fill="#ECF5F6" opacity="1.000000" stroke="none" 
	d="
M831.021423,429.007965 
	C830.835083,429.013336 830.648804,429.018707 829.817871,429.188354 
	C825.116516,429.885437 821.059875,430.418274 816.972168,430.625702 
	C816.918518,430.083527 816.895935,429.866791 817.224548,429.463440 
	C821.679565,428.770172 825.783325,428.263519 829.887146,427.756836 
	C824.874817,427.203766 819.953430,427.473389 815.058472,427.992340 
	C807.365906,428.807861 799.691284,429.791443 791.544434,430.713470 
	C767.767883,432.624237 744.455872,434.525421 721.143860,436.426575 
	C720.858887,435.862457 720.573853,435.298309 720.558960,434.366943 
	C746.934204,431.942200 773.041931,429.916534 799.142639,427.802887 
	C805.051636,427.324402 810.935852,426.540070 817.283203,425.941711 
	C824.155029,425.323975 830.575073,424.661041 836.995117,423.998108 
	C840.090759,426.611237 838.562195,427.986298 834.900879,429.009552 
	C833.103149,429.020050 832.062256,429.014008 831.021423,429.007965 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M789.370911,331.744751 
	C798.354553,337.877350 807.359558,343.978882 816.315430,350.151794 
	C824.321289,355.669952 832.272888,361.266876 840.374023,367.233246 
	C835.989807,366.900635 831.480042,366.163879 826.967590,365.219391 
	C826.975647,364.731567 826.967529,364.452057 827.301636,364.100281 
	C827.384766,363.223419 827.273438,361.848480 826.800842,361.710449 
	C824.247925,360.964630 821.605347,360.525360 818.908447,359.607544 
	C816.845154,358.798004 814.867065,358.361359 812.976074,357.639709 
	C813.651611,356.603729 814.240173,355.852753 815.043823,354.917969 
	C814.419250,353.763641 813.579529,352.793121 812.590210,351.579102 
	C811.942566,351.051880 811.444397,350.768188 810.836304,350.183563 
	C806.429260,347.110443 802.312378,343.929504 796.802185,343.966064 
	C796.500244,343.968079 796.193481,343.234161 796.220703,342.859161 
	C797.406860,342.536377 798.261292,342.197266 799.397400,341.746307 
	C797.298950,340.600525 795.561523,339.651855 793.690186,338.576782 
	C793.316772,338.178772 793.077332,337.907257 792.709778,337.262482 
	C790.869080,336.127197 789.156433,335.365173 787.534912,334.373291 
	C787.751160,333.826538 787.776001,333.501923 788.021301,332.985535 
	C788.685059,332.449188 789.028015,332.096954 789.370911,331.744751 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M816.873352,429.650055 
	C816.895935,429.866791 816.918518,430.083527 816.696411,430.708801 
	C816.272034,431.185059 816.092407,431.252808 815.521118,431.318848 
	C809.387695,432.064606 803.651367,432.856415 797.903137,433.550507 
	C785.261230,435.077026 772.630920,436.728699 759.962952,438.001740 
	C747.624573,439.241638 735.237549,439.995544 722.894470,441.194977 
	C721.540100,441.326569 720.334290,442.987579 719.059631,443.939209 
	C719.059631,443.939240 718.982788,444.018494 718.943970,444.059326 
	C718.126160,443.951416 717.347229,443.802673 716.540283,443.648590 
	C717.170471,442.060059 717.711731,440.695770 718.625854,439.128113 
	C743.314392,436.560059 767.630066,434.195343 792.374329,431.864807 
	C800.826355,431.149323 808.849854,430.399689 816.873352,429.650055 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M826.970337,365.427124 
	C831.480042,366.163879 835.989807,366.900635 840.697083,367.345093 
	C842.177979,367.821075 843.461304,368.589417 844.874939,369.679840 
	C845.005249,370.001892 844.944214,370.070190 844.944214,370.070190 
	C843.257568,369.888275 841.570923,369.706329 839.884277,369.524384 
	C839.851929,369.737427 839.819580,369.950439 839.787231,370.163483 
	C842.475952,371.467590 845.164673,372.771729 847.597900,374.451050 
	C846.845581,374.910248 846.348694,374.994263 845.851868,375.078278 
	C835.018188,375.307617 824.184631,375.544769 813.350647,375.756134 
	C812.042297,375.781647 810.731262,375.667816 809.827637,374.909698 
	C814.714478,371.914825 808.555847,371.811676 809.817261,369.527954 
	C815.654541,369.527954 821.888245,369.527954 828.125305,369.909607 
	C828.644958,370.766510 829.161194,371.241791 829.677368,371.717041 
	C830.086304,370.955078 830.495178,370.193085 830.656128,369.177734 
	C825.264709,368.320190 820.121216,367.716034 814.901978,366.866455 
	C814.576477,366.409210 814.326782,366.197418 814.299316,365.724396 
	C815.434326,365.393616 816.347168,365.324066 817.651489,365.382080 
	C821.018738,365.482147 823.994568,365.454620 826.970337,365.427124 
M836.313232,371.366028 
	C836.101685,370.962708 835.935608,370.520386 835.646606,370.183533 
	C835.574219,370.099182 835.106567,370.353943 834.820557,370.452850 
	C835.158997,370.744019 835.497437,371.035187 836.313232,371.366028 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M816.831482,425.896484 
	C810.935852,426.540070 805.051636,427.324402 799.142639,427.802887 
	C773.041931,429.916534 746.934204,431.942200 720.397156,434.093750 
	C717.025818,431.519989 720.032898,431.566986 721.395203,431.434967 
	C726.669556,430.924042 731.965759,430.641052 737.252747,430.257812 
	C760.708496,428.557556 784.162659,426.835175 807.620972,425.170410 
	C810.428223,424.971191 813.256897,425.074677 816.512634,425.242218 
	C816.949585,425.446564 816.831482,425.896484 816.831482,425.896484 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M638.963989,409.535980 
	C638.354675,410.640991 637.745361,411.746002 636.372498,414.235687 
	C634.879822,411.879425 633.826416,410.216553 633.259460,408.299408 
	C634.526550,407.528442 635.482849,407.159149 636.059753,406.471497 
	C640.198792,401.537231 644.168518,396.458984 648.368408,391.578217 
	C654.894470,383.994141 661.560425,376.530457 668.167053,369.015717 
	C667.931580,370.307709 667.696106,371.599731 667.031006,373.419861 
	C666.061890,375.308411 665.522522,376.668884 664.983154,378.029358 
	C664.983154,378.029358 665.004517,378.002167 664.682495,377.834106 
	C663.896973,378.451111 663.433350,379.236176 662.969788,380.021240 
	C662.969788,380.021240 662.997986,379.996094 662.719360,380.001984 
	C662.280518,380.346436 662.120361,380.684998 661.960144,381.023529 
	C661.960144,381.023529 661.988953,380.993408 661.671997,381.099609 
	C661.231628,381.814758 661.108154,382.423706 660.984741,383.032684 
	C660.984741,383.032684 661.003662,383.003723 660.678955,382.870300 
	C659.563660,383.828491 658.773010,384.920135 657.982361,386.011780 
	C657.982361,386.011780 657.998840,385.998596 657.612061,386.075623 
	C657.147644,386.780762 657.070068,387.408875 656.992493,388.036987 
	C656.992493,388.036987 657.009033,388.004944 656.642578,387.819000 
	C656.178711,388.096924 656.081299,388.560760 655.983826,389.024628 
	C655.983826,389.024628 656.002075,389.002106 655.642517,388.842438 
	C655.185059,389.126495 655.087219,389.570221 654.989380,390.013977 
	C654.989380,390.013977 654.999939,390.000671 654.685181,389.805664 
	C653.240601,391.077728 652.110779,392.544830 650.980957,394.011963 
	C650.980957,394.011963 650.998291,393.997833 650.635742,394.081512 
	C650.178589,394.790771 650.083923,395.416321 649.989258,396.041901 
	C649.989258,396.041901 650.009888,396.006561 649.661926,395.852966 
	C649.201721,396.142670 649.089417,396.585938 648.977173,397.029205 
	C648.977173,397.029205 649.001587,397.003021 648.678101,396.866699 
	C647.558777,397.829224 646.763000,398.928101 645.967224,400.026947 
	C645.967224,400.026947 645.998596,399.997559 645.715942,399.995728 
	C645.276184,400.338440 645.119080,400.682983 644.961914,401.027557 
	C644.961914,401.027557 644.991821,400.993927 644.673645,401.090210 
	C644.230896,401.807220 644.106262,402.428009 643.981689,403.048767 
	C643.981689,403.048767 644.010925,403.008392 643.686829,402.923706 
	C643.233154,403.236206 643.103577,403.633392 642.973999,404.030609 
	C642.973999,404.030609 643.001343,404.001495 642.697388,403.934143 
	C642.249268,404.255035 642.105103,404.643280 641.960938,405.031525 
	C641.960999,405.031525 641.996704,404.995758 641.738892,405.035400 
	C641.306396,405.392334 641.131592,405.709656 640.956787,406.026978 
	C640.956787,406.026978 640.988342,405.992065 640.682861,406.108582 
	C640.246582,406.827179 640.115784,407.429321 639.985046,408.031433 
	C639.985046,408.031433 640.002441,408.000885 639.619995,408.016235 
	C639.146301,408.533081 639.055115,409.034515 638.963989,409.535980 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M898.960083,568.508301 
	C898.886108,568.714600 898.861450,568.926575 898.767334,569.676575 
	C898.057800,573.101318 897.467102,575.993713 896.645691,578.717896 
	C896.221741,578.012939 896.028564,577.476196 895.880249,576.496460 
	C896.917969,568.715576 897.910828,561.377747 899.091064,553.676575 
	C899.491028,552.350342 899.791138,551.397522 899.903320,550.423035 
	C902.338135,529.275940 904.752014,508.126404 907.169983,486.977356 
	C907.630249,486.719452 908.090576,486.461548 908.834961,486.543823 
	C907.916931,496.904785 906.672546,506.920746 905.524475,516.947754 
	C904.301758,527.626038 903.170044,538.314819 901.790405,549.340149 
	C900.708252,555.956970 899.834167,562.232605 898.960083,568.508301 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M901.896362,487.081268 
	C902.675232,487.056122 903.454041,487.030975 904.633667,487.427490 
	C903.544495,499.867279 902.027039,511.882141 900.573059,523.904663 
	C899.359680,533.938843 898.220520,543.981934 896.952881,554.402710 
	C896.174500,558.474792 895.491638,562.165222 894.400146,565.963379 
	C893.718689,566.141296 893.445862,566.211609 893.336060,565.813171 
	C894.888550,553.262939 896.278076,541.181458 897.780518,528.676636 
	C899.227783,514.529297 900.562073,500.805298 901.896362,487.081268 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M789.326050,331.739685 
	C789.028015,332.096954 788.685059,332.449188 787.621948,332.980652 
	C782.602905,333.831665 778.304199,334.503418 774.005432,335.175171 
	C773.208008,334.793213 772.410522,334.411224 771.613098,334.029236 
	C772.753906,333.130341 773.894714,332.231445 775.150391,331.242004 
	C772.807800,330.556824 768.336731,332.063202 769.382324,326.426056 
	C771.344727,326.462280 772.986450,326.904724 774.664856,327.357056 
	C776.907410,323.436310 774.559265,323.004669 771.654724,323.006378 
	C770.012512,323.007355 768.370300,323.059814 766.728088,323.089081 
	C766.168030,321.958740 765.608032,320.828369 765.090088,319.367004 
	C766.614624,318.936066 768.097107,318.836151 769.728577,318.473907 
	C775.144165,321.613068 780.410645,325.014557 785.732788,328.832031 
	C786.241455,329.457611 786.694641,329.667236 787.147766,329.876831 
	C787.147766,329.876831 787.040466,329.882690 787.078796,330.198914 
	C787.814636,330.940063 788.512146,331.364990 789.209656,331.789917 
	C789.209656,331.789917 789.281128,331.734650 789.326050,331.739685 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M636.315674,396.194183 
	C646.711548,384.160339 657.107422,372.126465 667.855225,360.088745 
	C668.160767,360.781677 668.114380,361.478485 667.934265,362.880920 
	C666.179993,365.891479 664.684204,368.297119 662.917969,370.484375 
	C653.826904,381.742218 644.666443,392.944000 635.530151,404.165344 
	C634.633728,404.106049 633.737244,404.046722 632.531067,403.662903 
	C633.586121,400.956970 634.950928,398.575592 636.315674,396.194183 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M897.739319,488.152466 
	C898.241943,488.111420 898.744507,488.070404 899.636841,488.466858 
	C899.793640,491.378540 899.579285,493.854706 899.324585,496.326660 
	C898.203430,507.208130 897.070679,518.088440 895.857788,529.372192 
	C894.458374,538.177856 893.143188,546.580383 891.541809,554.989197 
	C890.873962,554.994263 890.492249,554.992981 890.116577,554.581726 
	C890.767029,548.451233 891.411560,542.730713 892.306641,536.696655 
	C894.284546,520.306152 896.011902,504.229279 897.739319,488.152466 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M883.319763,569.581299 
	C883.382935,569.258057 883.407898,568.931885 883.391846,568.359009 
	C883.388977,568.115234 883.449646,567.731750 883.587036,567.351074 
	C883.893250,566.671692 883.953552,566.352600 883.905457,566.013000 
	C883.359863,561.341736 882.811035,556.670776 882.272217,551.998718 
	C882.196228,551.339844 882.056396,550.661499 882.135437,550.015320 
	C884.306152,532.268005 886.501465,514.523682 888.830078,496.390320 
	C889.400024,496.003235 889.832642,496.004761 890.629517,496.411896 
	C890.736877,498.614136 890.461853,500.408417 890.225769,502.207794 
	C888.190796,517.720947 886.111938,533.228760 884.229126,548.760376 
	C884.078308,550.004578 885.293884,551.414429 885.993591,552.995361 
	C886.375793,553.493042 886.638916,553.742615 886.924438,554.413696 
	C886.221985,559.819580 885.497070,564.803833 884.524597,569.789001 
	C883.943848,569.784668 883.624817,569.715149 883.319763,569.581299 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M897.048401,554.021118 
	C898.220520,543.981934 899.359680,533.938843 900.573059,523.904663 
	C902.027039,511.882141 903.544495,499.867279 905.023315,487.427307 
	C905.635803,486.827057 906.259521,486.648590 907.026611,486.723724 
	C904.752014,508.126404 902.338135,529.275940 899.903320,550.423035 
	C899.791138,551.397522 899.491028,552.350342 898.782227,553.660645 
	C897.873535,554.012390 897.460999,554.016785 897.048401,554.021118 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M886.902100,553.992188 
	C886.638916,553.742615 886.375793,553.493042 886.009644,552.590698 
	C888.287659,530.628784 890.668701,509.319611 893.049744,488.010406 
	C893.849243,488.077972 894.648743,488.145538 895.698303,488.541748 
	C894.026672,504.935547 892.104980,521.000671 890.114746,537.473022 
	C888.998108,543.250793 887.950134,548.621521 886.902100,553.992188 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M791.945801,431.830627 
	C767.630066,434.195343 743.314392,436.560059 718.589111,438.819153 
	C718.993713,438.043976 719.807983,437.374390 720.883057,436.565674 
	C744.455872,434.525421 767.767883,432.624237 791.528992,430.996216 
	C791.967346,431.456451 791.956604,431.643524 791.945801,431.830627 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M901.998596,548.999023 
	C903.170044,538.314819 904.301758,527.626038 905.524475,516.947754 
	C906.672546,506.920746 907.916931,496.904785 909.079041,486.443604 
	C909.510071,485.096161 909.981140,484.189056 910.735596,483.142395 
	C910.019592,492.699646 909.019287,502.396393 908.021057,512.093323 
	C906.714294,524.786926 905.409302,537.480652 904.103027,550.593750 
	C904.102661,551.013123 903.702637,551.134827 903.392212,550.878906 
	C902.720703,550.081604 902.359680,549.540344 901.998596,548.999023 
z"/>
<path fill="#1E0505" opacity="1.000000" stroke="none" 
	d="
M686.697021,271.164917 
	C687.539551,272.060120 688.097107,272.751160 688.617615,273.396271 
	C688.323730,273.745605 688.171875,273.985321 688.130066,273.967438 
	C679.691162,270.360474 675.824524,276.632690 671.394592,281.693695 
	C670.638672,282.557343 669.852356,283.394440 668.649170,284.438599 
	C667.816467,284.427032 667.414673,284.220734 667.006226,283.624084 
	C667.583679,281.835022 668.027100,280.355347 668.777100,279.052063 
	C671.495239,274.328339 674.316223,269.663727 677.098389,264.976837 
	C677.930481,264.960388 678.762512,264.943939 680.008911,264.887634 
	C682.419556,266.885468 684.415771,268.923126 686.697021,271.164917 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M635.363342,404.468048 
	C644.666443,392.944000 653.826904,381.742218 662.917969,370.484375 
	C664.684204,368.297119 666.179993,365.891479 667.940674,363.267517 
	C668.262695,364.791840 668.444580,366.635254 668.396729,368.747192 
	C661.560425,376.530457 654.894470,383.994141 648.368408,391.578217 
	C644.168518,396.458984 640.198792,401.537231 636.059753,406.471497 
	C635.482849,407.159149 634.526550,407.528442 633.389771,407.998444 
	C633.754639,406.891418 634.475586,405.831055 635.363342,404.468048 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M892.704529,488.167114 
	C890.668701,509.319611 888.287659,530.628784 885.890564,552.342651 
	C885.293884,551.414429 884.078308,550.004578 884.229126,548.760376 
	C886.111938,533.228760 888.190796,517.720947 890.225769,502.207794 
	C890.461853,500.408417 890.736877,498.614136 890.953430,496.391296 
	C891.395142,493.418030 891.877197,490.870941 892.704529,488.167114 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M890.183289,537.065857 
	C892.104980,521.000671 894.026672,504.935547 895.932129,488.434753 
	C896.393616,487.976929 896.871399,487.954742 897.544250,488.042480 
	C896.011902,504.229279 894.284546,520.306152 891.994019,536.702515 
	C891.015015,537.036621 890.599121,537.051208 890.183289,537.065857 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M895.942078,528.969116 
	C897.070679,518.088440 898.203430,507.208130 899.324585,496.326660 
	C899.579285,493.854706 899.793640,491.378540 900.015137,488.458832 
	C900.447510,487.697815 900.891235,487.382263 901.615723,487.074005 
	C900.562073,500.805298 899.227783,514.529297 897.494141,528.647705 
	C896.710571,529.017700 896.326355,528.993408 895.942078,528.969116 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M846.050293,375.356018 
	C846.348694,374.994263 846.845581,374.910248 847.717896,374.772125 
	C852.228699,375.548462 856.363953,376.378937 860.499268,377.209381 
	C860.415833,377.776093 860.332397,378.342773 860.248962,378.909454 
	C855.703796,378.329559 851.158569,377.749634 846.209045,377.032928 
	C845.952759,376.475342 846.100769,376.054565 846.050293,375.356018 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M817.283203,425.941711 
	C816.831482,425.896484 816.949585,425.446564 816.977905,425.215637 
	C823.373596,424.320190 829.741089,423.655670 836.559143,422.996185 
	C837.009766,423.001221 837.011414,423.499817 837.003296,423.748962 
	C830.575073,424.661041 824.155029,425.323975 817.283203,425.941711 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M660.470459,399.921814 
	C659.590088,399.427460 659.062256,398.855347 658.534424,398.283234 
	C660.040405,397.128876 661.546387,395.974548 663.406494,394.817383 
	C662.781433,396.491089 661.802185,398.167603 660.470459,399.921814 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M665.263306,378.036987 
	C665.522522,376.668884 666.061890,375.308411 666.885559,373.787170 
	C667.219299,374.556824 667.268799,375.487274 667.095337,376.725769 
	C666.429443,377.370728 665.986450,377.707672 665.263306,378.036987 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M789.211548,331.472900 
	C788.512146,331.364990 787.814636,330.940063 787.119507,330.163879 
	C787.819092,330.260376 788.516296,330.708130 789.211548,331.472900 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M661.712280,416.703918 
	C661.021423,416.993988 660.358948,417.003845 659.365234,417.016876 
	C659.596863,416.630890 660.159729,416.241791 661.050659,415.938538 
	C661.378723,416.024384 661.391296,415.981750 661.391296,415.981750 
	C661.507751,416.129089 661.624207,416.276398 661.712280,416.703918 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M702.254089,420.635223 
	C701.738281,420.469116 701.365356,419.971100 700.970581,419.140808 
	C701.431519,419.306793 701.914246,419.805054 702.254089,420.635223 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M787.052673,329.648010 
	C786.694641,329.667236 786.241455,329.457611 785.951416,329.088623 
	C786.456055,328.988617 786.737061,329.151917 787.052673,329.648010 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M668.518494,360.198822 
	C668.240723,359.592407 668.329712,359.026306 668.668762,358.281616 
	C668.907715,358.815033 668.896484,359.527069 668.518494,360.198822 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M845.039185,369.971252 
	C845.176025,370.104919 845.278809,370.269287 845.381592,370.433594 
	C845.284668,370.396881 845.187744,370.360168 845.017456,370.196808 
	C844.944214,370.070190 845.005249,370.001892 845.039185,369.971252 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M841.000793,423.000824 
	C841.081482,422.966797 841.161377,422.931946 841.119385,422.947937 
	C840.997559,422.998779 841.000000,423.000000 841.000793,423.000824 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M661.495422,415.836060 
	C661.391296,415.981750 661.378723,416.024384 661.393433,416.040771 
	C661.527161,415.922729 661.594299,415.766052 661.582458,415.461121 
	C661.555237,415.335052 661.599548,415.690399 661.495422,415.836060 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M746.381104,930.856384 
	C746.011963,930.083069 746.114197,929.353149 746.200378,928.254944 
	C746.588196,927.933533 746.991943,927.980347 747.701416,928.015747 
	C781.694763,927.968933 815.382446,927.933411 849.535522,927.959351 
	C850.014893,928.642883 850.028809,929.264893 850.061584,930.195740 
	C848.429077,930.623901 846.777893,930.845764 845.126465,930.847412 
	C812.368408,930.880127 779.610413,930.887329 746.381104,930.856384 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M787.779968,923.444580 
	C787.785645,923.867371 787.618469,923.930969 787.202637,924.095215 
	C786.578308,922.513733 785.891846,920.834229 785.877136,919.148865 
	C785.774536,907.410461 785.811279,895.670898 785.856567,883.138550 
	C785.854736,881.898926 785.799744,881.452454 785.744751,881.005920 
	C785.772400,880.838684 785.800110,880.671448 785.909790,880.263550 
	C790.873657,880.428772 795.755554,880.834534 800.900635,881.588806 
	C799.401489,881.958984 797.639038,881.980713 795.003540,882.002502 
	C791.383484,882.256104 787.442444,880.678528 788.000000,886.000000 
	C787.999573,893.329468 788.046875,900.659363 787.982117,907.988281 
	C787.937683,913.021362 787.737610,918.053040 787.779968,923.444580 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M785.549316,649.316467 
	C785.805786,651.712219 785.838562,654.419800 785.740479,657.559631 
	C785.514893,655.204041 785.420227,652.416138 785.549316,649.316467 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M785.575562,881.171387 
	C785.799744,881.452454 785.854736,881.898926 785.809082,882.672607 
	C785.607727,882.445435 785.507019,881.891113 785.575562,881.171387 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M705.060791,105.671616 
	C697.949402,101.186737 690.299133,98.689926 680.944824,100.690224 
	C678.538818,101.065369 676.830200,101.064301 675.062500,100.660477 
	C674.980408,99.987343 674.957275,99.716957 674.934082,99.446571 
	C685.560547,94.270805 698.744995,96.836777 705.060791,105.671616 
z"/>
<path fill="#46382E" opacity="1.000000" stroke="none" 
	d="
M689.991089,115.573074 
	C692.947571,115.831200 695.936401,116.485847 699.265991,117.409851 
	C699.606750,117.679207 699.894287,117.916382 699.894287,117.916382 
	C699.894287,117.916382 699.991638,117.981598 699.584534,118.107750 
	C697.432312,118.541382 695.687195,118.848862 693.942078,119.156342 
	C689.334839,119.918381 684.727539,120.680412 679.556030,121.257874 
	C678.331909,120.720047 677.672119,120.366798 676.979126,119.978912 
	C676.945923,119.944283 676.879822,119.874748 676.879822,119.874748 
	C678.378662,115.606155 680.408325,113.904762 684.673950,117.399513 
	C686.053833,118.530037 689.059265,119.478493 689.991089,115.573074 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M693.126953,180.553436 
	C696.479675,180.874588 699.810425,181.570816 703.545166,182.170685 
	C705.639282,182.917206 707.329468,183.760056 708.985779,184.586029 
	C701.893677,189.659668 695.462708,188.204742 693.126953,180.553436 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M674.551025,99.552689 
	C674.957275,99.716957 674.980408,99.987343 675.267883,100.908722 
	C676.709229,102.054703 677.886108,102.549675 679.063049,103.044647 
	C672.612610,105.025406 666.162170,107.006172 659.351074,109.009155 
	C658.857361,108.630424 658.724243,108.229477 658.665649,107.490089 
	C658.740295,107.151657 659.011841,106.760582 659.083130,106.534027 
	C664.158936,104.091255 669.163452,101.875031 674.551025,99.552689 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M700.428528,195.298340 
	C697.235413,195.600677 694.475830,195.884552 691.436401,196.197220 
	C693.361755,193.064453 703.955627,191.603592 707.606750,194.313522 
	C704.888489,194.702988 702.875305,194.991425 700.428528,195.298340 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M718.463501,153.744873 
	C719.462097,154.305359 720.075928,154.944092 720.877258,155.862091 
	C716.707581,156.997910 712.361084,157.919327 707.980896,158.635147 
	C707.383118,158.732849 706.617615,157.804428 705.969971,157.001678 
	C710.033081,155.709198 714.055908,154.766144 718.463501,153.744873 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M694.089966,119.521988 
	C695.687195,118.848862 697.432312,118.541382 699.530884,118.084724 
	C701.968811,118.313591 704.053345,118.691643 706.555542,119.066467 
	C707.694885,119.324326 708.416443,119.585396 709.138000,119.846474 
	C709.087769,120.301308 709.037476,120.756142 708.987183,121.210976 
	C704.070801,120.769859 699.154358,120.328743 694.089966,119.521988 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M673.550171,127.065460 
	C671.123047,127.634537 668.896912,128.194687 666.670837,128.754852 
	C666.434265,128.354004 666.197693,127.953171 665.961121,127.552330 
	C668.695068,125.642822 671.428955,123.733307 674.566528,122.223511 
	C675.055969,123.505333 675.141785,124.387444 675.227539,125.269554 
	C675.227539,125.269554 675.171509,125.147896 674.953003,125.255142 
	C674.529236,125.702896 674.324097,126.043411 674.118958,126.383926 
	C674.139771,126.686546 674.017151,126.910751 673.550171,127.065460 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M716.867493,210.256744 
	C718.075806,209.107056 719.102417,208.223969 720.129089,207.340881 
	C720.514709,207.734177 720.900391,208.127457 721.286011,208.520737 
	C719.682434,209.978531 718.078796,211.436325 716.104553,212.898773 
	C716.051208,212.110062 716.368530,211.316696 716.867493,210.256744 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M723.077820,182.219757 
	C724.646301,181.748077 726.144287,181.531509 727.642334,181.314957 
	C727.696289,181.976501 727.750244,182.638062 727.804199,183.299606 
	C726.428833,183.299606 725.053406,183.299606 723.342834,183.151917 
	C723.007568,182.827774 723.007446,182.651321 723.077820,182.219757 
z"/>
<path fill="#46382E" opacity="1.000000" stroke="none" 
	d="
M675.505615,125.155663 
	C675.141785,124.387444 675.055969,123.505333 674.930054,122.177589 
	C675.354736,121.152275 675.819580,120.572594 676.582153,119.933830 
	C676.879822,119.874748 676.945923,119.944283 677.005981,120.297104 
	C677.378540,121.439674 677.691040,122.229424 678.003540,123.019180 
	C677.263550,123.693375 676.523560,124.367577 675.505615,125.155663 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M678.242432,122.964241 
	C677.691040,122.229424 677.378540,121.439674 677.039185,120.331741 
	C677.672119,120.366798 678.331909,120.720047 679.168335,121.419266 
	C679.057007,122.146584 678.769104,122.527946 678.242432,122.964241 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M721.869385,135.120682 
	C721.362305,134.172104 721.067078,133.047897 720.771851,131.923691 
	C721.031799,131.851974 721.291748,131.780273 721.551758,131.708557 
	C721.833191,132.461349 722.114563,133.214127 722.453308,134.283936 
	C722.367493,134.715668 722.224304,134.830368 721.869385,135.120682 
z"/>
<path fill="#46382E" opacity="1.000000" stroke="none" 
	d="
M674.388245,126.249107 
	C674.324097,126.043411 674.529236,125.702896 675.019836,125.251175 
	C675.089294,125.464737 674.873413,125.789513 674.388245,126.249107 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M706.813477,106.933899 
	C706.796875,107.082832 706.495667,107.189926 706.076904,107.134705 
	C706.149109,106.945610 706.338989,106.918831 706.813477,106.933899 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M705.986572,106.753395 
	C705.755188,106.839256 705.473694,106.641510 705.082764,106.207306 
	C705.294312,106.137161 705.615479,106.303474 705.986572,106.753395 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M447.610443,501.851196 
	C446.529877,502.413574 445.037170,502.873810 442.798767,503.559692 
	C438.723328,503.770325 435.393616,503.755249 432.063873,503.740173 
	C430.296753,503.555847 428.529663,503.371552 426.184814,502.905579 
	C424.744171,502.735992 423.881226,502.848114 423.018311,502.960205 
	C422.555939,502.996185 422.093536,503.032104 420.842010,503.047302 
	C416.705078,503.032166 413.357269,503.037750 410.009491,503.043365 
	C409.561462,502.982208 409.113464,502.921021 408.334839,502.941742 
	C396.162628,502.668335 384.320953,502.313019 372.176910,501.652039 
	C371.909637,500.849487 371.944733,500.352631 371.991913,499.482330 
	C384.385315,499.733368 396.762054,500.486237 409.149811,500.932159 
	C416.118195,501.183044 423.104767,500.927307 430.815308,501.211700 
	C433.364197,501.413452 435.180878,501.303314 436.997589,501.193176 
	C440.397827,501.378479 443.798096,501.563782 447.610443,501.851196 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M320.647858,486.635986 
	C316.475555,486.932709 311.986206,486.907593 307.500610,487.024292 
	C303.405121,487.130829 301.472107,485.674255 301.719910,481.159637 
	C302.084137,474.524231 301.848969,467.855896 301.902130,460.757263 
	C306.644440,460.528931 311.353760,460.744965 316.509766,461.064667 
	C313.884186,461.446564 310.805756,462.000702 307.741089,461.932922 
	C303.944672,461.848938 302.823273,463.584503 302.961029,467.073364 
	C303.130646,471.367432 303.174530,475.682159 302.953918,479.971558 
	C302.725952,484.403809 305.136047,485.835876 308.973999,485.987976 
	C312.759338,486.138031 316.546234,486.248230 320.647858,486.635986 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M267.062592,484.408234 
	C266.318024,484.834259 265.454224,484.826050 264.317505,484.644043 
	C264.044586,476.144806 264.044586,467.819366 264.044586,459.493896 
	C264.607178,459.480377 265.169800,459.466858 265.732391,459.453308 
	C266.136047,467.626892 266.539703,475.800446 267.062592,484.408234 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M285.687500,461.070251 
	C286.097900,469.235321 286.097900,477.433136 286.097900,485.630920 
	C285.616974,485.686157 285.136078,485.741364 284.655151,485.796600 
	C284.431458,484.246490 284.087128,482.702698 284.005585,481.145142 
	C283.702698,475.359802 283.489563,469.569794 283.206085,463.783295 
	C283.201813,463.696167 282.639496,463.494507 282.495026,463.588409 
	C282.261688,463.740173 282.159271,464.093384 281.703613,464.692017 
	C281.405670,465.022491 281.038269,464.972015 281.038269,464.972015 
	C281.166931,463.266083 281.295624,461.560150 281.471710,459.225861 
	C283.152496,460.054932 284.214783,460.578949 285.687500,461.070251 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M280.771057,464.987579 
	C281.038269,464.972015 281.405670,465.022491 281.590637,465.035950 
	C281.809845,469.392273 281.844147,473.735138 281.623169,478.838196 
	C281.211609,481.564270 281.055267,483.530151 280.898956,485.496033 
	C280.333191,484.387451 279.314545,483.293365 279.278442,482.167786 
	C279.103302,476.709686 279.191742,471.243134 279.318390,465.315063 
	C279.801270,464.901398 280.152557,464.952271 280.771057,464.987579 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M294.787628,460.173492 
	C295.157837,468.638489 295.157837,477.132660 295.157837,485.626831 
	C294.465790,485.633972 293.773773,485.641144 293.081726,485.648285 
	C293.081726,477.421692 293.081726,469.195099 293.014587,460.506653 
	C293.437469,460.097443 293.927429,460.150055 294.787628,460.173492 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M290.710022,460.034424 
	C291.042664,468.545227 291.042664,477.057739 291.042664,485.570221 
	C290.200745,485.560760 289.358856,485.551270 288.516937,485.541809 
	C288.886749,477.377380 289.256592,469.212952 289.683655,460.639099 
	C289.953064,460.165192 290.165222,460.100647 290.710022,460.034424 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M270.889954,484.598206 
	C270.124634,485.189697 269.245148,485.319855 268.176758,485.231750 
	C269.058502,477.169586 270.129120,469.325745 270.775787,464.587769 
	C270.775787,468.969238 270.775787,476.553040 270.889954,484.598206 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M255.013504,482.625092 
	C254.970917,483.099060 254.648529,483.384430 254.471634,483.262177 
	C253.900925,479.645050 253.344940,476.158844 253.157715,472.652924 
	C252.959854,468.947815 253.113312,465.223938 253.113312,461.508240 
	C253.760910,461.495392 254.408493,461.482513 255.056091,461.469666 
	C255.056091,468.363495 255.056091,475.257294 255.013504,482.625092 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M260.691650,483.765381 
	C260.275208,484.098816 259.778137,483.981781 258.939148,483.678955 
	C258.597198,475.614990 258.597198,467.736725 258.597198,459.858490 
	C259.268463,459.849335 259.939758,459.840210 260.611023,459.831055 
	C260.611023,467.659027 260.611023,475.486969 260.691650,483.765381 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M277.010376,471.981140 
	C276.655609,476.388824 276.300842,480.796539 275.526031,484.617615 
	C275.028748,479.366425 274.951538,474.701843 274.970337,469.780212 
	C275.117004,469.347321 275.167664,469.171509 275.218323,468.995667 
	C275.490662,469.148010 275.763031,469.300385 276.361786,469.789978 
	C276.688141,470.127197 276.702271,470.135315 276.543213,470.488983 
	C276.592896,471.222137 276.801636,471.601654 277.010376,471.981140 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M274.874268,470.037292 
	C274.951538,474.701843 275.028748,479.366425 275.081177,484.493225 
	C274.575989,484.724335 274.095581,484.493225 273.359680,484.181061 
	C273.065216,480.041382 273.026245,475.982758 273.276550,471.342590 
	C274.002014,470.519775 274.438141,470.278534 274.874268,470.037292 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M298.924377,460.237701 
	C299.284454,468.660034 299.284454,477.021179 299.284454,485.382294 
	C298.792145,485.386017 298.299835,485.389740 297.807526,485.393433 
	C297.807526,477.282532 297.807526,469.171600 297.821045,460.601624 
	C298.077820,460.153900 298.321075,460.165192 298.924377,460.237701 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M274.970337,469.780182 
	C274.438141,470.278534 274.002014,470.519775 273.347931,470.883453 
	C273.130005,467.201172 273.130005,463.396484 273.130005,459.591797 
	C273.781250,459.573853 274.432526,459.555878 275.083771,459.537933 
	C275.083771,462.393860 275.083771,465.249786 275.151062,468.550690 
	C275.167664,469.171509 275.117004,469.347321 274.970337,469.780182 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M279.223206,462.603729 
	C278.745880,463.084381 278.392456,463.158173 277.766113,463.231476 
	C277.729279,462.157867 277.965332,461.084778 278.201355,460.011658 
	C278.583282,460.740051 278.965179,461.468475 279.223206,462.603729 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M672.694458,107.808006 
	C680.605713,103.385933 688.342896,105.772507 696.034119,109.178062 
	C691.072998,108.816437 686.111938,108.454819 681.101746,108.528282 
	C680.320312,109.029205 679.587952,109.095039 678.079590,109.098419 
	C675.767151,108.626640 674.230835,108.217323 672.694458,107.808006 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M682.934814,109.512878 
	C687.236328,110.159149 691.493713,111.108070 695.751099,112.056992 
	C695.724121,112.457191 695.697144,112.857391 695.670105,113.257591 
	C692.907410,113.257591 690.144775,113.257591 687.258423,113.592796 
	C684.709045,113.600487 680.450806,115.164177 682.934814,109.512878 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M672.367676,107.906868 
	C674.230835,108.217323 675.767151,108.626640 677.709717,109.078232 
	C678.392639,110.317123 678.669495,111.513756 678.903076,112.523422 
	C675.203552,112.338074 671.338440,112.144432 666.995483,111.857315 
	C668.358704,110.511131 670.199768,109.258430 672.367676,107.906868 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M664.835449,112.763290 
	C664.825989,112.499931 665.112427,112.238213 665.699463,111.988396 
	C665.710510,112.255165 665.421021,112.510040 664.835449,112.763290 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M67.939484,777.020630 
	C68.000778,775.292114 68.062073,773.563599 68.131393,770.949524 
	C68.158501,767.905762 68.192223,765.747620 68.194489,763.589417 
	C68.236740,723.411560 68.275246,683.233765 68.615616,643.049438 
	C69.518196,643.046692 70.119720,643.050476 70.772705,643.516541 
	C70.795227,688.802673 70.772049,733.626526 70.735413,778.450317 
	C70.708313,811.607544 70.663704,844.764832 70.348633,877.933289 
	C69.515305,877.932129 68.960236,877.919800 68.335480,877.450256 
	C68.167206,863.329224 68.068626,849.665405 67.970032,836.001526 
	C68.027611,834.604492 68.085182,833.207397 68.146019,831.033203 
	C68.085831,829.508850 68.022369,828.761719 67.958916,828.014587 
	C68.018478,827.255981 68.078026,826.497375 68.143280,824.921021 
	C68.081253,821.072571 68.013512,818.041931 67.945770,815.011230 
	C68.006233,806.642822 68.066681,798.274475 68.128166,788.992188 
	C68.065956,784.392395 68.002724,780.706482 67.939484,777.020630 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M70.721237,643.054199 
	C70.119720,643.050476 69.518196,643.046692 68.491020,642.799194 
	C67.995560,642.382751 67.925766,642.210022 67.855972,642.037354 
	C67.902077,639.647705 67.948189,637.258057 67.977943,634.184204 
	C67.961594,633.499939 67.855202,633.020264 67.855209,633.020264 
	C67.891998,627.976562 67.928787,622.932800 67.971985,616.962830 
	C67.981361,585.026001 67.984314,554.015442 67.987274,523.004883 
	C75.185349,521.318542 82.327225,519.321899 89.592430,518.006287 
	C108.708946,514.544678 127.879082,511.379120 147.419647,508.007751 
	C147.897583,508.100922 147.983734,508.281952 148.025772,508.760498 
	C145.761948,509.825104 143.542236,510.592224 140.844513,511.601501 
	C140.366501,511.843689 139.999344,511.966919 139.640900,511.917236 
	C138.169388,512.208984 137.056320,512.550415 135.938538,512.881470 
	C135.933823,512.871155 135.917099,512.855591 135.527908,512.810059 
	C134.111206,512.918701 133.083694,513.072815 131.736938,513.236206 
	C130.611389,513.494080 129.805084,513.742737 128.637268,513.944336 
	C127.157265,514.225281 126.038773,514.553284 124.972214,514.894592 
	C125.024147,514.907898 124.932854,514.851135 124.537308,514.802551 
	C122.609276,515.105042 121.076782,515.456055 119.223274,515.777954 
	C118.269630,515.862549 117.637009,515.976318 116.617447,516.050903 
	C113.781540,516.496094 111.332581,516.980530 108.488388,517.522034 
	C106.365608,518.020081 104.638077,518.461060 102.948769,518.891968 
	C102.987000,518.881958 102.929459,518.827820 102.597969,518.782349 
	C101.842667,518.852173 101.418861,518.967468 100.654472,519.082397 
	C99.867065,519.156860 99.420242,519.231750 98.705917,519.283752 
	C98.156143,519.466553 97.873863,519.672180 97.434082,519.861938 
	C97.276588,519.846130 97.016708,520.026978 96.595055,519.991333 
	C88.949547,521.257385 81.725693,522.558960 74.409698,523.765015 
	C74.317566,523.669556 74.072968,523.772644 73.703156,523.729614 
	C72.602669,523.811462 71.872002,523.936340 70.964180,524.095215 
	C70.694809,524.203552 70.516808,524.290710 70.525688,524.349915 
	C70.558243,524.567322 70.652855,524.775391 70.787308,525.452271 
	C70.875412,548.684021 70.932205,571.450562 70.914497,594.217041 
	C70.901825,610.496155 70.789238,626.775146 70.721237,643.054199 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M67.640488,836.006042 
	C68.068626,849.665405 68.167206,863.329224 68.215698,877.857788 
	C68.084068,880.130859 68.002533,881.539246 67.555550,882.978027 
	C66.456566,883.004272 65.723030,883.000122 64.992043,882.527466 
	C64.996422,873.128662 64.892395,864.196045 65.071808,855.269226 
	C65.118813,852.930481 65.917557,850.571167 66.600471,848.289917 
	C67.481735,845.346008 66.815521,843.758179 63.473995,844.005310 
	C63.404324,842.273987 63.334652,840.542664 63.368538,838.387695 
	C64.751717,837.312805 66.031334,836.661682 67.640488,836.006042 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M67.604118,642.360657 
	C67.925766,642.210022 67.995560,642.382751 68.189957,642.805664 
	C68.275246,683.233765 68.236740,723.411560 68.194489,763.589417 
	C68.192223,765.747620 68.158501,767.905762 68.049255,770.523804 
	C67.639648,769.578430 67.042870,768.173523 67.041130,766.767883 
	C66.991074,726.221191 66.998558,685.674500 67.015198,645.127747 
	C67.015533,644.313110 67.235016,643.498535 67.604118,642.360657 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M67.707199,523.312256 
	C67.984314,554.015442 67.981361,585.026001 67.907410,616.497192 
	C67.557053,615.701050 67.035744,614.444702 67.033791,613.187500 
	C66.988922,584.138855 66.997047,555.090088 67.019394,526.041382 
	C67.020012,525.234009 67.285271,524.426880 67.707199,523.312256 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M67.565094,815.071289 
	C68.013512,818.041931 68.081253,821.072571 68.053848,824.548584 
	C64.032417,823.434448 63.664726,818.751038 67.565094,815.071289 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M67.672249,777.325684 
	C68.002724,780.706482 68.065956,784.392395 68.038086,788.529541 
	C67.766319,785.197449 67.585663,781.414062 67.672249,777.325684 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M67.727715,828.206055 
	C68.022369,828.761719 68.085831,829.508850 68.054947,830.621826 
	C67.805916,830.124329 67.651222,829.260925 67.727715,828.206055 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M67.722618,633.115356 
	C67.855202,633.020264 67.961594,633.499939 67.912735,633.740723 
	C67.504486,633.797546 67.413208,633.540527 67.722618,633.115356 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M407.947266,505.392517 
	C408.142151,505.932709 408.213013,506.843933 408.005585,507.932220 
	C407.759247,507.327362 407.791229,506.545502 407.947266,505.392517 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M664.833374,294.426941 
	C663.831360,297.857758 662.829346,301.288574 661.697510,305.391846 
	C661.333374,307.042358 661.099121,308.020477 660.864929,308.998566 
	C660.864929,308.998566 660.976257,308.937531 660.949707,308.880127 
	C657.517761,316.650238 654.100159,324.472412 650.709473,332.306244 
	C642.016968,352.389252 633.336487,372.477448 624.396606,392.798492 
	C623.500732,392.993652 622.859497,392.953888 621.873718,392.506836 
	C634.318298,362.093536 647.070618,332.071655 659.958069,302.107941 
	C661.141052,299.357452 663.187744,296.978455 664.833374,294.426941 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M635.995056,395.956604 
	C634.950928,398.575592 633.586121,400.956970 632.081909,403.648376 
	C631.942505,403.958435 631.540405,404.050995 631.172546,403.939148 
	C630.804688,403.827271 630.437866,403.545319 630.437866,403.545319 
	C631.715393,400.389252 632.992859,397.233154 634.521362,393.799377 
	C635.073059,394.254150 635.373779,394.986603 635.995056,395.956604 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M639.239746,409.473145 
	C639.055115,409.034515 639.146301,408.533081 639.629761,408.001678 
	C640.060608,408.177307 640.099304,408.382904 640.091064,408.898865 
	C639.867981,409.276215 639.691711,409.343262 639.239746,409.473145 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M630.971924,403.987122 
	C630.840210,404.404510 630.541199,404.662048 630.242249,404.919586 
	C630.184509,404.623932 630.126770,404.328278 630.253418,403.788971 
	C630.437866,403.545319 630.804688,403.827271 630.971924,403.987122 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M629.453003,407.421356 
	C629.095032,407.302490 628.737122,407.183624 628.737793,407.078308 
	C629.096436,407.091858 629.453003,407.421356 629.453003,407.421356 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M628.923279,406.921753 
	C629.061951,406.521057 629.373840,406.290466 629.685730,406.059875 
	C629.736877,406.357269 629.788025,406.654663 629.646057,407.186707 
	C629.453003,407.421356 629.096436,407.091858 628.923279,406.921753 
z"/>
<path fill="#679BA9" opacity="1.000000" stroke="none" 
	d="
M664.912354,294.060608 
	C663.187744,296.978455 661.141052,299.357452 659.958069,302.107941 
	C647.070618,332.071655 634.318298,362.093536 621.393921,392.461945 
	C620.951965,393.107605 620.645325,393.390900 619.834595,393.839905 
	C618.168823,394.646332 617.007141,395.287079 615.845459,395.927795 
	C608.782593,399.545563 601.719666,403.163330 594.243347,406.992859 
	C596.255798,401.328857 598.153748,395.986908 600.010010,390.239746 
	C603.340759,383.645996 606.742065,377.472900 610.063965,371.257416 
	C610.829468,369.825165 611.323059,368.247559 612.310303,366.205322 
	C613.188599,364.825409 613.763245,364.008972 614.198547,363.123932 
	C622.051575,347.155273 629.798584,331.133698 637.761719,315.220215 
	C642.364990,306.021118 647.310730,296.993378 652.102356,287.888519 
	C652.909607,286.055023 653.716858,284.221497 655.192139,282.180389 
	C659.577759,282.653320 663.295349,283.333862 667.012878,284.014404 
	C667.414673,284.220734 667.816467,284.427032 668.438110,284.701599 
	C667.435730,287.744629 666.213501,290.719452 664.912354,294.060608 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M536.492004,453.117310 
	C535.878662,452.392792 535.600403,451.576843 535.167603,450.477539 
	C540.243896,447.860016 545.474731,445.525818 551.061707,443.358429 
	C546.554199,446.692108 541.690613,449.858978 536.492004,453.117310 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M532.546875,455.391296 
	C533.277283,454.593903 534.343933,453.824219 535.783508,453.087708 
	C535.065369,453.886871 533.974243,454.652954 532.546875,455.391296 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M616.233215,395.951202 
	C617.007141,395.287079 618.168823,394.646332 619.665894,393.971771 
	C618.874512,394.616821 617.747742,395.295715 616.233215,395.951202 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M832.000000,908.000000 
	C831.472290,906.921570 831.164429,905.124390 830.380188,904.884399 
	C826.129395,903.583740 825.725769,900.457336 826.363770,896.372742 
	C827.791809,894.753235 829.691650,893.657227 829.768921,892.445007 
	C830.038025,888.224365 832.569824,887.380371 836.035400,886.939392 
	C839.399109,886.511414 842.703369,885.615784 846.033691,884.925537 
	C846.796936,884.781921 847.560242,884.638245 848.927795,885.004639 
	C853.040222,885.633911 856.548340,885.753113 860.056458,885.872314 
	C860.476074,885.892090 860.895691,885.911804 861.921448,886.207031 
	C875.312073,887.650513 888.093933,888.848877 900.885864,889.928650 
	C901.900085,890.014282 902.983704,889.278198 904.035034,888.923950 
	C904.474854,888.959778 904.914612,888.995605 905.746826,889.506836 
	C906.426147,896.654785 906.713074,903.327393 907.000000,910.000000 
	C886.803650,906.676819 866.442078,908.352539 846.148010,908.067749 
	C843.938965,908.036743 841.716736,908.941772 838.876953,909.698792 
	C836.168884,909.323303 834.084473,908.661682 832.000000,908.000000 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M845.765625,884.594788 
	C842.703369,885.615784 839.399109,886.511414 836.035400,886.939392 
	C832.569824,887.380371 830.038025,888.224365 829.768921,892.445007 
	C829.691650,893.657227 827.791809,894.753235 826.363770,895.951294 
	C824.006409,894.575745 822.012817,893.151428 820.371094,891.279602 
	C822.801270,886.619019 821.992493,884.464478 817.844238,884.031555 
	C814.927612,883.727173 811.951843,883.990295 809.002441,884.000000 
	C807.155762,883.488525 805.309082,882.977051 803.212158,882.190918 
	C806.950195,881.904663 810.953125,881.667419 814.923950,881.925293 
	C825.122742,882.587646 835.307251,883.469727 845.765625,884.594788 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M872.836060,869.198242 
	C878.800842,870.206116 884.523254,871.525024 890.622070,873.259644 
	C890.958130,874.039551 890.917847,874.403870 890.608398,874.890015 
	C890.187866,875.349365 890.036499,875.686768 889.885071,876.024109 
	C881.404175,874.529663 872.923279,873.035217 864.442444,871.540771 
	C864.384949,871.074707 864.327454,870.608643 864.269958,870.142639 
	C865.411743,870.003784 866.550293,869.818726 867.696167,869.737244 
	C869.325867,869.621277 870.960999,869.581604 872.836060,869.198242 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M903.790527,888.574707 
	C902.983704,889.278198 901.900085,890.014282 900.885864,889.928650 
	C888.093933,888.848877 875.312073,887.650513 862.235901,886.183105 
	C865.063477,885.829102 868.197998,885.550293 871.299316,885.760742 
	C882.054443,886.490479 892.798035,887.390320 903.790527,888.574707 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M911.370972,880.484741 
	C911.826721,881.218384 911.912720,881.780823 912.018677,882.631836 
	C904.299072,882.162964 896.559509,881.405457 888.819946,880.648010 
	C888.856873,880.129639 888.893799,879.611328 888.930664,879.092957 
	C893.569336,879.092957 898.208008,879.092957 903.096619,878.610718 
	C903.574463,878.057068 903.802307,877.985718 904.030090,877.914429 
	C906.353760,878.714172 908.677490,879.513855 911.370972,880.484741 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M907.043335,910.358643 
	C906.713074,903.327393 906.426147,896.654785 906.069214,889.581787 
	C906.672119,889.789917 907.881714,890.359314 907.929260,891.013306 
	C908.204773,894.799988 908.167725,898.608093 908.309448,902.406189 
	C908.529907,908.313477 910.269043,913.411072 916.208252,916.578979 
	C911.379639,917.761353 908.916809,914.720581 907.043335,910.358643 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M847.121155,865.970825 
	C850.273499,866.620422 853.014160,867.277161 855.754822,867.933899 
	C855.650696,868.459167 855.546631,868.984375 855.442505,869.509644 
	C850.600891,868.602112 845.759277,867.694580 840.917664,866.787048 
	C840.954468,866.329590 840.991333,865.872192 841.028137,865.414734 
	C842.921936,865.602478 844.815674,865.790222 847.121155,865.970825 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M904.013794,877.724121 
	C903.802307,877.985718 903.574463,878.057068 903.005371,878.237732 
	C898.660828,877.647583 894.657532,876.948242 890.269653,876.136475 
	C890.036499,875.686768 890.187866,875.349365 891.040649,874.955505 
	C895.842896,875.651001 899.943604,876.402893 904.044373,877.154785 
	C904.044373,877.154785 903.997498,877.533875 904.013794,877.724121 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M859.791016,885.543762 
	C856.548340,885.753113 853.040222,885.633911 849.252075,885.234375 
	C852.489868,885.041016 856.007690,885.128113 859.791016,885.543762 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M921.662964,919.025513 
	C921.991577,919.039795 922.121155,919.437927 922.157715,919.643555 
	C921.611206,919.756104 921.028137,919.663086 920.236938,919.352661 
	C920.463928,919.093933 920.899109,919.052612 921.662964,919.025513 
z"/>
<path fill="#F8F9EB" opacity="1.000000" stroke="none" 
	d="
M651.996399,287.505188 
	C647.310730,296.993378 642.364990,306.021118 637.761719,315.220215 
	C629.798584,331.133698 622.051575,347.155273 614.198547,363.123932 
	C613.763245,364.008972 613.188599,364.825409 612.377563,365.837311 
	C610.995361,356.763062 610.017090,347.511597 608.789185,338.293396 
	C608.201233,333.879913 608.613464,329.799713 611.442871,325.529907 
	C614.150757,321.334320 616.498535,317.637939 618.846313,313.941589 
	C623.843201,305.583435 628.830505,297.219513 633.850159,288.875061 
	C634.421875,287.924561 635.009521,286.855042 635.871765,286.229401 
	C640.398560,282.944855 645.016113,279.785431 650.183228,276.176758 
	C650.757202,279.856598 651.323853,283.489197 651.996399,287.505188 
z"/>
<path fill="#30221C" opacity="1.000000" stroke="none" 
	d="
M618.590393,313.734558 
	C616.498535,317.637939 614.150757,321.334320 611.477295,325.142212 
	C611.098999,324.368439 611.046326,323.483093 610.946594,322.156494 
	C612.313293,318.591583 613.727051,315.467926 615.388367,312.124512 
	C616.535461,312.445709 617.434998,312.986633 618.590393,313.734558 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M831.538574,908.000000 
	C834.084473,908.661682 836.168884,909.323303 838.626709,909.992432 
	C838.786255,914.771790 835.693970,917.044922 832.327515,914.277771 
	C829.070801,911.600830 825.840881,910.741089 821.932800,910.873291 
	C821.348572,910.892944 820.736938,910.102112 820.137939,909.686279 
	C820.180481,909.124207 820.223022,908.562073 820.265564,908.000000 
	C823.869385,908.000000 827.473267,908.000000 831.538574,908.000000 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M809.003296,884.404053 
	C811.951843,883.990295 814.927612,883.727173 817.844238,884.031555 
	C821.992493,884.464478 822.801270,886.619019 820.361450,890.916016 
	C817.666748,890.332825 815.364929,889.293457 812.992249,889.093262 
	C810.071167,888.846741 808.823975,887.646973 809.003296,884.404053 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M788.417480,886.000000 
	C787.442444,880.678528 791.383484,882.256104 794.564026,882.003723 
	C795.173828,886.777222 791.688843,885.901855 788.417480,886.000000 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M350.838623,487.944031 
	C341.937378,488.111115 332.748383,487.948090 323.175323,487.608643 
	C326.063354,486.954895 329.349518,486.005402 332.604431,486.102631 
	C338.596252,486.281616 344.569702,487.075989 350.838623,487.944031 
z"/>
<path fill="#1C0000" opacity="1.000000" stroke="none" 
	d="
M97.016708,520.026978 
	C97.016708,520.026978 97.276588,519.846130 97.694992,519.846741 
	C98.400063,519.667114 98.686745,519.486816 98.973419,519.306580 
	C99.420242,519.231750 99.867065,519.156860 100.825775,519.282654 
	C101.868256,519.264771 102.398849,519.046265 102.929451,518.827759 
	C102.929459,518.827820 102.987000,518.881958 103.384399,518.928833 
	C105.528267,518.976501 107.274734,518.977295 109.415344,518.970276 
	C112.207787,518.005066 114.606087,517.047546 117.004387,516.090088 
	C117.637009,515.976318 118.269630,515.862549 119.650726,515.831421 
	C121.910423,515.559753 123.421638,515.205444 124.932854,514.851135 
	C124.932854,514.851135 125.024147,514.907898 125.341309,514.936768 
	C126.771904,514.640869 127.885338,514.316162 128.998779,513.991455 
	C129.805084,513.742737 130.611389,513.494080 131.982880,513.442383 
	C133.671051,513.378052 134.794067,513.116821 135.917099,512.855591 
	C135.917099,512.855591 135.933823,512.871155 136.306213,512.921753 
	C137.785522,512.637268 138.892441,512.302124 139.999344,511.966919 
	C139.999344,511.966919 140.366501,511.843689 140.559921,511.844849 
	C144.066406,511.632080 146.999298,512.129456 148.500366,516.167114 
	C148.848816,516.665222 148.914215,516.831421 148.874451,517.456116 
	C148.706024,526.600403 148.642807,535.286072 148.270508,544.062988 
	C145.134903,547.029175 146.374390,549.569397 148.697876,552.486816 
	C148.821091,596.016479 148.887024,639.086853 148.879501,682.157227 
	C148.875198,706.763977 148.751266,731.370667 148.342010,756.093506 
	C147.674606,756.923645 147.069168,757.634583 147.061172,758.352234 
	C146.984482,765.234741 147.004852,772.118408 147.004883,779.001709 
	C147.004913,787.369263 146.968445,795.737427 147.074905,804.103699 
	C147.087418,805.087097 147.903259,806.060181 148.473114,807.487915 
	C148.556473,832.955322 148.513397,857.972961 148.193268,882.990845 
	C147.548508,883.025818 147.180817,883.060608 146.503418,883.071899 
	C145.785080,882.980164 145.376450,882.911987 144.645294,882.596436 
	C139.555145,882.516174 134.787552,882.683472 129.552551,882.901123 
	C127.100746,882.956299 125.116272,882.950195 123.132004,882.967651 
	C106.439964,883.114624 89.747955,883.265808 72.806358,883.285034 
	C72.378517,883.095154 72.200249,883.036072 72.017342,882.731628 
	C72.012703,882.486328 72.003983,881.995728 72.003494,881.527344 
	C72.002716,764.403076 71.989815,647.747131 72.088501,531.091309 
	C72.090569,528.651245 73.382675,526.212158 74.072968,523.772644 
	C74.072968,523.772644 74.317566,523.669556 74.903976,523.803345 
	C82.665833,522.633728 89.841270,521.330322 97.016708,520.026978 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M148.979630,516.997559 
	C148.914215,516.831421 148.848816,516.665222 148.859100,516.242004 
	C156.454514,516.031372 163.974213,516.077637 171.516876,516.767212 
	C169.694153,518.931702 167.848465,520.452881 166.002762,521.973999 
	C164.007568,522.064209 162.012375,522.154419 159.366394,521.959106 
	C155.470276,520.114929 152.224945,518.556274 148.979630,516.997559 
z"/>
<path fill="#FAF4DE" opacity="1.000000" stroke="none" 
	d="
M166.464523,522.057373 
	C167.848465,520.452881 169.694153,518.931702 171.907257,516.954651 
	C174.918259,516.437317 177.561874,516.375916 180.626373,516.455078 
	C179.962387,520.910278 184.859589,519.081360 185.923798,521.637207 
	C179.642944,522.050903 173.284622,522.095764 166.464523,522.057373 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M108.102493,460.756531 
	C108.182175,460.537720 108.339737,460.455444 108.727158,460.505737 
	C108.957031,460.638306 108.639282,460.937042 108.639282,460.937042 
	C108.639282,460.937042 108.180374,460.893066 108.102493,460.756531 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M281.272705,485.412842 
	C281.055267,483.530151 281.211609,481.564270 281.574463,479.300354 
	C281.736145,481.111389 281.691315,483.220520 281.272705,485.412842 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M436.585754,501.063721 
	C435.180878,501.303314 433.364197,501.413452 431.278900,501.293732 
	C432.731537,501.020660 434.452728,500.977448 436.585754,501.063721 
z"/>
<path fill="#1F0B0B" opacity="1.000000" stroke="none" 
	d="
M277.110046,471.666656 
	C276.801636,471.601654 276.592896,471.222137 276.561218,470.541809 
	C276.895447,470.611389 277.052612,470.981781 277.110046,471.666656 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M521.481812,948.283447 
	C521.020569,947.966614 521.007874,947.780701 520.972168,947.316650 
	C527.359070,947.020996 533.768982,947.003479 541.049927,946.965210 
	C543.245728,946.954346 544.570618,946.964233 545.895447,946.974182 
	C546.697998,946.977661 547.500549,946.981140 549.161194,946.965820 
	C560.317810,946.979370 570.616943,946.960571 580.914124,947.094482 
	C582.614624,947.116577 584.306458,947.807251 586.002258,948.188965 
	C564.645020,948.264099 543.287720,948.339294 521.481812,948.283447 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M586.251709,948.352539 
	C584.306458,947.807251 582.614624,947.116577 580.914124,947.094482 
	C570.616943,946.960571 560.317810,946.979370 549.562500,946.961426 
	C554.306458,946.652893 559.504822,946.280762 564.708923,946.026611 
	C567.515503,945.889587 570.331909,945.954407 573.615356,945.847656 
	C579.386902,945.801147 584.687012,945.835205 590.868774,945.901672 
	C593.165588,945.990601 594.580872,946.047058 595.996094,946.103516 
	C594.134644,946.617249 592.273193,947.130859 590.212830,947.961304 
	C588.842957,948.357422 587.672058,948.436768 586.251709,948.352539 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M635.059814,948.203125 
	C635.356140,948.061096 635.867065,948.029663 636.759766,948.043579 
	C636.519165,948.163879 635.896790,948.238708 635.059814,948.203125 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M87.579102,923.882446 
	C92.349815,923.569275 97.575378,923.162964 102.806633,923.067078 
	C107.596764,922.979126 112.393044,923.226318 117.506516,923.555176 
	C107.896416,923.831238 97.966423,923.877930 87.579102,923.882446 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M904.424927,877.245972 
	C899.943604,876.402893 895.842896,875.651001 891.309814,874.833618 
	C890.917847,874.403870 890.958130,874.039551 890.977356,873.401001 
	C896.502136,874.138916 902.048035,875.151123 907.593872,876.163269 
	C907.566040,876.472351 907.538269,876.781372 907.510437,877.090454 
	C906.608826,877.172668 905.707153,877.254883 904.424927,877.245972 
z"/>
<path fill="#F9F7E8" opacity="1.000000" stroke="none" 
	d="
M667.006226,283.624084 
	C663.295349,283.333862 659.577759,282.653320 655.438477,281.931030 
	C655.453247,275.252625 655.889771,268.615997 656.218933,261.535614 
	C661.310974,260.983093 666.533142,260.564362 671.697083,260.941864 
	C673.393188,261.065887 674.938538,263.253571 676.825562,264.737213 
	C674.316223,269.663727 671.495239,274.328339 668.777100,279.052063 
	C668.027100,280.355347 667.583679,281.835022 667.006226,283.624084 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M432.311432,504.076660 
	C435.393616,503.755249 438.723328,503.770325 442.506897,503.753113 
	C443.706512,503.826843 444.452301,503.932861 445.570496,504.106476 
	C441.481567,504.253754 437.020264,504.333466 432.311432,504.076660 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M410.309204,503.311523 
	C413.357269,503.037750 416.705078,503.032166 420.500061,503.112000 
	C417.501190,503.324799 414.055054,503.452240 410.309204,503.311523 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M423.237122,503.086060 
	C423.881226,502.848114 424.744171,502.735992 425.797913,502.848938 
	C425.144470,503.119965 424.300232,503.165924 423.237122,503.086060 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M679.485779,103.058853 
	C677.886108,102.549675 676.709229,102.054703 675.326904,101.311478 
	C676.830200,101.064301 678.538818,101.065369 680.676025,100.997398 
	C685.729919,101.836617 690.355225,102.744873 694.980591,103.653130 
	C694.897278,104.108757 694.813904,104.564384 694.730591,105.020012 
	C689.789917,104.371025 684.849182,103.722046 679.485779,103.058853 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M700.202881,117.968185 
	C699.894287,117.916382 699.606750,117.679207 699.630859,117.494461 
	C700.408020,117.240417 701.161072,117.171104 701.914185,117.101791 
	C701.946899,117.280762 701.979614,117.459740 702.012329,117.638718 
	C701.512085,117.765808 701.011780,117.892906 700.202881,117.968185 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M657.285400,110.097244 
	C657.442993,109.557663 657.919556,109.090988 658.717529,108.807159 
	C658.560791,109.383377 658.082581,109.776756 657.285400,110.097244 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M696.221985,105.298340 
	C696.518494,105.300224 696.591003,105.527023 696.663574,105.753815 
	C696.441711,105.676956 696.219849,105.600105 696.221985,105.298340 
z"/>
<path fill="#BBAFA8" opacity="1.000000" stroke="none" 
	d="
M590.955017,945.223022 
	C588.177979,945.091248 585.401001,944.959412 582.623962,944.827637 
	C582.632019,944.523743 582.640137,944.219788 582.648254,943.915894 
	C636.763428,943.915894 690.878662,943.915894 745.454834,943.937256 
	C745.898132,944.155212 745.880432,944.351746 745.823364,944.841248 
	C696.486694,945.110840 647.189453,945.087463 596.997070,945.083740 
	C594.386292,945.143311 592.670654,945.183167 590.955017,945.223022 
z"/>
<path fill="#46382E" opacity="1.000000" stroke="none" 
	d="
M590.943726,945.364258 
	C592.670654,945.183167 594.386292,945.143311 596.531128,945.157288 
	C596.819275,945.478699 596.622925,945.696350 596.183716,945.983887 
	C594.580872,946.047058 593.165588,945.990601 591.328369,945.860840 
	C590.906372,945.787598 590.932434,945.505493 590.943726,945.364258 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M146.813110,883.095459 
	C147.180817,883.060608 147.548508,883.025818 148.248077,883.416260 
	C148.554535,885.833984 148.529114,887.826416 148.084015,889.949341 
	C121.705605,890.079834 95.746902,890.079834 69.100685,890.079834 
	C69.076073,890.129822 68.528770,891.241821 67.812317,892.697510 
	C68.897194,892.897278 69.500343,893.106201 70.103127,893.105164 
	C86.259346,893.076172 102.415520,892.990417 118.571709,892.991577 
	C128.552811,892.992310 138.533920,893.119690 148.550201,893.584351 
	C148.550400,895.296936 148.515442,896.615356 148.480499,897.933838 
	C124.262428,897.977783 100.044205,898.064819 75.826584,897.980225 
	C73.982689,897.973816 72.187828,896.503235 70.289406,896.160339 
	C68.192406,895.781677 66.002304,895.918457 63.500290,895.663940 
	C63.127407,895.201904 63.100800,894.907288 63.053024,894.390747 
	C63.845722,894.095581 64.653389,894.022888 65.370445,893.958313 
	C64.546791,892.091309 63.805244,890.410461 63.029251,888.365723 
	C63.012241,887.591675 63.029682,887.181519 63.046154,886.463318 
	C63.045189,886.155334 63.400028,885.953796 64.018387,885.933472 
	C65.772858,885.928772 66.908958,885.944275 68.403900,886.066345 
	C69.487770,886.056152 70.212791,885.939453 71.296539,885.825684 
	C72.128632,885.767212 72.602005,885.705872 73.531662,885.727905 
	C96.945068,885.640625 119.902672,885.506042 142.858124,885.206299 
	C144.185394,885.188965 145.495178,883.830017 146.813110,883.095459 
z"/>
<path fill="#1C0505" opacity="1.000000" stroke="none" 
	d="
M63.374908,844.463745 
	C66.815521,843.758179 67.481735,845.346008 66.600471,848.289917 
	C65.917557,850.571167 65.118813,852.930481 65.071808,855.269226 
	C64.892395,864.196045 64.996422,873.128662 64.676315,882.575317 
	C64.149178,883.133240 63.940311,883.174866 63.731453,883.216492 
	C63.579575,870.451721 63.427700,857.686890 63.374908,844.463745 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M63.729919,883.544556 
	C63.940311,883.174866 64.149178,883.133240 64.673767,883.043762 
	C65.723030,883.000122 66.456566,883.004272 67.574013,883.355225 
	C67.986977,884.454590 68.016022,885.207153 68.045067,885.959778 
	C66.908958,885.944275 65.772858,885.928772 64.212326,885.870117 
	C63.768066,885.175598 63.748222,884.524048 63.729919,883.544556 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M746.292358,943.945435 
	C745.988770,943.184631 746.049866,942.443542 746.077881,941.331055 
	C746.482788,940.940369 746.920776,940.920959 747.683899,940.956055 
	C748.017456,941.693298 748.025940,942.376038 748.065979,943.396973 
	C747.617310,943.811768 747.137207,943.888428 746.292358,943.945435 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M65.260925,908.961670 
	C64.937996,908.495239 64.953392,908.107971 65.325470,907.383545 
	C70.375511,904.714050 75.361977,906.091675 80.194832,906.055603 
	C97.144630,905.928955 114.096443,906.070435 131.042023,906.421875 
	C131.066391,907.354858 131.096207,907.975952 131.126007,908.597107 
	C114.727272,908.739624 98.328613,908.892334 81.929756,909.018799 
	C76.486526,909.060791 71.042770,909.035339 65.260925,908.961670 
z"/>
<path fill="#1F0807" opacity="1.000000" stroke="none" 
	d="
M148.093948,914.120361 
	C144.322617,913.985535 140.551270,913.850708 136.382233,913.644470 
	C135.989883,912.314941 135.995209,911.056763 136.450592,909.360229 
	C140.635239,908.601868 144.369827,908.281982 148.173370,908.397888 
	C148.192856,910.595947 148.143402,912.358154 148.093948,914.120361 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M148.104431,907.962036 
	C144.369827,908.281982 140.635239,908.601868 136.449585,908.979980 
	C134.642975,908.931702 133.287415,908.825195 131.528931,908.657898 
	C131.096207,907.975952 131.066391,907.354858 131.503189,906.367737 
	C137.483337,905.929138 142.996872,905.856506 148.600021,906.068848 
	C148.494568,906.889893 148.299500,907.425964 148.104431,907.962036 
z"/>
<path fill="#FAF4DE" opacity="1.000000" stroke="none" 
	d="
M148.173370,908.397888 
	C148.299500,907.425964 148.494568,906.889893 148.656433,905.671631 
	C148.574280,903.396118 148.525330,901.802856 148.476349,900.209595 
	C148.544617,899.704895 148.612869,899.200195 148.580811,898.314697 
	C148.515442,896.615356 148.550400,895.296936 148.690567,893.174805 
	C148.698410,891.520386 148.601059,890.669617 148.503708,889.818909 
	C148.529114,887.826416 148.554535,885.833984 148.525146,883.416016 
	C148.513397,857.972961 148.556473,832.955322 148.619110,807.037292 
	C148.652847,789.417053 148.667023,772.697266 148.681183,755.977417 
	C148.751266,731.370667 148.875198,706.763977 148.879501,682.157227 
	C148.887024,639.086853 148.821091,596.016479 148.800674,552.048584 
	C148.736801,548.757996 148.658188,546.364868 148.579575,543.971802 
	C148.642807,535.286072 148.706024,526.600403 148.874451,517.456116 
	C152.224945,518.556274 155.470276,520.114929 158.938629,521.973145 
	C159.098953,560.564087 158.995270,598.855530 158.983429,637.146912 
	C158.966949,690.424377 159.019302,743.701904 159.075073,797.441040 
	C159.107925,838.669983 159.107925,879.437317 159.107925,921.128113 
	C155.740433,921.128113 152.831863,921.128113 149.466476,921.115112 
	C148.962372,920.561462 148.915085,920.020874 148.667267,919.302856 
	C148.438477,918.292786 148.410217,917.460022 148.261322,916.229858 
	C148.219009,915.442017 148.297379,915.051697 148.234833,914.390869 
	C148.143402,912.358154 148.192856,910.595947 148.173370,908.397888 
z"/>
<path fill="#1C0607" opacity="1.000000" stroke="none" 
	d="
M148.099457,900.261719 
	C148.525330,901.802856 148.574280,903.396118 148.566833,905.386597 
	C142.996872,905.856506 137.483337,905.929138 131.508636,906.055908 
	C114.096443,906.070435 97.144630,905.928955 80.194832,906.055603 
	C75.361977,906.091675 70.375511,904.714050 65.299431,907.093506 
	C64.572441,906.671692 64.228172,906.202759 63.884216,905.421509 
	C63.689110,904.286560 63.493687,903.463867 63.172508,902.339600 
	C66.931610,901.645325 70.815140,900.928955 74.701553,900.912964 
	C97.576225,900.818481 120.451591,900.898193 143.326675,900.875916 
	C144.792160,900.874512 146.257263,900.509705 148.099457,900.261719 
z"/>
<path fill="#6899A7" opacity="0.000000" stroke="none" 
	d="
M53.601379,909.558838 
	C51.254944,909.831543 48.608025,909.875305 45.523403,909.868042 
	C47.824100,909.654663 50.562492,909.492310 53.601379,909.558838 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M363.178772,893.775757 
	C362.712860,894.676514 362.217773,895.315063 361.491364,896.146606 
	C358.958832,896.156250 356.657806,895.815552 354.356323,895.812500 
	C292.977570,895.731812 231.598740,895.700195 170.220123,895.571228 
	C168.188660,895.566956 166.159149,894.635864 164.594147,893.933105 
	C165.384903,893.609924 165.710236,893.490356 166.399078,893.326538 
	C167.491791,893.323425 168.221024,893.364563 169.062256,893.527893 
	C169.174271,893.650085 169.651733,893.705566 169.651733,893.705566 
	C169.651733,893.705566 170.045715,893.430603 170.507751,893.377319 
	C191.913269,893.263733 212.856812,893.136841 233.800201,893.157776 
	C269.868011,893.193848 305.935760,893.321899 342.360779,893.602173 
	C348.476898,893.671143 354.235840,893.549316 360.365479,893.343872 
	C361.491455,893.175781 362.246765,893.091309 363.002045,893.006836 
	C363.051208,893.175659 363.100403,893.344543 363.178772,893.775757 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M342.003540,893.411377 
	C305.935760,893.321899 269.868011,893.193848 233.800201,893.157776 
	C212.856812,893.136841 191.913269,893.263733 170.235672,893.291626 
	C169.317780,893.308044 169.134003,893.356873 168.950241,893.405701 
	C168.221024,893.364563 167.491791,893.323425 166.143997,893.184265 
	C165.343399,893.017212 165.161392,892.948242 164.979370,892.879272 
	C166.568359,892.586243 168.157181,892.038086 169.746368,892.037048 
	C232.652649,891.995422 295.558929,892.000732 358.465240,892.023132 
	C359.772980,892.023621 361.080658,892.296753 362.695190,892.724731 
	C362.246765,893.091309 361.491455,893.175781 359.916565,893.207520 
	C353.399139,893.240417 347.701324,893.325867 342.003540,893.411377 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M164.873459,893.036621 
	C165.161392,892.948242 165.343399,893.017212 165.780502,893.228516 
	C165.710236,893.490356 165.384903,893.609924 164.810547,893.620972 
	C164.561523,893.512451 164.767548,893.193970 164.873459,893.036621 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M547.080261,849.468872 
	C547.606567,847.802734 548.079407,846.602295 548.823181,845.212402 
	C549.117310,857.859253 549.140442,870.695435 549.100403,884.032104 
	C548.378906,883.233765 547.171082,881.944092 547.149353,880.634766 
	C546.980042,870.403503 547.038574,860.168457 547.080261,849.468872 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M866.346802,594.909546 
	C866.333862,586.679077 866.698730,578.311951 867.092285,569.493286 
	C868.037476,569.346985 868.953918,569.652222 870.222046,570.091675 
	C869.290649,578.408142 868.007629,586.590515 866.346802,594.909546 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M875.932983,578.663452 
	C875.447998,579.031372 875.065979,579.001404 874.397827,578.998047 
	C874.397888,576.027771 874.684082,573.030945 875.050049,569.657043 
	C875.727112,569.389526 876.324280,569.498962 877.155640,569.830078 
	C876.938538,572.789673 876.487244,575.527588 875.932983,578.663452 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M883.718384,566.187378 
	C883.953552,566.352600 883.893250,566.671692 883.564087,567.158264 
	C883.446289,567.018066 883.488831,566.689941 883.718384,566.187378 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M658.693237,417.132263 
	C659.374329,418.621094 659.730347,420.210876 659.422119,421.833160 
	C653.897156,424.636688 649.036316,427.407684 643.921021,430.051483 
	C643.446716,429.624573 643.226929,429.324890 643.007080,429.025208 
	C648.127441,425.094543 653.247864,421.163910 658.693237,417.132263 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M636.219727,440.530640 
	C635.307190,440.972015 634.394714,441.413391 633.096313,441.830383 
	C631.264038,441.258179 629.817749,440.710297 628.455627,440.194336 
	C631.060486,438.193848 633.979675,435.951874 637.368286,433.777222 
	C637.958862,433.965027 638.080078,434.085510 638.137085,434.416168 
	C638.072937,434.626312 638.001831,435.060242 637.997437,435.028381 
	C637.992981,434.996521 638.057251,434.993805 637.795593,435.077698 
	C637.356140,435.460144 637.178284,435.758759 636.995972,436.025879 
	C636.991394,435.994385 637.054871,435.990906 636.735474,436.121338 
	C636.272400,436.681366 636.128723,437.110992 635.965454,437.917725 
	C635.976990,439.048492 636.008118,439.802246 636.083374,440.542175 
	C636.127441,440.528381 636.219727,440.530670 636.219727,440.530640 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M642.698486,429.140015 
	C643.226929,429.324890 643.446716,429.624573 643.755737,430.288879 
	C643.575745,431.124146 643.306641,431.594818 643.017456,432.035583 
	C642.997375,432.005646 643.053833,432.050018 642.716125,432.000824 
	C641.593628,432.654877 640.808838,433.358124 640.011597,434.032257 
	C639.999084,434.003143 640.047974,434.043457 639.738770,433.980042 
	C639.020081,434.013092 638.610657,434.109528 638.201233,434.205994 
	C638.080078,434.085510 637.958862,433.965027 637.680176,433.637329 
	C639.145081,432.038361 640.767517,430.646606 642.698486,429.140015 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M719.487488,443.947693 
	C720.334290,442.987579 721.540100,441.326569 722.894470,441.194977 
	C735.237549,439.995544 747.624573,439.241638 759.962952,438.001740 
	C772.630920,436.728699 785.261230,435.077026 797.903137,433.550507 
	C803.651367,432.856415 809.387695,432.064606 815.537231,431.565125 
	C815.960754,432.144226 815.958374,432.475220 815.937805,432.806061 
	C797.908569,435.110107 779.895630,437.553741 761.844727,439.673248 
	C747.893005,441.311401 733.893555,442.543518 719.487488,443.947693 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M762.036560,444.035431 
	C759.005432,443.863739 755.887024,443.148712 752.958923,443.618835 
	C742.820801,445.246582 732.750854,447.297760 722.648010,449.149414 
	C721.807129,449.303528 720.913452,449.169769 719.970398,448.361237 
	C748.177612,441.116638 777.263855,440.796265 805.833008,436.548431 
	C805.891602,436.971222 805.950195,437.394043 806.008789,437.816833 
	C791.616943,439.851196 777.225159,441.885590 762.423584,443.966919 
	C762.013916,444.013855 762.036560,444.035431 762.036560,444.035431 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M740.861450,451.955383 
	C753.599792,449.203339 765.914368,446.488892 778.229004,443.774414 
	C778.339783,444.326904 778.450562,444.879425 778.561340,445.431915 
	C763.053711,448.786407 747.546082,452.140930 732.038452,455.495422 
	C731.908386,454.894867 731.778259,454.294312 731.648193,453.693756 
	C734.578003,453.126801 737.507874,452.559875 740.861450,451.955383 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M762.007202,444.001343 
	C753.088074,446.329926 744.200806,448.773407 735.253113,450.970459 
	C730.504578,452.136414 725.650940,452.874603 720.844788,453.805878 
	C720.711182,453.196686 720.577637,452.587494 720.444031,451.978302 
	C734.023865,449.322449 747.603638,446.666595 761.609985,444.023071 
	C762.036560,444.035431 762.013916,444.013855 762.007202,444.001343 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M816.359863,432.862274 
	C815.958374,432.475220 815.960754,432.144226 815.928833,431.566833 
	C816.092407,431.252808 816.272034,431.185059 816.727417,431.034241 
	C821.059875,430.418274 825.116516,429.885437 829.538635,429.204254 
	C825.530029,430.343414 821.156006,431.630951 816.359863,432.862274 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M813.045471,436.317780 
	C812.230713,436.667480 811.184753,436.818298 810.138733,436.969116 
	C810.116028,436.751434 810.093384,436.533752 810.070679,436.316071 
	C810.985229,436.250366 811.899719,436.184631 813.045471,436.317780 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M831.188599,429.291626 
	C832.062256,429.014008 833.103149,429.020050 834.532715,429.025757 
	C833.732910,429.208710 832.544373,429.391998 831.188599,429.291626 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M584.988586,926.975891 
	C601.344482,926.964355 617.700378,926.952759 634.519897,927.006592 
	C634.960754,927.420532 634.937988,927.769104 634.475830,928.421875 
	C629.659485,928.546021 625.279480,928.213623 620.907532,928.297058 
	C619.860535,928.316956 618.840027,929.720825 617.807373,930.488159 
	C610.531677,930.416504 603.255981,930.344788 595.542114,929.705322 
	C591.732117,928.416992 588.360352,927.696411 584.988586,926.975891 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M618.255371,930.673462 
	C618.840027,929.720825 619.860535,928.316956 620.907532,928.297058 
	C625.279480,928.213623 629.659485,928.546021 634.446899,928.683105 
	C639.505737,929.129822 644.154114,929.619385 648.901489,930.488647 
	C638.901489,930.865112 628.802429,930.861938 618.255371,930.673462 
z"/>
<path fill="#46382E" opacity="1.000000" stroke="none" 
	d="
M584.601440,926.866821 
	C588.360352,927.696411 591.732117,928.416992 595.142090,929.482788 
	C594.369690,930.070312 593.559204,930.312622 592.300049,930.513672 
	C590.149048,930.287048 588.446594,930.101562 586.363403,929.994263 
	C584.563721,929.996826 583.144836,929.921326 581.317139,929.421875 
	C580.948303,928.385986 580.988281,927.774048 581.028259,927.162048 
	C581.028259,927.162048 581.063965,926.935059 581.031494,926.824890 
	C582.070801,926.729065 583.142578,926.743469 584.601440,926.866821 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M663.054321,563.012573 
	C664.809998,564.798889 666.572083,564.359253 668.667725,563.012817 
	C668.998657,562.997803 669.024353,563.018494 669.024353,563.018494 
	C666.504761,565.558350 663.985107,568.098145 660.848145,570.871033 
	C657.838013,574.412598 655.445251,577.721069 653.052490,581.029602 
	C653.052490,581.029602 653.012573,581.011658 652.786072,580.691895 
	C649.420898,583.613953 646.282227,586.855652 642.894531,589.964966 
	C642.419128,589.539490 642.192566,589.246399 642.107544,588.650024 
	C645.490173,583.897827 648.731262,579.448914 652.242004,574.806274 
	C652.671936,574.079285 652.832153,573.546082 652.992432,573.012878 
	C652.992432,573.012878 653.000366,573.000000 653.333862,573.156616 
	C654.436035,572.214417 655.204651,571.115723 655.973267,570.016968 
	C655.973267,570.016968 655.996704,569.995178 656.371948,569.924072 
	C656.833374,569.230957 656.919495,568.609009 657.005615,567.987000 
	C657.005615,567.987000 657.000000,567.999817 657.321167,568.158569 
	C658.103821,567.538086 658.565247,566.758789 659.026611,565.979492 
	C659.026611,565.979492 659.001282,566.002075 659.279724,565.992859 
	C659.712524,565.650696 659.866882,565.317688 660.021179,564.984741 
	C660.021179,564.984741 660.002625,565.001648 660.356445,564.916016 
	C660.808777,564.215088 660.907410,563.599915 661.005981,562.984680 
	C661.005981,562.984680 661.000000,562.999573 661.299683,563.232666 
	C662.084412,563.314758 662.569336,563.163635 663.054321,563.012573 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M643.143555,590.097412 
	C646.282227,586.855652 649.420898,583.613953 652.795715,580.711548 
	C652.369873,582.142517 651.707886,583.234070 650.628357,584.802979 
	C647.685791,588.396484 645.160828,591.512695 642.429993,594.758545 
	C641.972046,595.105957 641.791687,595.372314 641.411255,595.936584 
	C641.225830,596.553833 641.312195,596.921814 641.398560,597.289673 
	C641.846497,597.047180 642.294434,596.804688 642.901611,596.465210 
	C643.263489,596.213013 643.423340,596.022034 643.840332,595.614136 
	C645.790222,594.299438 647.440186,593.165833 649.090149,592.032288 
	C647.071960,594.473145 645.053772,596.914001 643.017944,599.667236 
	C643.000305,599.979675 643.063660,599.987061 642.756714,599.997925 
	C642.293518,600.360229 642.137390,600.711731 641.982605,601.023560 
	C641.984009,600.983887 642.063354,600.981689 641.757446,600.999207 
	C641.295837,601.365601 641.140137,601.714417 640.985474,602.024841 
	C640.986511,601.986389 641.063416,601.984375 640.756226,602.002075 
	C640.294006,602.367676 640.138916,602.715637 639.978577,603.022156 
	C639.973328,602.980713 640.054993,602.963074 639.609131,603.140259 
	C639.114197,604.061951 639.065063,604.806396 638.868408,605.725647 
	C638.162476,606.113831 637.604126,606.327271 636.680542,606.448242 
	C635.885681,606.605713 635.456177,606.855530 634.997559,606.752808 
	C636.600647,604.223206 638.232910,602.046082 640.057007,599.554749 
	C639.727234,599.116638 639.205566,598.992737 638.331726,598.922974 
	C637.289368,598.977417 636.599243,598.977661 636.019653,598.645264 
	C637.416565,596.217529 638.702820,594.122314 639.995117,592.015747 
	C640.001160,592.004272 640.021729,591.988525 640.259644,592.296265 
	C641.379578,591.768494 642.261597,590.932922 643.143555,590.097412 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M637.045837,606.540649 
	C637.604126,606.327271 638.162476,606.113831 639.131348,605.420898 
	C639.712952,604.281921 639.883972,603.622498 640.054993,602.963074 
	C640.054993,602.963074 639.973328,602.980713 640.360291,603.148987 
	C640.852661,602.872986 640.958008,602.428650 641.063416,601.984375 
	C641.063416,601.984375 640.986511,601.986389 641.367981,602.161011 
	C641.854065,601.884338 641.958679,601.432983 642.063354,600.981689 
	C642.063354,600.981689 641.984009,600.983887 642.362854,601.150330 
	C642.848999,600.873535 642.956299,600.430298 643.063660,599.987061 
	C643.063660,599.987061 643.000305,599.979675 642.994263,600.010681 
	C643.936279,599.581665 644.884277,599.121643 646.328979,598.711975 
	C646.975891,598.931763 647.126160,599.101196 647.276489,599.270569 
	C643.973938,603.017029 640.671326,606.763489 637.679932,610.772827 
	C637.991150,611.035828 638.003906,611.003418 637.616150,610.807983 
	C637.143921,611.085449 637.059448,611.558289 636.974976,612.031189 
	C636.974976,612.031189 636.996887,612.004089 636.656067,611.771912 
	C636.175659,611.999817 636.036011,612.459839 635.932983,612.935059 
	C635.969543,612.950256 635.970520,612.871094 635.685181,612.642700 
	C635.209229,612.566162 635.018677,612.718018 634.577759,612.972778 
	C633.892578,613.387329 633.457764,613.698914 633.027344,613.688965 
	C633.025696,612.939575 633.019714,612.511780 633.063232,611.824463 
	C633.100220,611.389282 633.087646,611.213623 633.043762,611.023438 
	C633.012451,611.008972 633.020020,611.077515 633.303284,611.037598 
	C633.750183,610.674255 633.913818,610.350769 634.041382,610.018188 
	C634.005371,610.009033 634.056519,610.063110 634.391357,609.960571 
	C635.499390,608.752197 636.272644,607.646484 637.045837,606.540649 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M642.635864,594.628906 
	C645.160828,591.512695 647.685791,588.396484 650.484375,585.104614 
	C651.266541,585.188354 651.775085,585.447693 652.508057,586.132935 
	C652.838684,586.720215 652.944946,586.881592 653.051270,587.043030 
	C653.051270,587.043030 653.008484,587.001343 652.705261,586.964722 
	C652.283386,587.299927 652.164734,587.671753 652.046021,588.043518 
	C652.046021,588.043518 652.000000,588.000000 651.716064,587.997437 
	C651.296204,588.347717 651.160339,588.700562 651.024475,589.053406 
	C651.024475,589.053406 651.001038,588.999207 650.636597,589.093201 
	C650.197449,589.802490 650.122803,590.417725 650.048096,591.033020 
	C650.048096,591.033020 650.000000,591.000000 649.654785,590.802490 
	C649.215088,591.087036 649.120667,591.569092 649.026245,592.051147 
	C649.026245,592.051147 649.044861,591.996155 649.067505,592.014221 
	C647.440186,593.165833 645.790222,594.299438 643.629395,595.310791 
	C642.957581,595.001953 642.796692,594.815430 642.635864,594.628906 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M653.410767,581.090820 
	C655.445251,577.721069 657.838013,574.412598 660.567566,571.066895 
	C658.525940,574.403870 656.147522,577.777954 653.410767,581.090820 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M653.363770,587.280640 
	C652.944946,586.881592 652.838684,586.720215 652.759766,586.270508 
	C654.899353,583.635498 657.011780,581.288818 659.696045,579.083008 
	C660.437134,579.344543 660.606323,579.465271 660.775513,579.586060 
	C658.409119,582.230103 656.042725,584.874146 653.363770,587.280640 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M660.035034,579.016479 
	C660.634705,577.764954 661.467346,576.720703 662.767578,575.672913 
	C663.420898,575.778992 663.606567,575.888733 663.792236,575.998413 
	C662.940308,577.038086 662.088379,578.077759 661.005981,579.351746 
	C660.606323,579.465271 660.437134,579.344543 660.035034,579.016479 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M648.835510,595.848633 
	C649.455811,594.364197 650.076111,592.879822 650.696411,591.395447 
	C651.411377,592.090515 652.126343,592.785645 652.841248,593.480713 
	C651.933716,594.473389 651.026184,595.466003 649.682251,596.401733 
	C649.109009,596.179321 648.972290,596.013977 648.835510,595.848633 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M663.696533,573.727844 
	C664.122864,573.086243 664.549133,572.444580 665.441040,571.859314 
	C666.058655,572.057190 666.210754,572.198669 666.362793,572.340210 
	C665.925720,572.959106 665.488708,573.577942 664.591309,574.153687 
	C663.986145,573.982971 663.841370,573.855408 663.696533,573.727844 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M665.685547,571.695984 
	C666.150391,570.721008 666.836243,569.965637 667.522095,569.210327 
	C667.731995,569.363342 667.941956,569.516357 668.151855,569.669312 
	C667.719299,570.418640 667.286743,571.167969 666.608521,572.128784 
	C666.210754,572.198669 666.058655,572.057190 665.685547,571.695984 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M646.616638,598.494629 
	C647.042847,597.516052 647.678101,596.805298 648.574463,595.971558 
	C648.972290,596.013977 649.109009,596.179321 649.422607,596.613892 
	C648.992615,597.591125 648.385803,598.299194 647.527710,599.138916 
	C647.126160,599.101196 646.975891,598.931763 646.616638,598.494629 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M665.379211,559.070740 
	C665.482666,558.312622 665.915161,557.609985 666.684082,556.974487 
	C666.583069,557.736511 666.145630,558.431396 665.379211,559.070740 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M663.157837,562.660278 
	C662.569336,563.163635 662.084412,563.314758 661.292847,563.236450 
	C661.458130,562.334961 661.929932,561.662720 662.725830,561.004456 
	C663.171936,561.292786 663.327087,561.558533 663.401245,561.845154 
	C663.435730,561.978455 663.312866,562.152405 663.157837,562.660278 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M672.019836,564.016418 
	C671.598816,564.658386 671.177734,565.300415 670.336060,565.939453 
	C669.772705,565.821411 669.630127,565.706421 669.487488,565.591370 
	C670.086487,565.036926 670.685486,564.482483 671.623901,563.945801 
	C671.963257,563.963562 672.019836,564.016418 672.019836,564.016418 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M667.410522,556.868408 
	C667.217957,556.353638 667.393738,555.701050 667.805176,554.774902 
	C667.953491,555.244446 667.866272,555.987488 667.410522,556.868408 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M663.009644,575.437500 
	C662.972900,574.808594 663.161743,574.411560 663.523560,573.871155 
	C663.841370,573.855408 663.986145,573.982971 664.345337,574.308838 
	C664.414917,574.911255 664.270142,575.315369 663.958801,575.858948 
	C663.606567,575.888733 663.420898,575.778992 663.009644,575.437500 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M650.425171,590.934570 
	C650.122803,590.417725 650.197449,589.802490 650.665771,589.097412 
	C650.973694,589.617188 650.887939,590.226624 650.425171,590.934570 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M664.321167,560.097290 
	C664.151917,559.712219 664.306274,559.374329 664.755432,559.014648 
	C664.915100,559.376709 664.779907,559.760620 664.321167,560.097290 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M663.362793,561.124451 
	C663.168640,560.712402 663.315918,560.379761 663.756714,560.022461 
	C663.934814,560.399841 663.819458,560.801880 663.362793,561.124451 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M668.988281,562.984802 
	C669.075867,562.947205 669.173828,562.922607 669.148071,562.958252 
	C669.024353,563.018494 668.998657,562.997803 668.988281,562.984802 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M671.935547,563.936646 
	C672.063782,563.596497 672.219788,563.283264 672.651611,562.947876 
	C672.927429,562.925720 672.971069,562.970642 672.992493,562.993591 
	C672.843567,563.297791 672.673157,563.579041 672.261353,563.938354 
	C672.019836,564.016418 671.963257,563.963562 671.935547,563.936646 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M670.113647,566.126831 
	C669.972046,566.487183 669.632141,566.657227 669.292297,566.827271 
	C669.236633,566.556396 669.180908,566.285583 669.306335,565.803040 
	C669.630127,565.706421 669.772705,565.821411 670.113647,566.126831 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M649.327271,592.058655 
	C649.120667,591.569092 649.215088,591.087036 649.678955,590.818604 
	C649.908264,591.376831 649.768250,591.721497 649.327271,592.058655 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M651.412109,589.245605 
	C651.160339,588.700562 651.296204,588.347717 651.737854,588.020020 
	C651.962341,588.509399 651.881104,588.973572 651.412109,589.245605 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M652.422546,588.229858 
	C652.164734,587.671753 652.283386,587.299927 652.719971,586.990234 
	C652.958313,587.506958 652.878723,587.961609 652.422546,588.229858 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M512.980042,895.562195 
	C511.919464,895.890625 510.864838,895.936829 509.410156,895.639648 
	C509.021729,894.715454 509.033356,894.134705 509.045044,893.553833 
	C510.102295,893.487183 511.159546,893.420471 512.600403,893.472656 
	C512.984680,894.154358 512.985352,894.717163 512.980042,895.562195 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M633.022949,614.010498 
	C633.457764,613.698914 633.892578,613.387329 634.668457,613.207886 
	C635.329834,613.183716 635.650208,613.027405 635.970520,612.871094 
	C635.970520,612.871094 635.969543,612.950256 636.208130,612.977844 
	C636.632202,613.004578 636.817810,613.003662 637.003479,613.002686 
	C634.121094,617.867859 631.238708,622.732971 628.187073,627.789001 
	C622.234131,634.955933 616.450378,641.932007 610.331238,648.977722 
	C609.988037,648.328369 609.980164,647.609375 610.044861,646.452759 
	C610.117432,646.015076 610.055176,646.045044 610.365356,645.995361 
	C611.351318,644.938171 612.027161,643.930603 612.972900,642.720825 
	C614.418396,640.875183 615.593872,639.231812 616.998291,637.385620 
	C618.040955,635.979248 618.854614,634.775635 619.931396,633.351135 
	C620.816956,631.752563 621.439392,630.374878 622.028687,628.999878 
	C621.995544,629.002625 621.998474,629.069092 622.363708,629.226440 
	C622.842224,628.923645 622.955505,628.463562 623.036133,628.003479 
	C623.003418,628.003540 623.020996,628.066528 623.338013,628.107056 
	C623.434387,627.746399 623.213745,627.345154 622.913513,626.756958 
	C622.696655,626.329041 622.509827,626.135925 622.325073,625.861450 
	C622.376648,625.732178 622.436218,625.460144 622.646851,625.320557 
	C623.029114,624.894714 623.200684,624.608459 623.626343,624.151428 
	C624.643921,623.041199 625.407410,622.101746 626.430542,621.028870 
	C627.055725,620.440857 627.421204,619.986206 627.935852,619.434204 
	C628.273865,619.184143 628.422974,618.999512 628.738770,618.639221 
	C629.152039,618.233032 629.358826,617.970459 629.821594,617.520203 
	C631.059326,616.225220 632.041138,615.117859 633.022949,614.010498 
M626.277344,624.952942 
	C626.097229,625.499329 625.917114,626.045715 625.736938,626.592102 
	C626.207336,626.103333 626.677673,625.614624 627.408264,624.924622 
	C627.222656,624.892639 627.037048,624.860657 626.277344,624.952942 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M584.312012,683.934998 
	C584.313232,682.873596 584.330994,681.474731 585.008484,680.569824 
	C589.056091,675.163391 593.255615,669.870667 597.714233,664.772949 
	C593.560669,671.279297 589.097046,677.552551 584.312012,683.934998 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M607.289978,653.006104 
	C607.447876,651.784546 607.901062,650.537170 608.678833,649.506836 
	C609.020691,650.006897 609.037903,650.289978 609.055115,650.573059 
	C608.565125,651.375488 608.075195,652.177917 607.289978,653.006104 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M582.302307,686.121094 
	C582.434937,685.244995 582.901978,684.457214 583.704956,683.827332 
	C583.572876,684.726624 583.104797,685.468018 582.302307,686.121094 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M605.316040,655.122009 
	C605.440674,654.241150 605.900085,653.456482 606.695862,652.830627 
	C606.571777,653.732300 606.111267,654.475220 605.316040,655.122009 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M600.298462,661.925842 
	C600.106750,661.415222 600.228638,660.784668 600.687378,660.058533 
	C600.886841,660.577271 600.749451,661.191589 600.298462,661.925842 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M598.362305,664.937866 
	C598.106934,664.407959 598.206116,663.791199 598.662964,663.070312 
	C598.919373,663.594482 598.818115,664.222778 598.362305,664.937866 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M603.334351,657.936829 
	C603.119690,657.437866 603.253906,656.836304 603.703003,656.096802 
	C603.906311,656.583984 603.794739,657.209167 603.334351,657.936829 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M579.273499,690.905884 
	C579.115234,690.423279 579.252441,689.796875 579.709595,689.064453 
	C579.875977,689.559631 579.722473,690.160889 579.273499,690.905884 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M581.294922,687.916260 
	C581.121521,687.435974 581.261902,686.823303 581.713440,686.084290 
	C581.885986,686.566589 581.747375,687.175293 581.294922,687.916260 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M578.246826,692.021057 
	C578.166626,691.665222 578.340759,691.194092 578.780090,690.852356 
	C578.863953,691.289734 578.682617,691.597778 578.246826,692.021057 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M599.296631,663.110779 
	C599.113525,662.638184 599.255249,662.246948 599.718506,661.915283 
	C599.900574,662.380676 599.760986,662.786438 599.296631,663.110779 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M601.292358,660.068237 
	C601.112671,659.660706 601.256409,659.290344 601.716553,658.946899 
	C601.893860,659.350952 601.754822,659.728088 601.292358,660.068237 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M602.276489,659.027710 
	C602.108276,658.643250 602.242676,658.253296 602.706055,657.919922 
	C602.883118,658.325012 602.731140,658.673645 602.276489,659.027710 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M604.315186,656.139648 
	C604.119751,655.681030 604.274475,655.333679 604.727905,654.983032 
	C604.906189,655.403503 604.785706,655.827271 604.315186,656.139648 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M609.355591,650.408569 
	C609.037903,650.289978 609.020691,650.006897 608.971313,649.299561 
	C609.139771,648.740417 609.340332,648.605530 609.791931,648.732117 
	C609.914062,649.410461 609.785095,649.827332 609.355591,650.408569 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M580.273560,689.047852 
	C580.114258,688.663025 580.262512,688.288696 580.726562,687.942627 
	C580.888611,688.333435 580.734863,688.695923 580.273560,689.047852 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M636.560669,440.533203 
	C636.219727,440.530670 636.127441,440.528381 636.418884,440.384277 
	C639.083191,439.089294 641.455994,437.938416 644.013550,437.009583 
	C644.331299,437.370789 644.464355,437.510010 644.597351,437.649231 
	C642.032104,438.611389 639.466919,439.573578 636.560669,440.533203 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M648.120361,435.125488 
	C647.141541,435.902344 646.162720,436.679199 644.890625,437.552612 
	C644.464355,437.510010 644.331299,437.370789 644.216431,436.768280 
	C645.291809,435.642426 646.349121,434.979919 647.584229,434.519958 
	C647.881592,434.856842 648.000977,434.991180 648.120361,435.125488 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M654.410583,430.497986 
	C653.872559,431.028748 653.334473,431.559540 652.305359,431.953156 
	C651.670837,431.670227 651.527405,431.524475 651.607666,431.184875 
	C652.500427,430.593018 653.169495,430.195038 653.980286,429.973328 
	C654.122009,430.149597 654.410583,430.497986 654.410583,430.497986 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M652.029053,432.035034 
	C651.808289,432.580139 651.372620,432.906281 650.511963,433.156982 
	C650.086914,433.081543 649.743164,432.720459 649.898926,432.501709 
	C650.497742,431.981506 650.940857,431.680084 651.383972,431.378662 
	C651.527405,431.524475 651.670837,431.670227 652.029053,432.035034 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M656.008484,428.982452 
	C656.008484,428.982452 656.039124,429.426239 655.995239,429.644165 
	C655.601257,429.986877 655.251221,430.111694 654.655884,430.367249 
	C654.410583,430.497986 654.122009,430.149597 654.177795,429.722412 
	C654.825195,429.190979 655.416870,429.086700 656.008484,428.982452 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M649.277100,434.283325 
	C649.131104,434.595978 648.902588,434.827148 648.355957,435.051147 
	C648.000977,434.991180 647.881592,434.856842 647.764160,434.380798 
	C648.007446,433.856445 648.252258,433.678650 648.695190,433.699768 
	C649.018799,434.023651 649.147949,434.153503 649.277100,434.283325 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M650.257019,433.263763 
	C650.257629,433.732727 650.025208,433.953217 649.503479,434.195404 
	C649.147949,434.153503 649.018799,434.023651 648.873230,433.525940 
	C649.119873,432.946625 649.415344,432.800720 649.743164,432.720459 
	C649.743164,432.720459 650.086914,433.081543 650.257019,433.263763 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M508.704681,893.433472 
	C509.033356,894.134705 509.021729,894.715454 509.016479,895.587402 
	C508.569794,895.935486 508.116730,895.992371 507.323792,895.994385 
	C506.981079,895.337158 506.978241,894.734741 506.966003,893.832458 
	C507.425842,893.459412 507.895081,893.386230 508.704681,893.433472 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M580.558838,927.128784 
	C580.988281,927.774048 580.948303,928.385986 580.907654,929.305054 
	C572.571594,929.509766 564.236084,929.407349 555.411255,928.738892 
	C554.879700,927.868652 554.837463,927.564453 554.795166,927.260254 
	C563.226562,927.205322 571.658020,927.150391 580.558838,927.128784 
z"/>
<path fill="#1D0303" opacity="1.000000" stroke="none" 
	d="
M554.310059,927.326660 
	C554.837463,927.564453 554.879700,927.868652 554.967102,928.631348 
	C554.616455,928.524231 554.220642,927.958618 554.310059,927.326660 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M651.972412,575.000000 
	C648.731262,579.448914 645.490173,583.897827 641.828857,588.812256 
	C640.946350,590.181396 640.484070,591.084961 640.021729,591.988525 
	C640.021729,591.988525 640.001160,592.004272 639.661438,592.016541 
	C637.917908,593.424133 636.514160,594.819519 634.980347,595.951904 
	C634.547852,595.450134 634.245422,595.211365 633.942993,594.972534 
	C639.397583,588.399353 644.852173,581.826172 650.863464,575.105713 
	C651.604248,574.972351 651.788330,574.986145 651.972412,575.000000 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M633.679077,595.139160 
	C634.245422,595.211365 634.547852,595.450134 634.795410,596.258301 
	C633.457153,597.528870 632.173706,598.230103 630.893921,598.609619 
	C630.950989,597.878845 631.004333,597.469788 631.057739,597.060669 
	C631.057678,597.060669 631.010193,597.119080 631.026184,597.152466 
	C631.833191,596.559204 632.624207,595.932495 633.679077,595.139160 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M655.666138,569.795654 
	C655.204651,571.115723 654.436035,572.214417 653.340210,573.151855 
	C653.795044,571.851807 654.577087,570.713074 655.666138,569.795654 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M658.690247,565.909546 
	C658.565247,566.758789 658.103821,567.538086 657.315796,568.162354 
	C657.444031,567.284790 657.898926,566.562195 658.690247,565.909546 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M652.242065,574.806274 
	C651.788330,574.986145 651.604248,574.972351 651.145020,574.928101 
	C651.376343,574.281494 651.882935,573.665344 652.690918,573.031006 
	C652.832153,573.546082 652.671936,574.079285 652.242065,574.806274 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M660.663025,563.083130 
	C660.907410,563.599915 660.808777,564.215088 660.350037,564.926880 
	C660.099915,564.409485 660.209961,563.795532 660.663025,563.083130 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M656.642700,568.073242 
	C656.919495,568.609009 656.833374,569.230957 656.379761,569.910156 
	C656.101379,569.364685 656.190613,568.762085 656.642700,568.073242 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M634.275452,590.863647 
	C634.286255,590.457520 634.553650,589.880676 635.138916,589.194641 
	C635.148499,589.621277 634.840271,590.157104 634.275452,590.863647 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M659.678833,564.895325 
	C659.866882,565.317688 659.712524,565.650696 659.268311,566.005371 
	C659.097778,565.620056 659.217163,565.213013 659.678833,564.895325 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M632.353516,593.320190 
	C632.266174,593.125061 632.430115,592.748047 632.857849,592.203613 
	C632.949402,592.403564 632.777100,592.770935 632.353516,593.320190 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M633.289795,592.008545 
	C633.231018,591.832031 633.409180,591.545593 633.811523,591.134521 
	C633.866089,591.306152 633.696472,591.602356 633.289795,592.008545 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M638.286072,585.028748 
	C638.239624,584.850586 638.443970,584.567200 638.896729,584.184631 
	C638.942383,584.364807 638.739624,584.644165 638.286072,585.028748 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M639.363953,584.085510 
	C639.281128,583.867432 639.462463,583.571106 639.886719,583.165039 
	C639.962463,583.372620 639.795288,583.689941 639.363953,584.085510 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M640.304321,583.025879 
	C640.238403,582.835754 640.419006,582.550110 640.828491,582.146973 
	C640.888489,582.329712 640.719666,582.630005 640.304321,583.025879 
z"/>
<path fill="#659AA8" opacity="1.000000" stroke="none" 
	d="
M641.251465,581.950745 
	C641.113281,581.739929 641.277161,581.473816 641.724243,581.132507 
	C641.892517,581.350220 641.741211,581.629578 641.251465,581.950745 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M545.686523,946.707092 
	C544.570618,946.964233 543.245728,946.954346 541.526550,946.957031 
	C542.580688,946.793152 544.029175,946.616638 545.686523,946.707092 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M896.876343,578.886108 
	C897.467102,575.993713 898.057800,573.101318 898.806885,569.832764 
	C899.410400,570.973206 900.281799,572.508423 900.216431,574.002563 
	C900.054504,577.704895 899.464233,581.388550 899.037598,585.532715 
	C898.670471,587.788086 898.311829,589.590576 897.831909,591.794189 
	C897.160583,592.180908 896.610474,592.166382 895.702637,591.710876 
	C895.855347,587.142029 896.365845,583.014038 896.876343,578.886108 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M901.790405,549.340149 
	C902.359680,549.540344 902.720703,550.081604 903.193970,550.945435 
	C902.871582,555.538025 902.586853,559.831604 901.925781,564.066406 
	C901.673340,565.683960 900.558960,567.167053 899.398682,568.609863 
	C899.834167,562.232605 900.708252,555.956970 901.790405,549.340149 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M883.229980,569.951660 
	C883.624817,569.715149 883.943848,569.784668 884.563599,570.130005 
	C884.604187,571.339783 884.358215,572.209412 884.062195,573.487854 
	C883.782104,576.353577 883.552124,578.810486 883.322144,581.267456 
	C882.955078,583.602966 882.588013,585.938477 881.880310,588.885193 
	C881.572266,590.405151 881.604919,591.313843 881.637634,592.222595 
	C881.458008,593.224731 881.278320,594.226868 881.045166,595.607483 
	C880.650024,595.947632 880.308472,595.909241 879.711487,595.823853 
	C879.640991,590.202454 879.825928,584.628052 879.964600,578.601440 
	C880.992310,575.540161 882.066284,572.931152 883.229980,569.951660 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M884.112305,573.079102 
	C884.358215,572.209412 884.604187,571.339783 884.811157,570.129150 
	C885.497070,564.803833 886.221985,559.819580 886.924438,554.413696 
	C887.950134,548.621521 888.998108,543.250793 890.114746,537.473022 
	C890.599121,537.051208 891.015015,537.036621 891.743469,537.016113 
	C891.411560,542.730713 890.767029,548.451233 890.017944,554.983826 
	C889.590576,557.729675 889.066284,559.655457 888.990234,561.598816 
	C888.863708,564.833252 889.024597,568.079041 889.088684,571.462891 
	C889.112915,571.605713 889.084656,571.894165 889.084656,571.894165 
	C888.274475,572.165894 887.464233,572.437622 886.035767,572.857544 
	C884.982422,573.030212 884.547363,573.054688 884.112305,573.079102 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M889.051025,572.329956 
	C889.084656,571.894165 889.112915,571.605713 889.177368,571.156860 
	C889.414734,570.100403 889.587769,569.492737 890.012268,569.043579 
	C890.754333,569.815979 891.244934,570.429810 891.736938,571.440918 
	C890.888367,575.890259 890.038391,579.942200 889.188354,583.994202 
	C888.952332,583.973450 888.716370,583.952637 888.141846,583.818604 
	C888.207947,580.058899 888.612610,576.412354 889.051025,572.329956 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M884.062195,573.487854 
	C884.547363,573.054688 884.982422,573.030212 885.746033,573.010864 
	C885.800293,575.481140 885.526062,577.946228 885.046875,580.767944 
	C884.503723,581.149963 884.165527,581.175415 883.574707,581.234131 
	C883.552124,578.810486 883.782104,576.353577 884.062195,573.487854 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M637.161316,612.749329 
	C636.817810,613.003662 636.632202,613.004578 636.171509,612.962646 
	C636.036011,612.459839 636.175659,611.999817 636.668091,611.759277 
	C637.120239,612.151245 637.219666,612.323608 637.161316,612.749329 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M637.328857,612.102661 
	C637.059448,611.558289 637.143921,611.085449 637.627808,610.794800 
	C637.912354,611.376038 637.797485,611.775085 637.328857,612.102661 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M881.807434,591.940674 
	C881.604919,591.313843 881.572266,590.405151 881.816284,589.270447 
	C882.054382,589.915894 882.015808,590.787292 881.807434,591.940674 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M896.645630,578.717896 
	C896.365845,583.014038 895.855347,587.142029 895.429077,591.668213 
	C895.354675,593.094727 895.196045,594.122864 894.961182,595.533325 
	C894.419128,595.673889 893.953430,595.432129 893.225952,594.628113 
	C893.553040,589.090088 894.141968,584.114258 894.832703,578.839600 
	C895.234802,578.007019 895.535095,577.473267 895.835327,576.939514 
	C896.028564,577.476196 896.221741,578.012939 896.645630,578.717896 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M889.104004,584.415833 
	C890.038391,579.942200 890.888367,575.890259 891.827759,571.119263 
	C892.335754,569.027527 892.754395,567.654724 893.173096,566.281921 
	C893.445862,566.211609 893.718689,566.141296 894.413696,566.361328 
	C894.254395,570.151306 893.672913,573.651001 893.052063,577.551697 
	C891.951904,584.587524 890.891052,591.222229 889.830261,597.856995 
	C889.392944,597.908997 888.955688,597.960938 888.187988,597.991089 
	C888.244995,593.592041 888.632385,589.214783 889.104004,584.415833 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M889.880249,598.218872 
	C890.891052,591.222229 891.951904,584.587524 893.278564,577.679199 
	C893.939880,577.983215 894.335388,578.560852 894.730896,579.138489 
	C894.141968,584.114258 893.553040,589.090088 892.988525,594.513672 
	C892.726074,596.399658 892.439209,597.837891 892.046143,599.632812 
	C891.527100,599.967834 891.114441,599.946106 890.396423,599.976074 
	C890.037476,599.545349 889.983826,599.063049 889.880249,598.218872 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M545.032104,557.635681 
	C544.372375,558.437256 543.417786,559.135803 543.096313,560.055664 
	C535.690125,581.244934 528.374268,602.465759 520.980103,623.659302 
	C519.842590,626.919861 518.403870,630.075378 516.785461,633.590332 
	C516.030945,633.605347 515.595337,633.309570 515.159790,633.013794 
	C521.880188,613.609741 528.655884,594.224487 535.286255,574.789734 
	C537.199707,569.181152 538.695374,563.429993 540.540894,557.364868 
	C542.128723,556.983276 543.558228,556.981018 545.002625,557.142822 
	C545.017456,557.306763 545.032104,557.635681 545.032104,557.635681 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M455.974548,630.125793 
	C446.855865,630.125793 437.737183,630.125793 428.618500,630.125793 
	C428.601166,629.347656 428.583862,628.569458 428.566559,627.791321 
	C445.375153,627.114563 462.183777,626.437866 479.454224,625.770996 
	C478.895081,626.747742 477.874054,627.714661 476.353821,628.989624 
	C472.570862,629.533142 469.287140,629.768738 465.629700,629.972412 
	C464.510376,629.997498 463.764801,630.054382 462.581360,630.058716 
	C460.087219,630.046082 458.030884,630.085938 455.974548,630.125793 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M424.979736,622.510742 
	C426.452606,622.021362 427.925507,621.531921 429.972839,621.337646 
	C432.022614,621.729187 433.509613,621.995300 434.971313,621.894531 
	C445.037964,621.200134 455.104279,620.489197 465.160095,619.655640 
	C469.734070,619.276550 474.283844,618.605774 478.844604,618.067322 
	C478.844604,618.067322 479.208191,618.261597 479.511017,618.399780 
	C480.543884,618.496887 481.273956,618.455750 482.003998,618.414673 
	C483.374847,618.414673 484.745697,618.414673 486.116547,618.414673 
	C486.155457,618.724426 486.194397,619.034180 486.233307,619.343933 
	C485.074310,619.617798 483.915283,619.891663 481.981628,620.205444 
	C467.493683,621.216370 453.780365,622.187256 439.600037,623.092651 
	C434.415314,622.854980 429.697510,622.682861 424.979736,622.510742 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M448.028503,618.651550 
	C448.032043,618.246948 448.035583,617.842285 448.459534,617.162842 
	C452.141418,616.492310 455.451813,616.315186 458.642059,615.601318 
	C460.063110,615.283325 461.242279,613.884521 462.531250,612.976318 
	C462.531250,612.976318 462.493103,612.945312 462.660400,613.429626 
	C464.430115,614.107544 466.046417,614.543457 467.632538,614.452698 
	C474.420593,614.063782 481.199738,613.519714 487.982147,613.032227 
	C489.712555,613.032227 491.442963,613.032227 493.173401,613.032227 
	C493.231781,613.479492 493.290161,613.926819 493.348541,614.374084 
	C489.156616,614.772400 484.964691,615.170654 479.889709,615.580322 
	C468.680634,616.611694 458.354553,617.631592 448.028503,618.651550 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M447.602631,618.759033 
	C458.354553,617.631592 468.680634,616.611694 479.458557,615.681641 
	C479.765198,616.163513 479.620026,616.555481 479.159729,617.507324 
	C474.283844,618.605774 469.734070,619.276550 465.160095,619.655640 
	C455.104279,620.489197 445.037964,621.200134 434.971313,621.894531 
	C433.509613,621.995300 432.022614,621.729187 430.274780,621.335022 
	C435.727081,620.313660 441.451904,619.590088 447.602631,618.759033 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M440.981445,639.131592 
	C439.272003,637.233032 437.562561,635.334473 435.730682,633.299988 
	C438.100037,632.926270 440.191620,632.596375 442.549744,632.437744 
	C443.606628,634.315552 447.204193,634.695190 445.227325,637.709778 
	C446.226166,638.136902 447.188721,638.713440 448.229858,638.966370 
	C454.100128,640.392517 459.989258,641.740967 466.333374,643.368896 
	C466.794769,643.622009 467.114014,643.976440 467.114014,643.976440 
	C466.911407,644.270569 466.708801,644.564697 465.878967,644.839355 
	C457.151184,643.210327 449.050598,641.600708 440.950012,639.991150 
	C440.950012,639.991150 441.011353,639.991394 441.059753,639.790161 
	C441.108124,639.588928 440.981445,639.131592 440.981445,639.131592 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M440.067017,623.158203 
	C453.780365,622.187256 467.493683,621.216370 481.630066,620.321777 
	C482.037750,621.019653 482.022430,621.641174 481.650177,622.680176 
	C481.061493,623.207153 480.829742,623.316650 480.597992,623.426086 
	C477.800476,623.417053 475.002991,623.408081 471.325378,623.280090 
	C469.828644,623.251404 469.212006,623.341675 468.595337,623.431885 
	C468.335571,623.493958 468.072510,623.515442 467.154053,623.335571 
	C463.674011,623.438660 460.846069,623.702637 458.018127,623.966553 
	C452.354950,624.127686 446.691772,624.288818 440.585999,624.170715 
	C440.062531,623.652832 440.037079,623.408386 440.067017,623.158203 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M479.595093,570.761841 
	C479.121796,570.383667 479.071198,570.001221 479.032654,569.329285 
	C482.101532,568.735046 485.158295,568.430237 488.951904,568.075562 
	C490.308197,567.769043 490.927673,567.512451 491.547150,567.255859 
	C497.555786,566.916626 503.564392,566.577393 509.967834,566.415833 
	C510.240845,567.122192 510.119080,567.650879 510.101440,568.456909 
	C500.143005,569.408691 490.080414,570.083130 479.595093,570.761841 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M474.946259,672.796692 
	C468.009125,670.788635 460.151733,670.886902 454.084564,666.369019 
	C454.247406,665.907166 454.410278,665.445374 454.573120,664.983582 
	C460.478333,666.181458 466.383575,667.379272 472.644348,668.289246 
	C472.999908,668.001343 472.982971,667.983826 472.884888,668.332397 
	C473.199829,668.917969 473.612823,669.154846 474.025848,669.391785 
	C474.340790,670.416870 474.655731,671.441895 474.946259,672.796692 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M440.935120,640.335815 
	C449.050598,641.600708 457.151184,643.210327 465.625977,644.950806 
	C465.998352,645.836975 465.996490,646.592346 465.590515,647.727539 
	C458.107147,646.512024 451.027954,644.916626 443.948730,643.321228 
	C442.939240,642.440918 441.929718,641.560669 440.935120,640.335815 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M475.571289,596.893433 
	C475.158630,596.282288 475.190765,595.685425 475.258148,594.791870 
	C475.640472,594.460022 475.987579,594.424927 476.838074,594.566650 
	C478.263367,594.545410 479.185242,594.347473 480.107147,594.149536 
	C486.232025,593.555908 492.353760,592.922913 498.487091,592.435730 
	C499.038574,592.391968 499.664948,593.290466 500.570984,593.821777 
	C500.885406,593.892273 501.031921,594.357422 500.664368,594.774109 
	C495.217316,595.737183 490.137787,596.283569 485.058289,596.829956 
	C482.044250,596.855896 479.030182,596.881836 475.571289,596.893433 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M452.399567,659.485474 
	C451.254120,657.551086 450.108673,655.616760 449.361694,653.153076 
	C455.299408,654.059021 460.819977,655.574463 466.391113,656.872803 
	C467.772064,657.194580 469.317017,656.812744 470.786560,656.754333 
	C470.855652,657.877502 470.924744,659.000732 470.607117,660.575439 
	C466.015594,659.821106 461.853455,658.407227 457.581024,657.531250 
	C456.231750,657.254700 454.587372,658.417542 452.906982,659.060852 
	C452.735901,659.200073 452.399567,659.485474 452.399567,659.485474 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M469.098724,606.278076 
	C474.067566,605.264648 479.078278,604.589172 484.875488,603.944092 
	C486.773895,604.214172 487.885803,604.453857 488.997681,604.693542 
	C486.743073,605.466492 484.488495,606.239441 482.233887,607.012390 
	C482.298584,607.163269 482.323090,607.364929 482.436310,607.454407 
	C482.674622,607.642639 482.959473,607.771912 483.254730,608.274902 
	C479.590088,608.782837 475.895966,608.941284 472.201843,609.099731 
	C471.181427,608.271851 470.160980,607.443909 469.098724,606.278076 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M446.970154,649.366028 
	C446.343933,648.722351 445.717743,648.078613 445.085052,646.835205 
	C452.732666,647.917969 460.386749,649.600342 468.040833,651.282715 
	C467.619293,652.144470 467.197754,653.006287 466.399048,654.071045 
	C459.671295,652.637939 453.320740,651.002014 446.970154,649.366028 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M487.978790,612.656250 
	C481.199738,613.519714 474.420593,614.063782 467.632538,614.452698 
	C466.046417,614.543457 464.430115,614.107544 462.639587,613.442627 
	C464.468872,612.661316 466.486267,612.351318 469.246429,611.743652 
	C476.010406,610.972961 482.031647,610.499817 488.052887,610.026611 
	C488.027069,610.777893 488.001251,611.529114 487.978790,612.656250 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M473.007111,666.538757 
	C466.225220,664.677917 459.433807,662.410522 452.520996,659.814331 
	C452.399567,659.485474 452.735901,659.200073 453.293152,659.079895 
	C458.779602,660.267639 463.683502,661.691956 468.661835,662.773438 
	C469.532562,662.962646 470.716309,661.710999 471.755493,661.124756 
	C472.169525,662.793945 472.583557,664.463074 473.007111,666.538757 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M456.983887,674.839783 
	C456.623932,673.818726 456.264008,672.797607 455.605255,670.928833 
	C462.293701,672.322815 468.207245,673.555298 474.504883,674.791199 
	C474.927429,675.569336 474.965851,676.344116 474.610626,677.520874 
	C468.472626,676.895203 462.728241,675.867493 456.983887,674.839783 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M468.345306,651.166626 
	C460.386749,649.600342 452.732666,647.917969 444.862854,646.577271 
	C444.418579,645.932495 444.190063,644.946045 443.955139,643.640381 
	C451.027954,644.916626 458.107147,646.512024 465.589539,648.105347 
	C466.748199,648.374634 467.503693,648.645935 468.635437,649.233398 
	C469.109650,649.689880 469.207611,649.830261 469.305603,649.970642 
	C469.086975,650.330627 468.868378,650.690552 468.345306,651.166626 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M514.929749,633.284912 
	C515.595337,633.309570 516.030945,633.605347 516.724121,633.961914 
	C516.743774,634.810974 516.505798,635.599243 516.196655,636.692200 
	C514.429565,641.075195 512.733582,645.153564 510.720947,649.808350 
	C509.914795,651.572266 509.425354,652.759705 508.935913,653.947083 
	C508.174316,655.098633 507.412720,656.250183 506.371277,657.324219 
	C506.091370,657.246643 505.924011,656.796997 505.924011,656.796997 
	C508.849213,649.049988 511.774445,641.303040 514.929749,633.284912 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M485.035034,597.146118 
	C490.137787,596.283569 495.217316,595.737183 500.687195,595.016846 
	C502.686707,595.282227 504.295807,595.721436 506.636200,596.360352 
	C505.250610,597.160339 504.499969,597.593689 502.950195,598.026245 
	C496.778900,598.521729 491.406708,599.018127 486.034485,599.514526 
	C485.915741,599.203857 485.696503,599.002502 485.165283,598.818237 
	C484.973083,598.304749 484.992462,597.883545 485.035034,597.146118 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M447.026917,649.679077 
	C453.320740,651.002014 459.671295,652.637939 466.412872,654.345642 
	C468.029480,655.027771 469.255066,655.638184 470.633606,656.501465 
	C469.317017,656.812744 467.772064,657.194580 466.391113,656.872803 
	C460.819977,655.574463 455.299408,654.059021 449.366211,652.815186 
	C448.972290,653.006653 449.022430,653.004028 449.033539,652.802307 
	C448.808716,652.388916 448.532318,652.280457 448.215454,652.275085 
	C447.838196,651.514099 447.460907,650.753113 447.026917,649.679077 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M460.302032,687.527527 
	C459.275818,686.748413 458.249603,685.969299 457.223389,685.190247 
	C458.485657,684.813354 459.793823,684.008057 461.001801,684.137573 
	C465.090271,684.575806 469.143250,685.345215 473.584747,686.148621 
	C473.307251,687.281677 472.654846,688.254150 471.622559,689.499512 
	C467.595825,689.024109 463.948914,688.275818 460.302032,687.527527 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M510.112122,563.643616 
	C504.351837,564.314758 498.628113,564.762756 492.900452,565.152893 
	C489.564819,565.380066 486.222870,565.514832 482.883759,565.691162 
	C482.863647,565.018188 482.843506,564.345276 482.823364,563.672302 
	C491.566315,563.481628 500.309235,563.291016 509.513306,563.013794 
	C509.974457,562.927185 510.147064,563.396179 510.112122,563.643616 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M476.574341,585.703247 
	C476.113892,585.306702 476.082520,584.947571 476.043274,584.317993 
	C483.814514,583.406799 491.593628,582.766113 499.802612,582.255249 
	C500.080261,583.019287 499.928040,583.653564 499.918091,584.591431 
	C492.374756,585.176941 484.689087,585.458862 476.574341,585.703247 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M470.988129,682.778870 
	C467.260498,682.427490 463.544525,681.942932 459.975159,680.937805 
	C458.791748,680.604492 457.985321,678.932434 457.412170,677.603699 
	C463.556427,678.351440 469.295074,679.367859 475.033722,680.384277 
	C473.708130,681.070435 472.382568,681.756592 470.988129,682.778870 
z"/>
<path fill="#ECE2CC" opacity="1.000000" stroke="none" 
	d="
M489.342651,604.687622 
	C487.885803,604.453857 486.773895,604.214172 485.315674,603.903381 
	C483.549133,603.544006 482.128967,603.255737 480.329224,602.933716 
	C481.763855,602.274109 483.578064,601.648254 486.135437,601.154541 
	C491.771332,601.172668 496.664093,601.058716 501.556885,600.944824 
	C499.747131,601.798706 497.937347,602.652588 495.433258,603.289673 
	C493.727570,603.460632 492.716217,603.848389 491.704865,604.236206 
	C491.032440,604.384705 490.360016,604.533203 489.342651,604.687622 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M475.306000,680.209839 
	C469.295074,679.367859 463.556427,678.351440 457.445740,677.224243 
	C457.060333,676.608093 457.046967,676.102661 457.008728,675.218506 
	C462.728241,675.867493 468.472626,676.895203 474.619293,677.908813 
	C475.207184,678.608337 475.392731,679.321838 475.306000,680.209839 
z"/>
<path fill="#FAF4DE" opacity="1.000000" stroke="none" 
	d="
M200.271637,522.513245 
	C200.691544,521.929321 201.507736,521.080322 202.332550,521.071838 
	C210.258057,520.990723 218.185181,521.032776 226.111160,521.109924 
	C226.684616,521.115479 227.252731,521.672180 228.065125,522.384399 
	C219.095245,522.743530 209.883606,522.690857 200.271637,522.513245 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M471.565521,661.115295 
	C470.716309,661.710999 469.532562,662.962646 468.661835,662.773438 
	C463.683502,661.691956 458.779602,660.267639 453.464233,658.940674 
	C454.587372,658.417542 456.231750,657.254700 457.581024,657.531250 
	C461.853455,658.407227 466.015594,659.821106 470.620667,660.997620 
	C471.020935,660.968323 471.375580,661.105835 471.565521,661.115295 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M488.439636,609.995483 
	C482.031647,610.499817 476.010406,610.972961 469.534485,611.510498 
	C469.959412,610.874512 470.839050,610.174133 471.960266,609.286743 
	C475.895966,608.941284 479.590088,608.782837 483.604492,608.390747 
	C486.745850,608.157043 489.566864,608.157043 492.387909,608.157043 
	C492.441284,608.521545 492.494659,608.885986 492.548004,609.250488 
	C491.307495,609.488464 490.066956,609.726440 488.439636,609.995483 
z"/>
<path fill="#2B2829" opacity="1.000000" stroke="none" 
	d="
M501.850067,600.906189 
	C496.664093,601.058716 491.771332,601.172668 486.548340,601.109131 
	C486.184814,600.696106 486.151489,600.460449 486.076294,599.869629 
	C491.406708,599.018127 496.778900,598.521729 502.591461,598.096924 
	C502.735596,599.068237 502.439423,599.967896 501.850067,600.906189 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M510.055054,578.353027 
	C506.710815,578.936462 503.356903,579.180054 499.472412,579.268616 
	C498.552429,579.201355 498.163055,579.289185 497.773651,579.376953 
	C497.475739,579.340271 497.177307,579.308350 496.605286,578.997742 
	C496.283875,578.184326 496.235535,577.654480 496.187225,577.124573 
	C500.503021,576.669739 504.818848,576.214844 509.555389,575.869812 
	C509.999237,576.657471 510.022308,577.335327 510.055054,578.353027 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M496.258636,572.436768 
	C500.913422,571.925049 505.568176,571.413330 510.648010,571.026184 
	C510.952209,571.594482 510.831299,572.038269 510.799255,572.817627 
	C506.256287,573.444092 501.624542,573.734985 496.559570,573.744263 
	C496.170471,573.120667 496.214569,572.778748 496.258636,572.436768 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M460.142395,687.814392 
	C463.948914,688.275818 467.595825,689.024109 471.626465,689.816284 
	C471.389862,690.273621 470.744446,691.075562 470.153351,691.037292 
	C466.377441,690.793213 462.613464,690.364197 458.430084,689.883118 
	C458.670624,689.213928 459.326691,688.657593 460.142395,687.814392 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M514.434204,561.905273 
	C514.780762,556.710449 518.800354,559.942322 521.656372,559.047119 
	C522.755188,559.616577 523.414062,560.171021 524.072998,560.725525 
	C524.014465,561.144470 523.955994,561.563416 523.897461,561.982361 
	C520.889954,561.982361 517.882385,561.982361 514.434204,561.905273 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M466.003387,630.004272 
	C469.287140,629.768738 472.570862,629.533142 476.252075,629.347900 
	C475.319519,630.070923 474.053253,631.041992 472.643860,631.343140 
	C470.759186,631.745850 468.763489,631.628967 466.385681,631.398804 
	C465.971710,630.709656 465.987549,630.356934 466.003387,630.004272 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M455.883179,630.478638 
	C458.030884,630.085938 460.087219,630.046082 462.583252,630.324463 
	C463.028076,630.997437 463.033234,631.352234 463.038361,631.706970 
	C459.224640,631.919983 455.410919,632.132935 451.226837,632.220764 
	C452.501556,631.674255 454.146667,631.252869 455.883179,630.478638 
z"/>
<path fill="#E9F2EE" opacity="1.000000" stroke="none" 
	d="
M544.987793,556.978821 
	C543.558228,556.981018 542.128723,556.983276 540.272095,557.027405 
	C537.608582,556.750916 535.372070,556.432495 533.313721,555.723877 
	C535.066895,555.197632 536.641907,555.061523 538.216858,554.925415 
	C540.248657,555.181213 542.280457,555.436951 544.655090,556.094604 
	C544.997925,556.496460 544.987793,556.978821 544.987793,556.978821 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M514.442871,566.098816 
	C513.998230,565.669250 513.985107,565.292053 513.978271,564.631836 
	C516.532654,564.348755 519.080627,564.348755 521.628662,564.348755 
	C521.662659,564.756409 521.696716,565.164062 521.730774,565.571777 
	C519.445312,565.764893 517.159912,565.958069 514.442871,566.098816 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M500.572266,669.648071 
	C500.113434,671.547974 499.371277,673.690186 498.256622,675.884766 
	C498.685730,673.921509 499.487335,671.905884 500.572266,669.648071 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M517.295837,570.722290 
	C516.997253,570.179077 517.038574,569.772095 517.091797,569.056152 
	C518.016418,568.793335 518.929199,568.839417 519.841919,568.885437 
	C519.106567,569.543091 518.371216,570.200745 517.295837,570.722290 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M505.672760,657.058594 
	C505.924011,656.796997 506.091370,657.246643 506.125580,657.482056 
	C505.548889,659.338135 504.937958,660.958923 503.970795,662.700684 
	C504.216858,660.987915 504.819183,659.153992 505.672760,657.058594 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M497.656311,676.127930 
	C497.403442,677.521667 496.881622,679.146301 495.997253,680.932983 
	C496.218964,679.516296 496.803192,677.937561 497.656311,676.127930 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M513.307983,580.887207 
	C513.029602,580.348633 513.068420,579.805176 513.152405,578.854736 
	C513.741821,578.507141 514.286072,578.566589 514.830261,578.625977 
	C514.428589,579.378113 514.026855,580.130188 513.307983,580.887207 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M503.424622,662.954834 
	C503.292480,664.055237 502.898315,665.361328 502.161560,666.801758 
	C502.266876,665.677551 502.714752,664.419128 503.424622,662.954834 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M537.881287,554.717163 
	C536.641907,555.061523 535.066895,555.197632 532.934570,555.529297 
	C532.377136,555.724792 532.397095,555.770142 532.370789,555.801514 
	C532.279419,555.523743 532.214355,555.214661 532.074707,554.437744 
	C533.848633,554.149597 535.697144,554.329285 537.881287,554.717163 
z"/>
<path fill="#6998A5" opacity="1.000000" stroke="none" 
	d="
M463.401154,631.766113 
	C463.033234,631.352234 463.028076,630.997437 463.021057,630.376953 
	C463.764801,630.054382 464.510376,629.997498 465.629700,629.972412 
	C465.987549,630.356934 465.971710,630.709656 465.948700,631.326782 
	C465.215668,631.669250 464.489807,631.747253 463.401154,631.766113 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M492.004364,604.415894 
	C492.716217,603.848389 493.727570,603.460632 495.047028,603.254272 
	C494.338043,603.822327 493.320953,604.208984 492.004364,604.415894 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M493.573212,685.691162 
	C493.408783,686.854126 492.972900,688.267822 492.191589,689.856689 
	C492.331360,688.668579 492.816528,687.305176 493.573212,685.691162 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M495.453583,681.524841 
	C495.407532,682.484619 495.074036,683.653015 494.358795,684.910645 
	C494.373413,683.911072 494.769775,682.822266 495.453583,681.524841 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M481.807007,618.083130 
	C481.273956,618.455750 480.543884,618.496887 479.712830,618.357849 
	C480.277863,618.035583 480.943939,617.893555 481.807007,618.083130 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M480.737000,623.695251 
	C480.829742,623.316650 481.061493,623.207153 481.663025,622.991455 
	C481.949036,623.606201 481.865204,624.327209 481.501099,625.082825 
	C481.105927,624.733093 480.990967,624.348755 480.737000,623.695251 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M473.717041,683.498657 
	C473.118195,683.635620 472.272064,683.563416 471.168427,683.288147 
	C471.763885,683.153198 472.616791,683.221375 473.717041,683.498657 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M501.630005,667.043945 
	C501.726501,667.457214 501.569244,668.060852 501.150269,668.852783 
	C501.051117,668.438782 501.213715,667.836609 501.630005,667.043945 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M473.006165,668.011841 
	C473.107178,667.851746 473.201965,667.681213 473.192505,667.237183 
	C473.533264,667.174072 473.978271,667.384338 474.017700,667.687683 
	C473.402405,667.848389 473.192688,667.916138 472.982971,667.983826 
	C472.982971,667.983826 472.999908,668.001343 473.006165,668.011841 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M489.622498,695.029053 
	C489.721802,695.433960 489.577545,696.027466 489.174255,696.799500 
	C489.069794,696.391235 489.224365,695.804443 489.622498,695.029053 
z"/>
<path fill="#6998A5" opacity="1.000000" stroke="none" 
	d="
M472.884888,668.332397 
	C473.192688,667.916138 473.402405,667.848389 473.930817,667.868286 
	C474.233673,668.198181 474.217865,668.440552 474.113953,669.037354 
	C473.612823,669.154846 473.199829,668.917969 472.884888,668.332397 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M469.402649,649.826660 
	C469.207611,649.830261 469.109650,649.689880 468.928375,649.306580 
	C469.063293,649.270020 469.281494,649.476379 469.402649,649.826660 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M467.240784,643.840088 
	C467.114014,643.976440 466.794769,643.622009 466.704681,643.401245 
	C466.865570,643.354919 467.116577,643.529358 467.240784,643.840088 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M488.116760,698.254639 
	C488.336060,698.231567 488.445465,698.420044 488.497101,698.623169 
	C488.511505,698.679810 488.355011,698.779907 488.117737,698.919312 
	C487.967407,698.809692 487.976257,698.640686 488.116760,698.254639 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M489.970764,694.213379 
	C490.217346,694.209656 490.343536,694.400208 490.423676,694.608398 
	C490.437317,694.643921 490.254120,694.755188 489.981079,694.863892 
	C489.800232,694.896301 489.834808,694.413513 489.970764,694.213379 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M491.578613,690.553345 
	C491.725128,690.763794 491.606476,691.167786 491.251160,691.784912 
	C491.114136,691.580994 491.213745,691.163879 491.578613,690.553345 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M462.074036,613.011353 
	C461.242279,613.884521 460.063110,615.283325 458.642059,615.601318 
	C455.451813,616.315186 452.141418,616.492310 448.471130,616.859497 
	C448.427856,616.249390 448.711426,615.241211 449.171814,615.152710 
	C453.302490,614.358704 457.464233,613.726318 462.074036,613.011353 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M439.600037,623.092651 
	C440.037079,623.408386 440.062531,623.652832 440.178772,624.071411 
	C433.201172,624.251404 426.188171,624.251404 419.175171,624.251404 
	C419.158173,623.941772 419.141205,623.632202 419.124207,623.322571 
	C420.807251,623.114014 422.490295,622.905457 424.576538,622.603760 
	C429.697510,622.682861 434.415314,622.854980 439.600037,623.092651 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M458.332886,624.183228 
	C460.846069,623.702637 463.674011,623.438660 466.957703,623.306152 
	C464.491516,623.758423 461.569580,624.079224 458.332886,624.183228 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M468.741089,623.646118 
	C469.212006,623.341675 469.828644,623.251404 470.905548,623.264648 
	C470.539459,623.532227 469.713165,623.696228 468.741089,623.646118 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M448.298126,652.453003 
	C448.532318,652.280457 448.808716,652.388916 449.028137,652.827148 
	C448.727722,653.022766 448.517426,652.881836 448.298126,652.453003 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M440.775879,639.202515 
	C440.981445,639.131592 441.108124,639.588928 441.057312,639.820740 
	C440.861084,639.792847 440.715698,639.533203 440.775879,639.202515 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M605.025635,625.984131 
	C606.848694,624.059021 608.671753,622.133911 610.791138,620.425354 
	C612.067261,620.432068 613.046936,620.222229 614.029236,620.338135 
	C614.026062,621.097168 614.020325,621.530518 614.006226,622.364014 
	C614.669922,622.478394 615.341980,622.192749 616.000488,621.954590 
	C615.986938,622.002075 615.889404,621.986206 616.231201,621.891724 
	C617.354065,621.473450 618.135132,621.149780 618.898804,621.163696 
	C617.489441,623.232300 616.097534,624.963196 614.512817,626.842041 
	C614.087708,627.222351 613.906372,627.489136 613.523987,627.950928 
	C612.843262,628.743713 612.414490,629.376099 611.992859,630.004272 
	C612.000000,630.000000 612.007690,629.985596 611.724243,630.078247 
	C610.918579,630.423706 610.396423,630.676636 609.735474,630.575439 
	C610.944763,628.348267 612.292847,626.475281 613.640930,624.602234 
	C613.322327,624.356995 613.003784,624.111694 612.685181,623.866394 
	C610.557739,626.286560 608.430298,628.706665 606.026123,631.315186 
	C603.732483,633.892029 601.715698,636.280334 599.479248,638.793213 
	C599.084778,639.200623 598.909912,639.483643 598.504150,639.893311 
	C597.966614,640.440979 597.659973,640.861877 597.091736,641.488708 
	C593.613464,645.161316 590.396790,648.628113 586.790039,652.048950 
	C586.143250,651.952393 585.886414,651.901733 585.802734,651.553345 
	C588.999878,647.183472 592.023987,643.111145 595.048157,639.038879 
	C595.048157,639.038879 595.005310,638.997559 595.300537,639.001953 
	C595.748840,638.674255 595.901917,638.342163 596.054993,638.010132 
	C596.054993,638.010132 596.000000,638.000000 596.319275,637.901978 
	C597.777161,636.209473 598.915710,634.614990 600.054321,633.020447 
	C600.054321,633.020447 600.000732,632.999512 600.293091,632.999390 
	C600.742065,632.669617 600.898621,632.339966 601.055237,632.010315 
	C601.055237,632.010315 601.000000,632.000061 601.318909,631.916443 
	C602.109009,630.884583 602.580139,629.936401 603.051331,628.988159 
	C603.051331,628.988159 602.988098,628.988953 603.234497,628.831421 
	C603.667664,628.105103 603.854492,627.536255 604.041260,626.967468 
	C604.041321,626.967468 603.975098,626.981140 604.274170,627.008301 
	C604.724060,626.684998 604.874878,626.334534 605.025635,625.984131 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M614.026672,620.012451 
	C613.046936,620.222229 612.067261,620.432068 611.117371,620.321289 
	C613.326843,617.576355 615.506409,615.151978 618.107727,612.334595 
	C618.704651,611.626465 618.879883,611.311340 619.055176,610.996155 
	C619.055115,610.996155 618.993286,610.993774 619.251831,610.941284 
	C619.691589,610.591309 619.872742,610.293823 620.053894,609.996338 
	C620.053894,609.996338 619.994263,609.994995 620.329346,609.840881 
	C621.345703,609.273010 622.026917,608.859314 623.811279,607.775513 
	C622.696594,609.891602 622.211426,610.812683 621.459595,611.885559 
	C620.396667,612.963562 619.600342,613.889893 618.548462,614.943237 
	C617.971069,615.534912 617.649231,615.999573 617.166382,616.597900 
	C616.799988,616.927612 616.616760,617.142639 616.194946,617.635620 
	C615.850464,618.036011 615.671875,618.203125 615.701843,618.313782 
	C615.773193,618.577454 615.942993,618.814514 616.043579,619.035278 
	C616.014099,619.008301 616.054199,619.077209 615.805786,618.814209 
	C615.047180,619.038208 614.536926,619.525330 614.026672,620.012451 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M621.726196,611.733826 
	C622.211426,610.812683 622.696594,609.891602 623.811279,607.775513 
	C622.026917,608.859314 621.345703,609.273010 620.334595,609.870239 
	C621.338684,608.013367 622.672607,605.973022 624.474670,603.937012 
	C624.942810,603.941345 625.308594,604.274963 625.205872,604.508606 
	C624.934143,605.036377 624.765137,605.330444 624.304077,605.873169 
	C623.954163,606.760376 623.896301,607.398926 623.838440,608.037537 
	C624.426880,607.627258 625.015259,607.216980 625.959229,606.482788 
	C627.607361,605.482361 628.899963,604.805908 629.915771,604.614258 
	C629.469360,605.430237 629.299744,605.761475 628.901306,606.325317 
	C626.675537,608.589111 624.678650,610.620361 622.442627,612.422485 
	C622.044373,612.040039 621.885315,611.886902 621.726196,611.733826 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M594.707153,638.906494 
	C592.023987,643.111145 588.999878,647.183472 585.618286,651.576660 
	C585.260742,651.897583 584.897766,651.977539 584.897766,651.977539 
	C584.474182,651.982056 584.050659,651.986511 583.309448,651.989685 
	C586.783264,647.583618 590.574707,643.178894 594.707153,638.906494 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M599.695190,632.968628 
	C598.915710,634.614990 597.777161,636.209473 596.330994,637.927368 
	C597.127686,636.339417 598.231873,634.628113 599.695190,632.968628 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M602.694092,629.014526 
	C602.580139,629.936401 602.109009,630.884583 601.330444,631.942139 
	C601.460938,631.047852 601.898926,630.044373 602.694092,629.014526 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M626.794312,600.811462 
	C627.180664,600.254578 627.567078,599.697693 628.577637,599.055481 
	C629.458923,598.979675 629.716125,598.989197 629.863281,599.272583 
	C629.075134,600.258911 628.396973,600.971313 627.487793,601.465454 
	C627.102661,601.101990 626.948486,600.956726 626.794312,600.811462 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M629.973267,598.998718 
	C629.716125,598.989197 629.458923,598.979675 628.815308,598.956177 
	C629.083130,598.186523 629.737366,597.430847 630.724670,596.867920 
	C631.004333,597.469788 630.950989,597.878845 630.908203,598.636963 
	C630.918762,598.986023 630.966003,598.946899 630.713623,598.880371 
	C630.298645,598.875427 630.135925,598.937073 629.973267,598.998718 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M603.796204,627.151367 
	C603.854492,627.536255 603.667664,628.105103 603.235901,628.863037 
	C603.177673,628.479858 603.364380,627.907593 603.796204,627.151367 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M625.559570,602.570740 
	C625.820068,602.089600 626.080627,601.608398 626.567749,600.969360 
	C626.948486,600.956726 627.102661,601.101990 627.330200,601.686829 
	C627.002319,602.481445 626.601135,602.836487 626.041870,603.034180 
	C625.883850,602.876892 625.559509,602.570740 625.559570,602.570740 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M595.725830,637.963379 
	C595.901917,638.342163 595.748840,638.674255 595.312500,639.028931 
	C595.151733,638.673279 595.274170,638.294983 595.725830,637.963379 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M624.762695,603.771606 
	C624.741272,603.320679 624.938538,603.069824 625.366943,602.710022 
	C625.559509,602.570740 625.883850,602.876892 625.954834,603.260498 
	C625.860352,603.937988 625.621277,604.148254 625.308594,604.274963 
	C625.308594,604.274963 624.942810,603.941345 624.762695,603.771606 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M600.737122,631.977295 
	C600.898621,632.339966 600.742065,632.669617 600.300232,633.027222 
	C600.149658,632.684875 600.284302,632.314575 600.737122,631.977295 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M604.766907,626.060303 
	C604.874878,626.334534 604.724060,626.684998 604.277588,627.041931 
	C604.157288,626.744446 604.332764,626.440491 604.766907,626.060303 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M618.753845,611.035889 
	C618.879883,611.311340 618.704651,611.626465 618.275269,612.051270 
	C618.164856,611.799133 618.308716,611.437378 618.753845,611.035889 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M619.756958,610.015015 
	C619.872742,610.293823 619.691589,610.591309 619.254761,610.972107 
	C619.152710,610.714844 619.306396,610.374268 619.756958,610.015015 
z"/>
<path fill="#263238" opacity="1.000000" stroke="none" 
	d="
M509.262695,654.099121 
	C509.425354,652.759705 509.914795,651.572266 510.641113,650.170044 
	C511.515198,651.235535 512.152344,652.515869 512.789551,653.796265 
	C511.722839,653.947937 510.656158,654.099548 509.262695,654.099121 
z"/>
<path fill="#FDFDED" opacity="1.000000" stroke="none" 
	d="
M564.741150,493.723450 
	C564.922974,493.682587 565.262451,493.808502 565.414062,494.073181 
	C565.226135,494.211945 564.898804,493.890198 564.741150,493.723450 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M519.904907,549.872803 
	C526.269287,550.158020 532.267395,550.500061 539.094604,550.888672 
	C546.135925,552.208008 552.348022,553.480835 558.003723,554.639648 
	C551.389587,572.863220 545.066223,590.285645 538.742859,607.708130 
	C537.234741,611.249634 535.726562,614.791077 533.738647,618.647766 
	C533.048035,618.958374 532.837341,618.953735 532.626587,618.949158 
	C539.653687,598.369751 546.680847,577.790283 553.974670,556.429810 
	C545.809753,553.203064 536.748169,552.798035 527.338623,551.863159 
	C523.597595,551.516357 520.313599,551.175293 516.603577,550.787659 
	C517.297913,550.470642 518.418213,550.200195 519.904907,549.872803 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M545.002625,557.142822 
	C544.987793,556.978821 544.997925,556.496460 545.001404,556.255371 
	C546.598755,556.288940 548.192688,556.563599 550.685059,556.993103 
	C543.763367,576.448975 537.017761,595.409790 529.700317,614.661133 
	C528.868164,614.941528 528.607910,614.931519 528.347656,614.921448 
	C534.016174,597.547791 539.709534,580.182068 545.307800,562.785767 
	C545.775574,561.332092 545.602417,559.672119 545.379639,557.871826 
	C545.032104,557.635681 545.017456,557.306763 545.002625,557.142822 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M528.156494,615.177002 
	C528.607910,614.931519 528.868164,614.941528 529.518311,614.973145 
	C527.229431,621.896667 524.550659,628.798584 521.871948,635.700562 
	C521.454163,635.549316 521.036377,635.398071 520.618652,635.246826 
	C523.067505,628.642029 525.516418,622.037231 528.156494,615.177002 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M532.404907,619.206055 
	C532.837341,618.953735 533.048035,618.958374 533.574341,618.969238 
	C532.890503,622.030457 531.891113,625.085388 530.882080,628.510498 
	C530.159180,630.259277 529.445984,631.637878 528.732788,633.016541 
	C528.418823,632.869568 528.104858,632.722656 527.790833,632.575684 
	C529.255005,628.204773 530.719116,623.833801 532.404907,619.206055 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M569.946716,685.962036 
	C574.575989,679.538635 579.214905,673.122131 583.826416,666.685974 
	C584.773315,665.364319 585.617554,663.969177 586.255737,662.302124 
	C586.002441,661.996399 585.982727,662.010376 586.296143,662.228943 
	C588.453979,660.697815 590.298279,658.948059 592.433777,657.471619 
	C592.918701,657.927307 593.112549,658.109802 593.306396,658.292297 
	C585.691101,667.918823 578.075745,677.545349 570.225708,686.993103 
	C569.976196,686.530212 569.961426,686.246155 569.946716,685.962036 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M603.963806,647.949890 
	C602.383423,649.073181 600.802979,650.196472 598.730774,651.280518 
	C598.080139,651.091492 597.921387,650.941895 597.842651,650.525757 
	C598.180176,649.772400 598.437805,649.285583 598.940918,648.570557 
	C601.668213,645.083740 604.149963,641.825195 606.758789,638.457458 
	C606.885925,638.348267 607.066956,638.064514 607.330872,637.887817 
	C608.234131,636.662659 608.873413,635.614136 609.820923,634.395508 
	C610.755371,632.812073 611.381531,631.398804 612.007690,629.985596 
	C612.007690,629.985596 612.000000,630.000000 612.313171,630.159668 
	C613.083801,630.197510 613.541260,630.075623 614.217529,630.107422 
	C614.641724,630.548096 614.847046,630.835144 614.955811,631.407715 
	C614.339478,632.516541 613.819763,633.339783 613.041626,634.377625 
	C610.841492,637.384460 608.899780,640.176758 606.979065,642.984497 
	C607.000000,643.000000 606.960022,642.966675 606.611511,643.055542 
	C605.491028,644.757507 604.718994,646.370605 603.970764,647.985718 
	C603.994507,647.987732 603.963806,647.949890 603.963806,647.949890 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M591.812073,663.811707 
	C594.407532,660.797241 596.786804,658.065674 599.166077,655.334045 
	C599.556458,655.658020 599.946838,655.982056 600.337219,656.306030 
	C593.875427,664.370422 587.413696,672.434814 580.951965,680.499207 
	C580.496948,680.159790 580.041992,679.820312 579.587036,679.480835 
	C583.589966,674.352051 587.592896,669.223328 591.812073,663.811707 
z"/>
<path fill="#F1F3E8" opacity="1.000000" stroke="none" 
	d="
M569.952942,696.974854 
	C568.504578,697.895081 567.056213,698.815308 565.306396,699.869690 
	C565.004883,700.003906 565.000000,700.000000 564.998779,699.594238 
	C564.986328,698.111633 564.975037,697.034790 564.963806,695.957886 
	C564.963806,695.957886 564.974121,695.970825 564.958374,695.961426 
	C565.698364,695.965698 566.454102,695.979370 567.766479,696.224121 
	C568.866333,696.628479 569.409668,696.801697 569.952942,696.974854 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M596.939819,653.966125 
	C595.905273,655.291626 594.870789,656.617126 593.571350,658.117493 
	C593.112549,658.109802 592.918701,657.927307 592.606628,657.222412 
	C592.892822,655.998230 593.297302,655.296448 593.938477,654.458496 
	C594.758301,653.840088 595.341553,653.357849 596.040649,653.104065 
	C596.417603,653.543701 596.678711,653.754883 596.939819,653.966125 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M603.563843,652.425293 
	C602.721069,653.945679 602.098999,655.142639 601.476929,656.339600 
	C600.883545,655.774719 600.290100,655.209778 599.696716,654.644897 
	C600.891663,653.347961 602.086609,652.051086 603.281494,650.754150 
	C603.449219,651.203369 603.616882,651.652649 603.563843,652.425293 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M610.137939,646.042847 
	C609.389343,646.301941 608.620300,646.533264 607.317017,646.775146 
	C606.629761,646.638916 606.476807,646.492065 606.533203,646.009521 
	C606.815125,644.771362 606.887573,643.869019 606.960022,642.966675 
	C606.960022,642.966675 607.000000,643.000000 607.317871,643.258972 
	C608.433716,643.338806 609.231750,643.159668 610.026855,643.404907 
	C610.034363,644.567932 610.044739,645.306458 610.055176,646.045044 
	C610.055176,646.045044 610.117432,646.015076 610.137939,646.042847 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M607.012817,647.005249 
	C606.337280,648.224182 605.431641,649.223633 604.526001,650.223022 
	C604.375671,649.726501 604.225342,649.229980 604.019409,648.341675 
	C603.963806,647.949890 603.994507,647.987732 604.277527,648.016968 
	C605.148254,647.479187 605.736023,646.912231 606.323792,646.345276 
	C606.476807,646.492065 606.629761,646.638916 607.012817,647.005249 
z"/>
<path fill="#FDFDED" opacity="1.000000" stroke="none" 
	d="
M569.959412,696.649658 
	C569.409668,696.801697 568.866333,696.628479 568.144043,696.222290 
	C568.280579,695.487976 568.596069,694.986694 568.911621,694.485352 
	C569.263062,695.098389 569.614441,695.711365 569.959412,696.649658 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M597.168213,653.788269 
	C596.678711,653.754883 596.417603,653.543701 596.163879,652.849976 
	C596.701721,651.842407 597.232239,651.317383 597.762695,650.792358 
	C597.921387,650.941895 598.080139,651.091492 598.457703,651.487854 
	C598.249817,652.359802 597.823181,652.985107 597.168213,653.788269 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M579.237000,682.222656 
	C578.794800,682.848694 578.352539,683.474731 577.502930,684.096680 
	C577.095581,684.092651 576.794739,683.802795 576.794739,683.802795 
	C577.206299,683.142456 577.617920,682.482178 578.453918,681.845642 
	C578.997925,681.987122 579.117432,682.104858 579.237000,682.222656 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M569.704529,686.139099 
	C569.961426,686.246155 569.976196,686.530212 569.956787,687.245239 
	C569.827087,687.934143 569.642456,688.079102 569.189697,688.050842 
	C569.161194,687.432373 569.311768,686.874268 569.704529,686.139099 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M568.285034,689.881226 
	C568.141052,689.429871 568.286072,688.853394 568.711670,688.145386 
	C568.852844,688.594604 568.713501,689.175354 568.285034,689.881226 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M574.081360,688.072998 
	C573.597595,688.293945 573.113831,688.514893 572.630066,688.735840 
	C572.794800,688.426208 572.959534,688.116577 573.501282,687.845093 
	C573.878235,687.883118 574.081360,688.072937 574.081360,688.072998 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M565.307739,693.858154 
	C565.163391,693.405762 565.311279,692.837769 565.733276,692.128174 
	C565.871643,692.571838 565.735779,693.157104 565.307739,693.858154 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M576.415100,685.463440 
	C576.116943,685.917480 575.818787,686.371582 575.259888,686.912231 
	C574.999084,686.998901 574.989075,686.987061 574.989075,686.987061 
	C575.114990,686.405457 575.240845,685.823792 575.764160,685.213257 
	C576.161621,685.184387 576.415100,685.463440 576.415100,685.463440 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M566.266602,691.987183 
	C566.146851,691.704529 566.304688,691.398926 566.726562,691.051514 
	C566.841797,691.327881 566.692993,691.646057 566.266602,691.987183 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M567.278625,690.959595 
	C567.160156,690.679565 567.310913,690.368286 567.733032,690.026489 
	C567.852173,690.306702 567.700012,690.617493 567.278625,690.959595 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M575.000488,687.000732 
	C574.854126,687.298279 574.706299,687.594055 574.319946,687.981384 
	C574.081360,688.072937 573.878235,687.883118 573.772461,687.785522 
	C573.920410,687.406799 574.174011,687.125793 574.708374,686.915894 
	C574.989075,686.987061 574.999084,686.998901 575.000488,687.000732 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M577.245972,684.237549 
	C577.251953,684.684082 577.059753,684.951355 576.617493,685.323914 
	C576.415100,685.463440 576.161621,685.184387 576.035645,685.043823 
	C575.931824,684.549438 576.079224,684.259277 576.573364,683.917786 
	C576.794739,683.802795 577.095581,684.092651 577.245972,684.237549 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M578.707031,681.685059 
	C578.916687,681.327759 579.297791,681.154724 579.678833,680.981689 
	C579.693970,681.271729 579.709167,681.561707 579.480652,682.037231 
	C579.117432,682.104858 578.997925,681.987122 578.707031,681.685059 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M447.115601,887.623413 
	C447.922363,886.603821 448.658386,885.856995 449.703735,885.054321 
	C449.287415,886.612305 448.561798,888.226135 447.383331,889.871216 
	C446.789764,889.772461 446.649109,889.642517 446.508484,889.512634 
	C446.687286,888.973816 446.866058,888.435059 447.115601,887.623413 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M437.001038,903.471558 
	C436.731110,903.123474 436.680847,902.732788 436.748871,902.364014 
	C436.761597,902.295044 437.248688,902.313721 437.516876,902.291992 
	C437.431763,902.673462 437.346680,903.054993 437.001038,903.471558 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M438.859436,899.875610 
	C439.255585,899.219604 439.651733,898.563660 440.476624,897.913940 
	C440.905365,897.920105 441.234253,898.200378 441.234253,898.200378 
	C440.759674,898.816650 440.285095,899.432983 439.432098,900.055420 
	C439.053680,900.061462 438.859467,899.875610 438.859436,899.875610 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M441.920654,895.936951 
	C442.295441,895.314209 442.670258,894.691467 443.045044,894.068787 
	C443.272400,894.195618 443.499756,894.322510 443.727112,894.449402 
	C443.426544,894.979736 443.125946,895.510132 442.452637,896.054688 
	C442.079926,896.068909 441.920654,895.936951 441.920654,895.936951 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M444.727722,891.747498 
	C445.154572,891.098572 445.581451,890.449646 446.258423,889.656677 
	C446.649109,889.642517 446.789764,889.772461 447.134308,890.106445 
	C446.875885,890.943909 446.413574,891.577454 445.512268,892.149658 
	C445.073273,892.088379 444.727722,891.747498 444.727722,891.747498 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M440.740051,897.781006 
	C440.861267,897.166199 441.147797,896.690430 441.677490,896.075806 
	C441.920654,895.936951 442.079926,896.068909 442.164612,896.136902 
	C442.053955,896.739319 441.858643,897.273682 441.448792,898.004211 
	C441.234253,898.200378 440.905365,897.920105 440.740051,897.781006 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M445.236145,892.268311 
	C445.085327,892.573792 444.771667,892.699341 444.458038,892.824890 
	C444.433746,892.587219 444.409424,892.349609 444.556427,891.929688 
	C444.727722,891.747498 445.073273,892.088379 445.236145,892.268311 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M439.148865,900.156372 
	C439.033356,900.445984 438.822723,900.640747 438.612061,900.835510 
	C438.568787,900.593811 438.525543,900.352112 438.670898,899.993042 
	C438.859467,899.875610 439.053680,900.061462 439.148865,900.156372 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M495.079041,558.346008 
	C499.701019,558.534424 504.002747,558.919678 508.304443,559.304932 
	C508.242493,559.795410 508.180542,560.285950 508.118591,560.776428 
	C503.667053,560.015442 498.772308,563.442810 495.079041,558.346008 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M491.195862,567.207764 
	C490.927673,567.512451 490.308197,567.769043 489.345398,567.994385 
	C489.616211,567.695312 490.230377,567.427490 491.195862,567.207764 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M470.691040,845.082520 
	C468.226349,849.593750 465.435974,854.207275 462.319641,858.922119 
	C464.784241,854.410583 467.574768,849.797729 470.691040,845.082520 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M480.709717,830.116943 
	C478.573730,834.008850 476.131134,838.044800 473.336456,842.049927 
	C475.457275,838.099792 477.930145,834.180420 480.709717,830.116943 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M453.701355,871.077515 
	C453.748749,872.066895 453.481049,873.152039 453.088379,874.561035 
	C452.963409,874.884949 452.558838,875.024963 452.227966,874.959106 
	C451.897125,874.893188 451.643280,874.627258 451.643311,874.627258 
	C452.224304,873.475952 452.805298,872.324646 453.701355,871.077515 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M472.703369,842.082520 
	C472.582428,843.002991 472.149048,844.025879 471.350464,845.034607 
	C471.453827,844.075256 471.922394,843.130066 472.703369,842.082520 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M461.651428,858.955322 
	C461.541992,859.916870 461.062958,860.843933 460.287048,861.899170 
	C460.420715,860.991821 460.851288,859.956360 461.651428,858.955322 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M455.693085,868.061157 
	C455.565399,868.955811 455.111816,869.929871 454.323212,870.962891 
	C454.447876,870.061401 454.907562,869.100952 455.693085,868.061157 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M451.376770,876.367126 
	C451.154175,876.881958 450.931519,877.396729 450.331177,877.932556 
	C449.953491,877.953613 449.610107,877.613770 449.610107,877.613770 
	C449.828247,877.095764 450.046417,876.577759 450.648560,876.044434 
	C451.032562,876.029053 451.376770,876.367126 451.376770,876.367126 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M446.048584,883.048462 
	C446.281891,883.258850 446.300201,883.511780 446.254517,883.752563 
	C446.246979,883.792419 445.959625,883.779175 445.801575,883.790466 
	C445.805115,883.555908 445.808624,883.321411 446.048584,883.048462 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M456.724121,866.107056 
	C456.887115,866.596436 456.758514,867.214417 456.309357,867.928223 
	C456.136719,867.427979 456.284637,866.831726 456.724121,866.107056 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M458.694763,863.084717 
	C458.859924,863.568970 458.705902,864.149658 458.271423,864.878662 
	C458.119141,864.411621 458.247375,863.796387 458.694763,863.084717 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M449.362152,879.371338 
	C449.126282,879.875366 448.890411,880.379333 448.286743,880.906006 
	C447.918945,880.928711 447.643707,880.660950 447.643707,880.660950 
	C447.874817,880.150940 448.105896,879.640869 448.703674,879.101685 
	C449.070343,879.072449 449.362152,879.371338 449.362152,879.371338 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M459.730469,861.992065 
	C459.869446,862.313904 459.715759,862.644043 459.277405,862.993774 
	C459.141083,862.675598 459.289459,862.337952 459.730469,861.992065 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M457.730652,864.992188 
	C457.870270,865.321045 457.718048,865.658142 457.274841,866.007690 
	C457.135529,865.680298 457.287201,865.340393 457.730652,864.992188 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M450.861694,875.858765 
	C450.837494,875.398865 451.024200,875.137817 451.447113,874.766357 
	C451.643280,874.627258 451.897125,874.893188 452.024841,875.026489 
	C452.077179,875.482239 451.937378,875.773804 451.554993,876.200806 
	C451.376770,876.367126 451.032562,876.029053 450.861694,875.858765 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M448.924072,878.923401 
	C448.870605,878.458191 449.023834,878.174683 449.423767,877.768799 
	C449.610107,877.613770 449.953491,877.953613 450.125580,878.125549 
	C450.168579,878.597656 449.987122,878.861938 449.557709,879.230835 
	C449.362152,879.371338 449.070343,879.072449 448.924072,878.923401 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M448.053925,881.065247 
	C447.959991,881.428284 447.731110,881.654785 447.502197,881.881287 
	C447.435730,881.596375 447.369232,881.311462 447.473206,880.843750 
	C447.643707,880.660950 447.918945,880.928711 448.053925,881.065247 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M574.026001,671.981689 
	C573.428955,672.060181 572.831909,672.138611 571.736694,672.199280 
	C571.238586,672.181519 570.857788,671.874268 570.857788,671.874268 
	C575.195557,665.823425 579.533264,659.772583 584.477051,653.883911 
	C585.332397,654.288269 585.581665,654.530457 585.671021,655.037048 
	C585.331848,655.870667 585.152527,656.439941 584.990417,657.007568 
	C585.007629,657.005981 585.003174,656.971680 584.665771,657.030518 
	C580.878235,662.066772 577.428162,667.044189 573.994751,672.017090 
	C574.011414,672.012573 574.026001,671.981689 574.026001,671.981689 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M573.689819,674.048706 
	C573.110657,675.782715 572.209045,677.592529 570.882202,679.544800 
	C570.233215,679.480774 570.071777,679.233582 569.972656,678.945618 
	C571.104248,677.338501 572.235901,675.731445 573.689819,674.048706 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M578.562805,669.498535 
	C577.253906,671.110413 575.945068,672.722351 574.306824,674.175171 
	C574.012024,673.565735 574.046570,673.115356 574.053589,672.323303 
	C574.026001,671.981689 574.011414,672.012573 574.311401,671.971313 
	C575.621216,670.851196 576.630981,669.772278 577.866699,668.899963 
	C578.249329,669.237244 578.406067,669.367920 578.562805,669.498535 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M568.524902,675.521179 
	C568.830872,674.774353 569.136902,674.027527 569.807983,673.319641 
	C570.438354,673.565369 570.703613,673.772278 570.968994,673.979187 
	C570.566956,674.626892 570.164917,675.274597 569.347900,675.913330 
	C568.796936,675.776733 568.660950,675.648987 568.524902,675.521179 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M566.740967,677.774170 
	C567.164001,677.114563 567.587036,676.454956 568.267517,675.658325 
	C568.660950,675.648987 568.796936,675.776733 569.130737,676.104736 
	C568.882019,676.914062 568.435547,677.523071 567.553040,678.113159 
	C567.116943,678.094238 566.740967,677.774170 566.740967,677.774170 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M584.834839,652.204224 
	C584.897766,651.977539 585.260742,651.897583 585.445190,651.874268 
	C585.886414,651.901733 586.143250,651.952393 586.729492,652.350464 
	C586.649597,653.389404 586.240234,654.081055 585.830872,654.772644 
	C585.581665,654.530457 585.332397,654.288269 584.727783,653.660156 
	C584.487610,652.984680 584.620850,652.703613 584.834839,652.204224 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M569.722900,679.127075 
	C570.071777,679.233582 570.233215,679.480774 570.647644,679.802246 
	C570.416748,680.362061 569.995239,680.807007 569.278320,681.132690 
	C569.146301,680.445190 569.309692,679.876831 569.722900,679.127075 
z"/>
<path fill="#6594A0" opacity="1.000000" stroke="none" 
	d="
M571.116455,673.750244 
	C570.703613,673.772278 570.438354,673.565369 570.050171,673.133057 
	C570.022583,672.592651 570.192444,672.325012 570.647339,671.989441 
	C570.857788,671.874268 571.238586,672.181519 571.427856,672.345154 
	C571.499451,672.846313 571.381714,673.183838 571.116455,673.750244 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M567.303345,678.256104 
	C567.217712,678.906799 566.945740,679.395691 566.319458,679.927185 
	C565.965210,679.969788 565.719360,679.724060 565.719360,679.724060 
	C565.923157,679.203186 566.127014,678.682251 566.535889,677.967773 
	C566.740967,677.774170 567.116943,678.094238 567.303345,678.256104 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M568.689819,681.084839 
	C568.886475,681.589233 568.762024,682.200806 568.311890,682.919678 
	C568.113708,682.415344 568.241211,681.803650 568.689819,681.084839 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M565.731567,685.105957 
	C565.871765,685.565430 565.726440,686.166504 565.280151,686.901001 
	C565.134888,686.438843 565.290466,685.843201 565.731567,685.105957 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M565.349426,681.352722 
	C565.110657,681.855103 564.871948,682.357544 564.280334,682.895142 
	C563.927429,682.930359 563.684204,682.682983 563.684204,682.682983 
	C563.915222,682.173035 564.146240,681.663025 564.737427,681.124512 
	C565.097717,681.096008 565.349426,681.352722 565.349426,681.352722 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M563.757019,688.029663 
	C563.876160,688.322998 563.725586,688.675842 563.271545,689.026978 
	C563.141174,688.713196 563.314270,688.401245 563.757019,688.029663 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M564.736877,686.997986 
	C564.870483,687.309021 564.713623,687.644714 564.264648,688.003479 
	C564.130432,687.691956 564.288452,687.357239 564.736877,686.997986 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M561.351074,686.300537 
	C561.544312,686.268799 561.615417,686.358765 561.686584,686.448792 
	C561.534058,686.439941 561.381531,686.431091 561.351074,686.300537 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M566.745239,684.013367 
	C566.875671,684.323853 566.725830,684.674683 566.272949,685.025513 
	C566.134827,684.701599 566.299805,684.377686 566.745239,684.013367 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M563.264954,684.259399 
	C563.033508,684.461975 562.802063,684.664490 562.570679,684.867065 
	C562.517029,684.602356 562.463440,684.337585 562.718994,684.048035 
	C563.028198,684.023193 563.264954,684.259399 563.264954,684.259399 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M567.722778,682.974731 
	C567.867065,683.314636 567.711121,683.649963 567.264404,684.005493 
	C567.123169,683.673889 567.272827,683.322083 567.722778,682.974731 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M562.910156,683.904785 
	C562.896912,683.474976 563.064636,683.202393 563.489746,682.825806 
	C563.684204,682.682983 563.927429,682.930359 564.049561,683.055054 
	C564.072693,683.494019 563.904297,683.765198 563.465698,684.126343 
	C563.264954,684.259399 563.028198,684.023193 562.910156,683.904785 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M564.972656,680.966919 
	C564.947021,680.525452 565.107239,680.248352 565.523865,679.865234 
	C565.719360,679.724060 565.965210,679.969788 566.089478,680.093750 
	C566.109314,680.528870 565.946716,680.806030 565.537720,681.200928 
	C565.349426,681.352722 565.097717,681.096008 564.972656,680.966919 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M495.718079,577.027527 
	C496.235535,577.654480 496.283875,578.184326 496.381287,578.975464 
	C492.069550,579.819885 487.720093,580.778381 483.345642,580.910889 
	C473.012604,581.223694 462.667633,581.143433 451.894043,581.118774 
	C459.624756,580.046875 467.775635,578.939087 475.957428,578.154175 
	C482.368042,577.539062 488.817017,577.323547 495.718079,577.027527 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M495.816132,572.320374 
	C496.214569,572.778748 496.170471,573.120667 496.105652,573.720886 
	C488.034119,574.569824 479.983307,575.160461 471.502136,575.713989 
	C470.536255,575.318726 470.000702,574.960571 469.325287,574.407349 
	C477.914825,573.542908 486.644226,572.873474 495.816132,572.320374 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M498.130096,579.524658 
	C498.163055,579.289185 498.552429,579.201355 499.082825,579.279053 
	C498.978088,579.520447 498.732330,579.596375 498.130096,579.524658 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M542.680786,606.606934 
	C542.284973,607.379150 542.042969,607.805786 541.716797,608.468323 
	C541.164124,608.911865 540.695557,609.119385 540.248169,608.849854 
	C541.516418,606.250977 542.763611,604.129150 544.006592,602.004578 
	C544.002441,602.001831 543.995911,601.997681 543.995911,601.997681 
	C543.608826,603.418884 543.221741,604.840027 542.680786,606.606934 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M544.993896,598.990723 
	C544.822998,599.841614 544.662598,600.702393 544.249023,601.780396 
	C543.995911,601.997681 544.002441,602.001831 543.932251,601.659912 
	C544.250549,600.550781 544.638977,599.783508 545.027466,599.016235 
	C545.027466,599.016235 545.004395,599.000610 544.993896,598.990723 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M544.958679,598.622559 
	C544.638977,599.783508 544.250549,600.550781 543.936462,601.662720 
	C542.763611,604.129150 541.516418,606.250977 540.118469,608.682251 
	C539.666382,608.735107 539.365112,608.478394 538.903320,607.964905 
	C545.066223,590.285645 551.389587,572.863220 558.003723,554.639648 
	C552.348022,553.480835 546.135925,552.208008 539.519897,550.862305 
	C539.930542,550.499146 540.745117,550.208740 541.894714,549.854187 
	C548.797791,551.174805 555.365845,552.559692 561.612671,553.876770 
	C555.826172,569.223755 550.358032,583.726318 544.958679,598.622559 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M479.700806,594.053467 
	C479.185242,594.347473 478.263367,594.545410 477.100708,594.556152 
	C477.671448,594.231750 478.482941,594.094604 479.700806,594.053467 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M586.010803,661.987427 
	C584.722107,663.071045 583.425049,664.163696 581.639099,665.204102 
	C580.987366,665.005737 580.824524,664.859619 580.809998,664.405762 
	C582.306580,661.722534 583.654846,659.347107 585.003174,656.971680 
	C585.003174,656.971680 585.007629,657.005981 585.392700,657.148254 
	C586.553040,657.201355 587.328369,657.112244 588.438721,656.984558 
	C587.463745,658.979675 586.723206,660.495056 585.982727,662.010376 
	C585.982727,662.010376 586.002441,661.996399 586.010803,661.987427 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M580.263916,667.245850 
	C579.838257,667.872742 579.412598,668.499634 578.774902,669.312500 
	C578.406067,669.367920 578.249329,669.237244 577.982300,668.634827 
	C578.360596,667.569275 578.849243,666.975342 579.569519,666.597412 
	C579.955383,666.957581 580.109680,667.101746 580.263916,667.245850 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M581.376099,665.389771 
	C581.307068,666.068970 581.012207,666.510132 580.490601,667.098633 
	C580.109680,667.101746 579.955383,666.957581 579.716431,666.373291 
	C579.975037,665.526611 580.318359,665.120056 580.661682,664.713562 
	C580.824524,664.859619 580.987366,665.005737 581.376099,665.389771 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M564.702393,696.135132 
	C564.975037,697.034790 564.986328,698.111633 564.996338,699.591858 
	C564.605103,699.994690 564.215088,699.994202 563.535583,699.984924 
	C562.884155,699.512939 562.522217,699.049744 562.075684,698.289673 
	C562.807678,697.432556 563.624329,696.872437 564.702393,696.135132 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M795.888977,342.842773 
	C796.193481,343.234161 796.500244,343.968079 796.802185,343.966064 
	C802.312378,343.929504 806.429260,347.110443 810.430908,350.218597 
	C803.076721,351.315613 796.017944,352.076630 788.959229,352.837646 
	C787.037354,352.810791 785.115540,352.783936 783.433594,352.226837 
	C788.908813,350.280914 794.144104,348.865234 799.379395,347.449554 
	C793.912598,347.618713 788.250916,345.553955 782.897461,348.721985 
	C782.478394,348.869080 782.059326,349.016174 781.136169,348.995300 
	C780.468506,348.756805 780.304871,348.686310 780.141174,348.615784 
	C781.420288,347.656281 782.591614,346.474640 784.003784,345.789398 
	C786.115051,344.764832 788.395874,344.089600 790.395142,342.720276 
	C792.290405,340.659637 794.331848,339.683838 795.888977,342.842773 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M817.259949,365.254517 
	C816.347168,365.324066 815.434326,365.393616 813.824890,365.703796 
	C806.462585,366.386993 799.796997,366.829590 793.131348,367.272156 
	C792.459534,365.289032 791.787781,363.305908 791.290161,360.759857 
	C793.882812,359.810028 796.292786,359.297241 798.726318,359.131836 
	C799.279358,359.094269 799.914185,360.260406 800.280823,361.162231 
	C799.179260,361.777649 798.308289,362.103333 797.437317,362.429016 
	C797.439270,362.673462 797.441223,362.917877 797.443176,363.162292 
	C799.379028,363.307678 801.314819,363.453064 803.698975,363.588745 
	C808.421448,363.598572 812.695679,363.618103 817.106934,364.030304 
	C817.249268,364.700165 817.254639,364.977356 817.259949,365.254517 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M814.977722,367.111877 
	C820.121216,367.716034 825.264709,368.320190 830.312988,369.086792 
	C829.519165,369.342163 828.820557,369.435059 828.121948,369.527954 
	C821.888245,369.527954 815.654541,369.527954 809.817261,369.527954 
	C808.555847,371.811676 814.714478,371.914825 809.687988,374.220673 
	C809.142212,374.240509 809.028198,374.143829 808.716370,374.219818 
	C807.955627,374.257568 807.506653,374.219330 807.057739,374.181091 
	C807.081665,372.419128 807.105591,370.657196 807.461060,368.363525 
	C810.187744,367.591858 812.582703,367.351868 814.977722,367.111877 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M796.220703,342.859131 
	C794.331848,339.683838 792.290405,340.659637 790.116760,342.530212 
	C786.994934,343.184509 783.947021,343.611786 780.887329,343.726013 
	C779.444702,343.779907 777.979553,343.232941 776.757141,342.538727 
	C782.601135,340.980560 788.212646,339.841888 793.824158,338.703217 
	C795.561523,339.651855 797.298950,340.600525 799.397400,341.746307 
	C798.261292,342.197266 797.406860,342.536377 796.220703,342.859131 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M814.828735,355.101776 
	C814.240173,355.852753 813.651611,356.603729 812.527893,357.622803 
	C805.385071,358.010559 798.777466,358.130249 792.169800,358.249969 
	C792.144897,357.947296 792.120056,357.644623 792.320862,356.692810 
	C794.726624,355.611542 796.906860,355.179443 799.546997,354.777100 
	C804.947571,354.905121 809.888123,355.003448 814.828735,355.101776 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M789.087219,353.095184 
	C796.017944,352.076630 803.076721,351.315613 810.540894,350.519562 
	C811.444397,350.768188 811.942566,351.051880 812.155151,351.609314 
	C807.434753,352.485046 802.999878,353.087097 798.163818,353.693390 
	C795.166992,354.096802 792.571472,354.495972 789.975830,354.895142 
	C789.722290,354.381012 789.468750,353.866852 789.087219,353.095184 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M791.950073,358.484192 
	C798.777466,358.130249 805.385071,358.010559 812.440918,357.907806 
	C814.867065,358.361359 816.845154,358.798004 818.530701,359.636719 
	C817.480835,360.059570 816.723511,360.080353 815.541626,360.100616 
	C810.732971,359.784790 806.348816,359.469482 801.566406,359.106140 
	C800.960876,359.439362 800.753540,359.820709 800.389282,360.378510 
	C800.232239,360.554962 800.441772,360.978333 800.461548,360.953461 
	C800.481384,360.928589 800.511353,360.872498 800.511353,360.872498 
	C799.914185,360.260406 799.279358,359.094269 798.726318,359.131836 
	C796.292786,359.297241 793.882812,359.810028 791.183472,360.440735 
	C790.360840,360.558167 789.818909,360.431885 789.277039,360.305603 
	C790.094788,359.776550 790.912537,359.247498 791.950073,358.484192 
z"/>
<path fill="#7DA2A8" opacity="1.000000" stroke="none" 
	d="
M787.443848,334.603088 
	C789.156433,335.365173 790.869080,336.127197 792.311279,337.150269 
	C786.339111,338.069305 780.637207,338.727325 774.935364,339.385376 
	C774.434814,338.671021 773.934326,337.956726 774.115051,337.115784 
	C779.012146,336.193817 783.228027,335.398468 787.443848,334.603088 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M814.901978,366.866455 
	C812.582703,367.351868 810.187744,367.591858 807.385742,367.928833 
	C803.406006,368.289337 799.836121,368.624817 796.258484,368.769531 
	C795.266846,368.809662 794.252380,368.283325 793.189819,367.644501 
	C799.796997,366.829590 806.462585,366.386993 813.602661,365.965027 
	C814.326782,366.197418 814.576477,366.409210 814.901978,366.866455 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M775.014893,339.691925 
	C780.637207,338.727325 786.339111,338.069305 792.439453,337.523499 
	C793.077332,337.907257 793.316772,338.178772 793.690186,338.576782 
	C788.212646,339.841888 782.601135,340.980560 776.608521,342.195496 
	C775.849731,341.514008 775.472107,340.756256 775.014893,339.691925 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M782.929565,349.139709 
	C788.250916,345.553955 793.912598,347.618713 799.379395,347.449554 
	C794.144104,348.865234 788.908813,350.280914 783.345947,351.884186 
	C782.999573,351.233643 782.980652,350.395538 782.929565,349.139709 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M787.534912,334.373291 
	C783.228027,335.398468 779.012146,336.193817 774.394104,336.953918 
	C773.990601,336.628204 773.989258,336.337677 773.996704,335.611145 
	C778.304199,334.503418 782.602905,333.831665 787.301025,333.164795 
	C787.776001,333.501923 787.751160,333.826538 787.534912,334.373291 
z"/>
<path fill="#6898A5" opacity="1.000000" stroke="none" 
	d="
M766.706604,323.428833 
	C768.370300,323.059814 770.012512,323.007355 771.654724,323.006378 
	C774.559265,323.004669 776.907410,323.436310 774.664856,327.357056 
	C772.986450,326.904724 771.344727,326.462280 769.332581,326.023590 
	C768.203125,325.274445 767.444153,324.521515 766.706604,323.428833 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M789.615723,354.974670 
	C792.571472,354.495972 795.166992,354.096802 798.320190,353.945770 
	C798.947571,354.378418 799.017273,354.562897 799.087036,354.747375 
	C796.906860,355.179443 794.726624,355.611542 792.257202,356.241760 
	C790.169983,356.914978 788.371948,357.390137 786.573914,357.865265 
	C786.398682,357.567780 786.223389,357.270294 786.048157,356.972809 
	C787.117249,356.333282 788.186401,355.693756 789.615723,354.974670 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M806.730103,374.286865 
	C807.506653,374.219330 807.955627,374.257568 808.717529,374.153778 
	C808.982300,374.632019 808.999207,375.776550 808.875977,375.791809 
	C807.571472,375.953705 806.244568,375.935822 804.294861,375.866089 
	C803.381287,375.646912 803.182251,375.444885 803.068787,375.158630 
	C804.179993,374.903320 805.291199,374.647980 806.730103,374.286865 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M802.737671,375.098938 
	C803.182251,375.444885 803.381287,375.646912 803.840088,375.895233 
	C802.669556,376.382477 801.324768,376.739197 799.979980,377.095917 
	C799.914429,376.902283 799.848816,376.708679 799.783264,376.515045 
	C800.657715,376.023132 801.532166,375.531189 802.737671,375.098938 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M780.141846,348.806885 
	C780.304871,348.686310 780.468506,348.756805 780.803345,349.032562 
	C780.697205,349.157867 780.419922,349.077911 780.141846,348.806885 
z"/>
<path fill="#1F0807" opacity="1.000000" stroke="none" 
	d="
M661.259460,308.858185 
	C661.099121,308.020477 661.333374,307.042358 661.792969,305.743652 
	C661.896912,306.521271 661.775452,307.619537 661.259460,308.858185 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M649.898926,432.501709 
	C649.415344,432.800720 649.119873,432.946625 648.678711,433.331970 
	C648.252258,433.678650 648.007446,433.856445 647.586243,434.178284 
	C646.349121,434.979919 645.291809,435.642426 644.031677,436.546265 
	C641.455994,437.938416 639.083191,439.089294 636.374817,440.398071 
	C636.008118,439.802246 635.976990,439.048492 636.228027,437.709290 
	C636.691711,436.746155 636.873291,436.368530 637.054871,435.990906 
	C637.054871,435.990906 636.991394,435.994385 637.325928,436.030151 
	C637.792786,435.708527 637.924988,435.351166 638.057251,434.993805 
	C638.057251,434.993805 637.992981,434.996521 638.320923,435.186920 
	C639.115173,434.932709 639.581543,434.488068 640.047974,434.043457 
	C640.047974,434.043457 639.999084,434.003143 640.365906,434.124084 
	C641.506409,433.513336 642.280151,432.781677 643.053833,432.050018 
	C643.053833,432.050018 642.997375,432.005646 643.358643,432.228882 
	C648.807617,429.283783 653.895447,426.115479 658.983276,422.947144 
	C658.506042,424.531616 658.028809,426.116058 656.948853,427.927917 
	C656.223633,428.451691 656.101135,428.748047 655.978699,429.044403 
	C655.978699,429.044403 655.965698,428.998840 655.987061,428.990662 
	C655.416870,429.086700 654.825195,429.190979 654.036072,429.546143 
	C653.169495,430.195038 652.500427,430.593018 651.607666,431.184845 
	C650.940857,431.680084 650.497742,431.981506 649.898926,432.501709 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M659.104675,422.692780 
	C653.895447,426.115479 648.807617,429.283783 643.378723,432.258789 
	C643.306641,431.594818 643.575745,431.124146 644.010132,430.416077 
	C649.036316,427.407684 653.897156,424.636688 659.156372,421.923004 
	C659.445190,422.132996 659.335693,422.285706 659.104675,422.692780 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M656.236694,428.997864 
	C656.101135,428.748047 656.223633,428.451691 656.621338,428.098389 
	C656.762634,428.344757 656.628723,428.648071 656.236694,428.997864 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M651.293457,394.226379 
	C652.110779,392.544830 653.240601,391.077728 654.692139,389.800812 
	C653.877808,391.474243 652.741882,392.957520 651.293457,394.226379 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M658.306885,386.192139 
	C658.773010,384.920135 659.563660,383.828491 660.691284,382.858063 
	C660.229248,384.110321 659.430298,385.241394 658.306885,386.192139 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M646.312134,400.099335 
	C646.763000,398.928101 647.558777,397.829224 648.691467,396.854828 
	C648.237915,398.043427 647.447449,399.107574 646.312134,400.099335 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M663.305420,380.164429 
	C663.433350,379.236176 663.896973,378.451111 664.695557,377.822754 
	C664.567444,378.755524 664.104248,379.531555 663.305420,380.164429 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M655.718689,402.566772 
	C655.152283,403.159485 654.585876,403.752197 653.590759,404.278229 
	C653.031677,404.064911 652.901245,403.918304 652.770752,403.771667 
	C653.300659,403.217072 653.830627,402.662476 654.817139,402.184265 
	C655.421997,402.362671 655.570312,402.464722 655.718689,402.566772 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M655.083496,402.068909 
	C655.452271,401.623871 656.011169,401.370605 656.570068,401.117371 
	C656.428467,401.481628 656.286865,401.845917 655.932007,402.388489 
	C655.570312,402.464722 655.421997,402.362671 655.083496,402.068909 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M644.296753,402.902924 
	C644.106262,402.428009 644.230896,401.807220 644.686584,401.071777 
	C644.882385,401.557098 644.747070,402.157104 644.296753,402.902924 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M650.328918,395.899719 
	C650.083923,395.416321 650.178589,394.790771 650.640747,394.071411 
	C650.895020,394.570953 650.781860,395.164246 650.328918,395.899719 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M640.340698,407.945740 
	C640.115784,407.429321 640.246582,406.827179 640.696411,406.089294 
	C640.909058,406.589050 640.802673,407.224548 640.340698,407.945740 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M657.330566,387.908691 
	C657.070068,387.408875 657.147644,386.780762 657.616882,386.066284 
	C657.895264,386.580078 657.781982,387.180267 657.330566,387.908691 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M661.361328,382.940308 
	C661.108154,382.423706 661.231628,381.814758 661.682861,381.081787 
	C661.919678,381.587799 661.828857,382.217865 661.361328,382.940308 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M642.285889,405.075989 
	C642.105103,404.643280 642.249268,404.255035 642.711670,403.920319 
	C642.890320,404.356018 642.750610,404.738220 642.285889,405.075989 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M643.266235,404.016052 
	C643.103577,403.633392 643.233154,403.236206 643.703125,402.904907 
	C643.881897,403.314362 643.720154,403.657928 643.266235,404.016052 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M645.299500,401.101013 
	C645.119080,400.682983 645.276184,400.338440 645.731079,399.980469 
	C645.898254,400.369537 645.767700,400.771973 645.299500,401.101013 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M649.275940,397.023743 
	C649.089417,396.585938 649.201721,396.142670 649.675903,395.838043 
	C649.883423,396.323883 649.729065,396.671082 649.275940,397.023743 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M655.292847,390.021423 
	C655.087219,389.570221 655.185059,389.126495 655.653625,388.833069 
	C655.881592,389.331879 655.738953,389.680389 655.292847,390.021423 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M656.271912,389.000366 
	C656.081299,388.560760 656.178711,388.096924 656.655762,387.806641 
	C656.876831,388.312195 656.718445,388.644165 656.271912,389.000366 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M662.323730,381.158661 
	C662.120361,380.684998 662.280518,380.346436 662.732056,379.988037 
	C662.911377,380.410034 662.799316,380.851898 662.323730,381.158661 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M653.333740,404.450714 
	C653.321289,404.919464 653.104797,405.112762 652.489502,405.195801 
	C652.123169,405.121826 651.777527,404.782562 651.777527,404.782562 
	C651.851440,404.441833 652.030762,404.170868 652.543091,403.870667 
	C652.901245,403.918304 653.031677,404.064911 653.333740,404.450714 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M651.349976,406.414520 
	C651.030640,406.534485 650.711365,406.654419 650.392029,406.774384 
	C650.410095,406.554352 650.428162,406.334320 650.784424,406.130920 
	C651.122620,406.147552 651.349976,406.414520 651.349976,406.414520 
z"/>
<path fill="#8E8784" opacity="1.000000" stroke="none" 
	d="
M651.006104,406.016602 
	C651.032166,405.589630 651.174744,405.293671 651.547424,404.890137 
	C651.777527,404.782562 652.123169,405.121826 652.293823,405.295502 
	C652.304565,405.769623 652.084167,406.016022 651.576660,406.311462 
	C651.349976,406.414520 651.122620,406.147552 651.006104,406.016602 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M641.295959,406.105896 
	C641.131592,405.709656 641.306396,405.392334 641.756226,405.016907 
	C641.899231,405.367462 641.767151,405.776154 641.295959,406.105896 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M792.374329,431.864807 
	C791.956604,431.643524 791.967346,431.456451 791.993530,430.986603 
	C799.691284,429.791443 807.365906,428.807861 815.058472,427.992340 
	C819.953430,427.473389 824.874817,427.203766 829.887146,427.756836 
	C825.783325,428.263519 821.679565,428.770172 817.224548,429.463440 
	C808.849854,430.399689 800.826355,431.149323 792.374329,431.864807 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M815.966125,360.101135 
	C816.723511,360.080353 817.480835,360.059570 818.615967,360.009583 
	C821.605347,360.525360 824.247925,360.964630 826.800842,361.710449 
	C827.273438,361.848480 827.384766,363.223419 826.896729,364.022217 
	C823.077087,363.890564 820.023438,363.764130 816.969849,363.637695 
	C812.695679,363.618103 808.421448,363.598572 803.683960,363.320007 
	C803.154724,362.715302 803.088745,362.369629 803.424927,361.942169 
	C807.873352,361.273956 811.919739,360.687531 815.966125,360.101135 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M799.546997,354.777100 
	C799.017273,354.562897 798.947571,354.378418 798.721436,353.941528 
	C802.999878,353.087097 807.434753,352.485046 812.304688,351.852814 
	C813.579529,352.793121 814.419250,353.763641 815.043823,354.917969 
	C809.888123,355.003448 804.947571,354.905121 799.546997,354.777100 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M817.106934,364.030334 
	C820.023438,363.764130 823.077087,363.890564 826.535522,364.095032 
	C826.967529,364.452057 826.975647,364.731567 826.967590,365.219391 
	C823.994568,365.454620 821.018738,365.482147 817.651489,365.382080 
	C817.254639,364.977356 817.249268,364.700165 817.106934,364.030334 
z"/>
<path fill="#FCFCEC" opacity="1.000000" stroke="none" 
	d="
M828.125305,369.909607 
	C828.820557,369.435059 829.519165,369.342163 830.560913,369.340179 
	C830.495178,370.193085 830.086304,370.955078 829.677368,371.717041 
	C829.161194,371.241791 828.644958,370.766510 828.125305,369.909607 
z"/>
<path fill="#FCFCEC" opacity="1.000000" stroke="none" 
	d="
M836.074585,371.346191 
	C835.497437,371.035187 835.158997,370.744019 834.820557,370.452850 
	C835.106567,370.353943 835.574219,370.099182 835.646606,370.183533 
	C835.935608,370.520386 836.101685,370.962708 836.074585,371.346191 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M894.832703,578.839600 
	C894.335388,578.560852 893.939880,577.983215 893.317871,577.278076 
	C893.672913,573.651001 894.254395,570.151306 894.822266,566.253662 
	C895.491638,562.165222 896.174500,558.474792 896.952881,554.402710 
	C897.460999,554.016785 897.873535,554.012390 898.594971,554.023926 
	C897.910828,561.377747 896.917969,568.715576 895.880249,576.496460 
	C895.535095,577.473267 895.234802,578.007019 894.832703,578.839600 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M893.336060,565.813171 
	C892.754395,567.654724 892.335754,569.027527 891.826294,570.721985 
	C891.244934,570.429810 890.754333,569.815979 890.002441,568.620483 
	C890.436768,563.686829 891.132385,559.334900 891.828003,554.982910 
	C893.143188,546.580383 894.458374,538.177856 895.857788,529.372192 
	C896.326355,528.993408 896.710571,529.017700 897.381226,529.070984 
	C896.278076,541.181458 894.888550,553.262939 893.336060,565.813171 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M891.541809,554.989197 
	C891.132385,559.334900 890.436768,563.686829 889.750977,568.461914 
	C889.587769,569.492737 889.414734,570.100403 889.153076,571.014038 
	C889.024597,568.079041 888.863708,564.833252 888.990234,561.598816 
	C889.066284,559.655457 889.590576,557.729675 890.011963,555.393860 
	C890.492249,554.992981 890.873962,554.994263 891.541809,554.989197 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M73.703148,523.729614 
	C73.382675,526.212158 72.090569,528.651245 72.088501,531.091309 
	C71.989815,647.747131 72.002716,764.403076 71.767181,881.528625 
	C71.531349,881.998291 71.059059,881.990479 70.997772,881.584106 
	C70.833290,880.092468 70.730103,879.007263 70.626907,877.922058 
	C70.663704,844.764832 70.708313,811.607544 70.735413,778.450317 
	C70.772049,733.626526 70.795227,688.802673 70.772705,643.516541 
	C70.789238,626.775146 70.901825,610.496155 70.914497,594.217041 
	C70.932205,571.450562 70.875412,548.684021 70.898193,525.223755 
	C71.011024,524.373779 71.076180,524.217529 71.141327,524.061218 
	C71.872002,523.936340 72.602669,523.811462 73.703148,523.729614 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M70.348633,877.933289 
	C70.730103,879.007263 70.833290,880.092468 70.954758,881.838196 
	C70.983467,882.669983 70.993904,882.841248 70.991302,883.364014 
	C70.964783,884.417908 70.951302,885.120300 70.937820,885.822693 
	C70.212791,885.939453 69.487770,886.056152 68.403900,886.066345 
	C68.016022,885.207153 67.986977,884.454590 67.939461,883.324768 
	C68.002533,881.539246 68.084068,880.130859 68.285385,878.314941 
	C68.960236,877.919800 69.515305,877.932129 70.348633,877.933289 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M96.595055,519.991333 
	C89.841270,521.330322 82.665833,522.633728 74.996117,523.898804 
	C81.725693,522.558960 88.949547,521.257385 96.595055,519.991333 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M116.617447,516.050903 
	C114.606087,517.047546 112.207787,518.005066 109.380508,518.592163 
	C108.928886,517.969604 108.906258,517.717285 108.883621,517.464966 
	C111.332581,516.980530 113.781540,516.496094 116.617447,516.050903 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M108.488380,517.522034 
	C108.906258,517.717285 108.928886,517.969604 108.986359,518.599976 
	C107.274734,518.977295 105.528267,518.976501 103.346176,518.938843 
	C104.638077,518.461060 106.365608,518.020081 108.488380,517.522034 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M124.537308,514.802551 
	C123.421638,515.205444 121.910423,515.559753 119.971741,515.860596 
	C121.076782,515.456055 122.609276,515.105042 124.537308,514.802551 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M128.637268,513.944336 
	C127.885338,514.316162 126.771904,514.640869 125.289383,514.923462 
	C126.038773,514.553284 127.157265,514.225281 128.637268,513.944336 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M135.527908,512.810059 
	C134.794067,513.116821 133.671051,513.378052 132.302109,513.433105 
	C133.083694,513.072815 134.111206,512.918701 135.527908,512.810059 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M139.640900,511.917267 
	C138.892441,512.302124 137.785522,512.637268 136.310944,512.932129 
	C137.056320,512.550415 138.169388,512.208984 139.640900,511.917267 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M102.597961,518.782349 
	C102.398849,519.046265 101.868256,519.264771 101.166359,519.282959 
	C101.418861,518.967468 101.842667,518.852173 102.597961,518.782349 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M98.705925,519.283752 
	C98.686745,519.486816 98.400063,519.667114 97.852486,519.862549 
	C97.873863,519.672180 98.156143,519.466553 98.705925,519.283752 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M70.964180,524.095215 
	C71.076180,524.217529 71.011024,524.373779 70.834991,524.758545 
	C70.652855,524.775391 70.558243,524.567322 70.525688,524.349915 
	C70.516808,524.290710 70.694809,524.203552 70.964180,524.095215 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M146.503418,883.071899 
	C145.495178,883.830017 144.185394,885.188965 142.858124,885.206299 
	C119.902672,885.506042 96.945068,885.640625 73.517792,885.449097 
	C73.050407,884.529785 73.053169,883.972778 73.055939,883.415710 
	C89.747955,883.265808 106.439964,883.114624 123.132004,882.967651 
	C125.116272,882.950195 127.100746,882.956299 130.011032,882.980286 
	C135.613907,882.953918 140.290863,882.898865 144.967834,882.843872 
	C145.376450,882.911987 145.785080,882.980164 146.503418,883.071899 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M148.342010,756.093506 
	C148.667023,772.697266 148.652847,789.417053 148.492676,806.587524 
	C147.903259,806.060181 147.087418,805.087097 147.074905,804.103699 
	C146.968445,795.737427 147.004913,787.369263 147.004883,779.001709 
	C147.004852,772.118408 146.984482,765.234741 147.061172,758.352234 
	C147.069168,757.634583 147.674606,756.923645 148.342010,756.093506 
z"/>
<path fill="#25100D" opacity="1.000000" stroke="none" 
	d="
M148.270508,544.062988 
	C148.658188,546.364868 148.736801,548.757996 148.712616,551.589355 
	C146.374390,549.569397 145.134903,547.029175 148.270508,544.062988 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M144.645294,882.596375 
	C140.290863,882.898865 135.613907,882.953918 130.478455,882.929871 
	C134.787552,882.683472 139.555145,882.516174 144.645294,882.596375 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M72.806358,883.285034 
	C73.053169,883.972778 73.050407,884.529785 73.061508,885.365662 
	C72.602005,885.705872 72.128632,885.767212 71.296539,885.825684 
	C70.951302,885.120300 70.964783,884.417908 71.246506,883.380615 
	C71.683838,883.022766 71.852913,882.999817 72.021980,882.976929 
	C72.200249,883.036072 72.378517,883.095154 72.806358,883.285034 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M72.017342,882.731628 
	C71.852913,882.999817 71.683838,883.022766 71.259552,883.029053 
	C70.993904,882.841248 70.983467,882.669983 71.016037,882.244629 
	C71.059059,881.990479 71.531349,881.998291 71.767670,881.997009 
	C72.003983,881.995728 72.012703,882.486328 72.017342,882.731628 
z"/>
<path fill="#666C6A" opacity="1.000000" stroke="none" 
	d="
M148.084015,889.949341 
	C148.601059,890.669617 148.698410,891.520386 148.655396,892.780640 
	C138.533920,893.119690 128.552811,892.992310 118.571709,892.991577 
	C102.415520,892.990417 86.259346,893.076172 70.103127,893.105164 
	C69.500343,893.106201 68.897194,892.897278 67.812317,892.697510 
	C68.528770,891.241821 69.076073,890.129822 69.100685,890.079834 
	C95.746902,890.079834 121.705605,890.079834 148.084015,889.949341 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M342.360779,893.602173 
	C347.701324,893.325867 353.399139,893.240417 359.545837,893.291260 
	C354.235840,893.549316 348.476898,893.671143 342.360779,893.602173 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M169.062256,893.527893 
	C169.134003,893.356873 169.317780,893.308044 169.773621,893.344849 
	C170.045715,893.430603 169.651733,893.705566 169.651733,893.705566 
	C169.651733,893.705566 169.174271,893.650085 169.062256,893.527893 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M639.738770,433.980042 
	C639.581543,434.488068 639.115173,434.932709 638.325317,435.218781 
	C638.001831,435.060242 638.072937,434.626312 638.137085,434.416138 
	C638.610657,434.109528 639.020081,434.013092 639.738770,433.980042 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M636.735474,436.121338 
	C636.873291,436.368530 636.691711,436.746155 636.247559,437.332214 
	C636.128723,437.110992 636.272400,436.681366 636.735474,436.121338 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M637.795593,435.077698 
	C637.924988,435.351166 637.792786,435.708527 637.330505,436.061615 
	C637.178284,435.758759 637.356140,435.460144 637.795593,435.077698 
z"/>
<path fill="#769098" opacity="1.000000" stroke="none" 
	d="
M642.716125,432.000824 
	C642.280151,432.781677 641.506409,433.513336 640.378418,434.153198 
	C640.808838,433.358124 641.593628,432.654877 642.716125,432.000824 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M640.259644,592.296265 
	C640.484070,591.084961 640.946350,590.181396 641.687378,589.115601 
	C642.192566,589.246399 642.419128,589.539490 642.894592,589.964966 
	C642.261597,590.932922 641.379578,591.768494 640.259644,592.296265 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M635.909058,598.977905 
	C636.599243,598.977661 637.289368,598.977417 638.479370,599.145996 
	C639.274475,599.499573 639.569763,599.684265 639.865112,599.869019 
	C638.232910,602.046082 636.600647,604.223206 634.982300,606.718201 
	C634.996155,607.036133 635.063904,607.001282 634.747803,607.136230 
	C634.289307,607.705322 634.146912,608.139465 633.977234,608.832520 
	C633.985474,609.415405 634.020996,609.739258 634.056519,610.063110 
	C634.056519,610.063110 634.005371,610.009033 633.732544,610.007446 
	C633.313110,610.363037 633.166565,610.720276 633.020020,611.077515 
	C633.020020,611.077515 633.012451,611.008972 632.628906,610.922852 
	C631.108582,610.820190 629.971802,610.803711 628.957397,610.482910 
	C629.656860,608.795349 630.233948,607.412109 631.112915,605.883118 
	C632.253784,604.475891 633.092773,603.214355 633.963867,601.971985 
	C633.995850,601.991150 633.942993,601.937927 634.217346,601.908325 
	C634.641602,601.565674 634.791504,601.252686 634.966553,600.971130 
	C634.991638,601.002625 634.923828,600.958923 635.249390,600.869019 
	C635.702515,600.171875 635.830139,599.564636 635.956116,598.982910 
	C635.954468,599.008423 635.909058,598.977844 635.909058,598.977905 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M630.904541,598.958618 
	C632.173706,598.230103 633.457153,597.528870 634.925476,596.521240 
	C636.514160,594.819519 637.917908,593.424133 639.655396,592.027954 
	C638.702820,594.122314 637.416565,596.217529 636.019653,598.645264 
	C635.909058,598.977844 635.954468,599.008423 635.626465,599.116211 
	C635.173523,599.802307 635.048706,600.380615 634.923828,600.958923 
	C634.923828,600.958923 634.991638,601.002625 634.619080,600.852417 
	C634.145325,601.114136 634.044189,601.526001 633.942993,601.937927 
	C633.942993,601.937927 633.995850,601.991150 633.515137,601.920166 
	C632.094666,602.605957 631.154907,603.362732 630.009277,603.578552 
	C630.190918,601.674011 630.578491,600.310486 630.966003,598.946899 
	C630.966003,598.946899 630.918762,598.986023 630.904541,598.958618 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M634.391357,609.960571 
	C634.020996,609.739258 633.985474,609.415405 634.224548,608.615601 
	C634.687439,607.760254 634.875671,607.380737 635.063904,607.001282 
	C635.063904,607.001282 634.996155,607.036133 635.011414,607.070740 
	C635.456177,606.855530 635.885681,606.605713 636.680542,606.448303 
	C636.272644,607.646484 635.499390,608.752197 634.391357,609.960571 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M640.057007,599.554749 
	C639.569763,599.684265 639.274475,599.499573 638.831543,599.091858 
	C639.205566,598.992737 639.727234,599.116638 640.057007,599.554749 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M639.609131,603.140259 
	C639.883972,603.622498 639.712952,604.281921 639.278931,605.246094 
	C639.065063,604.806396 639.114197,604.061951 639.609131,603.140259 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M642.756714,599.997925 
	C642.956299,600.430298 642.848999,600.873535 642.361450,601.190002 
	C642.137390,600.711731 642.293518,600.360229 642.756714,599.997925 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M640.756226,602.002075 
	C640.958008,602.428650 640.852661,602.872986 640.365540,603.190430 
	C640.138916,602.715637 640.294006,602.367676 640.756226,602.002075 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M641.757446,600.999207 
	C641.958679,601.432983 641.854065,601.884338 641.366943,602.199463 
	C641.140137,601.714417 641.295837,601.365601 641.757446,600.999207 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M642.742371,596.562256 
	C642.294434,596.804688 641.846497,597.047180 641.398560,597.289673 
	C641.312195,596.921814 641.225830,596.553833 641.666748,596.166748 
	C642.376892,596.285889 642.559631,596.424072 642.742371,596.562256 
z"/>
<path fill="#1A2A33" opacity="1.000000" stroke="none" 
	d="
M643.329407,595.491943 
	C643.423340,596.022034 643.263489,596.213013 642.901611,596.465210 
	C642.559631,596.424072 642.376892,596.285889 641.938599,595.917480 
	C641.791687,595.372314 641.972046,595.105957 642.429932,594.758545 
	C642.796692,594.815430 642.957581,595.001953 643.329407,595.491943 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M633.027344,613.688965 
	C632.041138,615.117859 631.059326,616.225220 629.599365,617.257690 
	C628.961426,617.018372 628.801636,616.853943 628.710327,616.309937 
	C628.891663,614.652222 629.004578,613.374023 629.420044,612.152344 
	C630.819641,612.167297 631.916687,612.125610 633.013733,612.083923 
	C633.019714,612.511780 633.025696,612.939575 633.027344,613.688965 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M633.063232,611.824463 
	C631.916687,612.125610 630.819641,612.167297 629.120728,612.122192 
	C628.318970,612.019714 628.119019,612.004089 627.961975,611.720093 
	C628.281555,611.230225 628.558228,611.008728 628.834961,610.787231 
	C629.971802,610.803711 631.108582,610.820190 632.660278,610.937317 
	C633.087646,611.213623 633.100220,611.389282 633.063232,611.824463 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M635.685181,612.642700 
	C635.650208,613.027405 635.329834,613.183716 634.918823,613.104980 
	C635.018677,612.718018 635.209229,612.566162 635.685181,612.642700 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M633.303284,611.037598 
	C633.166565,610.720276 633.313110,610.363037 633.768555,610.016541 
	C633.913818,610.350769 633.750183,610.674255 633.303284,611.037598 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M622.436218,625.460144 
	C622.436218,625.460144 622.376648,625.732178 622.033203,626.082275 
	C621.122009,627.632812 620.554260,628.833130 619.996582,630.018433 
	C620.006592,630.003357 620.033386,629.978882 619.676514,629.851562 
	C619.205627,630.159424 619.091614,630.594543 618.989014,631.017212 
	C619.000366,631.004761 619.026428,630.983337 618.741089,630.804504 
	C618.064514,630.843018 617.673340,631.060364 617.024658,631.038696 
	C616.584656,630.653137 616.402039,630.506531 616.376831,630.065063 
	C618.126038,627.916138 619.717834,626.062012 621.589722,624.522339 
	C622.058594,625.044556 622.247437,625.252319 622.436218,625.460144 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M616.769348,637.588379 
	C615.593872,639.231812 614.418396,640.875183 612.773560,642.440552 
	C612.152588,642.191895 612.001099,642.021240 611.958252,641.551025 
	C612.387207,640.494873 612.707458,639.738098 613.016113,638.992065 
	C613.004517,639.002747 612.989319,639.030273 613.284607,638.965759 
	C614.243408,638.144897 614.906982,637.388550 615.863647,636.879395 
	C616.361084,637.280579 616.565247,637.434509 616.769348,637.588379 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M619.668274,633.572021 
	C618.854614,634.775635 618.040955,635.979248 616.998291,637.385620 
	C616.565247,637.434509 616.361084,637.280579 616.013794,636.604980 
	C616.785339,634.947327 617.699890,633.811218 618.873657,632.904419 
	C619.311279,633.279785 619.489746,633.425903 619.668274,633.572021 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M622.061829,628.997192 
	C621.439392,630.374878 620.816956,631.752563 619.931396,633.351135 
	C619.489746,633.425903 619.311279,633.279785 618.994019,632.632202 
	C618.912292,631.748291 618.969360,631.365784 619.026428,630.983337 
	C619.026428,630.983337 619.000366,631.004761 619.280945,630.996033 
	C619.718811,630.651184 619.876099,630.315002 620.033386,629.978882 
	C620.033386,629.978882 620.006592,630.003357 620.290161,629.961304 
	C621.069824,629.611877 621.565796,629.304565 622.061829,628.997192 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M622.028687,628.999878 
	C621.565796,629.304565 621.069824,629.611877 620.280151,629.976379 
	C620.554260,628.833130 621.122009,627.632812 621.981689,626.211548 
	C622.509827,626.135925 622.696655,626.329041 622.879517,627.038574 
	C622.957031,627.693665 622.989014,627.880066 623.020996,628.066528 
	C623.020996,628.066528 623.003418,628.003540 622.744812,628.030701 
	C622.323608,628.394958 622.161072,628.731995 621.998474,629.069092 
	C621.998474,629.069092 621.995544,629.002625 622.028687,628.999878 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M626.170898,621.162292 
	C625.407410,622.101746 624.643921,623.041199 623.316345,623.900879 
	C622.544617,623.655212 622.336975,623.489319 622.253540,623.026978 
	C623.305298,621.871277 624.232910,621.012207 625.412842,620.405701 
	C625.833740,620.826294 626.002319,620.994263 626.170898,621.162292 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M610.365295,645.995361 
	C610.044739,645.306458 610.034363,644.567932 610.014282,643.414307 
	C610.004639,642.999207 609.985229,643.023560 610.276489,643.020142 
	C610.994995,642.627991 611.422302,642.239258 611.849609,641.850525 
	C612.001099,642.021240 612.152588,642.191895 612.503540,642.642822 
	C612.027161,643.930603 611.351318,644.938171 610.365295,645.995361 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M627.148071,625.125854 
	C626.677673,625.614624 626.207336,626.103333 625.736938,626.592102 
	C625.917114,626.045715 626.097229,625.499329 626.636230,624.967468 
	C626.995056,624.981995 627.148071,625.125854 627.148071,625.125854 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M627.786621,619.531616 
	C627.421204,619.986206 627.055725,620.440857 626.430542,621.028870 
	C626.002319,620.994263 625.833740,620.826294 625.558960,620.180420 
	C625.861938,619.366516 626.271118,619.030518 626.948120,618.915405 
	C627.406189,619.268005 627.596375,619.399780 627.786621,619.531616 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M629.343445,617.445312 
	C629.358826,617.970459 629.152039,618.233032 628.538696,618.356018 
	C627.982422,618.040222 627.832642,617.864136 627.757324,617.451050 
	C628.015442,616.905945 628.285522,616.731079 628.641846,616.689453 
	C628.801636,616.853943 628.961426,617.018372 629.343445,617.445312 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M622.363708,629.226440 
	C622.161072,628.731995 622.323608,628.394958 622.777527,628.030640 
	C622.955505,628.463562 622.842224,628.923645 622.363708,629.226440 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M623.338013,628.107056 
	C622.989014,627.880066 622.957031,627.693665 622.959045,627.225586 
	C623.213745,627.345154 623.434387,627.746399 623.338013,628.107056 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M626.923218,624.905334 
	C627.037048,624.860657 627.222656,624.892639 627.278198,625.025269 
	C627.148071,625.125854 626.995056,624.981995 626.923218,624.905334 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M623.062256,624.071594 
	C623.200684,624.608459 623.029114,624.894714 622.646851,625.320557 
	C622.247437,625.252319 622.058594,625.044556 621.695618,624.348633 
	C621.697449,623.651367 621.900085,623.472351 622.129395,623.323486 
	C622.336975,623.489319 622.544617,623.655212 623.062256,624.071594 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M628.332336,618.499573 
	C628.422974,618.999512 628.273865,619.184143 627.935852,619.434204 
	C627.596375,619.399780 627.406189,619.268005 627.043335,618.684937 
	C627.069031,617.943970 627.339722,617.762146 627.682861,617.688110 
	C627.832642,617.864136 627.982422,618.040222 628.332336,618.499573 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M586.296143,662.228943 
	C586.723206,660.495056 587.463745,658.979675 588.438721,656.984558 
	C587.328369,657.112244 586.553040,657.201355 585.375488,657.149841 
	C585.152527,656.439941 585.331848,655.870667 585.671021,655.037048 
	C586.240234,654.081055 586.649597,653.389404 587.119507,652.396362 
	C590.396790,648.628113 593.613464,645.161316 597.343201,641.761780 
	C598.036560,641.998474 598.216980,642.167908 598.248535,642.641724 
	C596.397034,645.633850 594.694275,648.321472 592.995850,651.004395 
	C593.000183,650.999573 593.008057,650.988159 592.596436,650.914612 
	C592.318604,651.725159 592.452454,652.609192 592.849365,653.784668 
	C593.308838,654.249023 593.505371,654.421875 593.701843,654.594727 
	C593.297302,655.296448 592.892822,655.998230 592.315491,656.949219 
	C590.298279,658.948059 588.453979,660.697815 586.296143,662.228943 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M599.698853,638.668701 
	C601.715698,636.280334 603.732483,633.892029 606.264343,631.617859 
	C606.959412,631.915222 607.139404,632.098328 607.146118,632.605042 
	C606.619202,633.943176 606.265564,634.957764 605.948364,635.978271 
	C605.984802,635.984253 605.950562,635.918762 605.653564,635.674805 
	C603.785767,636.872070 602.214966,638.313354 600.408386,639.482666 
	C600.014587,639.030029 599.856689,638.849365 599.698853,638.668701 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M606.541138,631.429443 
	C608.430298,628.706665 610.557739,626.286560 612.685181,623.866394 
	C613.003784,624.111694 613.322327,624.356995 613.640930,624.602234 
	C612.292847,626.475281 610.944763,628.348267 609.762939,630.596680 
	C609.929199,630.972046 609.975403,630.919800 609.689819,630.717651 
	C608.709290,631.104187 608.014282,631.692810 607.319336,632.281433 
	C607.139404,632.098328 606.959412,631.915222 606.541138,631.429443 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M614.705566,626.694092 
	C616.097534,624.963196 617.489441,623.232300 618.928101,621.201904 
	C618.974854,620.902344 618.911804,620.974976 619.230225,620.897949 
	C620.712036,619.902100 621.875366,618.983337 623.087585,618.412231 
	C620.635437,621.741333 618.134338,624.722778 615.401733,627.451294 
	C615.015320,627.030334 614.860413,626.862244 614.705566,626.694092 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M611.724243,630.078247 
	C611.381531,631.398804 610.755371,632.812073 609.624878,634.174805 
	C608.973755,633.993042 608.827026,633.861816 608.790222,633.436890 
	C609.258545,632.402100 609.617004,631.660950 609.975403,630.919800 
	C609.975403,630.919800 609.929199,630.972046 609.901733,630.950806 
	C610.396423,630.676636 610.918579,630.423706 611.724243,630.078247 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M618.945557,620.864197 
	C618.135132,621.149780 617.354065,621.473450 615.867004,621.637085 
	C615.458740,620.677063 615.756470,619.877136 616.054199,619.077209 
	C616.054199,619.077209 616.014099,619.008301 616.312256,619.089111 
	C616.787903,619.126038 616.965393,619.082153 617.438538,619.038818 
	C618.128235,619.021118 618.522278,619.002930 618.789368,619.349792 
	C618.745605,620.134949 618.828735,620.554932 618.911865,620.974976 
	C618.911804,620.974976 618.974854,620.902344 618.945557,620.864197 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M615.805786,618.814209 
	C615.756470,619.877136 615.458740,620.677063 615.525269,621.731567 
	C615.889404,621.986206 615.986938,622.002075 615.747314,621.616943 
	C615.009949,621.475891 614.512207,621.719910 614.014526,621.963928 
	C614.020325,621.530518 614.026062,621.097168 614.029236,620.338135 
	C614.536926,619.525330 615.047180,619.038208 615.805786,618.814209 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M613.998657,629.953674 
	C613.541260,630.075623 613.083801,630.197510 612.306030,630.163940 
	C612.414490,629.376099 612.843262,628.743713 613.767456,628.158752 
	C614.425903,628.343872 614.588867,628.481567 614.606812,628.831177 
	C614.307373,629.346619 614.153015,629.650146 613.998657,629.953674 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M597.604736,641.555908 
	C597.659973,640.861877 597.966614,640.440979 598.777832,640.114685 
	C599.465576,640.355713 599.648926,640.502136 599.690308,640.890015 
	C599.164795,641.533386 598.781067,641.935364 598.397339,642.337341 
	C598.216980,642.167908 598.036560,641.998474 597.604736,641.555908 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M614.006226,622.364014 
	C614.512207,621.719910 615.009949,621.475891 615.760864,621.569519 
	C615.341980,622.192749 614.669922,622.478394 614.006226,622.364014 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M599.008667,639.987976 
	C598.909912,639.483643 599.084778,639.200623 599.479248,638.793213 
	C599.856689,638.849365 600.014587,639.030029 600.285645,639.642700 
	C600.225830,640.281677 600.036987,640.473022 599.832214,640.648621 
	C599.648926,640.502136 599.465576,640.355713 599.008667,639.987976 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M614.019409,627.998352 
	C613.906372,627.489136 614.087708,627.222351 614.512817,626.842041 
	C614.860413,626.862244 615.015320,627.030334 615.246826,627.596130 
	C615.117371,628.188049 614.926819,628.396484 614.751892,628.619263 
	C614.588867,628.481567 614.425903,628.343872 614.019409,627.998352 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M618.804016,614.816162 
	C619.600342,613.889893 620.396667,612.963562 621.459595,611.885559 
	C621.885315,611.886902 622.044373,612.040039 622.268799,612.672974 
	C621.451294,613.987915 620.568542,614.823059 619.465393,615.447632 
	C619.098022,615.096741 618.951050,614.956482 618.804016,614.816162 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M617.327393,616.464294 
	C617.649231,615.999573 617.971069,615.534912 618.548462,614.943237 
	C618.951050,614.956482 619.098022,615.096741 619.325012,615.685120 
	C618.990173,616.473694 618.575317,616.814148 617.962646,616.969482 
	C617.619019,616.677673 617.473267,616.570984 617.327393,616.464294 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M617.142822,619.038330 
	C616.965393,619.082153 616.787903,619.126038 616.341797,619.116089 
	C615.942993,618.814514 615.773193,618.577454 615.701843,618.313782 
	C615.671875,618.203125 615.850464,618.036011 616.376953,617.849487 
	C616.953003,617.935791 617.086304,618.067261 617.194824,618.408081 
	C617.170044,618.617493 617.142822,619.038330 617.142822,619.038330 
z"/>
<path fill="#383C41" opacity="1.000000" stroke="none" 
	d="
M616.637695,617.590454 
	C616.616760,617.142639 616.799988,616.927612 617.166382,616.597900 
	C617.473267,616.570984 617.619019,616.677673 617.894653,617.212769 
	C617.756165,617.827087 617.487854,618.012878 617.219604,618.198669 
	C617.086304,618.067261 616.953003,617.935791 616.637695,617.590454 
z"/>
<path fill="#DAE7E4" opacity="1.000000" stroke="none" 
	d="
M617.194824,618.408081 
	C617.487854,618.012878 617.756165,617.827087 618.092468,617.397949 
	C618.575317,616.814148 618.990173,616.473694 619.545410,615.895691 
	C620.568542,614.823059 621.451294,613.987915 622.507935,612.902222 
	C624.678650,610.620361 626.675537,608.589111 629.181824,606.319763 
	C630.064453,606.064026 630.437744,606.046387 630.811035,606.028809 
	C630.233948,607.412109 629.656860,608.795349 628.957397,610.482910 
	C628.558228,611.008728 628.281555,611.230225 627.669312,611.815002 
	C626.590637,613.124329 625.847595,614.070312 624.757874,615.102295 
	C622.579590,616.453735 620.747925,617.719238 618.916260,618.984680 
	C618.522278,619.002930 618.128235,619.021118 617.438538,619.038818 
	C617.142822,619.038330 617.170044,618.617493 617.194824,618.408081 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M630.713623,598.880371 
	C630.578491,600.310486 630.190918,601.674011 630.031982,603.561279 
	C630.260620,604.084961 630.192566,604.129517 630.192566,604.129517 
	C628.899963,604.805908 627.607361,605.482361 625.721619,606.175049 
	C624.950989,606.002319 624.773499,605.813416 624.596069,605.624573 
	C624.765137,605.330444 624.934143,605.036377 625.205872,604.508606 
	C625.621277,604.148254 625.860352,603.937988 626.112915,603.417725 
	C626.601135,602.836487 627.002319,602.481445 627.561157,601.905029 
	C628.396973,600.971313 629.075134,600.258911 629.863281,599.272583 
	C630.135925,598.937073 630.298645,598.875427 630.713623,598.880371 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M629.915771,604.614258 
	C630.192566,604.129517 630.260620,604.084961 630.237915,604.102234 
	C631.154907,603.362732 632.094666,602.605957 633.483154,601.901001 
	C633.092773,603.214355 632.253784,604.475891 631.112915,605.883057 
	C630.437744,606.046387 630.064453,606.064026 629.410645,606.087158 
	C629.299744,605.761475 629.469360,605.430237 629.915771,604.614258 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M625.366089,606.499023 
	C625.015259,607.216980 624.426880,607.627258 623.838440,608.037537 
	C623.896301,607.398926 623.954163,606.760376 624.304077,605.873169 
	C624.773499,605.813416 624.950989,606.002319 625.366089,606.499023 
z"/>
<path fill="#575F62" opacity="1.000000" stroke="none" 
	d="
M625.104492,615.016357 
	C625.847595,614.070312 626.590637,613.124329 627.626404,612.083374 
	C628.119019,612.004089 628.318970,612.019714 628.818237,612.065552 
	C629.004578,613.374023 628.891663,614.652222 628.710327,616.309937 
	C628.285522,616.731079 628.015442,616.905945 627.757324,617.451050 
	C627.339722,617.762146 627.069031,617.943970 626.775574,618.464111 
	C626.271118,619.030518 625.861938,619.366516 625.306641,619.927795 
	C624.232910,621.012207 623.305298,621.871277 622.253540,623.026978 
	C621.900085,623.472351 621.697449,623.651367 621.415527,624.034180 
	C619.717834,626.062012 618.126038,627.916138 616.181763,630.191406 
	C615.604675,630.834839 615.345703,631.004700 615.052429,631.122131 
	C614.847046,630.835144 614.641724,630.548096 614.217529,630.107422 
	C614.153015,629.650146 614.307373,629.346619 614.606812,628.831177 
	C614.926819,628.396484 615.117371,628.188049 615.478394,627.848999 
	C618.134338,624.722778 620.635437,621.741333 623.075317,618.397217 
	C623.014160,618.034485 623.031128,618.027649 623.352173,618.030762 
	C623.903320,617.682983 624.133484,617.332092 624.619141,616.935181 
	C624.951294,616.264954 625.027893,615.640625 625.104492,615.016357 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M606.631714,638.566650 
	C604.149963,641.825195 601.668213,645.083740 598.671692,648.267822 
	C597.963379,648.006958 597.769775,647.820557 597.711731,647.324585 
	C600.477600,643.936646 603.107971,640.858215 605.957214,637.981628 
	C606.328003,638.311218 606.479858,638.438965 606.631714,638.566650 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M610.017212,642.989868 
	C609.231750,643.159668 608.433716,643.338806 607.296936,643.243469 
	C608.899780,640.176758 610.841492,637.384460 613.259521,634.652222 
	C613.896790,634.879456 614.057556,635.046753 614.065918,635.547119 
	C613.605347,636.930176 613.297363,637.980225 612.989380,639.030273 
	C612.989319,639.030273 613.004517,639.002747 612.666382,638.826355 
	C611.547241,640.107849 610.766235,641.565674 609.985229,643.023560 
	C609.985229,643.023560 610.004639,642.999207 610.017212,642.989868 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M597.842651,650.525757 
	C597.232239,651.317383 596.701721,651.842407 596.048035,652.621521 
	C595.341553,653.357849 594.758301,653.840088 593.938477,654.458496 
	C593.505371,654.421875 593.308838,654.249023 593.002808,653.468140 
	C592.931519,652.236206 592.969788,651.612183 593.008057,650.988159 
	C593.008057,650.988159 593.000183,650.999573 593.308899,651.166016 
	C594.937134,650.099731 596.256653,648.866943 597.576233,647.634155 
	C597.769775,647.820557 597.963379,648.006958 598.426147,648.495972 
	C598.437805,649.285583 598.180176,649.772400 597.842651,650.525757 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M606.533203,646.009521 
	C605.736023,646.912231 605.148254,647.479187 604.253784,648.014893 
	C604.718994,646.370605 605.491028,644.757507 606.611511,643.055542 
	C606.887573,643.869019 606.815125,644.771362 606.533203,646.009521 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M614.955811,631.407715 
	C615.345703,631.004700 615.604675,630.834839 616.024414,630.486206 
	C616.402039,630.506531 616.584656,630.653137 616.893799,631.323975 
	C616.086426,632.970154 615.152405,634.092102 614.218384,635.214111 
	C614.057556,635.046753 613.896790,634.879456 613.518005,634.437622 
	C613.819763,633.339783 614.339478,632.516541 614.955811,631.407715 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M609.316589,634.344971 
	C608.873413,635.614136 608.234131,636.662659 607.230957,637.658081 
	C606.601013,637.419800 606.334961,637.234558 606.068420,636.763672 
	C606.028748,636.291565 605.989624,636.105164 605.950562,635.918762 
	C605.950562,635.918762 605.984802,635.984253 606.250244,635.928223 
	C607.237244,635.158325 607.958801,634.444458 608.680359,633.730591 
	C608.827026,633.861816 608.973755,633.993042 609.316589,634.344971 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M606.068909,637.049377 
	C606.334961,637.234558 606.601013,637.419800 606.967041,637.834778 
	C607.066956,638.064514 606.885925,638.348267 606.758789,638.457458 
	C606.479858,638.438965 606.328003,638.311218 606.007690,637.784424 
	C605.839233,637.385498 606.068909,637.049377 606.068909,637.049377 
z"/>
<path fill="#E3F1F2" opacity="1.000000" stroke="none" 
	d="
M580.809998,664.405762 
	C580.318359,665.120056 579.975037,665.526611 579.484863,666.157349 
	C578.849243,666.975342 578.360596,667.569275 577.756348,668.428284 
	C576.630981,669.772278 575.621216,670.851196 574.294800,671.975830 
	C577.428162,667.044189 580.878235,662.066772 584.665771,657.030518 
	C583.654846,659.347107 582.306580,661.722534 580.809998,664.405762 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M803.022827,362.023987 
	C803.088745,362.369629 803.154724,362.715302 803.235718,363.329712 
	C801.314819,363.453064 799.379028,363.307678 797.443176,363.162292 
	C797.441223,362.917877 797.439270,362.673462 797.437317,362.429016 
	C798.308289,362.103333 799.179260,361.777649 800.280823,361.162231 
	C800.511353,360.872498 800.481384,360.928589 800.793091,361.063110 
	C801.744080,361.473083 802.383423,361.748535 803.022827,362.023987 
z"/>
<path fill="#161617" opacity="1.000000" stroke="none" 
	d="
M803.424866,361.942139 
	C802.383423,361.748535 801.744080,361.473083 800.773254,361.087982 
	C800.441772,360.978333 800.232239,360.554962 800.666260,360.256775 
	C801.388306,359.690430 801.676453,359.422302 801.964600,359.154175 
	C806.348816,359.469482 810.732971,359.784790 815.541626,360.100616 
	C811.919739,360.687531 807.873352,361.273956 803.424866,361.942139 
z"/>
<path fill="#160C0B" opacity="1.000000" stroke="none" 
	d="
M801.566406,359.106110 
	C801.676453,359.422302 801.388306,359.690430 800.823242,360.080322 
	C800.753540,359.820709 800.960876,359.439362 801.566406,359.106110 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M635.249390,600.869019 
	C635.048706,600.380615 635.173523,599.802307 635.628052,599.090698 
	C635.830139,599.564636 635.702515,600.171875 635.249390,600.869019 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M634.217346,601.908325 
	C634.044189,601.526001 634.145325,601.114136 634.593994,600.820923 
	C634.791504,601.252686 634.641602,601.565674 634.217346,601.908325 
z"/>
<path fill="#94ACB1" opacity="1.000000" stroke="none" 
	d="
M634.747803,607.136230 
	C634.875671,607.380737 634.687439,607.760254 634.251892,608.356628 
	C634.146912,608.139465 634.289307,607.705322 634.747803,607.136230 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M614.065918,635.547119 
	C615.152405,634.092102 616.086426,632.970154 617.151245,631.562927 
	C617.673340,631.060364 618.064514,630.843018 618.741089,630.804504 
	C618.969360,631.365784 618.912292,631.748291 618.734863,632.402954 
	C617.699890,633.811218 616.785339,634.947327 615.720581,636.357788 
	C614.906982,637.388550 614.243408,638.144897 613.284668,638.965759 
	C613.297363,637.980225 613.605347,636.930176 614.065918,635.547119 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M619.676514,629.851562 
	C619.876099,630.315002 619.718811,630.651184 619.269531,631.008484 
	C619.091614,630.594543 619.205627,630.159424 619.676514,629.851562 
z"/>
<path fill="#6C808C" opacity="1.000000" stroke="none" 
	d="
M610.276489,643.020142 
	C610.766235,641.565674 611.547241,640.107849 612.677979,638.815674 
	C612.707458,639.738098 612.387207,640.494873 611.958252,641.551025 
	C611.422302,642.239258 610.994995,642.627991 610.276489,643.020142 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M606.068420,636.763672 
	C606.068909,637.049377 605.839233,637.385498 605.788818,637.582642 
	C603.107971,640.858215 600.477600,643.936646 597.711731,647.324585 
	C596.256653,648.866943 594.937134,650.099731 593.304565,651.170837 
	C594.694275,648.321472 596.397034,645.633850 598.248535,642.641724 
	C598.781067,641.935364 599.164795,641.533386 599.690308,640.890015 
	C600.036987,640.473022 600.225830,640.281677 600.521484,639.914673 
	C602.214966,638.313354 603.785767,636.872070 605.653564,635.674805 
	C605.989624,636.105164 606.028748,636.291565 606.068420,636.763672 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M592.596436,650.914612 
	C592.969788,651.612183 592.931519,652.236206 592.739746,653.176758 
	C592.452454,652.609192 592.318604,651.725159 592.596436,650.914612 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M608.790222,633.436890 
	C607.958801,634.444458 607.237244,635.158325 606.213806,635.922241 
	C606.265564,634.957764 606.619202,633.943176 607.146118,632.605042 
	C608.014282,631.692810 608.709290,631.104187 609.689819,630.717651 
	C609.617004,631.660950 609.258545,632.402100 608.790222,633.436890 
z"/>
<path fill="#5A707F" opacity="1.000000" stroke="none" 
	d="
M624.757935,615.102295 
	C625.027893,615.640625 624.951294,616.264954 624.281006,616.930603 
	C623.468628,617.323914 623.249878,617.675781 623.031128,618.027649 
	C623.031128,618.027649 623.014160,618.034485 623.026428,618.049561 
	C621.875366,618.983337 620.712036,619.902100 619.230286,620.897949 
	C618.828735,620.554932 618.745605,620.134949 618.789368,619.349792 
	C620.747925,617.719238 622.579590,616.453735 624.757935,615.102295 
z"/>
<path fill="#A8BEC0" opacity="1.000000" stroke="none" 
	d="
M623.352173,618.030762 
	C623.249878,617.675781 623.468628,617.323914 624.025513,616.976562 
	C624.133484,617.332092 623.903320,617.682983 623.352173,618.030762 
z"/>
</svg>
    );
  }
}

export default FeelingProud;
